<template>
  <div class="resources-page page">
    <div class="page-content">
      <h1>Our many resources for you</h1>

      <h2>Documentation >
        <span class="span-link " v-on:click="toggleDocumentationDisplay()">Explore All</span>
      </h2>

      <div v-bind:class="documentationDisplayCss + '-container' " >
        <div id="documentation-wrapper" v-bind:class="documentationDisplayCss + '-wrapper'" >
          <div v-bind:class="documentationDisplayCss + '-slide'"
            v-for="(file, index) in files" :key="index"
            v-bind:style="{ 'background-image': 'url(/static/files/' + file.thumbnail + ')' }"
            v-on:click="openFile(file.filename)"
            >
            <div class="description">
                <span class="img-title">{{file.description}}</span>
            </div>
          </div>
        </div>
        <div v-show="!exploreDocumentation">
          <div class="swiper-button-prev">
            <i class="far fa-chevron-left"></i>
          </div>
          <div class="swiper-button-next">
            <i class="far fa-chevron-right"></i>
          </div>
        </div>
      </div>

      <h2>Support Pages</h2>
      <div v-bind:class="documentationDisplayCss + '-container' " >
        <div id="support-wrapper" v-bind:class="documentationDisplayCss + '-wrapper'" >
          <div v-bind:class="documentationDisplayCss + '-slide'"
            v-for="supportPage in supportPages" :key="supportPage.id"
            v-bind:style="{ 'background-image': 'url(/static/images/thumbnails/' + supportPage.thumbnail + ')',
                            'background-color': 'lightskyblue',
                            'text-align': 'center' }"
            @click="goToSupportPage(supportPage.id)"
            >
            <!-- <span :style="{'font-size': '16px' }">{{supportPage.description}}</span> -->
            <!-- <router-link :to="'/support/' + supportPage.id">{{supportPage.description}} </router-link> -->
            <div class="description">
                <span class="img-title">{{supportPage.description}}</span>
            </div>
          </div>
        <!--
        <div class="support-pages">
          <div class="button" v-for="supportPage in supportPages" :key="supportPage.id">
            <router-link :to="'/support/' + supportPage.id">{{supportPage.description}} </router-link>
          </div>
        </div> -->
        </div>
         <div v-show="!exploreDocumentation">
          <div class="swiper-button-prev">
            <i class="far fa-chevron-left"></i>
          </div>
          <div class="swiper-button-next">
            <i class="far fa-chevron-right"></i>
          </div>
        </div>
      </div>

      <h2>Videos</h2>
      <div v-bind:class="documentationDisplayCss + '-container' " >
        <div id="video-wrapper" v-bind:class="documentationDisplayCss + '-wrapper'" >

          <div id="how-to-videos" class="how-to-videos tiles">
            <div class="tile" v-for="video in videos" :key="video.id" v-bind:class="documentationDisplayCss + '-slide'"  v-on:click="showVideo(video)">
              <img v-bind:src="thumbnailFolder + video.thumbnail" v-bind:alt="video.description" >
              <span class="img-title">{{ video.description }}</span>
              <img class="play-video-button" src="/static/images/icons/play-video.png" alt="Play video"  />
            </div>
            <div v-show="!exploreDocumentation">
              <div class="swiper-button-prev">
                <i class="far fa-chevron-left"></i>
              </div>
              <div class="swiper-button-next">
                <i class="far fa-chevron-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <video-popup-window class="draggable" v-bind:videoId="videoId" v-bind:videoTitle="videoTitle"  v-show="showVideoScreen" @close="showVideoScreen=false;"></video-popup-window>

    </div> <!-- end of page-content -->
  </div>
</template>

<script>
import VideoPopupWindow from '@/components/VideoPopupWindow';

var thisPage;

//import videoUtils from "@/utils/VideoUtils.js";
var slideWidthPx = 200;

export default {
  name: 'ResourcesPage',
  data () {
    return {
      exploreDocumentation: false,
      documentationDisplayCss: 'swiper',

      swiper: null,
      swiperOptions: {
        // http://idangero.us/swiper/api/
        // css added in styles.scss
        speed: 1500, // transition ms
        spaceBetween: 5, // px
        slidesPerView: this.calcNumSlidesPerView(slideWidthPx),
        // effect: 'cube',
        freeMode: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        on: {
          resize: function(){
            // redraw
            this.params.slidesPerView = thisPage.calcNumSlidesPerView(slideWidthPx),
            this.update();
          }
        },

      },

      thumbnailFolder: '/static/images/thumbnails/',
      videos: [
        {id: 'uBoxNsRvAt8', thumbnail: 'replacing-carthe-battery.jpg',        description: 'How to Replace a CARTHE Battery', },
        {id: 'lFyUHkptDqk', thumbnail: 'reef-drifter-instructions.jpg',       description: 'Reef Drifter with Internal Flash Memory', },
        // {id: '6yW7C7w5YNQ', thumbnail: 'web-interface-1.jpg',                 description: 'Web Browser Data Interface', },
        {id: 'aTyVFhTRIdM', thumbnail: 'replacing-reef-drifter-battery.jpg',  description: 'How to Replace Reef Drifter Battery', },
        {id: 'OL6TmJhx91M', thumbnail: 'carthe-drifter-assembly.jpg',         description: 'CARTHE Drifter Assembly and Test', },
      ],
      videoId: null,
      videoTitle: null,
      showVideoScreen: false,

      supportPages: [
        { id:'awi-ice-tether',  thumbnail: 'awi-ice-tether.png',   description: 'awi-ice-tether ' },
        { id:'axibelectronics', thumbnail: 'axib-electronics.jpg',  description: 'axibelectronics ' },
        { id:'carthe',          thumbnail: 'carthe.jpg',           description: 'carthe ' },
        { id:'globeacon',       thumbnail: 'globeacon.jpg',        description: 'globeacon ' },
        { id:'iceberg-tracker', thumbnail: 'iceberg-tracker.jpg',   description: 'iceberg-tracker ' },
        { id:'microstar',       thumbnail: 'microstar.jpg',        description: 'microstar ' },
        { id:'microtracker',    thumbnail: 'universal-tracker.jpg', description: 'microtracker ' },
        { id:'ReefDrifter',     thumbnail: 'reef-drifter.jpg',      description: 'ReefDrifter ' },
        { id:'SatDrive',        thumbnail: 'satdrive.jpg',         description: 'SatDrive ' },
        { id:'sidekick',        thumbnail: 'sidekick.jpg',         description: 'sidekick ' },
        { id:'Survival',        thumbnail: 'survival.jpg',         description: 'Survival ' },
        { id:'svp',             thumbnail: 'svp.jpg',              description: 'svp ' },
      ],

      files: [
        { filename: 'globeacon_datasheet.pdf',   thumbnail: 'globeacon-datasheet.png',  description: 'Globeacon Data Sheet', },
        { filename: 'icetracker_datasheet.pdf',  thumbnail: 'icetracker-datasheet.png', description: 'Ice Tracker Data Sheet', },
        { filename: 'microstar_datasheet.pdf',   thumbnail: 'microstar-datasheet.png',  description: 'Microstar Data Sheet', },
        { filename: 'svp_datasheet.pdf',         thumbnail: 'svp-datasheet.png',        description: 'SVP Data Sheet', },
      ],

    };
  },
  computed: {
  },
  methods: {
    toggleDocumentationDisplay() {
      this.exploreDocumentation = !this.exploreDocumentation;
      this.documentationDisplayCss = this.exploreDocumentation ? 'pg-grid' : 'swiper';
      $('#documentation-wrapper').removeAttr('style');
      this.swiper.allowTouchMove = !this.exploreDocumentation;

    },
    openFile(filename) {
      var fullpath = '/static/files/' + filename;
      console.log('open file ', fullpath);
      window.open(fullpath);
    },
    goToSupportPage(id) {
      var pageLink = '/support/' + id;
      this.$router.push({path: pageLink })
    },
    calcNumSlidesPerView(slideWidth) {
      var numSlides = Math.floor($(window).width() / slideWidth) || 1;
      console.warn('Whoa!  only ', numSlides, ' slides!');
      return numSlides;
    },
    showVideo(video) {
      // console.log('show vid ', video.id, ' ', video.description);
      this.videoId = video.id;
      this.videoTitle = video.description;
      this.showVideoScreen = true;
      $(".draggable").draggable({
        handle: ".popup-header",
        opacity: 0.5,
        containment: "document"
      });
      $(".resizeable-bottom-right").resizable();
    },

  },
  created: function(){
    thisPage = this;
  },
  // beforeMount: function() {
  //   this.videoInfo = {
  //     thumbnail: 'replacing-carthe-battery.jpg',
  //     id: 'uBoxNsRvAt8',
  //     description: 'how to replace a carthe battery',
  //   }
  // },
  mounted: function() {
    this.swiper = new Swiper ('.swiper-container', this.swiperOptions);
  },
  components: {
    VideoPopupWindow,
    //swiperSlide,
  }
}
</script>

/************************************************/

<!-- Add "scoped" attribute to limit CSS to this component only -->


<style lang="scss">
  #resources-body .app-content {
    overflow: auto;
  }

  .resources-page {
    h1 {
      margin-bottom: -0.8em;
    }
    h2 {
      margin-top: 2em;
    }
    .span-link:hover {
      text-decoration: underline;
    }
    .pg-grid-wrapper {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      grid-auto-rows: 21em;
      grid-gap: 5px;
      width: 100%;
    }
  }

</style>


<style scoped lang="scss">

  .page-content {
    padding: 10px;
  }

  // VIDEOS
  #pg-video-component {
      position: absolute;
  }

.pg-grid-container,
  .swiper-container {
    display: inline-table;
    max-width: 100%;
    width: 100vw;
    .pg-grid-slide,
    .swiper-slide {
      cursor: pointer;
      position: relative;
      box-shadow: 0px 0px 3px 0px black;
      height: 20em;
      background-size: cover;
      border: 1px solid white;
      .description {
        background-color: rgba(1, 1, 1, .5);
        text-align: center;
        color: white;
        height: 3em;
        padding: 1em;
        bottom: 0;
        position: absolute;
        left: 0;
        right: 0;
        visibility: hidden;
      }
    }
    .pg-grid-slide:hover,
    .swiper-slide:hover {
      // padding: 0;
      border: 1px solid gray;
    }
    .pg-grid-slide:hover .description,
    .swiper-slide:hover .description {
      visibility: unset !important;
    }
    .swiper-button-disabled {
      opacity: 0.15;
    }
    .swiper-button-prev {
      left: 0;
    }
    .swiper-button-next {
      right: 0;
    }
    .swiper-button-prev,
    .swiper-button-next {
      top: 0;
      bottom: 0;
      margin: 0;
      padding: 0;
      height: auto;
      width: 50px;
      display: flex;
      align-items: center;
      justify-items: center;

      background-image: none;
      font-size: 5em;
      color: white;
      background-color: rgba(1, 1, 1, .5);
      font-weight: 900;
    }
    .swiper-button-prev:hover,
    .swiper-button-next:hover {
      font-size: 6em;
    }
    .swiper-button-prev i,
    .swiper-button-next i {
      margin: 0 auto;
    }

  }


  .tile {
    cursor: pointer;
    width: 200px;
    display: inline-grid;
    padding: 0; /* 10px */
    background-color: #eee;
    height: 182px;
    text-align: center;
    // margin: 10px; /* 10px */
    margin-right: 5px;
    border: 1px solid #aaa;
    a {
      text-decoration: none;
    }
    img {
      max-width: 100%;
      max-height: 140px;
      display: block;
      margin: 0 auto;
    }
    .img-title {
      display: block;
      padding-top: 7px;
    }
  }

  .support-pages > div {
    display: inline-block;
    padding: 5px;
    font-size: 1.4em;
  }

  .tile img.play-video-button {
    position: absolute;
    width: 50px;
    top: 50%;
    left: 50%;
    margin-top: -47px;
    margin-left: -25px;
  }


</style>
