<template >
  <div class="sensor-section">
    <div v-for="(item, index) in selectedSensors" v-bind:key="index" class="sensor-row">
      <pg-select
        id="selectedSensor"
        :options="sensorList"
        v-model="item.sensor"
        label="sensorName"
        track-by="sensorId"
        @input="onSelect(item.sensor, index)"
        @touched="touched"
        :disabled="disabled"
      ></pg-select>
      <span
        class="side-icon"
        v-if="showAddButton(index)"
        :class="{ disabled : formDisabled || disableAddButton(index) }"
        title="Add Additional Sensor"
        @click="addDropdown"
      >
        <i class="far fa-plus-circle fa-lg" />
      </span>
      <span
        class="side-icon"
        v-if="showRemoveButton(index)"
        :class="{ disabled : formDisabled }"
        @click="removeDropdown(item.sensor, index)"
        title="Remove Sensor"
      >
        <i class="far fa-times-circle fa-lg" />
      </span>
    </div>
  </div>
</template>

/************************************************************/

// maybe add title attr after loading?

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import PgSelect from "@/components/Common/PgSelect.vue";

// var props = ["sensors"];
var props = ["sensorPackages", "formDisabled"];

var data = function() {
  return {
    selectedSensors: [],
    sensor: {},
    disabled: false
    // localSensorPackages: [],
    // sensorPackageIds: [],
  };
};

var validations = {
  selectedSensors: {},
  sensor: {}
};

var mounted = function() {
  this.getSensors();
  this.addDropdown();
};

var computed = {
  ...mapGetters("sensorModule", ["sensorList"])
};

var methods = {
  ...mapActions("sensorModule", ["getSensors"]),

  addDropdown() {
    this.selectedSensors.push({
      sensor: null
    });
  },

  // Somethings going on with this
  setExistingSensors(value) {
    this.selectedSensors.length = 0;
    if (value === null || value === undefined || value.length === 0) {
      this.sensorList.forEach(sensor => (sensor.$isDisabled = false));
      this.addDropdown();
      return;
    }
    if (value.length > 0) {
      for (let x = 0; x < value.length; x++) {
        var pkg = this.sensorList.find(sensor => sensor.sensorId === value[x].sensorId);
        this.sensorList[pkg.sensorListIndex].$isDisabled = true;
        this.selectedSensors.push({ sensor: pkg });
      }
    }
  },

  showAddButton(index) {
    return index + 1 === this.selectedSensors.length ? true : false;
  },

  showRemoveButton(index) {
    return index + 1 === this.selectedSensors.length ? false : true;
  },

  disableAddButton(index) {
    return this.selectedSensors[index].sensor === null || this.selectedSensors[index].sensor === undefined
      ? true
      : false;
  },

  onSelect(sensor, index) {
    if (sensor === null) {
      this.removeDropdown(sensor, index);
    }

    this.sensorList.forEach(function(sensor) {
      sensor.$isDisabled = false;
    });

    for (var x = 0; x < this.selectedSensors.length; x++) {
      if (this.selectedSensors[x].sensor !== null) {
        var tempId = this.selectedSensors[x].sensor.sensorId;
        var pkg = this.sensorList.find(sensor => sensor.sensorId === tempId);
        this.sensorList[pkg.sensorListIndex].$isDisabled = true;
      }
    }
    this.updateSensorPackages();
  },

  removeDropdown(sensor, index) {
    // if value has been added then removed by backspace
    if (sensor === null) {
      if (this.selectedSensors.length > 1) {
        this.selectedSensors.splice(index, 1);
      }
    } else {
      let temp = this.sensorList.find(item => item.sensorId === sensor.sensorId);
      this.sensorList[temp.sensorListIndex].$isDisabled = false;
      this.selectedSensors.splice(index, 1);
    }
    this.updateSensorPackages();
  },

  touched(id) {
    // console.log('Touched from sensor section', id)
    // this.$emit("touched", id);
  },

  updateSensorPackages() {
    var sensorIds = [];
    for (let x = 0; x < this.selectedSensors.length; x++) {
      console.log("fix pushing null when removing sensor dropdown");
      sensorIds.push(this.selectedSensors[x].sensor.sensorId);
    }
    // console.log(sensorIds);
    this.$emit("onUpdate", sensorIds);
  }
};

var watch = {
  selectedSensors: function(val) {
    // this.updateSensorPackageIds();
  },

  sensorPackages: function(value) {
    this.setExistingSensors(value);
    // console.log('sensorpackages', value);
  }
};

var components = {
  Multiselect,
  PgSelect
};

export default {
  data,
  validations,
  mounted,
  props,
  computed,
  components,
  methods,
  watch
};
</script>

<style scoped>
.side-icon {
  padding-left: 5px;
}

/* .disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.6;
} */
</style>

