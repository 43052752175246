import axios from 'axios';
import environmentSpecific from 'static/environmentSpecific';
import { resolve } from 'path';
import moment from 'moment';

const state = {
  deviceData: [], // info about a specific device
  deviceList: [], // all devices
  deviceName: 'no device name set',
  deviceId: null,
  selectedDeviceIds: [],
  deviceActivations: [],
  allFieldsSelectedDevices: [], // dataFields for the selected device IDs  {dataFieldId, dataFieldName, dataTypeId, displayName, isExtendedData, sortOrder, unitId, unitAbbrev}
  // device: {},
  serverError: null,
  selectedFieldNames: [], // Array of names
  //allDevicesSelected: null,
  selectedDevicesWithCommands: [],
  highlightedDeviceId: null,
};

function log(...msg) {
  //console.warn(msg);
}
function forceArray(values) {
  let returnValue = [];
  if (Array.isArray(values)) {
    returnValue = values;
  } else if (typeof values === 'string') {
    returnValue = values.split(',');
  } else if (typeof values === 'number') {
    returnValue = [values];
  }
  return returnValue;
}

const mutations = {
  setHighlightedDeviceIdState(state, deviceId) {
    state.highlightedDeviceId = deviceId;
  },
  setDeviceDataState(state, deviceData) {
    state.deviceData = Array.isArray(deviceData) ? deviceData : [];
  },
  setDeviceListState(state, deviceList) {
    state.deviceList = Array.isArray(deviceList) ? deviceList : [];
  },
  setDeviceNameState(state, deviceName) {
    state.deviceName = deviceName;
  },
  setDeviceIdState(state, deviceId) {
    state.deviceId = deviceId;
  },
  setSelectedDeviceIdsState(state, selectedDeviceIds) {
    // console.log('setSelectedDeviceIdsState()', selectedDeviceIds);
    state.selectedDeviceIds = forceArray(selectedDeviceIds);
    if (!state.selectedDeviceIds.includes(state.highlightedDeviceId)) {
      // no sense highlighting something that isn't there
      state.highlightedDeviceId = null;
    }
  },
  setDeviceActivationsState(state, deviceActivations) {
    state.deviceActivations = Array.isArray(deviceActivations) ? deviceActivations : [];
  },
  // setDeviceByDeviceIdState(state, device) {
  //   state.device = device;
  // },
  setAllFieldsSelectedDevices(state, allFieldsSelectedDevices) {
    // console.log('>>>> setAllFieldsSelectedDevices', allFieldsSelectedDevices);
    state.allFieldsSelectedDevices = allFieldsSelectedDevices;
  },
  setSelectedFieldsState(state, selectedFieldNames) {
    state.selectedFieldNames = selectedFieldNames;
  },
  setSelectedDevicesWithCommands(state, selectedDevicesWithCommands) {
    state.selectedDevicesWithCommands = selectedDevicesWithCommands;
  },
};

const actions = {
  setHighlightedDeviceId({ state, commit }, deviceId) {
    commit('setHighlightedDeviceIdState', deviceId);
    // console.log('>> highlighted deviceID is ', state.highlightedDeviceId)
  },

  getDeviceData({ commit }, { deviceId, startDateTime, endDateTime }) {
    // log('>>> getDeviceData for did ', deviceId, ' from ', startDateTime, ' to ', endDateTime);
    commit('setDeviceIdState', deviceId);
    var fullUrl = `${environmentSpecific.apiBaseUrl}data/search?deviceId=${deviceId}&getExtendedData=true`;

    if (startDateTime) {
      fullUrl += '&startDateTime=' + startDateTime;
    }
    if (endDateTime) {
      fullUrl += '&endDateTime=' + endDateTime;
    }
    // log('>>> API call ', fullUrl);
    // log('>>> StartDateTime ', startDateTime);
    // log('>>> EndDateTime ', endDateTime);
    axios
      .get(fullUrl)
      .then(response => {
        commit('setDeviceDataState', response.data.data);
      })
      .catch(error => {
        console.log('DeviceStore getDeviceData() caught error', error);
        reject(error);
      });
  },

  getDevices({ commit }, searchCriteria) {
    // var example = {
    //   orgId: 0,
    //   deviceIds: 'string',
    //   deviceTypeId: 0,
    //   sensorId: 0,
    //   getActiveOnly: false,
    //   getSensorPackages: true,
    //   getCommands: true,
    //   getBillingPlans: true,
    //   maxDaysSinceTransmit: 0,
    //   mobileApp: true,
    //   skipRecords: 0,
    //   takeRecords: 0,
    // };

    var fullUrl = `${environmentSpecific.apiBaseUrl}devices/search`;
    return new Promise((resolve, reject) => {
      axios
        .post(fullUrl, searchCriteria)
        .then(response => {
          commit('setDeviceListState', response.data.data);
          resolve(response.data.data);
        })
        .catch(error => {
          console.log('DeviceStore getDevices() caught error', error);
          reject(error);
        });
    });
  },

  getDevicesActiveButNotTransmitting({ commit }, searchCriteria) {
    var fullUrl = `${environmentSpecific.apiBaseUrl}devices/searchDevicesActiveNotTransmitting`;
    return new Promise((resolve, reject) => {
      axios
        .post(fullUrl, searchCriteria)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(error => {
          console.log('DeviceStore getDevicesActiveButNotTransmitting() caught error', error);
          reject(error);
        });
    });
  },

  getAllFieldsForSelectedDevices({ state, commit }) {
    // console.trace('device store')
    // only return list of fields if devicelist is provided
    var deviceIdListCsv = Array.isArray(state.selectedDeviceIds) ? state.selectedDeviceIds.join(',') : '';
    var fullUrl = `${environmentSpecific.apiBaseUrl}dataFields/search`;
    return new Promise((resolve, reject) => {
      if (deviceIdListCsv === '') {
        commit('setAllFieldsSelectedDevices', []);
        resolve([]);
      } else {
        axios
          .post(fullUrl, {
            deviceIdList: deviceIdListCsv,
          })
          .then(response => {
            commit('setAllFieldsSelectedDevices', response.data.data);
            resolve(response.data.data);
          });
      }
    });
  },

  setDeviceName({ commit }, deviceName) {
    commit('setDeviceNameState', deviceName);
  },

  setDeviceId({ state, commit, dispatch, rootState }, deviceId) {
    log('>> setDeviceID setting deviceId to', deviceId);
    commit('setDeviceIdState', deviceId);
    //dispatch('getDeviceData', deviceId);
  },

  // selectedDeviceIds should be Array
  setSelectedDeviceIds({ state, commit, dispatch, rootState }, selectedDeviceIds) {
    return new Promise((resolve, reject) => {
      // console.log('setSelectedDeviceIds(', selectedDeviceIds, ')');
      commit('setSelectedDeviceIdsState', selectedDeviceIds);
      dispatch('getAllFieldsForSelectedDevices').then(() => {
        resolve();
      });
    });
  },

  setSelectedFields({ commit, dispatch }, selectedFieldNames) {
    commit('setSelectedFieldsState', selectedFieldNames);
  },

  createDevice({ dispatch }, device) {
    var fullUrl = `${environmentSpecific.apiBaseUrl}devices/`;
    return new Promise((resolve, reject) => {
      axios
        .post(fullUrl, device)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  updateDevice({ state, commit, dispatch, rootState }, device) {
    var deviceId = device.pop();
    var fullUrl = `${environmentSpecific.apiBaseUrl}devices/${deviceId.deviceId}`;
    return new Promise((resolve, reject) => {
      axios
        .patch(fullUrl, device)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log('DeviceStore updateDevice() caught error', error.response);
          reject(error);
        });
    });
  },

  createDeviceActivationLog({ state, commit, dispatch, rootState }, activation) {
    var fullUrl = `${environmentSpecific.apiBaseUrl}deviceActivationLogs/`;
    return new Promise((resolve, reject) => {
      axios
        .post(fullUrl, activation)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log('DeviceStore createDeviceActivationLog() caught error', error);
          reject(error);
        });
    });
  },

  // changeDeviceActivation({ state, commit, dispatch, rootState }, { deviceActivationLogId, value, path }) {
  changeDeviceActivation({ state, commit, dispatch, rootState }, data) {
    var logId = data.pop();
    var id = logId.deviceActivationLogId;

    var fullUrl = `${environmentSpecific.apiBaseUrl}deviceActivationLogs/${id}`;

    return new Promise((resolve, reject) => {
      axios
        .patch(fullUrl, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log('DeviceStore changeDeviceActivation() caught error', error);
          reject(error);
        });
    });
  },
  getDeviceActivations({}, params) {
    // var exParams = {
    //   deviceActivationLogId: 0,
    //   orgId: 0,
    //   deviceIds: 'string',
    //   currentStatusOnly: true,
    //   startDateTime: '2020-08-06T20:17:30.472Z',
    //   endDateTime: '2020-08-06T20:17:30.472Z',
    //   billingPriceId: 0,
    //   action: 'string',
    //   skipRecords: 0,
    //   takeRecords: 0,
    // };
    var searchCriteria = params;
    var fullUrl = `${environmentSpecific.apiBaseUrl}deviceActivationLogs/search`;
    return new Promise(resolve => {
      axios.post(fullUrl, searchCriteria).then(response => {
        resolve(response.data.data);
      });
    });
  },
};

const methods = {};

/* "computed properties for stores" - calculate data based on store state  */
const getters = {
  // All devices
  allDevicesCount: state => state.deviceList.length,

  allDeviceIds: state => {
    return state.deviceList.filter(d => d.serviceTypeCode != 'telemetryOnly').map(d => d.deviceId);
  },

  allDeviceIdsIncludingTelemetryOnly: state => {
    return state.deviceList.map(d => d.deviceId);
  },

  // Selected Devices
  selectedDeviceIdsCount: state => state.selectedDeviceIds.length,

  selectedDeviceIds: state => {
    return state.selectedDeviceIds;
  },

  getSelectedDevices: state => {
    return state.deviceList.filter(d => state.selectedDeviceIds.includes(d.deviceId));
  },

  getSelectedCommIds: state => {
    return state.deviceList.filter(d => state.selectedDeviceIds.includes(d.deviceId)).map(d => d.comm1);
  },

  getSelectedCommIdString: state => {
    return state.deviceList
      .filter(d => state.selectedDeviceIds.includes(d.deviceId))
      .map(d => d.comm1)
      .join(',');
  },

  selectedDeviceNames: state => {
    return state.deviceList.filter(d => state.selectedDeviceIds.includes(d.deviceId)).map(d => d.deviceName);
  },

  // highlighted device
  highlightedDevice: state => {
    let d = state.deviceList.filter(d => d.deviceId === state.highlightedDeviceId);
    if (d.length > 0) {
      return d[0];
    }
    return null;
  },

  // Fields
  getSelectedFields: state => {
    if (state.selectedFieldNames.length === 0) {
      return [];
    }
    return state.allFieldsSelectedDevices.filter(f => state.selectedFieldNames.includes(f.dataFieldName));
  },

  getSelectedFieldDisplayNames: state => {
    if (state.selectedFieldNames.length === 0) {
      return [];
    }
    return state.allFieldsSelectedDevices.filter(f => state.selectedFieldNames.includes(f.dataFieldName)).map(f => f.displayName);
  },

  selectedFieldsCount: state => {
    return state.selectedFieldNames.length;
  },

  // AP's stuff
  // deviceActivationsByOrg: state => state.deviceActivations,

  devicesHaveCameras: state => {
    var camera = state.deviceList.filter(d => d.hasCamera);
    // console.log(camera)
    return camera;
  },

  deviceByDeviceId: state => id => {
    var device = state.deviceList.find(d => d.deviceId === id);
    return device;
  },

  // what kind of name is this? smdh
  currentDeviceListOrgId: (state, getters) => {
    var currentOrgs = state.deviceList.map(d => d.orgId);
    var currentOrg = _.uniq(currentOrgs);
    return currentOrg;
  },

  deviceListWithDestinationsByOrg: (state, getters) => {
    var devicesWithDestinations = getters.mostRecentDeviceActivityByOrg.filter(x => x.destinations);
    var ddArray = [];
    state.deviceList.forEach(function (device) {
      var deviceDest = _.find(devicesWithDestinations, function (o) {
        return o.deviceId === device.deviceId;
      });
      if (deviceDest !== undefined) {
        device.destinations = deviceDest.destinations;
      }
      ddArray.push(device);
    });
    return ddArray;
  },

  telemetryDevicesByOrg: (state, getters) => {
    var telemetryDeviceByOrg = state.deviceList.filter(d => d.serviceTypeCode === 'telemetryOnly');
    return telemetryDeviceByOrg;
  },

  mostRecentDeviceActivityByOrg: (state, getters) => {
    var sortedActivations = state.deviceActivations.sort(function (a, b) {
      return new Date(b.actionDateUtc) - new Date(a.actionDateUtc);
    });
    var lastActions = _.uniqBy(sortedActivations, 'deviceId');
    return lastActions;
  },

  mostRecentActivationForSelectedDevices: (state, getters) => deviceIds => {
    return getters.mostRecentDeviceActivityByOrg.filter(d => deviceIds.includes(d.deviceId));
  },
};

const plugins = [
  //createPersistedState(),
];

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  plugins,
  methods,
};
