<template>
  <div class="under-construction-page">
    <img src="@/assets/images/under_construction.jpg" class="under-construction"/>
  </div>
</template>

<script>
export default {
  name: 'TestPage',
  data () {
    return { }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  img.under-construction {height: 100%;}
</style>
