<template>
  <div class="billing-report-overview pg-component" v-if="displayOverview">
    <div v-if="showAdHocNotes" class="userMessage">Note: data may be incomplete for the most recent 7 day period.</div>
    <h3 class="heading">Amount due for {{startDate}} to {{endDate}} ${{totalDue.toFixed(2)}}</h3>
    <div class="org-info" v-if="selectedOrg">
      <div class="">{{selectedOrg.orgName}}</div>
      <div class="">
        {{selectedOrg.orgAddress1}}
        <span v-if="selectedOrg.orgAddress2">, {{selectedOrg.orgAddress2}} </span>
      </div>
      <div class="">{{selectedOrg.orgCity}}, {{selectedOrg.orgStateProvCode}} {{selectedOrg.orgPostalCode}}</div>
      <div class="">{{selectedOrg.orgCountryCode}}</div>
    </div>
    <div class="fee-summary">
      <table id="AmountDue" class="fee-table">
        <tbody>
          <tr v-if="showActivationInOverview">
            <td class="category">Activations</td>
            <td class="adjustmentDate"></td>
            <td class="amount">${{billingOverview.activations.toFixed(2)}}</td>
          </tr>
          <tr v-if="billingOverview.monthly > 0">
            <td class="category">Monthly Fees</td>
            <td class="adjustmentDate"></td>
            <td class="amount">${{billingOverview.monthly.toFixed(2)}}</td>
          </tr>
          <tr v-if="billingOverview.suspended > 0">
            <td class="category">Suspended Fees</td>
            <td class="adjustmentDate"></td>
            <td class="amount">${{billingOverview.suspended.toFixed(2)}}</td>
          </tr>
          <tr v-if="billingOverview.iridiumWithData > 0">
            <td class="category">Iridium with Data Services</td>
            <td class="adjustmentDate"></td>
            <td class="amount">${{billingOverview.iridiumWithData.toFixed(2)}}</td>
          </tr>
          <tr v-if="billingOverview.iridiumWithoutData > 0">
            <td class="category">Iridium Telemetry Only</td>
            <td class="adjustmentDate"></td>
            <td class="amount">${{billingOverview.iridiumWithoutData.toFixed(2)}}</td>
          </tr>
          <tr v-if="billingOverview.processingOnly > 0">
            <td class="category">Processingly Only</td>
            <td class="adjustmentDate"></td>
            <td class="amount">${{billingOverview.processingOnly.toFixed(2)}}</td>
          </tr>
          <tr v-if="billingOverview.globalstar > 0">
            <td class="category">Globalstar</td>
            <td class="adjustmentDate"></td>
            <td class="amount">${{billingOverview.globalstar.toFixed(2)}}</td>
          </tr>
          <tr v-if="billingOverview.sms > 0">
            <td class="category">SMS</td>
            <td class="adjustmentDate"></td>
            <td class="amount">${{billingOverview.sms.toFixed(2)}}</td>
          </tr>
          <tr v-if="billingOverview.rudics > 0">
            <td class="category">RUDICS</td>
            <td class="adjustmentDate"></td>
            <td class="amount">${{billingOverview.rudics.toFixed(2)}}</td>
          </tr>
          <tr v-if="billingOverview.rudicsTelemetryOnly > 0">
            <td class="category">RUDICS Telemetry Only</td>
            <td class="adjustmentDate"></td>
            <td class="amount">${{billingOverview.rudicsTelemetryOnly.toFixed(2)}}</td>
          </tr>
          <tr>
            <td class="category">Current Charges</td>
            <td class="adjustmentDate"></td>
            <td class="amount current-charge">${{currentCharges.toFixed(2)}}</td>
          </tr>
          <tr>
            <td class="category">Previous Balance</td>
            <td class="adjustmentDate"></td>
            <td class="amount">${{startingBalance.toFixed(2)}}</td>
          </tr>
          <tr v-for="(payment, index) in payments" :key="index">
            <td v-if="payment.description != ''" class="category">{{payment.description}}</td>
            <td v-if="payment.asOfDate != null" class="adjustmentDate">{{payment.asOfDate}}</td>
            <td v-if="payment.amount != 0" class="amount">{{payment.amount}}</td>
          </tr>
          <tr>
            <td class="category">Total Due</td>
            <td class="adjustmentDate"></td>
            <td class="amount total-due">${{totalDue.toFixed(2)}}</td>
          </tr>
          <tr v-if="totalDue < 0">
            <td class="amount" colspan="3">Credit balance - do not pay</td>
          </tr>
          <tr v-if="totalDue > 0">
            <td class="amount" colspan="3">All amounts in USD. Please pay promptly.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

/*************************************************************/

<script>
  var data = function () {
    return {
      currentCharges: 0,
      totalDue: 0,
      startingBalance: 0,
      payments: [],
      paymentTotal: 0,
      displayOverview: false,
      activationTotal: 0,
      monthlyTotal: 0,
    };
  };

  var props = ['selectedOrg', 'startDate', 'endDate', 'billingOverview', 'billingAdjustments', 'showActivationInOverview', 'showAdHocNotes'];

  var beforeMount = function () {};

  var mounted = function () {};

  var methods = {
    // formatData(obj) {
    //   Object.keys(this.billingOverview).forEach(key => {
    //     if (
    //       key == 'activations' ||
    //       key == 'monthly' ||
    //       key == 'suspended' ||
    //       key == 'iridiumWithData' ||
    //       key == 'iridiumWithoutData' ||
    //       key == 'rudics' ||
    //       key == 'rudicsTelemetryOnly' ||
    //       key == 'globalstar' ||
    //       key == 'processingOnly' ||
    //       key == 'sms'
    //     ) {
    //       if (obj[key] > 0 || key == 'activations') {
    //         obj[key] = '$' + obj[key].toFixed(2);
    //       }
    //     }
    //   });
    // },

    calculateCurrentCharges(value) {
      this.currentCharges =
        value.activations +
        value.monthly +
        value.suspended +
        value.iridiumWithData +
        value.iridiumWithoutData +
        value.rudics +
        value.rudicsTelemetryOnly +
        value.globalstar +
        value.processingOnly +
        value.sms;
    },

    calculateAdjustments(sortedAdj) {
      var adjustmentsTotal = 0;
      for (var x = 0; x < sortedAdj.length; x++) {
        adjustmentsTotal += sortedAdj[x].amount;
        sortedAdj[x].amount = '$' + sortedAdj[x].amount.toFixed(2);
      }
      this.paymentTotal = adjustmentsTotal;
    },

    calculateTotalDue() {
      if (this.currentCharges == -1 || this.paymentTotal == -1) {
        // set timeout
      } else {
        this.totalDue = this.startingBalance + this.currentCharges + this.paymentTotal;
      }
      this.displayOverview = true;
    },

    sortAdjustments(adjustments) {
      if (adjustments.length > 1) {
        adjustments.sort((a, b) => {
          if (a.asOfDate < b.asOfDate) {
            return -1;
          }
          if (a.asOfDate > b.asOfDate) {
            return 1;
          }
          return 0;
        });
      }
    },
  };

  var computed = {};

  var watch = {
    billingOverview(value) {
      if (value == undefined) {
        return;
      }
      console.log(value);
      this.startingBalance = value.startingBalance;
      this.calculateCurrentCharges(value);
      this.calculateTotalDue();
    },
    billingAdjustments(value) {
      this.payments = value.map(a => Object.assign({}, a));
      if (this.payments.length > 1) {
        this.sortAdjustments(this.payments);
      }
      this.calculateAdjustments(this.payments);
      this.calculateTotalDue();
    },
  };

  var components = {};

  export default {
    name: 'BillingReportOverview',
    props,
    data,
    beforeMount,
    mounted,
    methods,
    computed,
    watch,
    components,
  };
</script>

/*************************************************************/

<style scoped lang="scss">
  .billing-report-overview {
    display: block;
    .heading {
      border: 1px solid black;
      background-color: #eeeeee;
      margin-top: 9px;
      padding: 2px 0 2px 5px;
      width: 100%;

      display: block;
      font-size: 1.17em;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
    }
    .org-info {
      border: 1px solid #666666;
      border-radius: 5px;
      float: left;
      margin: 10px 15px;
      min-width: 300px;
      padding: 10px;
    }
    .fee-table {
      float: right;
      width: 50%;
      background: none;
      margin-top: 10px;
      margin-right: 15px;
    }
    tr .amount {
      text-align: right;
    }
    tr:nth-child(even),
    tr:nth-child(odd) {
      background: none;
      color: black;
    }
    .adjustmentDate {
      text-align: right;
    }
    .fee-table,
    .fee-table td {
      border: none;
    }

    .fee-table .current-charge,
    .fee-table .total-due {
      border-top: 1px black solid;
    }
    .total-due {
      font-weight: bold;
    }
  }
</style>
