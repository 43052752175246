<template>
  <div class="command-form pg-component pg-sidebar" v-if="isDeviceSelected && userHasClaim('Commands', 'update')">
    <div class="component-title">
      <h4>Send this command to the selected device{{selectedDeviceCount > 1 ? 's' : ''}}</h4>
    </div>
    <div class="pg-form-wrapper">
      <form @submit.prevent="submit">
        <div class="button-wrapper">
          <!-- <button type="button" name="continue" @click="onContinue" v-show="selectedCommand != null">Send Command</button> -->
        </div>
        <fieldset :disabled="isEditing">
          <div class="pg-form">
            <div class="form-group">
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>IMEI</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(device, index) in devicesWithCommands" :key="index">
                    <td>{{ device.deviceName }}</td>
                    <!-- <td> {{ device.commands.length }} </td> -->
                    <td>{{ device.comm1 }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="form-group radio-commands">
              <div class="section-divider group-title">Commands</div>
              <div class="section-contents">
                <div v-if="showCommandBoxMsg">{{warningMessage}}</div>
                <ul v-if="availableCommands.length > 0">
                  <li v-for="command in availableCommands" :key="command.index" :disabled="command.commandDisabled">
                    <input
                      :id="command.index"
                      type="radio"
                      v-model="selectedCommand"
                      :value="command"
                      :disabled="command.commandDisabled"
                      :class="{ disabled: command.commandDisabled }"
                    />
                    <label :for="command.index" :class="{ disabled: command.commandDisabled }"
                      ><span :class="{ disabled: command.commandDisabled }">{{ command.messageName }}</span></label
                    >
                  </li>
                </ul>
                <!-- <span v-else>No commands available</span> -->
                <div class="button-row">
                  <button type="button" name="continue" @click="onContinue" v-show="selectedCommand != null && availableCommands.length > 0">
                    Send Command
                  </button>
                  <button type="button" @click="onCancel" v-show="selectedCommand != null && availableCommands.length > 0">Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
    <div class="modal-wrapper">
      <command-confirm-modal
        v-show="showConfirmModal"
        @close="closeModal"
        @confirmed="onUserConfirmation"
        :command="messageName"
        :deviceNames="selectedDeviceNames"
      />
    </div>
  </div>
</template>

/*************************************************************/

<script>
  import { mapGetters, mapState, mapActions } from 'vuex';
  import CommandConfirmModal from '@/components/CommandConfirmModal';

  var props = ['isEditing', 'devicesWithCommands'];

  var data = function() {
    return {
      availableCommands: [],
      localDeviceIds: [],
      localDevices: [],
      selectedCommand: null,
      showConfirmModal: false,
      messageName: null,
      deviceId: null,
      warningMessage: '',
      showCommandBoxMsg: false,
    };
  };

  var beforeMount = function() {};

  var mounted = function() {};

  var methods = {
    ...mapActions('commandModule', [
      // 'getCommandHistoryByDeviceIds',
      'sendCommandToDevice',
    ]),

    checkDevicesForCommands() {
      this.availableCommands = [];
      this.showCommandBoxMsg = false;
      var allCommands = [];
      var commandsPerDevice = [];
      var commonCommands = [];
      var disableIt = null;

      this.devicesWithCommands.forEach(device => {
        allCommands.push(device.commands);
        commandsPerDevice.push(
          device.commands.map(command => {
            command.messageName = command.messageName.replace('  ', ' ');
            return command;
          }),
        );
      });
      commonCommands = _.intersectionBy(...commandsPerDevice, 'messageName');
      allCommands = _.uniq(_.flatten(allCommands).map(command => command.messageName.replace('  ', ' ')));

      this.availableCommands = allCommands.map((command, index) => {
        if (commonCommands.length === 0) {
          disableIt = true;
        } else {
          var c = _.findIndex(commonCommands, { messageName: command });
          disableIt = c == -1 ? true : false;
        }
        return { index: index, messageName: command, commandDisabled: disableIt };
      });

      if (this.availableCommands.length === 0) {
        var devices = this.selectedDevices.length > 1 ? 'devices' : 'device';
        this.warningMessage = `No commands available for the selected ${devices}`;
        this.showCommandBoxMsg = true;
      } else if (allCommands.length !== commonCommands.length) {
        this.warningMessage = 'Not all commands are available for the selected devices';
        this.showCommandBoxMsg = true;
      }
    },

    onContinue() {
      this.messageName = this.selectedCommand.messageName;
      this.showConfirmModal = true;
    },
    onCancel() {
      this.selectedCommand = null;
    },

    onUserConfirmation() {
      this.submit();
    },

    submit() {
      var deviceCommands = [];
      this.selectedDevices.forEach(d => {
        var x = _.find(d.commands, { messageName: this.messageName });
        deviceCommands.push({ deviceId: d.deviceId, deviceName: d.deviceName, messageShortName: x.messageShortName, userId: this.userId });
      });

      var snotifyText = '';

      var orgId = this.selectedOrganization.orgId;
      var selectedDeviceIds = this.selectedDeviceIds.join(',');

      for (let x = 0; x < deviceCommands.length; x++) {
        this.sendCommandToDevice(deviceCommands[x])
          .then(response => {
            if (response.status === 204) {
              snotifyText = this.messageName + ' submitted for ' + deviceCommands[x].deviceName;
              this.$snotify.success(snotifyText, 'Command');
              // this.getCommandHistoryByDeviceIds({deviceIds: selectedDeviceIds, orgId});
            } else {
              snotifyText = 'Error processing ' + this.messageName + ' for ' + deviceCommands[x].deviceName;
              this.$snotify.error(snotifyText, response.status);
            }
            if (x === deviceCommands.length - 1) {
              this.$emit('onNewCommand');
            }
          })
          .catch(error => {
            this.$snotify.error(error);
          });
      }
    },

    closeModal() {
      this.selectedCommand = null;
      this.showConfirmModal = false;
    },
  };

  var computed = {
    ...mapState('deviceModule', ['selectedDeviceIds', 'selectedDevicesWithCommands']),
    ...mapGetters('deviceModule', ['selectedDeviceNames', 'getSelectedDevices']),
    // ...mapGetters('commandModule', ['getMessageShortNames']),
    ...mapState('userModule', ['currentUser']),
    ...mapGetters('userModule', ['userHasClaim']),
    ...mapState('organizationModule', ['selectedOrganization']),

    isDeviceSelected() {
      return this.selectedDeviceIds.length > 0;
    },
    selectedDeviceCount() {
      return this.selectedDeviceIds.length;
    },
    selectedDevices() {
      return this.getSelectedDevices;
    },
    userId() {
      return this.currentUser.userId;
    },
  };

  var watch = {
    devicesWithCommands: function(data) {
      if (data.length > 0) {
        this.checkDevicesForCommands();
      }
    },
  };

  var components = {
    CommandConfirmModal,
  };

  export default {
    name: 'CommandForm',
    props,
    data,
    beforeMount,
    mounted,
    methods,
    computed,
    watch,
    components,
  };
</script>

/*************************************************************/

<style scoped>
  .command-form fieldset {
    border: none;
  }
  .form-group {
    display: inline-block;
    vertical-align: top;
    width: 49%;
  }
  .form-group table {
    max-width: none;
    width: 100%;
  }
  .radio-commands ul {
    display: block;
    border: none;
    padding: 0;
  }

  /* .disabled {
  opacity: .5;
} */

  input[type='radio'] {
    display: none;
  }
  input[type='radio'] + label:before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }

  input[type='radio'] + label:before {
    font-family: 'Font Awesome 5 Pro';
    font-weight: 400;
    font-size: 1.1em;
    content: '\f111';
    padding-right: 3px;
  }

  input[type='radio']:checked + label:before {
    font-family: 'Font Awesome 5 Pro';
    font-weight: 400;
    font-size: 1.1em;
    content: '\f058';
    padding-right: 3px;
  }
</style>
