<template>
  <div class="contact-list-grid">
    <h6>ContactListGrid.vue</h6>
    <div style="display: flex; flex-direction: row; ">
      <div style=" overflow: hidden; flex-grow: 1; ">
        <span class="filter">Filter:</span>
        <label for="only-recent">
          <input type="checkbox" id="only-recent" v-model="filterRecentOnly" v-on:click="externalFilterChanged">Only the last
          <input type="number" id="recentNumDays" v-model="recentNumDays" v-on:keyup="externalFilterChanged" class="inline" />
          days
        </label>
        <label for="only-open">
          <input type="checkbox" id="only-open"   v-model="filterOpenOnly"   v-on:click="externalFilterChanged">Only open
        </label>
        <span class="right">
          <input type="button" v-on:click="resetColumnState" value="Reset columns" />
        </span>
        <ag-grid-vue
            style="width: 100%; height: 400px; "
            class="ag-theme-balham"
            :gridOptions="gridOptions"
            :columnDefs="columnDefs"
            :modules="agGridModules"
            >
        </ag-grid-vue>
      </div>
    </div>
  </div>
</template>

/*************************************************************/

<script>
import Vue from "vue";
import { AgGridVue } from "@ag-grid-community/vue";
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { mapGetters, mapState, mapActions } from "vuex";
import pgUtils from "@/utils/PgUtils.js";
import environmentSpecific from "static/environmentSpecific";

var myDateFormat = "MM/DD/YY"; // "YYYY-MM-DD HH:mm:ss "; // ISO  ISO.MSnp

var data = function() {
  return {
    columnDefs: null,
    rowData: null,
    gridOptions: null,
    gridApi: null,
    columnApi: null,
    agGridModules: AllCommunityModules,
    filterRecentOnly: true,
    filterOpenOnly: false,
    recentNumDays: 360
  };
};

var beforeMount = function() {
  this.gridOptions = {
    rowData: this.rowData,
    columnDefs: this.columnDefs,
    enableFilter: true,
    enableSorting: true,
    enableColResize: true,
    onGridReady: this.onGridReady,
    onCellValueChanged: this.onCellValueChanged,
    onColumnMoved: this.onColumnMoved,
    isExternalFilterPresent: this.isExternalFilterPresent,
    doesExternalFilterPass: this.doesExternalFilterPass,
    defaultColDef: { width: 100 }
  };

  this.columnDefs = [
    { headerName: "Name", field: "name", pinned: "left" },
    {
      headerName: "ContactInfo",
      children: [
        {
          headerName: "Organization",
          field: "organization",
          columnGroupShow: "always"
        },
        {
          headerName: "Email",
          field: "email",
          columnGroupShow: "open",
          cellRenderer: function(params) {
            var c = environmentSpecific.contactUsReplyEmail;
            var cr = "%0A%0D";
            var body = encodeURIComponent(
              params.data.name +
                ", \n\n" +
                c.body +
                "\n\n" +
                "Original Request:" +
                "\n" +
                params.data.userComments
            );
            // makeMailToLink(sendTo, subject, body, cc, bcc, linkText)
            var emailLink = pgUtils.makeMailToLink(
              params.data.email,
              c.subjectLine,
              body,
              c.cc,
              c.bcc,
              params.data.email
            );
            return emailLink;
          }
        },
        {
          headerName: "Phone Number",
          field: "phoneNumber",
          columnGroupShow: "open"
        },
        { headerName: "Country", field: "country", columnGroupShow: "open" }
      ]
    },
    {
      headerName: "Request",
      children: [
        {
          headerName: "Date Created",
          field: "dateCreated",
          columnGroupShow: "always",
          valueFormatter: function(params) {
            return pgUtils.formatDate(params.data.dateCreated, myDateFormat);
          }
        },
        {
          headerName: "User Comments",
          field: "userComments",
          width: 200,
          cellEditor: "agLargeTextCellEditor",
          columnGroupShow: "always"
        },
        {
          headerName: "Web Page",
          columnGroupShow: "open",
          valueGetter: function(params) {
            var page = params.data.originalWebPage;
            page = page.replace(/http[s]?:\/\/www.pacificgyre.com\//g, "");
            return page;
          }
        },
        {
          headerName: "Contact Type",
          columnGroupShow: "open",
          valueGetter: function(params) {
            var cType = params.data.contactType;
            cType = cType.replace("Web ", "");
            return cType;
          }
        }
      ]
    },
    {
      headerName: "Response",
      children: [
        {
          headerName: "Date Updated",
          field: "dateUpdated",
          columnGroupShow: "always",
          valueFormatter: function(params) {
            return pgUtils.formatDate(params.data.dateUpdated, myDateFormat);
          }
        },
        {
          headerName: "Status",
          field: "status",
          editable: true,
          columnGroupShow: "always",
          cellEditor: "agPopupSelectCellEditor",
          cellEditorParams: { values: ["", "Call back", "Complete"] },
          cellRenderer: statusCellRenderer,
          width: 50
        },
        {
          headerName: "Notes",
          field: "notes",
          editable: true,
          width: 200,
          cellEditor: "agLargeTextCellEditor",
          columnGroupShow: "open"
        }
      ]
    }
  ];
};

var mounted = function() {
  this.getContactList();
};

var statusCellRenderer = function(params) {
  var cellContents = "";
  if (params.data.status === "Complete") {
    cellContents = '<i class="fas fa-check" title="Complete" />';
  } else if (this.moment().diff(this.moment(params.data.dateUpdated), "d") > 14) {
    cellContents =
      '<i class="far fa-exclamation-triangle" title="Needs attention" />';
  }
  return cellContents;
};

var methods = {
  onColumnMoved(params) {
    var columnState = JSON.stringify(params.columnApi.getColumnState());
    localStorage.setItem("pgContactsColumnState", columnState);
  },
  onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    params.api.setRowData(this.contactList);
    var columnState = JSON.parse(localStorage.getItem("pgContactsColumnState"));
    if (columnState) {
      params.columnApi.setColumnState(columnState);
    }
    params.api.sizeColumnsToFit();
    window.addEventListener("resize", function() {
      setTimeout(function() {
        params.api.sizeColumnsToFit();
      });
    });
  },
  onCellValueChanged(event) {
    this.updateContact(event.data);
  },
  isExternalFilterPresent() {
    return this.filterOpenOnly || this.filterRecentOnly;
  },
  doesExternalFilterPass(node) {
    var openPass = !this.filterOpenOnly || node.data.status != "Complete";
    var recentPass =
      !this.filterRecentOnly ||
      this.moment(this.moment()).diff(this.moment(node.data.dateCreated), "days") <
        this.recentNumDays;
    return openPass && recentPass;
  },
  externalFilterChanged(event) {
    // Inside of JS functions like nextTick or setTimeout, we lose "this" so save it as my
    // "this" changes but "my" won't
    var my = this;
    Vue.nextTick(function() {
      my.gridOptions.api.onFilterChanged();
    });
  },
  resetColumnState(event) {
    if (this.columnApi) {
      this.columnApi.resetColumnState();
    } else {
      console.log("resetColumnState() this.columnApi not set");
    }
  },
  ...mapActions("backOfficeModule", ["getContactList", "updateContact"])
};

var computed = {
  ...mapState("backOfficeModule", ["contactList"])
};

var watch = {
  contactList: function() {
    if (this.gridApi) {
      this.gridApi.setRowData(this.contactList);
    }
  }
};

var components = {
  AgGridVue
};

export default {
  name: "ContactListGrid",
  data,
  beforeMount,
  mounted,
  computed,
  methods,
  watch,
  components
};
</script>

/*************************************************************/

<style scoped>
input[type="checkbox"] {
  margin-left: 10px;
}
label {
  display: inline-block;
}
.inline {
  display: inline-block;
  width: 75px;
}
.right {
  float: right;
}
.fa-frog {
  color: green;
}

.icon {
  display: inline-block;
  height: 1em;
  width: 1em;
  color: red;
}
/* --------------------------------
  Change icon size
  -------------------------------- */
.icon-xs {
  height: 0.5em;
  width: 0.5em;
}
.icon-sm {
  height: 0.8em;
  width: 0.8em;
}
.icon-lg {
  height: 1.6em;
  width: 1.6em;
}
.icon-xl {
  height: 2em;
  width: 2em;
}
</style>
