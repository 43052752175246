<template>
  <multiselect
    :id="id"
    :options="options"
    :value="value"
    :label="label"
    :track-by="trackBy"
    :allow-empty="allowEmpty"
    :hide-selected="hideSelected"
    :placeholder="placeholder"
    :title="title"
    :custom-label="customLabel"
    :show-labels="true"
    v-bind="$attrs"
    v-on="listeners"
    class="mySelect"
    :allowTooltips="allowTooltips"
    :tooltipText="tooltipText"
    :taggable="taggable"
  >
    <template v-if="!customLabel" slot="option" slot-scope="props" :label="label" :custom-label="customLabel">
      <div :class="{tooltip : allowTooltips, highlight : props.option.unitId > 0}">
        <span>{{ props.option[label] }}</span>
        <span class="tooltiptext" v-if="allowTooltips">{{props.option[tooltipText]}}</span>
      </div>
    </template>

    <template v-else slot="singleLabel" :custom-label="customLabel">
      <span class="option__desc">
        <span class="option__title">{{ customLabel }}</span>
      </span>
    </template>
  </multiselect>
</template>

/************************************************************/

<script>
  import Multiselect from 'vue-multiselect';

  var data = function() {
    return {};
  };

  var props = {
    id: [String, Number],
    options: Array,
    value: [Object],
    label: String,
    placeholder: { type: String, default: '' },
    title: String,
    trackBy: String,
    hideSelected: { type: Boolean, default: false },
    allowEmpty: { type: Boolean, default: true },
    customLabel: null,
    allowTooltips: false,
    tooltipText: null,
    taggable: false,
  };

  var computed = {
    localValue() {
      return this.value;
    },
    listeners() {
      return {
        ...this.$listeners,
        open: event => {
          this.$emit('touched', this.$options.propsData.id);
        },
      };
    },
  };

  var watch = {};

  var components = {
    Multiselect,
  };

  var methods = {};

  export default {
    inheritAttrs: false,
    data,
    props,
    computed,
    components,
    watch,
    methods,
  };
</script>
<style>
  .mySelect.multiselect .multiselect__option--selected:after {
    /* check */
    content: '\f00c';
    font-family: 'Font Awesome 5 Pro';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    padding: 3px 10px 0 5px;
    width: 30px;
  }
  /* new icon-enter (return) has been requested ... keep your eye out */
  .mySelect.multiselect .multiselect__option--highlight:after {
    /* return key */
    content: '\f149';
    transform: rotate(90deg);
    font-family: 'Font Awesome 5 Pro';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    background: #02361f;
    color: white;
    height: 30px;
    width: 30px;
  }
  .mySelect.multiselect .multiselect__option--selected.multiselect__option--highlight:after {
    padding: 5px 12px 0 5px;
  }
  .mySelect.multiselect .multiselect__element .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }

  .mySelect.multiselect .multiselect__element .tooltip:hover .tooltiptext {
    visibility: visible;
  }
  .highlight {
    color: black;
    font-weight: bold;
  }
</style>
