const textHelper = {

  gpsQualityText(gpsQuality) {
    var text = 'N/A';
    switch (gpsQuality) {
      case 1:
        text = 'Unreliable';
        break;
      case 2:
        text = '2D';
        break;
      case 3:
        text = '3D';
        break;
      case 5:
        text = 'Differential GPS';
        break;
    }
    return text;
  },

  makeMailToLink(sendTo, subject, body, cc, bcc, linkText) {
    var link = `<a href="mailto:${sendTo}?`;
    if (subject) {
      link = `${link}subject=${subject}&`;
    }
    if (cc) {
      link = `${link}cc=${cc}&`;
    }
    if (bcc) {
      link = `${link}bcc=${bcc}&`;
    }
    if (body) {
      link = `${link}body=${body}`;
    }
    if (linkText) {
      link = `${link}">${linkText}</a>`;
    } else {
      link = `${link}">${sendTo}</a>`;
    }
    return link;
  },

};

export { textHelper };
