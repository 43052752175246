import axios from 'axios';
import environmentConfig from 'static/environmentSpecific';
import ProfileService from '../services/ProfileService';
import moment from 'moment';

const allOrganizations = { orgId: -1, orgName: 'All Organizations', numberActiveDevices: 0 };
/*
{
  "metaData": {},
  "data": {
    "users": [
      {
        "userId": 0,
        "userName": "string",
        "firstName": "string",
        "lastName": "string",
        "email": "string"
      }
    ],
    "numberActiveDevices": 0,
    "orgId": 0,
    "orgTypeId": 0,
    "orgName": "string",
    "orgAddress1": "string",
    "orgAddress2": "string",
    "orgAddress3": "string",
    "orgCity": "string",
    "orgStateProvCode": "string",
    "orgCountryCode": "string",
    "orgPostalCode": "string"
  }
}
*/
const state = {
  organizations: [],
  selectedOrganization: {
    changed: null,
    orgId: null,
    orgName: null,
  },
  orgsWithUserString: [],
};

const mutations = {
  updateOrganizationList(state, organizations) {
    state.organizations = organizations;
    state.organizations.find(o => o.orgName === 'All Organizations').numberActiveDevices = organizations.length;
  },
  setSelectedOrganization(state, organization) {
    state.selectedOrganization = organization;
    ProfileService.saveLocalSetting('selectedOrg', organization);
  },
  setOrganizationsWithUserString(state, value) {
    state.orgsWithUserString = value;
  },
};

const actions = {
  getOrganizations({ state, commit, rootState }) {
    var fullUrl = `${environmentConfig.apiBaseUrl}organizations/search`;
    return new Promise(resolve => {
      axios.get(fullUrl).then(response => {
        response.data.data.splice(0, 0, allOrganizations);
        commit('updateOrganizationList', response.data.data);
        resolve(response);
      });
    });
  },
  // clean this up
  getOrganizationById({ state, commit, rootState }, orgId) {
    var fullUrl = `${environmentConfig.apiBaseUrl}organizations/search?orgId=${orgId}`;
    return new Promise(resolve => {
      axios.get(fullUrl).then(response => {
        resolve(response.data.data[0]);
      });
    });
  },
  getOrganizationsWithUsers({ state, commit, rootState }) {
    var fullUrl = `${environmentConfig.apiBaseUrl}organizations/search?withUsers=true`;
    return new Promise(resolve => {
      axios.get(fullUrl).then(response => {
        var results = [];
        response.data.data.forEach(function (org) {
          var str = [];
          org.users.forEach(function (user) {
            var temp = [];
            temp.push(user.firstName);
            temp.push(user.lastName);
            temp.push(user.email);
            str.push(temp.join(' ').trim());
          });
          var result = str.join('; ');
          org.userStr = result;
          results.push(org);
        });
        commit('setOrganizationsWithUserString', results);
        response.data.data.splice(0, 0, allOrganizations);
        commit('updateOrganizationList', response.data.data);
        resolve(response);
      });
    });
  },

  setSelectedOrganizationDefault({ commit }) {
    var savedOrg = ProfileService.getLocalSetting('selectedOrg', null);
    if (state.selectedOrganization.orgId) {
      // state.selectedOrganization;
    } else if (savedOrg && savedOrg.orgId) {
      commit('setSelectedOrganization', savedOrg);
    } else {
      commit('setSelectedOrganization', this._vm.$pgGlobal.currentUser.organization); // {orgId: null, orgName: 'Not set'};
    }
  },

  // Images and Sensors page uses this.
  setSelectedOrganization({ state, commit, rootState }, organization) {
    if (organization && organization.orgId) {
      commit('setSelectedOrganization', organization);
    }
  },

  setSelectedOrganizationByOrgId({ state, commit }, orgId) {
    if (orgId === null) {
      commit('setSelectedOrganization', {});
      return;
    }
    if (orgId === -1) {
      commit('setSelectedOrganization', allOrganizations);
    }
    // TODO: A - this needs cleaning up!
    // it's going to arnold no matter what except when the org is new?
    if (state.organizations.length === 0) {
      console.error('>> this should never happen - why are there no orgs?');
      var fullUrl = `${environmentConfig.apiBaseUrl}organizations/search?orgId=${orgId}`;
      axios.get(fullUrl).then(response => {
        commit('setSelectedOrganization', response.data.data[0]);
      });
    } else {
      // console.log('are we pulling from an old org list... yep, logic is off.  brain dead.')
      let org = state.organizations.find(o => o.orgId === orgId);
      if (org) {
        commit('setSelectedOrganization', org);
      }
    }
  },

  setUpdatedOrganizationbyOrgId({ state, commit, dispatch, rootState }, orgId) {
    var fullUrl = `${environmentConfig.apiBaseUrl}organizations/search?orgId=${orgId}`;
    axios.get(fullUrl).then(response => {
      commit('setSelectedOrganization', response.data.data[0]);
    });
  },

  createOrganization({ state, commit, rootState }, body) {
    var fullUrl = `${environmentConfig.apiBaseUrl}organizations`;
    return new Promise((resolve, reject) => {
      axios.post(fullUrl, body).then(response => {
        commit('setSelectedOrganization', response.data.data);
        resolve(response);
      });
    });
  },

  updateOrganization({ state, commit, rootState }, orgPatch) {
    let org = orgPatch.pop();
    var fullUrl = `${environmentConfig.apiBaseUrl}organizations/${org.orgId}?orgId=${org.orgId}`;
    return new Promise(resolve => {
      axios.patch(fullUrl, orgPatch).then(response => {
        commit('setSelectedOrganization', response.data.data);
        resolve(response);
      });
    });
  },
};

/* 'computed properties for stores' - calculate data based on store state  */
const getters = {
  storeOrgId: state => {
    return state.selectedOrganization.orgId;
  },

  // selectedOrganization: (state, commit) => {
  //   if (state.selectedOrganization.orgId) {
  //     return state.selectedOrganization;
  //   }

  //   return {orgId: null, orgName: null};
  // },
};

const plugins = [
  //createPersistedState(),
];

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  plugins,
};
