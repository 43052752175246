<template>
  <div class="sensor-details-form pg-detail-component pg-component">
    <div class="pg-component-toolbar">
      <div class="pg-component-toolbar-left">Sensor Details</div>
      <!-- <div class="pg-component-toolbar-center" :class="{ 'fade-out': fadeout, 'fade-in': fadein }"> -->

      <div v-if="fadeout" class="pg-component-toolbar-center pagestate" :class="{ 'fade-Out': fadeout }">
        <span>{{ infoMessage }}</span>
      </div>
      <div v-else class="pg-component-toolbar-center pagestate" :class="{ 'fade-In': fadein }">
        <span>{{ infoMessage }}</span>
      </div>
    </div>
    <div class="pg-form-wrapper">
      <form @submit.prevent="submit">
        <fieldset class="section-contents" :disabled="formDisabled">
          <div class="pg-form vertical">
            <div @click="chastiseUser">
              <div class="form-group" :class="{ disabled: formDisabled }">
                <input
                  class="form-input"
                  id="sensorName"
                  ref="sensorName"
                  name="sensorName"
                  type="text"
                  autocomplete="off"
                  v-model.trim="localSensor.sensorName"
                  @change="updateInputValue"
                  @blur="onNameInputBlur"
                />
                <label for="sensorName">Sensor Name</label>
              </div>
              <div class="needName" v-show="needName"><span>Please enter a sensor name to get started</span></div>
            </div>

            <div @click="chastiseUser">
              <div class="form-group" :class="{ disabled: inputsDisabled }">
                <textarea
                  class="form-input"
                  id="sensorDescription"
                  type="text"
                  rows="2"
                  v-model.trim="localSensor.sensorDescription"
                  @change="updateInputValue"
                >
                </textarea>
                <label for="sensorDescription">Sensor Description</label>
              </div>
            </div>

            <div @click="chastiseUser">
              <div class="form-group" :class="{ disabled: inputsDisabled }">
                <pg-select
                  id="sensorTypeId"
                  :options="sensorTypes"
                  :value="selectedSensorType"
                  label="sensorTypeName"
                  track-by="sensorTypeId"
                  @input="onDropdownInput"
                >
                </pg-select>
                <label for="sensorTypeId">Sensor Type</label>
              </div>
            </div>

            <div @click="chastiseUser">
              <div class="form-group" :class="{ disabled: inputsDisabled }">
                <input
                  class="form-input"
                  id="wmoHeading"
                  name="wmoHeading"
                  type="text"
                  autocomplete="off"
                  v-model.trim="localSensor.wmoHeading"
                  @change="updateInputValue"
                />
                <label for="wmoHeading">WMO Heading IOBX06</label>
              </div>
            </div>
            <div @click="chastiseUser">
              <div class="form-group" :class="{ disabled: inputsDisabled }">
                <input
                  class="form-input"
                  id="isMultiTransmit"
                  name="isMultiTransmit"
                  type="checkbox"
                  v-model="localSensor.isMultiTransmit"
                  @change="updateInputValue"
                />
                <label for="isMultiTransmit" class="checkbox-label">Is Multi Transmit</label>
              </div>
            </div>

            <div @click="chastiseUser">
              <div class="form-group" :class="{ disabled: inputsDisabled }">
                <input
                  class="form-input"
                  id="isVariableLength"
                  name="isVariableLength"
                  type="checkbox"
                  v-model="localSensor.isVariableLength"
                  @change="updateInputValue"
                />
                <label for="isVariableLength" class="checkbox-label">Is Variable Length</label>
              </div>
            </div>

            <div @click="chastiseUser">
              <div class="form-group" :class="{ disabled: inputsDisabled }">
                <input
                  class="form-input"
                  id="rawDataProcessor"
                  name="rawDataProcessor"
                  type="text"
                  autocomplete="off"
                  v-model="localSensor.rawDataProcessor"
                  @change="updateInputValue"
                />
                <label for="rawDataProcessor">Raw Data Processor</label>
              </div>
            </div>

            <div @click="chastiseUser">
              <div class="form-group" :class="{ disabled: inputsDisabled }">
                <input class="form-input" id="numDataBytes" type="number" v-model="localSensor.numDataBytes" @change="updateInputValue" />
                <label for="numDataBytes">Number Data Bytes</label>
              </div>
            </div>
            <div>
              <span class="form-control-static" v-if="updatedBytes">Calculated bytes: {{ bytes }} / bits: {{ bits }} </span>
            </div>
            <div v-if="localSensor.sensorId">
              <span class="form-control-static" v-if="localSensor.sensorId">Sensor Id: {{ localSensor.sensorId }}</span>
            </div>
          </div>
          <div class="notification" :class="{ 'fade-Out': fadeout }" v-if="notify.showNotification">
            <span v-html="notify.userNotification"></span>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
</template>

/*************************************************************/

<script>
  import { required } from 'vuelidate/lib/validators';
  import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
  import PgSelect from '@/components/Common/PgSelect';

  var thisComponent;

  var data = function() {
    return {
      formDisabled: true,
      inputsDisabled: true,
      selectedSensorTypeId: null,
      bits: null,
      bytes: null,
      localSensor: {},
      detailsClone: {},
      infoMessage: '',
      // userNotification: '',
      // showNotification: false,
      fadeout: false,
      fadein: false,
      lastUserAction: '',
      needName: false,
      detailsChanged: false,
    };
  };
  var props = ['updatedBytes', 'userAction', 'sensorDetails', 'notify', 'sensorDetailsChanged'];

  var created = function() {
    thisComponent = this;
  };

  var beforeMount = function() {};

  var mounted = function() {
    this.getSensorTypes();
    this.allowEditing(false);
    console.log(this.detailsChanged);
  };

  var methods = {
    ...mapActions('sensorModule', ['getSensorTypes']),

    addNewSensor() {
      this.lastUserAction = 'create';
      this.localSensor.sensorId = 0;
      this.clearInputs();
      this.allowEditing(true);
      this.$nextTick(() => this.$refs.sensorName.focus());
    },

    editSensor() {
      this.detailsClone = _.cloneDeep(this.localSensor);
      this.allowEditing(true);
      this.lastUserAction = 'edit';
    },

    copyPackage() {
      this.detailsClone = _.cloneDeep(this.localSensor); // necessary? cancel renames it to cancelled
      this.allowEditing(true);
      this.lastUserAction = 'copy';
      this.$nextTick(() => {
        this.$refs.sensorName.focus();
        var len = this.$refs.sensorName.value.length;
        this.$refs.sensorName.selectionStart = 0;
        this.$refs.sensorName.selectionEnd = len;
      });
    },

    finishedEditing() {
      this.allowEditing(false);
      this.lastUserAction = '';
    },

    cancel() {
      this.allowEditing(false);
      this.showNotification = false;
      if (this.localSensor.sensorId !== undefined) {
        var obj = {
          sensorName: '',
        };
        if (this.lastUserAction === 'create' || this.lastUserAction === 'copy') {
          obj.sensorName = 'CANCELLED ' + this.lastUserAction.toUpperCase() + ' - id: ' + this.localSensor.sensorId;
          this.sendToDatabase(obj, 'patch');
          this.clearInputs();
        } else if (this.lastUserAction === 'edit') {
          this.sendToDatabase(this.detailsClone, 'put');
        }
      }
      this.$nextTick(() => {
        this.showNotification = false;
      });
      this.lastUserAction = '';
    },

    allowEditing(enable) {
      if (enable) {
        if (this.lastUserAction === 'create') {
          this.formDisabled = null;
        } else {
          this.formDisabled = null;
          this.inputsDisabled = false;
        }
      } else {
        this.formDisabled = true;
        this.inputsDisabled = true;
      }
    },

    updateInputValue(event) {
      var inputId = event.target.id;
      var inputValue = event.target.value;
      var inputChecked = event.target.checked;
      var inputType = event.target.type;
      var item = null;
      var value = null;
      if (inputType === 'checkbox') {
        item = inputId;
        value = inputChecked;
      } else {
        item = inputId;
        value = inputValue;
      }
      var obj = {};
      obj[item] = value;
      this.sendToDatabase(obj, 'patch');
      if (inputId === 'sensorName') {
        this.needName = false;
      }
    },

    onNameInputBlur(event) {
      console.log('inputblur');
      var sensorName = event.srcElement.value;
      if (sensorName === '') {
        this.$refs.sensorName.focus();
        this.needName = true;
        // this.fadeout = false;
      } else {
        this.needName = false;
      }
    },

    onDropdownInput(value, id) {
      if (value === 'null') {
        return;
      }
      var obj = { target: { id: id, value: value.sensorTypeId } };
      this.updateInputValue(obj);
    },

    setFormValues(sensor) {
      if (sensor !== undefined && sensor.hasOwnProperty('sensorName') && sensor.sensorName.substring(0, 9) === 'CANCELLED') {
        this.clearInputs();
        return;
      } else {
        this.localSensor = Object.assign({}, sensor);
        this.selectedSensorTypeId = this.localSensor.sensorTypeId;
      }
    },

    clearInputs() {
      this.showNotification = false;
      this.setFormValues({});
    },

    sendToDatabase(obj, action) {
      var id = this.localSensor.sensorId;
      var dirty = [];
      console.log('send to database a patch is sneaking though', obj);

      if (!this.localSensor.hasOwnProperty('sensorId')) {
        this.$emit('onNewSensorIdRequest', obj.sensorName);
      } else if (action === 'patch') {
        var path = Object.getOwnPropertyNames(obj);
        var val = obj[path];
        var payload = {
          op: 'replace',
          path: '/' + path,
          value: val,
        };
        dirty.push(payload);
        dirty.push({ sensorId: id });
        this.$emit('onDetailsPatchRequest', dirty);
      } else if (action === 'put' && this.sensorDetailsChanged) {
        this.$emit('onDetailsPutRequest', obj);
      }
    },
    updateUserMessage(action) {
      this.fadeout = false;
      this.fadein = false;
      console.log();
      var msg = '';
      switch (action) {
        case 'view':
          msg = `Viewing ${this.localSensor.sensorName}`;
          break;
        case 'create':
          msg = 'Creating a new sensor package';
          break;
        case 'copy':
          msg = 'Creating a new sensor package from copy';
          break;
        case 'edit':
          msg = `Editing ${this.localSensor.sensorName}`;
          break;
        case 'save':
          msg = 'Operation was completed';
          break;
        case 'cancel':
          msg = 'Operation was cancelled';
      }
      this.infoMessage = msg;
      if (action === 'save' || action === 'cancel') {
        this.fadeout = true;
        if (this.lastUserAction === 'edit') {
          setTimeout(() => {
            thisComponent.fadeout = false;
            thisComponent.updateUserMessage('view');
            thisComponent.fadein = true;
          }, 2000);
        }
      }
    },
    chastiseUser() {
      if (this.formDisabled) {
        this.$emit('userClickingOnDisabledPage', 'form');
      }
    },
  };

  var computed = {
    ...mapState('sensorModule', ['sensorTypes']),

    selectedSensorType: function() {
      return this.sensorTypes.find(_ => _.sensorTypeId === this.selectedSensorTypeId);
    },
  };

  var watch = {
    sensorDetails: function(value) {
      if (value === null) {
        value = {};
      }
      this.setFormValues(value);
    },
    updatedBytes: function(value) {
      this.bits = value.bits;
      this.bytes = value.bytes;
    },
    notify: function(value) {
      console.log(value);
      return value;
    },
    userAction: function(userAction) {
      var action = userAction.action;
      var sensorId = userAction.sensorId;
      this.updateUserMessage(action);

      if (action === 'view') {
      } else if (action === 'edit') {
        this.editSensor();
      } else if (action === 'copy') {
        this.copyPackage();
      } else if (action === 'create') {
        if (sensorId === null) {
          this.addNewSensor();
        } else {
          this.inputsDisabled = false;
          this.needName = false;
        }
      } else if (action === 'save') {
        this.finishedEditing();
      } else if (action === 'cancel') {
        this.cancel();
      }
    },
  };

  var components = {
    PgSelect,
  };

  export default {
    name: 'SensorDetailForm',
    data,
    // validations,
    beforeMount,
    created,
    mounted,
    methods,
    computed,
    watch,
    components,
    props,
  };
</script>

/*************************************************************/
<style>
  .sensors-page .details-container {
    width: 40%;
    min-width: 400px;
  }
</style>
<style scoped>
  .pg-component-toolbar + div {
    flex: 1;
  }
  .header-icons {
    display: flex;
  }

  .pg-component-toolbar-item {
    align-items: center;
  }
  .inputsDisabled {
    pointer-events: none;
  }
  .pg-form {
    display: flex;
    direction: column;
  }
  .form-group {
    display: flex;
    direction: row;
  }

  .form-group label {
    /* flex: 0 1 125px; */
    margin-right: 10px;
  }
  .form-group .form-input {
    flex: 2 1 auto;
    margin-bottom: 5px;
  }
  .form-group .form-input[type='checkbox'] {
    flex: 0 0 auto;
  }

  .needName {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .needName span {
    color: rgb(226, 26, 26);
  }

  .notification {
    width: max-content;
    padding: 10px;
    margin: 0 auto;
    opacity: 1;
  }
  .notification.fade-Out {
    opacity: 0;
    transition: opacity 10s ease-in;
  }
  .pagestate {
    opacity: 1;
  }
  .pagestate.fade-Out {
    opacity: 0;
    transition: opacity 2s ease-in;
  }
  .pagestate.fade-In {
    opacity: 1;
    transition: opacity 1s ease-in;
  }

  .fade-out {
    animation: fadeOut ease 5s;
    -webkit-animation: fadeOut ease 5s;
    -moz-animation: fadeOut ease 5s;
    -o-animation: fadeOut ease 5s;
    -ms-animation: fadeOut ease 5s;
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @-moz-keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @-webkit-keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @-o-keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @-ms-keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .fade-in {
    animation: fadeIn ease 10s;
    -webkit-animation: fadeIn ease 10s;
    -moz-animation: fadeIn ease 10s;
    -o-animation: fadeIn ease 10s;
    -ms-animation: fadeIn ease 10s;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
</style>
