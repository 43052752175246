<template>
  <div class="billing-report-header pg-component page-header">
    <div class="column head-left header-section">
      <img class="logo" src="/static/images/pglogo.png" alt="Pacific Gyre logo" style="width: 50%; height: 50%" />
      <div class="remit">Remit to:</div>
      <div class="">{{remitToOrg.orgName}}</div>
      <div class="" v-if="remitToOrg.orgAddress1 != ''">{{remitToOrg.orgAddress1}}, {{remitToOrg.orgAddress2}}</div>
      <div class="">{{remitToOrg.orgCity}}, {{remitToOrg.orgStateProvCode}} {{remitToOrg.orgPostalCode}}</div>
      <div class="">(760) 433-6300</div>
    </div>
    <div class="column header-center header-section">
      <div class="org-name">{{selectedOrg.orgName}}</div>
    </div>
    <div class="column head-right header-section">
      <div class="bill-title">Telemetry Statement</div>
      <div class="date-range">{{startDate}} to {{endDate}}</div>
      <div v-if="showAdHocNotes" class="statement-info">Statement Number: Ad hoc</div>
      <div class="billing-group">Billing Group: {{selectedOrg.billingGroupName}}</div>
    </div>
  </div>
</template>

/*************************************************************/

<script>
  var data = function () {
    return { organization: {} };
  };

  var props = ['selectedOrg', 'remitToOrg', 'startDate', 'endDate', 'showAdHocNotes'];

  var beforeMount = function () {};

  var mounted = function () {};

  var methods = {};

  var computed = {};

  var watch = {
    selectedOrg(value) {},
  };

  var components = {};

  export default {
    name: 'BillingReportHeader',
    data,
    props,
    beforeMount,
    mounted,
    methods,
    computed,
    watch,
    components,
  };
</script>

/*************************************************************/

<style scoped lang="scss">
  .page-header {
    margin-bottom: 50px;

    .header-section {
      display: inline-block;
      width: 32%;
      vertical-align: top;
    }

    .head-left {
      font-size: 1.1em;

      .logo {
        display: block;
        margin: 10px 0 30px;
      }
      .remit {
        font-size: 1em;
        font-style: italic;
        padding-bottom: 4px;
      }
    }
    .header-center {
      text-align: center;

      .org-name {
        display: table-cell;
        font-size: 1.3em;
        height: 125px;
        vertical-align: middle;
        width: 238px;
        margin: auto;
      }
    }
    .head-right {
      padding-top: 20px;
      text-align: right;

      .bill-title {
        font-size: 1.3em;
        font-weight: bold;
        margin-bottom: 18px;
      }
      .date-range {
        font-size: 1.2em;
        margin-bottom: 5px;
      }
    }
  }
</style>
