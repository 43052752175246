var moment = require('moment');

const pgUtils = {

  formatDate(timestamp, formatString) {
    // console.warn('pgUtils is obsolete. Instead, use:');
    // console.warn('  import * as pgHelpers from "@/utils/PgHelpers.js";');
    // console.warn('  pgHelpers.dateTimeHelper.formatDate()');

    if (!timestamp || timestamp.length < 5) {
      return '';
    }
    if (timestamp.substr(0, 4) === '0001') {
      return '';
    }
    formatString = formatString || "";
    if (formatString === "" || formatString === "ISO") {
      return moment.utc(timestamp).toISOString().replace(/\.\d+Z/,'Z');
    } else if (formatString === "ISO.MS") {
      return moment.utc(timestamp).toISOString();
    } else if (formatString === "LOCAL") {
      return moment().format();
    } else {
      return moment.utc(timestamp).format(formatString);
    }
  },

  timeAgo(timestamp) {
    console.warn('pgUtils is obsolete. Instead, use:');
    console.warn('  import * as pgHelpers from "@/utils/PgHelpers.js";');
    console.warn('  pgHelpers.dateTimeHelper.timeAgo()');
    if (!timestamp || timestamp.substr(0, 4) === '0001') {
      return 'never';
    }
    return moment(timestamp).fromNow(true);
  },

  makeJsonPatch(path, value) {
    console.warn('pgUtils is obsolete. Instead, use:');
    console.warn('  import * as pgHelpers from "@/utils/PgHelpers.js";');
    console.warn('  pgHelpers.dataHelper.makeJsonPatch()');
    return {
    'op': 'replace',
    'path': path,
    'value': value,
    };
  },

  makeMailToLink(sendTo, subject, body, cc, bcc, linkText) {
    console.warn('pgUtils is obsolete. Instead, use:');
    console.warn('  import * as pgHelpers from "@/utils/PgHelpers.js";');
    console.warn('  pgHelpers.textHelper.makeMailToLink()');
    var link = `<a href="mailto:${sendTo}?`;
    if (subject) {
      link = `${link}subject=${subject}&`;
    }
    if (cc) {
      link = `${link}cc=${cc}&`;
    }
    if (bcc) {
      link = `${link}bcc=${bcc}&`;
    }
    if (body) {
      link = `${link}body=${body}`;
    }
    if (linkText) {
      link = `${link}">${linkText}</a>`;
    } else {
      link = `${link}">${sendTo}</a>`;
    }
    return link;
  },

  gpsQualityText(gpsQuality) {
    console.warn('pgUtils is obsolete. Instead, use:');
    console.warn('  import * as pgHelpers from "@/utils/PgHelpers.js";');
    console.warn('  pgHelpers.textHelper.gpsQualityText()');
    var text = 'N/A';
    switch (gpsQuality) {
      case 1:
        text = 'Unreliable';
        break;
      case 2:
        text = '2D';
        break;
      case 3:
        text = '3D';
        break;
      case 5:
        text = 'Differential GPS';
        break;
    }
    return text;
  },


}; // pgutils

export default pgUtils;

// export * from './PgUtils/PgUtilsSystem';
