<template>
  <div class='org-details-wrapper org-details-form pg-component pg-sidebar'>
    <div class="pg-component-toolbar">
      <div class="pg-component-toolbar-left">Organization Details</div>
      <div class="pg-component-toolbar-center"></div>
      <div class="pg-component-toolbar-right">
        <div class="header-icons" v-if="hideSubmitCancelBtns">
          <span class="pg-component-toolbar-item" v-if="userHasClaim('Organization', 'update')">
            <span @click="editOrganization" title="Edit Organization Details" class="far fa-edit fa-lg"></span>
          </span>
        </div>
        <div class="header-icons" v-else>
          <span class="pg-component-toolbar-item">
            <span @click="submit" title="Submit" class="far fa-check-circle fa-lg"></span>
          </span>
          <span class="pg-component-toolbar-item">
            <span @click="cancel" title="Cancel" class="far fa-times-circle fa-lg"></span>
          </span>
        </div>
      </div>
    </div>

    <div class='pg-form-wrapper pg-component-content'>
      <form @submit.prevent="submit">
        <fieldset :disabled="formDisabled">
          <div class="pg-form vertical">
            <div class='form-group' :class='{ "form-group--error": $v.localOrgData.orgName.$error }'>
              <div @click="chastiseUser">
                <input ref='orgNameInput' id='orgName' type='text' :value='localOrgData.orgName'
                  @input='updateInputValue' name='orgName' />
              </div>
              <label for="orgName">Organization Name:</label>
            </div>

            <div class='form-group'>
              <div @click="chastiseUser">
                <input id="orgAddress1" type='text' :value='localOrgData.orgAddress1' @input='updateInputValue'
                  name='orgAddress1'>
              </div>
              <label for="orgAddress1">Address 1:</label>
            </div>

            <div class='form-group'>
              <div @click="chastiseUser">
                <input id="orgAddress2" type='text' :value='localOrgData.orgAddress2' @input='updateInputValue'
                  name='orgAddress2'>
              </div>
              <label for="orgAddress2">Address 2:</label>
            </div>

            <div class='form-group'>
              <div @click="chastiseUser">
                <input id="orgAddress3" type='text' :value='localOrgData.orgAddress3' @input='updateInputValue'
                  name='orgAddress3'>
              </div>
              <label for="orgAddress3">Address 3:</label>
            </div>

            <div class='form-group'>
              <div @click="chastiseUser">
                <input id="orgCity" type='text' :value='localOrgData.orgCity' @input='updateInputValue' name='orgCity'>
              </div>
              <label for="orgCity">City:</label>
            </div>

            <div class='form-group'>
              <div @click="chastiseUser">
                <input id="orgStateProvCode" type='text' :value='localOrgData.orgStateProvCode'
                  @input='updateInputValue' name='orgStateProvCode'>
              </div>
              <label for="orgStateProvCode">State/Province:</label>
            </div>

            <div class='form-group'>
              <div @click="chastiseUser">
                <input id="orgPostalCode" type='text' :value='localOrgData.orgPostalCode' @input='updateInputValue'
                  name='orgPostalCode'>
              </div>
              <label for="orgPostalCode">Postal Code:</label>
            </div>

            <div class='form-group'>
              <div @click="chastiseUser">
                <input id="orgCountryCode" type='text' :value='localOrgData.orgCountryCode' @input='updateInputValue'
                  name='orgCountryCode'>
              </div>
              <label for="orgCountryCode">Country:</label>
            </div>
          </div>
        </fieldset>
      </form>
      <!-- <div><pre>{{$v}}</pre></div> -->
    </div> <!-- End of main content to show/hide -->

    <div class="notification" v-if="showNotification">
      <span v-html="userMessage"></span>
    </div>
  </div> <!-- End of page wrapper -->
</template>

/*************************************************************/

<script>
  import { required } from 'vuelidate/lib/validators';
  import { mapState, mapActions, mapGetters } from 'vuex';

  var data = function () {
    return {
      message: '',
      localOrgData: {
        orgName: '',
      },
      formDisabled: true,
      isNewOrg: null,
      hideSubmitCancelBtns: true,
      showNotification: false,
      userMessage: '',

    };
  };

  var props = ['isEditingUser',];

  var validations = {
    localOrgData: {
      orgId: {},
      orgName: { required },
      orgAddress1: {},
      orgAddress2: {},
      orgAddress3: {},
      orgCity: {},
      orgStateProvCode: {},
      orgPostalCode: {},
      orgCountryCode: {}
    }
  };

  var mounted = function () {
    this.localOrgData = Object.assign({}, this.selectedOrganization);
  };

  var computed = {
    ...mapState('organizationModule', [
      'selectedOrganization',
    ]),
    ...mapGetters('userModule', [
      'userHasClaim',
      'userHasRole'
    ]),
    orgSelected: function () {
      return this.selectedOrganization.orgId > 0 ? true : false;
    },
  };

  var methods = {
    ...mapActions('organizationModule', [
      'updateOrganization',
      'createOrganization',
    ]),
    //  TODO: org list - deselect item in grid when clicked
    addOrganization() {
      if (this.isEditingUser) {
        return;
      }
      if (this.$v.$anyDirty) {
        alert('unsaved changes')
        return;
      } else {
        this.$v.$reset();
        this.clearInputs();
        this.isNewOrg = true;
        this.enableEditing(this.isNewOrg);
      }
    },

    editOrganization() {
      if (this.selectedOrganization.orgId === -1 || this.isEditingUser) {
        this.chastiseUser();
        return;
      }
      if (this.$v.$anyDirty) {
        alert('unsaved changes');
        return;
      }
      if (this.$v.localOrgData.orgName.$model === null) {
        this.disableEditing();
        this.localOrgData = Object.assign({}, this.selectedOrganization);
      } else {
        this.isNewOrg = false;
        this.enableEditing(this.isNewOrg);
      }
    },

    submit() {
      if (this.$v.localOrgData.$invalid) {
        return;
      } else {

        if (this.isNewOrg) {
          this.localOrgData.orgId = 0;
          this.createOrganization(this.localOrgData)
            .then(response => {
              var text = `${response.data.data.orgName} (${response.data.data.orgId}) successfully added`;
              this.$snotify.success(text, 'Organization');
              this.$emit('onSuccessfulOrgUpdate', response.data.data);
            });
        } else {
          // TODO: move to new function
          let orgId = this.localOrgData.orgId;
          let orgPatch = [];

          Object.keys(this.$v.localOrgData).forEach(element => {
            if (this.$v.localOrgData[element].$dirty) {
              let dirtyInput = {
                op: 'replace',
                path: '/' + element,
                value: this.localOrgData[element]
              };
              orgPatch.push(dirtyInput);
            }
          });
          orgPatch.push({ orgId: orgId });
          this.updateOrganization(orgPatch)
            .then(response => {
              var text = `${response.data.data.orgName} (${response.data.data.orgId}) successfully edited`;
              this.$snotify.success(text, 'Organization');
              this.$emit('onSuccessfulOrgUpdate', response.data.data);
            });
        }
        this.disableEditing();
        this.$v.$reset();
      }
    },

    clearInputs() {
      this.showNotification = false;
      Object.keys(this.localOrgData).forEach(key => {
        this.localOrgData[key] = '';
      });
    },

    chastiseUser() {
      if (!this.formDisabled) {
        return;
      }
      this.userMessage = '';
      if (this.isEditingUser) {
        this.userMessage = 'User Details are being edited.'
      } else if (this.selectedOrganization.orgId === -1) {
        this.userMessage = 'Please select an organization to edit from the dropdown in the user profile. ';
     } else {
        this.userMessage = `Please click the <i class="far fa-edit"></i> icon to edit this organization.`;
     }
      this.showNotification = true;
    },

    enableEditing(addingOrg) {
      this.$emit('onEditingOrg', true);
      var text = addingOrg ? 'added' : 'edited';
      this.formDisabled = null;
      this.showNotification = true;
      this.userMessage = `Organization is being ${text}`;
      this.hideSubmitCancelBtns = false;
      this.$nextTick(() => this.$refs.orgNameInput.focus());
    },

    disableEditing() {
      this.$emit('onEditingOrg', false);
      this.formDisabled = true;
      this.hideSubmitCancelBtns = true;
      this.isNewOrg = null;
      this.showNotification = false;
      this.userMessage = '';
    },

    cancel() {
      this.clearInputs();
      this.disableEditing();
      this.$v.$reset();
      if (this.selectedOrganization) {
        this.localOrgData = Object.assign({}, this.selectedOrganization);
      }
    },

    updateInputValue(e) {
      this.localOrgData[e.target.name] = e.target.value;
      this.$v.localOrgData[e.target.name].$touch();
    },

  };

  var watch = {
    selectedOrganization: function (value) {
      this.clearInputs();
      if (this.$v.$anyDirty) {
        // should never ever get here
        // alert('unsaved changes ... '); // TODO:  fix this :|
      } else {
        this.localOrgData = Object.assign({}, this.selectedOrganization);
      }
    },
    isEditingUser: function(value) {
      if (value && this.formDisabled) {
        this.userMessage = "User Details are being edited";
        this.showNotification = true;
      } else {
        this.userMessage = "";
        this.showNotification = false;
      }
    },
  };

  export default {
    data,
    props,
    validations,
    mounted,
    computed,
    methods,
    watch
  };
</script>

/*************************************************************/

<style scoped>
  .pg-component-toolbar {
    display: flex;
    font-size: 12px;
    font-weight: 600;
    flex-direction: row;
    align-items: center;
  }

  .pg-component-toolbar+span {
    flex: 1;
  }

  .header-icons {
    display: flex;
  }

  .pg-component-toolbar-item {
    border: none;
  }

  /* .organization-details-form {
    border: 1px solid #ccc;
  }

  .pg-form-wrapper {
    padding:  7px;
  } */

  .form-group--error input {
    border: red 1px solid;
  }
</style>
