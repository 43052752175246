<template>
  <div class="date-range-filter  pg-component ">
    <h6>Date Range Filter</h6>
    <form>
      <div class="type-selector">
        <label>
          <input type="radio" name="rangeType" id="timespan" value="TimeSpan" v-model="value.rangeType" checked /> Time Span
        </label>
        <label>
          <input type="radio" name="rangeType" id="daterange" value="DateRange" v-model="value.rangeType" /> Date Range
        </label>
      </div>
      <div v-show="value.rangeType==='TimeSpan'" class="wrap-container time-range-container">
        <div class="time-range-row">
          <span>Most recent</span>
          <input type="number" id="num-units" value="5" class="narrow number" v-model="value.numUnits" />
          <select id="time-units" v-model="value.timeUnit">
            <option value="minutes">Minutes</option>
            <option value="hours" selected>Hours</option>
            <option value="days">Days</option>
          </select>
        </div>
      </div>
      <div v-show="value.rangeType==='DateRange'" class="wrap-container date-range-container">
        <div class="flatpickr">
          <span class="from-to">From</span>
          <input type="text" id="start-date" placeholder="Select starting date..." class="start-date datetime" data-input />
          <a class="input-button" title="toggle" data-toggle v-on:click="toggleCalendar(startCalendar)">
              <i class="far fa-calendar"></i>
          </a>
          <a class="input-button" title="clear" data-clear v-on:click="startCalendar.clear();">
              <i class="far fa-times-circle"></i>
          </a>
        </div>
        <div class="flatpickr">
          <span class="from-to">To</span>
          <input type="text" id="end-date" placeholder="Select ending date..." class="end-date datetime" data-input />
          <a class="input-button" title="toggle" data-toggle v-on:click="toggleCalendar(endCalendar);">
              <i class="far fa-calendar"></i>
          </a>
          <a class="input-button" title="clear" data-clear v-on:click="endCalendar.clear();">
              <i class="far fa-times-circle"></i>
          </a> UTC
        </div>
      </div>
    </form>
    <hr />
  </div>
</template>

/*************************************************************/

<script>
  import moment from 'moment';

  // save component to var so it can be used in generic JS functions
  // "this" changes definitions
  var thisComponent = this;
  //console.log(thisComponent);

  var data = function() {
    return {
      startCalendar: '',
      endCalendar: '',
    };
  };

  var props = {
    value: {
      type: pgDateRangeParameters,
      default: pgDateRangeParameters,
    }
  };

  var beforeMount = function() {
    thisComponent = this;
  };

  var mounted = function() {
    flatpickrOptions.defaultDate = this.value.startDate;
    flatpickrOptions.defaultHour = 0;
    flatpickrOptions.defaultMinute = 0;
    this.startCalendar = flatpickr('#start-date', flatpickrOptions);

    flatpickrOptions.defaultDate = this.value.endDate;
    flatpickrOptions.defaultHour = 23;
    flatpickrOptions.defaultMinute = 59;
    this.endCalendar = flatpickr('#end-date', flatpickrOptions);
  };

  var methods = {
    toggleCalendar: function(calendar) {
      console.log(calendar.isOpen);
      var wasOpen = calendar.isOpen;
      setTimeout(function(){
        // calendar.toggle(); does not work
        if (wasOpen) {
          calendar.close();
        } else {
          calendar.open();
        }
        console.log('toggle');
      }, 0);
    }
  };

  var computed = {  };

  var watch= {
    // value: {
    //   handler(val) {
    //     console.log('date range value set', val);
    //     this.$emit('date-range-set', val);
    //   },
    //   deep: true,
    // },
  };

  var components = {  };

  var saveDates = function (selectedDates, dateStr, instance){
    // this is just a standard js function so "this" is not the component
    //console.log('saving dates. thisComponent=', thisComponent.value);
    if (thisComponent.value.rangeType==='TimeSpan') {
      thisComponent.value.startDate = '';
      thisComponent.value.endDate = '';
    } else {
      thisComponent.value.startDate = $('#start-date').val();
      thisComponent.value.endDate = $('#end-date').val();
    }
    thisComponent.$emit('date-range-set', thisComponent.value);
    //console.log('saveDates() notified parent of change ', thisComponent.value);
  };

  // this is the structure of the object passed between parent and this component
  // JS does not enforce this structure, it is just so we know what should be there
  var pgDateRangeParameters = {
    rangeType: 'TimeSpan', // timespan | daterange
    numUnits: 5,           // any int
    timeUnit: 'hours',     // minutes | hours | days
    startDate: moment().subtract(7,'d').format("YYYY-MM-DD"),
    endDate: '',
  };

  // https://flatpickr.js.org/options/
  var flatpickrOptions = {
    allowInput: true,
    enableTime: true,
    time_24hr: true,
    minuteIncrement: 15,
    onClose: saveDates,
    onChange: saveDates,
    plugins: [new confirmDatePlugin({
      showAlways: true,
    })]
  };

  export default {
    name: 'DateRangeFilter',
    data,
    props,
    beforeMount,
    mounted,
    methods,
    computed,
    watch,
    components,
    pgDateRangeParameters,
  };

</script>

/*************************************************************/

<style scoped>

  .wrap-container {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0 0 10px;
    align-items: center;
  }
  .time-range-container * {
    margin: 0 auto 5px;
  }
  .wrap-container input {
    margin: 0 3px 0 5px;
  }
  .datetime {
    display: inline-block;
    height: auto;
    width: 10em;
  }
  .flatpickr {
    display: inline-block;
    padding-bottom: 8px;
    margin-left: 10px;
    /* flex: 0; */
  }

  #time-units {
    height: 1.5em;
    padding: 0;
    xx-width: 50%;
  }
  .from-to {
    width: 2.3em;
    display: inline-block;
    text-align: right;
  }
  .type-selector {
    padding-bottom: 0.4em;
    text-align: center;
    line-height: 26px;
    vertical-align: middle;
  }
  @media (min-width: 34em) and (max-width: 44em) {
    .date-range-container {
      width: 18em;
    }
  }

</style>
