<template>
  <div class="devices-page page">
    <div class="page-content">
      <div class="panel-container-horizontal">
        <div class="panel-left">
          <div class="panel-contents">
            <device-list-grid
              :orgDevices="orgDevices"
              :isEditing="isEditing"
              :newDeviceInfo="newDeviceInfo"
              :exclude-telemetry-only="false"
              :noShowColumns="noShowColumns"
            ></device-list-grid>
          </div>
          <div class="handle v-handle">
            <i
              class="fas fa-caret-right display-none"
              :class="{'fa-rotate-180':rightPanelCollapsed}"
              @click="togglePanel('.panel-right', $event)"
            ></i>
          </div>
        </div>

        <div class="panel-right" v-bind:class="{'collapsed':rightPanelCollapsed}">
          <div class="panel-contents">
            <tabs ref="tabs" :can-change-tabs="!isEditing" :cant-change-tab-reason="cantChangeTabReason" @onTabChange="onTabChange">
              <tab name="Details" icon="far fa-edit" :selected="true">
                <div class="device-details-holder">
                  <device-details-form
                    @onEditing="onEditing"
                    @onNewDeviceInfo="onNewDeviceInfo"
                    @onViewBillingGroups="onViewBillingGroups" 
                    @onUpdatingDeviceBillingGroup="onUpdatingDeviceBillingGroup"
                    :isEditing="isEditing"
                    :deviceDetails="deviceDetails"
                    :billingGroups="billingGroups"
                    class="pg-detail-component"
                  ></device-details-form>
                </div>
              </tab>
              <tab name="Activations" icon="far fa-power-off">
                <div class="activations-holder">
                  <activation-form
                    :deviceData="deviceData"
                    :billingGroups="billingGroups"
                    :isEditing="isEditing"
                    :devicesWithBilling="devicesWithBilling"
                    @onNewActivation="onNewActivation"
                    @onViewBillingGroups="onViewBillingGroups"
                    @onUpdatingDeviceBillingGroup="onUpdatingDeviceBillingGroup"
                    class="pg-detail-component"
                  ></activation-form>
                  <div class="grid-container">
                    <activation-grid
                      ref="activationGrid"
                      :activationHistoryData="activationHistoryData"
                      :isEditing="isEditing"
                      @onActivationUpdate="onActivationUpdate"
                      class="pg-detail-component"
                    ></activation-grid>
                  </div>
                </div>
              </tab>
              <tab name="Commands" icon="far fa-bolt">
                <div class="commands-holder">
                  <command-form
                    :devicesWithCommands="devicesWithCommands"
                    :isEditing="isEditing"
                    @onNewCommand="onNewCommand"
                    class="pg-detail-component"
                  ></command-form>
                  <div class="grid-container">
                    <command-history-grid
                      :commandHistoryData="commandHistoryData"
                      :isEditing="isEditing"
                      class="pg-detail-component"
                    ></command-history-grid>
                  </div>
                </div>
              </tab>
            </tabs>
          </div>
        </div>
      </div>
    </div>
    <billing-group-modal
      v-show="showBillingGroupModal"
      @close="closeModal"
      @onUpdatingGroup="onUpdatingGroup"
      @onDeleteGroup="onDeleteGroup"
      @onUndoChange="onUndoChange"
      :billingGroups="billingGroupsForModal"
      :singleBillingGroup="singleBillingGroup"
    />

  </div>
</template>

/*************************************************************/

<script>
  import { mapGetters, mapState, mapActions } from 'vuex';
  import DeviceListGrid from '@/components/DeviceListGrid';
  import ActivationGrid from '@/components/ActivationGrid';
  import ActivationForm from '@/components/ActivationForm';
  import BillingGroupModal from '@/components/BillingGroupModal';
  import BillingGroupConfirmModal from "@/components/BillingGroupConfirmModal";
  import CommandHistoryGrid from '@/components/CommandHistoryGrid';
  import CommandForm from '@/components/CommandForm';
  import DeviceDetailsForm from '@/components/DeviceDetailsForm';
  import PgSelect from '@/components/Common/PgSelect';
  import Tabs from '@/components/Common/TabsComponent';
  import Tab from '@/components/Common/TabComponent';
  import environmentSpecific from 'static/environmentSpecific';
  import pgUtils from '@/utils/PgUtils.js';


  var thisComponent = null;

  export default {
    name: 'DevicesPage',
    props: [],
    data() {
      return {
        isEditing: false,
        newDeviceInfo: {},
        newActivations: [],
        noShowColumns: [],
        rightPanelCollapsed: false,
        rightPanelWidthPx: 430,
        cantChangeTabReason: null,
        currentTab: '',
        telemetryOnlyDevicesIncluded: false,
        showBillingGroupModal: false,
        showConfirmModal: false,
        billingGroupsForModal: [],
        singleBillingGroup: {},

        needCommands: true,
        needBilling: true,

        // for grids - filtered as needed
        activationHistoryData: [],
        commandHistoryData: [],

        // for forms
        deviceDetails: {},
        deviceData: [],
        billingGroups: [],
        devicesWithBilling: [],
        devicesWithCommands: [],

        // orig to filter and for deviceListGrid
        orgCommandHistory: [],
        orgActivationHistory: [],
        orgDevices: [],
      };
    },

    created() {
      thisComponent = this;
      this.cantChangeTabReason = "Can't change tabs while in edit mode";
    },

    mounted() {
      this.showBillingGroupModal = false;
      this.telemetryOnlyDevicesIncluded = false;
      this.updateNoShowList();
      this.getDevicesForOrg();
      this.getBillingGroupsForOrg();
      if (this.selectedOrganization.orgId === -1) {
        this.setSelectedDeviceIds([]);
      }
      this.needCommands = true;
      this.needBilling = true;
      // this.getDataForOrg();

      $('.panel-left').panelresizable({
        handleSelector: '.v-handle',
        resizeWidth: true,
        resizeHeight: false,
        resizeWidthFrom: 'right',
        onDragEnd: function(evt) {
          thisComponent.onResizeEnd('.panel-right', evt);
        },
      });

      // 5/21/21 - changed so that details is always selected onload
      var requestedTab = this.$route.hash;
      if (requestedTab) {
        this.$router.push({ path: 'devices' });
      } else {
        this.$router.push({ path: 'devices', hash: '#details-tab' });
      }
      this.currentTab = '#details-tab';
    },

    beforeDestroy() {},

    methods: {
      ...mapActions('deviceModule', ['getDevices', 'getDeviceActivations', 'setSelectedDeviceIds']),
      ...mapActions('commandModule', ['getCommandHistory']),
      ...mapActions('backOfficeModule', ['getBillingGroups', 'updateBillingGroup', 'createBillingGroup', 'deleteBillingGroup', 'getBillingGroupById', 'setDeviceBillingGroup']),


      onEditing(value) {
        this.isEditing = value;
      },
      onNewDeviceInfo(value) {
        this.newDeviceInfo = value;
      },

      onNewActivation() {
        this.getActivationHistoryForOrg();
      },

      onViewBillingGroups() {
        this.showBillingGroupModal = true;
      },

      onActivationUpdate() {
        this.getActivationHistoryForOrg();
      },

      onNewCommand() {
        this.getCommandHistoryForOrg();
      },

      closeModal() {
        this.showBillingGroupModal = false;
      },

      onAddBillingGroup(group) {
        var orgId = this.selectedOrganization.orgId;
        var name = '';
        var description = '';
        group.forEach(gr => {
          if (gr.column == "billingGroupName") {
            name = gr.value;
          }
          if (gr.column == "billingGroupDescription") {
            description = gr.value;
          }
        });
        var isActive = true;
        var whoEntered = this.$pgGlobal.currentUser.userName;
        var nowUtc = pgUtils.formatDate(this.moment().format(), 'ISO');
        
        var newGroup = {
          orgId : orgId,
          billingGroupName : name,
          billingGroupDescription : description,
          isActive : isActive,
          whenUpdated: nowUtc,
          whoUpdated: whoEntered
        };
        this.createBillingGroup(newGroup).then(response => {
          this.getBillingGroupsForOrg();
        });
      },

      onUndoChange(id) {
        this.getBillingGroupById(id).then(response => {
          this.singleBillingGroup = response[0];
        });
      },

      onUpdatingDeviceBillingGroup(billingGroupId, deviceIds) {
        this.setDeviceBillingGroup({billingGroupId, deviceIds}).then(response => {
          this.getDevicesForOrg();
        });
      },

      getBillingGroupsForOrg() {
        var orgId = this.selectedOrganization.orgId;
        this.getBillingGroups(orgId).then(response => {
          this.billingGroupsForModal = response.filter(re => re.isActive == true);
          this.billingGroups = response.filter(re => re.isActive == true);
          this.billingGroups.splice(0, 0, {billingGroupId: 0, billingGroupName: 'Unassigned'});
          this.billingGroups.push({billingGroupId: null, billingGroupName: 'Add Group'});
        });
      },

      onUpdatingGroup(changes) {
        console.log(changes)
        var whoEntered = this.$pgGlobal.currentUser.userName;
        var nowUtc = pgUtils.formatDate(this.moment().format(), 'ISO');
        var id = null;
        var newGroup = [];
        changes.forEach(el => {
            id = el.id;
            if (id != 0) {
              var patch = [ {
                "op": "replace",
                "path" : '/' + el.column,
                "value": el.value,
              },
              id];
            this.updateBillingGroup(patch).then(response => {
              console.log(changes.length)
            });
          } else {
            newGroup.push(el);
          }
        });
        if (newGroup.length > 0) {
          this.onAddBillingGroup(newGroup);
        }
      },

      onDeleteGroup(group) {
        var id = group.billingGroupId;
        this.deleteBillingGroup(id).then(response => {
          this.getBillingGroupsForOrg();
          this.getDevicesForOrg();
        });
      },

      getDevicesForOrg() {
        var orgId = this.selectedOrganization.orgId;
        var params = {
          getCommands: false,
          getBillingPlans: false,
          getSensorPackages: false,
        };
        if (orgId === -1) {
          params.takeRecords = environmentSpecific.maxNumberDevices;
        } else {
          params.orgId = orgId;
        }
        this.getDevices(params).then(response => {
          var devices = response;
          var serviceTypes = devices.map(d => d.serviceTypeCode);
          this.getBillingGroupsForOrg();
          this.telemetryOnlyDevicesIncluded = serviceTypes.includes('telemetryOnly');
          this.updateNoShowList();
          this.orgDevices = response;
          this.getDataForActiveTab();
        });
      },



      updateNoShowList() {
        this.noShowColumns = ['highlight'];
        if (this.selectedOrganization.orgId != -1) {
          this.noShowColumns.push('orgName');
        }
        if (!this.telemetryOnlyDevicesIncluded) {
          this.noShowColumns.push('destinations');
        }
        this.noShowColumns = _.uniq(this.noShowColumns);
      },

      togglePanel(selector, evt) {
        this.rightPanelCollapsed = !this.rightPanelCollapsed;
        var width = this.rightPanelCollapsed ? 0 : this.rightPanelWidthPx;
        $('.panel-right').outerWidth(width);
      },
      onResizeEnd() {
        var width = $('.panel-right').outerWidth();
        this.rightPanelCollapsed = width <= 3;
      },

      emptyArrays() {
        this.deviceWithBilling = [];
        this.devicesWithCommands = [];
        this.orgDevices = [];
        this.orgActivationHistory = [];
        this.orgCommandHistory = [];
      },

      emptyFilteredArrays() {
        this.activationHistoryData = [];
        this.commandHistoryData = [];
      },

      filterDataByDeviceId(dids) {
        this.activationHistoryData = this.orgActivationHistory.filter(d => dids.includes(d.deviceId));
        this.commandHistoryData = this.orgCommandHistory.filter(d => dids.includes(d.deviceId));
      },

      removeFilters() {
        this.activationHistoryData = this.orgActivationHistory;
        this.commandHistoryData = this.orgCommandHistory;
      },

      onTabChange(tab) {
        this.currentTab = tab.id;
        if (this.currentTab.length > 0) {
          this.getDataForActiveTab();
        }
      },

      getDataForActiveTab() {
        if (this.currentTab == '#activations-tab') {
          if (this.orgActivationHistory.length === 0) {
            this.getActivationHistoryForOrg();
          }
          if (this.selectedDeviceIds.length > 0 && this.needBilling) {
            this.getBillingPlansForSelectedDevices();
          }
        } else if (this.currentTab == '#commands-tab') {
          if (this.orgCommandHistory.length === 0) {
            this.getCommandHistoryForOrg();
          }
          if (this.selectedDeviceIds.length > 0 && this.needCommands) {
            this.getCommandsForSelectedDevices();
          }
        } else if (this.currentTab == '#details-tab') {
          this.getDeviceDetails();
        }
      },

      getActivationHistoryForOrg() {
        var orgId = this.selectedOrganization.orgId;
        var sc = {};
        if (orgId === -1) {
          var startDate = this.moment()
            .subtract(environmentSpecific.maxNumberHistoryDays, 'days')
            .format();
          sc = {
            startDateTime: startDate,
          };
        } else {
          sc = { orgId: orgId };
        }
        this.getDeviceActivations(sc).then(response => {
          this.orgActivationHistory = response;
          if (this.selectedDeviceIds.length === 0) {
            this.activationHistoryData = response;
          } else {
            var dids = this.selectedDeviceIds;
            this.activationHistoryData = this.orgActivationHistory.filter(d => dids.includes(d.deviceId));
          }
        });
      },

      // used in activation form
      getBillingPlansForSelectedDevices() {
        if (this.selectedDeviceIds.length > 0) {
          var dids = this.selectedDeviceIds.join(',');
          var params = {
            deviceIds: dids,
            getCommands: false,
            getBillingPlans: true,
            getSensorPackages: false,
          };
          this.getDevices(params).then(response => {
            this.devicesWithBilling = response;
            this.needBilling = false;
          });
        }
      },

      getCommandHistoryForOrg() {
        var orgId = this.selectedOrganization.orgId;
        var sc = {};
        if (orgId === -1) {
          var startDate = this.moment()
            .subtract(environmentSpecific.maxNumberHistoryDays, 'days')
            .format();
          sc = {
            startDateTime: startDate,
          };
        } else {
          sc = { orgId: orgId };
        }
        this.getCommandHistory(sc).then(response => {
          this.orgCommandHistory = response;
          if (this.selectedDeviceIds.length === 0) {
            this.commandHistoryData = response;
          } else {
            var dids = this.selectedDeviceIds;
            this.commandHistoryData = this.orgCommandHistory.filter(d => dids.includes(d.deviceId));
          }
        });
      },

      getCommandsForSelectedDevices() {
        if (this.selectedDeviceIds.length > 0) {
          var dids = this.selectedDeviceIds.join(',');
          var params = {
            deviceIds: dids,
            getCommands: true,
            getBillingPlans: false,
            getSensorPackages: false,
          };
          this.getDevices(params).then(response => {
            this.deviceData = response;
            this.devicesWithCommands = response;
            this.needCommands = false;
          });
        }
      },

      getDeviceDetails() {
        if (this.selectedDeviceIds.length === 1) {
          var deviceId = this.selectedDeviceIds[0].toString();
          var params = {
            deviceIds: deviceId,
            getCommands: false,
            getBillingPlans: true,
            getSensorPackages: true,
          };
          this.getDevices(params).then(response => {
            this.deviceDetails = response[0];
          });
        } else {
          this.deviceDetails = {};
        }
      },
    },

    computed: {
      isPgStaff: function() {
        return this.userHasRole('PGStaff');
      },
      ...mapState('organizationModule', ['selectedOrganization']),
      ...mapState('deviceModule', ['selectedDeviceIds']),
      ...mapGetters('userModule', ['userHasRole']),
    },

    watch: {
      selectedOrganization: function(value) {
        this.emptyArrays();
        this.getDevicesForOrg();
        this.getBillingGroupsForOrg();
        this.setSelectedDeviceIds([]);
      },
      selectedDeviceIds: function(value) {
        this.emptyFilteredArrays();
        if (value.length > 0) {
          this.filterDataByDeviceId(value);
        } else {
          this.removeFilters();
          return;
        }
        this.needCommands = true;
        this.needBilling = true;
        this.getDataForActiveTab();
      },
    },

    components: {
      DeviceListGrid,
      ActivationGrid,
      ActivationForm,
      BillingGroupModal,
      BillingGroupConfirmModal,
      CommandHistoryGrid,
      CommandForm,
      DeviceDetailsForm,
      PgSelect,
      Tabs,
      Tab,
    },
  };
</script>

/*************************************************************/

<style lang="scss">
  .app-content {
    overflow: hidden;
  }

  .devices-page {
    /* device list grid */
    .device-list-container {
      flex: 1 1 auto;
      display: flex;
    }
    .device-list-grid {
      width: 100%;
      display: flex;
      flex-direction: row;
    }
    .device-list-grid-body {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    .device-list-grid-container {
      flex: 1 1 20vh; // SET TO AUTO ---------- debugging

      display: flex;
    }

    /* right side */
    .activations-holder {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .activation-grid-container {
      display: flex;
      width: 100%;
    }
    .activation-list-grid {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      width: 100%;
    }

    .commands-holder {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .command-history-container {
      display: flex;
      width: 100%;
    }
    .command-history-list-grid {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      width: 100%;
    }
    .panel-container-horizontal {
      flex: 1 1 auto;
    }
    .panel-container-vertical {
      max-height: calc(100vh - 40px);
    }
    .panel-left {
      width: 50vw;
      min-width: 16px;
      max-width: 100%;
      display: flex;
      overflow: hidden; /* to fix Safari */
    }
    .panel-contents {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
    }
    .panel-right {
      padding: 10px;
      overflow: auto;
    }
    .panel-right.collapsed {
      padding: 0;
      width: 0;
    }

    // footer
    .footer {
      flex: 0 0 auto;
    }

    // popup windows
    .activation-confirm-popup {
      margin: 40px auto 0;
      width: 300px;
    }
    .command-confirm-popup {
      margin: 40px auto 0;
      width: 300px;
    }
    .billing-group-popup {
      margin: 35px auto 0;
      width: 700px;
    }

    .billing-group-popup .popup-content {
      overflow: auto;
      height: 600px;
    }
    .billing-group-confirm-popup.popup-window {
      margin: 80px auto 0;
      width: 300px;
    }
    .billing-group-confirm-popup .popup-content {
      height: 250px;
    }
    .button.disabled {
      cursor: none;
    }

  }
</style>

<style scoped></style>
