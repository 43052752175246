/*

USE:
import * as pgHelpers from "@/utils/PgHelpers.js";
let formattedDate = pgHelpers.dateTimeHelper.formatDate(someDate);

https://momentjs.com/docs/#/displaying/
Here are some of the more common options...
Month
M	     1 2 ... 11 12
Mo	   1st 2nd ... 11th 12th
MM	   01 02 ... 11 12
MMM	   Jan Feb ... Nov Dec
MMMM	 January February ... November December

Quarter
Q	     1 2 3 4
Qo	   1st 2nd 3rd 4th

Day of Month
D	     1 2 ... 30 31
Do	   1st 2nd ... 30th 31st
DD	   01 02 ... 30 31

Day of Week
ddd	   Sun Mon ... Fri Sat
dddd	 Sunday Monday ... Friday Saturday

Year
YY	   70 71 ... 29 30
YYYY	 1970 1971 ... 2029 2030
Y	     1970 1971 ... 9999 +10000 +10001

AM/PM
A	     AM PM
a	     am pm

Hour
H	     0 1 ... 22 23
HH	   00 01 ... 22 23
h	     1 2 ... 11 12
hh	   01 02 ... 11 12
k	     1 2 ... 23 24
kk	   01 02 ... 23 24

Minute
m	     0 1 ... 58 59
mm	   00 01 ... 58 59

Second
s	     0 1 ... 58 59
ss	   00 01 ... 58 59

Time Zone
Z	    -07:00 -06:00 ... +06:00 +07:00
ZZ	  -0700 -0600 ... +0600 +0700

*/

var moment = require('moment');
var myDateFormat = 'YYYY-MM-DD HH:mm [(UTC)]'; // ISO  ISO.MSnp

const dateTimeHelper = {
  formatDate(timestamp, formatString) {
    if (!timestamp || timestamp.length < 5) {
      return '';
    }
    if (typeof timestamp === 'string' && timestamp.substr(0, 4) === '0001') {
      return '';
    }
    formatString = formatString || myDateFormat;

    if (formatString === '' || formatString === 'ISO') {
      return moment
        .utc(timestamp)
        .toISOString()
        .replace(/\.\d+Z/, 'Z');
    } else if (formatString === 'ISO.MS') {
      return moment.utc(timestamp).toISOString();
    } else if (formatString === 'LOCAL') {
      return moment().format();
    } else {
      return moment.utc(timestamp).format(formatString);
    }
  },

  timeAgo(timestamp) {
    if (!timestamp || timestamp.substr(0, 4) === '0001') {
      return 'never';
    }
    return moment(timestamp).fromNow(true);
  },

  dateRangeObjectToText(dateRangeFilter) {
    var text = '';
    if (dateRangeFilter.rangeType === 'TimeSpan') {
      text += 'Most recent ' + dateRangeFilter.numUnits + ' ' + dateRangeFilter.timeUnit;
    } else {
      if (dateRangeFilter.startDate && dateRangeFilter.endDate) {
        text += 'From ' + dateRangeFilter.startDate + ' through ' + dateRangeFilter.endDate;
      } else if (dateRangeFilter.startDate) {
        text += 'Since ' + dateRangeFilter.startDate;
      } else if (dateRangeFilter.endDate) {
        text += 'Through ' + dateRangeFilter.endDate;
      } else {
        text += 'No dates specified';
      }
    }
    return text;
  },
  previousQtrDates(timestamp) {
    var month = timestamp.getUTCMonth(); // Jan = 0
    var year = timestamp.getUTCFullYear();
    var prevYear = year - 1;
    var start = '';
    var end = '';
    if (month <= 2) {
      // jfm returns ond previous year
      start = new Date(prevYear, 9, 1);
      end = new Date(prevYear, 11, 31);
    } else if (month <= 5) {
      // amj returns jfm current year
      start = new Date(year, 0, 1);
      end = new Date(year, 2, 31);
    } else if (month <= 8) {
      // jas returns amj current year
      start = new Date(year, 3, 1);
      end = new Date(year, 5, 30);
    } else {
      // ond returns jas current year
      start = new Date(year, 6, 1);
      end = new Date(year, 8, 30);
    }
    start = start.toLocaleDateString();
    end = end.toLocaleDateString();
    return { startDate: start, endDate: end };
  },

  currentQtrDates(timestamp) {
    var month = timestamp.getUTCMonth(); // Jan = 0
    var year = timestamp.getUTCFullYear();
    var prevYear = year - 1;
    var start = '';
    var end = '';
    if (month <= 2) {
      // jfm returns jfm
      start = new Date(year, 0, 1);
      end = new Date(year, 2, 31);
    } else if (month <= 5) {
      // amj returns amj current year
      start = new Date(year, 3, 1);
      end = new Date(year, 5, 30);
    } else if (month <= 8) {
      // jas returns jas current year
      start = new Date(year, 6, 1);
      end = new Date(year, 8, 30);
    } else {
      // ond returns ond current year
      start = new Date(year, 9, 1);
      end = new Date(year, 11, 31);
    }
    start = start.toLocaleDateString();
    end = end.toLocaleDateString();
    return { startDate: start, endDate: end };
  },
};

export { dateTimeHelper };
