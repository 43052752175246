<!-- template for the modal component -->
<template id="unsaved-changes-modal">
  <pg-modal :show="show" @close="close">
    <div class="modal-header">
      <h3>Warning</h3>
    </div>
    <div class="modal-body">
      <span>
       You have unsaved changes.  Do you wish to leave without saving them?
      </span>
    </div>
    <div class="modal-footer">
      <button class="modal-default-button" @click="submit">
          Yes
      </button>
      <button class="modal-default-button" @click="close">
          No
      </button>
    </div>
  </pg-modal>
</template>

<script>
import PgModal from "@/components/Common/PgModal";
// needs work

export default {
  name: "UnsavedChangesModal",
  props: ["show",],
  components: { PgModal },
  data: function() {
    return {
      // title: "",
      // body: ""
    };
  },
  methods: {
    close: function() {
      this.$emit('close');
    },
    submit: function() {
      this.$emit('close');
      this.close();
    }
  }
};
</script>


/*************************************************************/

<style scoped>
</style>