// Example:
//   import ProfileService from '@/services/ProfileService';
//   var selectedSensors = ProfileService.getLocalSetting('selectedSensors', null);

import axios from 'axios';
import environmentSpecific from 'static/environmentSpecific';

const profileService = {

  profile: {
    currentTheme: null,
  },

  // Local settings - may vary by user's device
  // e.g. color might be different on PC and tablet
  getLocalSetting(settingName, defaultValue) {
    if (!settingName) { return; }
    var settingValue = localStorage.getItem(settingName);
    if (!settingValue) { return defaultValue; }
    if (settingValue[0] === "{" || settingValue[0] === "[") {
      settingValue = JSON.parse(settingValue);
    }
    return settingValue;
  },

  saveLocalSetting(settingName, settingValue) {
    if (!settingName || !settingValue) { return; }
    if (typeof settingValue === "object") {
      settingValue = JSON.stringify(settingValue);
    }
    localStorage.setItem(settingName, settingValue);
  },

  deleteLocalSetting(settingName) {
    localStorage.removeItem(settingName);
  },

  getDbSetting(userId, settingName, defaultValue) {
    return new Promise((resolve, reject) => {
      if (!settingName) { resolve(null); }
      var url = `users/getUserSettings?userId=${userId}&settingName=${settingName}`;
      this.callApi(url)
        .then((response) => {
          if (response && response.data && response.data.data && response.data.data.setting) {
            var settingValue = response.data.data.setting;
            try {
              settingValue = JSON.parse(response.data.data.setting);
            }
            catch(error){
              settingValue = response.data.data.setting;
            }
            resolve(settingValue)
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          console.error('Profile service getDbSetting caught error calling Url [' + url + ']', error);
          reject(error);
        });
    });
  },

  saveDbSetting(userId, settingName, settingValue) {
    return new Promise((resolve, reject) => {
      if (!settingName) { reject('Must include setting name'); }
      if (typeof settingValue === 'object') {
        settingValue = JSON.stringify(settingValue);
      }

      var data = {
        'userId': userId,
        'settingName': settingName,
        'setting': settingValue,
        'comment': null,
      };
      var url = `users/saveUserSettings`;
      this.callApiPost(url, data)
        .then((response) => {
          if (response && response.data && response.data.setting) {
            var settingValue = response.data.setting;
            if (settingValue[0] === '{') {
              settingValue = JSON.parse(settingValue);
            }
            resolve(settingValue)
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          console.error('Profile service saveDbSetting caught error calling Url [' + url + ']', error);
          reject(error);
        });
    });

  },

  // FUTURE - Global user settings saved to DB
  //          Same for all devices, e.g. date format
  callApi(urlSegment) {
    return new Promise((resolve, reject) => {
      var fullUrl = `${environmentSpecific.apiBaseUrl}${urlSegment}`;
      axios.get(fullUrl)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            console.warn('ProfileService callApi() returned 404 calling Url [' + fullUrl + ']', error);
            resolve({});
          } else {
            console.error('ProfileService callApi() caught error calling Url [' + fullUrl + ']', error);
            reject(error);
          }
        });
    });
  },

  callApiPost(urlSegment, data) {
    return new Promise((resolve, reject) => {
      var fullUrl = `${environmentSpecific.apiBaseUrl}${urlSegment}`;
      axios.post(fullUrl, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error('ProfileService callApiPost() caught error calling Url [' + fullUrl + ']');
          console.error('   with data ', data, ' ERROR:', error);
          console.error('   ERROR:', error);
          reject(error);
        });
    });
  },

}; // profileService

export default profileService;
