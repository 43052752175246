<template>
  <div class="pg-map-popup-window popup-window resizeable-bottom-right">
    <div class="popup-header ">
      <div> {{ deviceName }} <span class="dev-only">({{ deviceId }})</span>  </div>
      <i class="far fa-times-square closer" v-on:click="closeMe()"></i>
    </div>

    <div class="pg-component-toolbar popup-toolbar fullwidth-toolbar" v-bind:class="{'showing-tracks':showingTracks}">
      <div class="pg-component-toolbar-item " v-on:click="previousDevice();" title="Previous Device" >
        <i class="far fa-arrow-circle-left" ></i>
      </div>
      <div class="pg-component-toolbar-item " v-on:click="firstPoint();" title="First Point" >
        <i class="far fa-fast-backward" ></i>
      </div>
      <div class="pg-component-toolbar-item " v-on:click="previousPoint();" title="Previous Point" >
        <i class="far fa-backward" ></i>
      </div>

      <div class="pg-component-toolbar-item" v-on:click="showTracks(marker.device.deviceId.toString())" title="Show tracks this device" >
        <i class="fas fa-signature" ></i>
      </div>
      <div class="pg-component-toolbar-item" v-on:click="hideTracks(marker.device.deviceId.toString())" title="Hide tracks this device" >
        <i class="fal fa-signature " ></i>
      </div>

      <div class="pg-component-toolbar-item " v-on:click="nextPoint();" title="Next Point" >
        <i class="far fa-forward" ></i>
      </div>
      <div class="pg-component-toolbar-item " v-on:click="finalPoint();" title="Final Point" >
        <i class="far fa-fast-forward" ></i>
      </div>
      <div class="pg-component-toolbar-item " v-on:click="nextDevice();" title="Next Device" >
        <i class="far fa-arrow-circle-right" ></i>
      </div>
    </div>

    <div class="popup-content">

      <tabs ref="tabs">
        <tab name="Overview" :selected=true icon="far fa-search-location">
          <!-- use v-if to avoid marker.device not defined error -->
          <div v-if="marker && marker.device && marker.data">
            <div class="org-name">{{ marker.device.orgName }} </div>
            <div class="row">
              <span class="prompt">DeviceName</span>
              <span class="device-name" v-bind:title="'DID: ' +  marker.device.deviceId">{{ marker.device.deviceName }} </span>
              <span class="dev-only"> ({{ marker.device.deviceId }}) </span>
            </div>
            <div class="row">
              <span class="prompt">Comm ID</span>
              {{ marker.device.comm1 }}
            </div>
            <div class="row">
              <span class="prompt">Date/Time UTC</span>
              {{ deviceDateTime | formatDate }}
            </div>
            <div class="row">
              <span class="prompt">Location</span>
              {{ latlong }}
              <!-- {{ marker.data.latitude }}, {{ marker.data.longitude }} -->
            </div>
            <div class="row">
              <span class="prompt">GPS Quality</span>
              {{ marker.data.gpsQuality }} ({{ gpsQualityText(marker.data.gpsQuality) }})
            </div>
            <div class="row">
              <span class="prompt">SST</span>
              {{ marker.data.sst }}&deg;C
            </div>
            <div class="row">
              <span class="prompt">Battery Volts</span>
              {{ marker.data.battVoltage }} VDC
              <span class="battery-bar">
                <span v-bind:style="batteryBar(marker.data.battVoltage, 5, 13)"></span>
              </span>
            </div>
            <div class="row lat-long-row">
              <span>Lat/Long Display</span>
              <input type="radio" name="lat-long" id="degree" value="d" v-model="selectedCoordFormat" @change="formatCoordinates"><label for="degree">D</label>
              <input type="radio" name="lat-long" id="deg-min" value="dm" v-model="selectedCoordFormat" @change="formatCoordinates"><label for="deg-min">DM</label>
              <input type="radio" name="lat-long" id="deg-min-sec" value="dms" v-model="selectedCoordFormat" @change="formatCoordinates"><label for="deg-min-sec">DMS</label>
            </div>
            <div class="row track-color-row">
              <span class="prompt">Track color</span>
              <select v-model="desiredTrackColor" v-on:change="changeTrackColor()" v-bind:style="{ 'backgroundColor' : desiredTrackColor }">
                <option v-for="color in trackColors" v-bind:value="color" v-bind:key="color" v-bind:style="{ 'backgroundColor' : color }">
                  <span class="track-color-bar" ></span>
                </option>
              </select>
            </div>
            <div class="row track-index-row pg-only" v-if="false">
              <span class="prompt">Track Index</span>
              {{ marker.trackIndex }}
            </div>

            <div class="device-type">
              <img v-if="marker.icon" v-bind:src="marker.device.deviceTypeIcon" class="device-type-icon" >
              <div class="device-type-name">{{ marker.device.deviceTypeName }}</div>
            </div>

          </div>
        </tab>
        <tab name="Data" icon="far fa-list-alt">
          <p> {{ deviceDateTime | formatDate }} </p>
          <div class="div-table simple-table">
            <div class="table-head">
                <span class="sensor-name">Sensor</span>
                <span class="sensor-value">Value</span>
            </div>
            <div class="table-body">
              <div v-for="(value, propertyName, index) in positionData" v-bind:key="propertyName" class="table-row">
                <span class="sensor-name">{{ value.displayName }}</span>
                <span class="sensor-value">{{ value.floatValue | formatData(value.displayName) }}{{ value.unitDisplay }}</span>
              </div>
            </div>
          </div>
        </tab>
        <!-- <tab name="Graph" icon="far fa-chart-area">
          <p>Coming soon...</p>
        </tab> -->
      </tabs>
    </div>
  </div>
</template>

<script>
import dataService from "@/services/DataService";
import Vue from 'vue';
//import pgUtils from "@/utils/PgUtils.js";
import * as pgHelpers from "@/utils/PgHelpers.js";
import ProfileService from '@/services/ProfileService';

import Tabs from '@/components/Common/TabsComponent';
import Tab from '@/components/Common/TabComponent';
import { EventBus } from '../EventBus.js';

var mapPopupComponent;
var myDateFormat = "YYYY-MM-DD HH:mm "; // ISO  ISO.MSnp

export default {
  name: "MapPopupWindow",

  data() {
    return {
      positionData: {},
      latlong: '',
      selectedCoordFormat: '',
      marker: {},
      desiredTrackColor: this.trackColor,
    };
  },

  props: {
    initialMarker: {default: null},
    trackColors:   {default: null},
    showingTracks: {default: false},
  },

  created() {
    this.selectedCoordFormat = ProfileService.getLocalSetting('coordinateFormat', 'd');
    mapPopupComponent = this;
  },

  mounted() {
    // EventBus.$on('HIGHLIGHT_DEVICEID', deviceId => {
    //   console.log('MapPopupWindow caught the bus HIGHLIGHT_DEVICEID ', deviceId);
    // });
    // console.log('>> prop marker is ', this.marker);
    // console.log('Map popup window mounted() - why?');
    // debugger
    // this.getDataThisPosition();
  },

  computed: {
    trackColor: function() {
      return _.get(this.marker, 'trackColor', 'cyan');
    },
    deviceName: function() {
      return _.get(this.marker, 'device.deviceName', 'No device selected');
    },
    deviceId: function() {
      return _.get(this.marker, 'device.deviceId', '');
    },
    deviceDateTime: function() {
      return _.get(this.marker, 'data.deviceDateTime', null);
    },
    // trackColor: function() {
    //   return this.lineColors[this.marker.markerId % this.lineColors.length];
    // },
  },

  filters: {

    formatDate: function(value) {
      //console.log('>> filter formatDate formatting: ', value);
      // return pgUtils.formatDate(value, myDateFormat);
      return pgHelpers.dateTimeHelper.formatDate(value, myDateFormat);

    },
    formatData: function(value, displayName) {
      if (displayName && displayName.toLowerCase().includes('date')) {
        // value = pgUtils.formatDate(value, myDateFormat);
        value = pgHelpers.dateTimeHelper.formatDate(value, myDateFormat);
      }
      return value;
    },

  },

  methods: {
    // toolbar commands
    previousDevice: function() { EventBus.$emit('MAP_SELECT_PREVIOUS_DEVICE'); },
    nextDevice: function() { EventBus.$emit('MAP_SELECT_NEXT_DEVICE'); },
    previousPoint: function() {
      console.log('prev point', this.marker);
      EventBus.$emit('MAP_SELECT_PREVIOUS_POINT', this.marker.trackIndex);
    },
    nextPoint: function() { EventBus.$emit('MAP_SELECT_NEXT_POINT'); },
    firstPoint: function() { EventBus.$emit('MAP_SELECT_FIRST_POINT'); },
    finalPoint: function() { EventBus.$emit('MAP_SELECT_FINAL_POINT'); },

    changeTrackColor: function(){
      console.log('change track color from ', this.trackColor, ' to ', this.desiredTrackColor );
      EventBus.$emit('MAP_SET_TRACK_COLOR', this.marker.device.deviceId, this.desiredTrackColor);
      EventBus.$emit('MAP_SHOW_TRACKS', this.marker.device.deviceId);
    },

    showTracks: function(deviceIds){
      EventBus.$emit('MAP_SHOW_TRACKS', deviceIds);
    },
    hideTracks: function(deviceIds){
      EventBus.$emit('MAP_HIDE_TRACKS', deviceIds);
    },
    showAllTracks: function(){
      EventBus.$emit('MAP_SHOW_ALL_TRACKS');
    },
    hideAllTracks: function(){
      EventBus.$emit('MAP_HIDE_ALL_TRACKS');
    },

    gpsQualityText: function(gpsQuality) {
      // return pgUtils.gpsQualityText(gpsQuality);
      return pgHelpers.textHelper.gpsQualityText(gpsQuality);
    },

    batteryBar: function(volts, min, max) {
      var goodVolts = 11.5;
      var warningVolts = 10;
      var barColor = volts > goodVolts ? 'green' : volts > warningVolts ? 'yellow' : 'red';
      var percentScale = (volts-min) / (max-min) *100 ;
      percentScale = Math.max(percentScale, 0);
      percentScale = Math.min(percentScale, 100);
      var html =`width: ${percentScale}%; background-color: ${barColor}; height: 100%; display: block;`;
      return html;
    },

    closeMe() {
      this.$emit('close');
    },

    getDataThisPosition() {
      mapPopupComponent = this;
      if (!this.marker || !this.marker.device) {
        console.log('no marker yet');
        return;
      }
      var deviceId = this.marker.device.deviceId;
      var dataId = this.marker.data.dataId;
      this.latlong = this.formatCoordinates();

      dataService
        .getDataById(deviceId, dataId, true)
        .then(function(result){
          mapPopupComponent.positionData = Object.assign({}, result);
        });

    },
    formatCoordinates(e) {
      var lat = this.marker.data.latitude;
      var lon = this.marker.data.longitude;
      ProfileService.saveLocalSetting('coordinateFormat', this.selectedCoordFormat);
      this.latlong = pgHelpers.latLongDisplayHelper.formatLatLong(lat, lon, this.selectedCoordFormat);
      return this.latlong;
    },
  },

  watch: {
    initialMarker: function(newVal, oldVal) {
      this.marker = newVal;
    },
    marker: function(newVal, oldVal) {
      // console.log('>>> marker changed to ', newVal.deviceId);
      // this.device = newVal;
      this.desiredTrackColor = newVal.trackColor;
      this.getDataThisPosition();
    },
  },

  components: {
    Tabs,
    Tab,
  },

};
</script>

<style scoped lang="scss">

  .pg-map-popup-window {
    min-height: 415px;
    min-width: 295px;
    position: absolute;
    padding: 0;
    left: 10%;
    top: 10%;
    z-index: 110;
    display: flex;
    flex-direction: column;
    height: 40%;
    width: 23em;

    .track-color-row select {
      height: 0.9em;
      top: 0.3em;
      width: 10em;
      position: relative;
    }
    .track-color-row select:active,
    .track-color-row select:hover  {
      outline: none !important;
      outline-color: red;
    }

    .pg-toolbar-item.not-done {
      color: #ddd;
    }

    .pg-toolbar {
      justify-content: space-around;
      font-size: 1.1em;
      margin: 5px -10px;
      border-bottom: 1px solid;
      padding-bottom: 5px;
      height: 2em;
      align-items: center;
      // .disable i {
      //   color: transparent;
      // }
    }
    .pg-toolbar.showing-tracks .pg-toolbar-item i {
      color: inherit;
    }

    .pg-toolbar-item {
      text-align: center;
      border: 1px solid transparent;
      padding: 2px;
    }
    .pg-toolbar-item:not(:first-child) {
      // border-left: 1px solid;
    }
    .pg-toolbar-item i:hover {
      font-weight: 900;
    }
    .battery-bar {
      display: inline-flex;
      height: 1em;
      width: 6em;
      border: 1px solid gray;
    }
    .row {
      line-height: 1.5em;
    }
    .org-name {
      padding: 20px 0;
      font-weight: bold;
      max-width: 190px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .device-name {
      font-weight: bold;
    }
    .prompt {
      display: inline-block;
      width: 8em;
    }

    .device-type {
      position: absolute;
      top: 6px;
      right: 6px;
      text-align: center;
    }

    .tabs-component {
      display: flex;
      flex-direction: column;
      flex: 1 1;
      position: relative;
      margin-top: 4px;
    }
    .tab-contents {
      flex: 1 1;
      flex-direction: column;
      overflow: auto;
    }
    .tab-content-area{
      flex: 1 1;
    }
    .sensor-name {
      display: inline-block;
      width: 155px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .sensor-value {
      width: 80px;
      display: inline-block;
      white-space: pre-line;
    }
    .div-table {
      // background-color: aquamarine;
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
    }
    .table-head {
      font-weight: bold;
      flex: 0 0;
    }
    .table-body {
      overflow-x: hidden;
      flex: 1 1 50px;
    }
    .table-row {
      min-height: 1.5em;
    }
    .table-row > span {
      vertical-align: top;
    }
    // .closer {
    //   position: absolute;
    //   right: 1px;
    //   top: -1px;
    //   font-size: 2em;
    //   cursor: pointer;
    // }
    .links {
      padding: 10px 5px 0 5px;
      a {
        display: block;
        margin-top: 5px;
      }
      i {
        padding-right: 5px;
      }
    }
    input[type='radio'] {
      margin: 0 1px 0 5px;
    }
    input[type='radio'], label {
      display: inline;
      vertical-align: middle;
    }
    .pg-a-button {
      display: block;
      margin-top: 5px;
      padding: 5px;
      border: 1px solid e5e5e5;
      border-radius: 7px;
      background-color: #eee;
      box-shadow: 1px 1px 1px silver;
      cursor: pointer;
    }
    .track-color-bar {
      width: 10em;
      display: inline-block;
      height: .5em;
      border: 1px solid silver;
      vertical-align: middle;    }
    }
</style>
