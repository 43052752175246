import Vue from 'vue';
import Multiselect from 'vue-multiselect';
import PgSelect from '@/components/Common/PgSelect';
import calculations from 'static/files/sensor-calculations.json';
import PgSelectTooltip from '@/utils/PgSelectTooltip.js';

export default Vue.extend({
  template: `<div v-if="customCalculations != undefined" ref="calculationsWrapper" id="calculationsWrapper">
                <pg-select
                  ref="customCalculations"
                  id="customCalculations"
                  :options='customCalculations'
                  :value='selectedCalculation'
                  v-on:input="onDropdownInput"
                  label="name"
                  :showLabels="false"
                  :close-on-select="closeOnSelect"
									:searchable="true"
									:allowTooltips="false"
									tooltipText="description"
                >
                </pg-select>
            </div>`,
  data() {
    return {
      customCalculations: calculations,
      selectedCalculation: null,
      closeOnSelect: null,
      ref: null,
    };
  },

  beforeMount() {},

  beforeDestroy() {
    var y = document.getElementById('customCalculations');
    if (y === document.activeElement) {
      document.getElementById('customCalculations').blur();
    }
  },

  mounted() {
    var width = this.params.colDef.width;
    this.closeOnSelect = true;
    this.ref = this.$refs.customCalculations;
    this.$nextTick(() => {
      var parent = this.$refs.calculationsWrapper.parentNode;
      parent.style.width = width + 'px';
      this.ref.$el.focus();
    });
  },

  methods: {
    onDropdownInput(value, id) {
      this.ref.$parent.$el.focus();
      this.$nextTick(() => {
        if (value === null) {
          return;
        }
        this.selectedCalculation = value;
        this.params.stopEditing();
      });
    },
    isPopup() {
      return true;
    },

    getValue() {
      return this.selectedCalculation != null ? this.selectedCalculation.usage : this.params.data.customCalculation;
    },
  },
  components: {
    PgSelect,
    PgSelectTooltip,
    Multiselect,
  },
});
