<template>
<!-- <div id="flatpickr-wrapper"> -->
<!-- <div class="ag-input-text-wrapper flatpickr" ref='flatpickr'> -->
<div class="flatpickr" ref='flatpickr'>
	<input type="text" id="sensor-calendar"  placeholder="Select date..." class="datetime" data-input />
	<a class="input-button" title="toggle" data-toggle v-on:click="toggleCalendar(calendar)">
	<i class="far fa-calendar"></i>
	</a>
	<a class="input-button" title="clear" data-clear v-on:click="calendar.clear();">
	<i class="far fa-times-circle"></i>
	</a>
</div>
<!-- </div> -->
</template>

/*************************************************************/

<script>
	var thisComponent = this;

  var data = function() {
    return {
        selectedDate: '',
        calendar: '',
    };
  };

  var beforeMount = function() {
	  thisComponent = this;
	};

  var mounted = function() {
		flatpickrOptions.defaultDate = Date.now();
		flatpickrOptions.defaultHour = 0;
		flatpickrOptions.defaultMinute = 0;
		flatpickrOptions.wrap = true;
		this.calendar = flatpickr(this.$refs['flatpickr'], flatpickrOptions);
		this.calendar.set('appendTo', document.querySelector('.ag-cell-focus'));
		// this.calendar.set('appendTo', document.querySelector('#flatpickr-wrapper'));

		console.log(this.calendar)

	};

  var methods = {

		toggleCalendar: function (calendar) {
			calendar.open();
			console.log('are we getting to open')

			// var wasOpen = calendar.isOpen;
			//     console.log('calendar is open?', calendar.isOpen);

			// setTimeout(function () {
			//     if (wasOpen) {
			//         calendar.close();
			//     } else {
			//         calendar.open();
			//     }
			//     console.log('after toggle', calendar.isOpen);
			// }, 0);
		},

		// isPopup() {
		// 		return true;
		// },

		getValue() {
				return this.selectedDate != null ? this.selectedDate : '2000-01-01';
		}
  };

  var computed = {
    // access these getters from MainStore (which gets them from store Modules)
    //...mapState('storeName', ['getterName1', 'getterName2']),
  };

  var watch= {  };

	var components = {  };
	var saveDate = function (selectedDates, dateStr, instance){
		// this is just a standard js function so "this" is not the component
    thisComponent.selectedDate = $('#sensor-calendar').val();
	};

	var flatpickrOptions = {
		allowInput: true,
		enableTime: true,
		time_24hr: true,
		minuteIncrement: 15,
		onClose: saveDate,
		onChange: saveDate,
		plugins: [new confirmDatePlugin({
				showAlways: true,
		})],
	};

  export default {
    name: 'MyComponent',
    data,
    beforeMount,
    mounted,
    methods,
    computed,
    watch,
    components
  };

</script>

/*************************************************************/

<style scoped>
</style>
