<!-- template for the modal component -->
<template id="command-confirm-modal">
  <pg-modal :show="show" @close="close">

    <div class="popup-window command-confirm-popup">

      <div class="popup-header">
        Please Confirm
      </div>

      <div class="popup-content text-padding">
        <span v-if="command !== null || deviceNames !== null">
          Send command "{{ command }}" to {{ deviceNames.length === 1 ? ' this device' : ' these devices' }}:
        </span>
        <ul>
          <li v-for="(device, index) of deviceNames" :key="index">{{device}}</li>
        </ul>
      </div>

      <div class="popup-footer popup-button-wrapper">
        <button class="" @click="close()">
          Cancel
        </button>
        <button class="" @click="confirm()">
          Confirm
        </button>
      </div>

    </div>

  </pg-modal>
</template>

<script>
import PgModal from "@/components/Common/PgModal";

export default {
  name: "CommandConfirmModal",
  props: ["show", "command", "deviceNames"],
  components: { PgModal },
  data: function() {
    return {
      // title: "",
      // body: ""
    };
  },
  methods: {
    close: function() {
      this.$emit("close");
      // this.title = "";
      // this.body = "";
    },
    confirm: function() {
      this.$emit('confirmed')
      this.close();
    }
  }
};
</script>


/*************************************************************/

<style scoped>
</style>
