import axios from 'axios';
import environmentSpecific from 'static/environmentSpecific';
import moment from 'moment';

import { resolve } from 'path';

const state = {
  commandHistory: [],
  commandsByDevice: [],
};

const mutations = {
  setCommandHistoryState(state, commandHistory) {
    state.commandHistory = commandHistory;
  },
  setCommandsByDevice(state, commands) {
    state.commandsByDevice = commands;
  },
};

const actions = {
  getCommandHistory({ commit }, searchCriteria) {
    // var exParams = {
    //   orgId: null,
    //   deviceIds: null,
    //   startDateTime: null,
    //   endDateTime: null,
    //   skipRecords: null,
    //   takeRecords: null,
    // };

    var paramString = '';
    for (const [key, value] of Object.entries(searchCriteria)) {
      paramString.length > 0 ? (paramString += '?') : '';
      paramString += key + '=' + value;
    }
    var fullUrl = `${environmentSpecific.apiBaseUrl}commandHistory/search?${paramString}`;
    return new Promise((resolve, reject) => {
      axios
        .get(fullUrl)
        .then(response => {
          commit('setCommandHistoryState', response.data.data);
          resolve(response.data.data);
        })
        .catch(error => {
          console.log('CommandStore getCommandHistory() caught error', error);
          reject(error);
        });
    });
  },

  getCommandHistoryByDeviceIds({ commit }, { deviceIds, orgId }) {
    var fullUrl = `${environmentSpecific.apiBaseUrl}commandHistory/search?orgId=${orgId}&deviceIds=${deviceIds}`;
    return new Promise((resolve, reject) => {
      axios
        .get(fullUrl)
        .then(response => {
          commit('setCommandHistoryState', response.data.data);
          resolve(response);
        })
        .catch(error => {
          console.log('CommandStore getCommandHistory() caught error', error);
          reject(error);
        });
    });
  },

  getCommandHistoryByOrgId({ commit }, orgId) {
    if (orgId === -1) {
      var startDate = moment()
        .subtract(1, 'months')
        .format();
      var fullUrl = `${environmentSpecific.apiBaseUrl}commandHistory/search?startDateTime${startDate}`;
    } else {
      var fullUrl = `${environmentSpecific.apiBaseUrl}commandHistory/search?orgId=${orgId}`;
    }
    return new Promise((resolve, reject) => {
      axios
        .get(fullUrl)
        .then(response => {
          commit('setCommandHistoryState', response.data.data);
          resolve(response);
        })
        .catch(error => {
          console.log('CommandStore getCommandHistory() caught error', error);
          reject(error);
        });
    });
  },
  // Need to know what commands are available for the specific device
  // getCommandsByDeviceIds({ commit }, deviceIds) {
  //   var fullUrl = `${environmentSpecific.apiBaseUrl}commands/search?deviceIds=${deviceIds}`;
  //   return new Promise((resolve, reject) => {
  //     axios.get(fullUrl)
  //       .then((response) => { commit('setCommandsByDevice', response.data.data); resolve(response); })
  //       .catch((error) => { console.log('CommandStore getCommandsBySelectedDeviceIds() caught error', error); reject(error); })
  //   });
  // },

  sendCommandToDevice({ state, commit, dispatch, rootState }, command) {
    var fullUrl = `${environmentSpecific.apiBaseUrl}commands/sendcommand?deviceId=${command.deviceId}&messageShortName=${command.messageShortName}&userId=${command.userId}`;
    return new Promise((resolve, reject) => {
      axios
        .put(fullUrl)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

const methods = {};

/* "computed properties for stores" - calculate data based on store state  */
const getters = {
  getMessageShortNames: (state, getters) => (message, userId) => {
    var devices = getters.getSelectedDevices;
    var results = [];
    var messageShortName = '';
    var deviceId = '';
    var deviceName = '';

    for (let x = 0; x < devices.length; x++) {
      var deviceId = devices[x].deviceId;
      var deviceName = devices[x].deviceName;
      for (let y = 0; y < devices[x].commands.length; y++) {
        if (devices[x].commands[y].messageName === message) {
          messageShortName = devices[x].commands[y].messageShortName;
          results.push({ deviceId: deviceId, deviceName: deviceName, messageShortName: messageShortName, userId: userId });
        }
      }
    }
    return results;
  },
  commandHistoryByOrg: state => {
    return state.commandHistory;
  },
  commandHistoryByDevices: state => deviceIds => {
    return state.commandHistory.filter(d => deviceIds.includes(d.deviceId));
  },
};

const plugins = [
  //createPersistedState(),
];

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  plugins,
  methods,
};
