<template>
  <div id='data-fields-grid' class='data-fields-grid'>
    <div class="data-fields-grid-body">
      <div class="pg-component-toolbar">
        <span class="pg-component-toolbar-left"></span>
        <span class="pg-component-toolbar-center"></span>
        <span class="pg-component-toolbar-right">
          <!-- <span class="pg-component-toolbar-item" :class="{ 'disabled': !dataIsFiltered }">
            <span class="fa-stack" @click="clearAllFilters" title="Clear all filters" :class="{ 'disabled': !dataIsFiltered }">
              <i class="far fa-filter fa-stack-1x"></i>
              <i class="fas fa-slash fa-stack-1x"></i>
            </span>
          </span> -->
        </span>
      </div>
      <ag-grid-vue
          class='ag-theme-balham grid-holder'
          style='width: 100%;'
          :gridOptions='gridOptions'
          :columnDefs='columnDefs'
          :modules="agGridModules"
          >
      </ag-grid-vue>
    </div>
  </div>
</template>

/*************************************************************/

<script>
  import { AgGridVue } from "@ag-grid-community/vue";
  import { AllCommunityModules } from '@ag-grid-community/all-modules';
  import { mapGetters, mapState, mapActions } from 'vuex';
  import ProfileService from '@/services/ProfileService';
  import environmentSpecific from 'static/environmentSpecific';

  //import pgUtils from '@/utils/PgUtils.js';
  //import agGridUtils from '@/utils/AgGridUtils.js';

  var myDateFormat = 'MM/DD/YY'; // 'YYYY-MM-DD HH:mm:ss '; // ISO  ISO.MSnp
  var thisComponent = this;

  var data = function() {
    return {
      columnDefs: null,
      rowData: null,
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      agGridModules: AllCommunityModules,
      gridWidth: 0,
      selectedFields: [],
      dataIsFiltered: false,
    };
  };

  var props = {
    //value: {type: Array, default: function() { return []; } },
    showSelectAll: {type: Boolean, default: true},
  };

  var beforeMount = function() {
    //this.selectedFields = this.value; // v-model
    //console.log('beforeMount');
    this.gridOptions = {
      headerHeight: 25,
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      onGridReady: this.onGridReady,
      onGridSizeChanged: this.onGridSizeChanged,
      onFilterChanged: this.onFilterChanged,
      onSelectionChanged: this.onSelectionChanged,
      defaultColDef: {
        width: 100,
        filter: true,
        resizable: true,
      },
      rowSelection: 'multiple',
      suppressMenuHide: true,
      icons: {
        menu: '<i class="far fa-filter"/>',
      },
      rowHeight: environmentSpecific.gridRowHeightPx,
      enableCellTextSelection: true,
      ensureDomOrder: true,
    };

    this.columnDefs = [
      { colId: 'displayName',
        headerName: 'Sensors',
        field: 'displayName',
        width: 300,
        checkboxSelection: true,
        headerCheckboxSelection: this.showSelectAll,
        headerCheckboxSelectionFilteredOnly: true,
        filterParams: {
          applyButton: true,
          clearButton: true,
        },
      },
    ];
  };

  var mounted = function() {
    // console.log('mounted');
  };

  var methods = {
    ...mapActions('deviceModule', ['setSelectedFields' ]), // 'getAllFieldsForSelectedDevices',
    onRowSort(event) {
      // console.log('row reordered');
      this.selectedFields = this.getCheckedFields();
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.gridApi.setPopupParent(document.body);
      this.gridApi.setRowData(this.allFieldsSelectedDevices);
      this.checkForPrevSelectedDevices();
    },

    onRowClicked(event) {    },

    onGridSizeChanged(params) {
      // var gridWidth = document.getElementById('activation-grid').offsetWidth;
      // this.gridWidth = gridWidth; // to display during dev
      // agGridUtils.showColumnsBySize(params, gridWidth, hideColumnsBySize);
    },

    onSelectionChanged(event) {
      this.selectedFields = this.getCheckedFields();
      // console.log('onSelectionChanged to', this.selectedFields);
      ProfileService.saveLocalSetting('selectedSensors', this.selectedFields);
    },

    onFilterChanged(params) {
      var colFilterActive = this.gridApi.isColumnFilterPresent();
      this.dataIsFiltered = colFilterActive ? true : false;
    },

    getCheckedFields() {
      // can't do this.  won't allow deselecting all fields
      // if (this.gridApi.getSelectedRows().length == 0) {
      //   this.checkForPrevSelectedDevices();
      // }
      var selectedFields = [];
      this.gridApi.forEachNodeAfterFilterAndSort(function(rowNode, index){
        if (rowNode.selected) {
          selectedFields.push(rowNode.data.dataFieldName);
        }
      });
      this.setSelectedFields(selectedFields);
      this.selectedFields = selectedFields;
      return this.selectedFields;
    },

    clearAllFilters() {
      this.gridApi.setFilterModel(null);
    },

    checkForPrevSelectedDevices() {
      var selectedSensors = ProfileService.getLocalSetting('selectedSensors', null);
      if (!selectedSensors || selectedSensors.length === 0) {
        return;
      }
      console.log('Restoring sensors...', selectedSensors);
      this.gridApi.forEachNode(function(node, index) {
        if (selectedSensors.includes(node.data.dataFieldName)) {
          node.setSelected(true);
        } else {
          node.setSelected(false);
        }
      });
    },

  };

  var computed = {
    ...mapState('deviceModule', ['allFieldsSelectedDevices', 'selectedDeviceIds', 'selectedFieldNames']),
    ...mapGetters('deviceModule', ['getSelectedFields', ]),
    //justOneSelected: function(){ return this.selectedDeviceIdsCount == 1; },
    //numDevicesSelected: function() {return this.selectedDeviceIds.length},
  };

  var watch= {
    selectedFieldNames: function(newValue, oldValue) {
      this.gridApi.forEachNode(function(node, index) {
        if (newValue.includes(node.data.dataFieldName)) {
          node.setSelected(true);
        } else {
          node.setSelected(false);
        }
      });
    },

    allFieldsSelectedDevices: function(newValue, oldValue) {
      if (!this.gridApi) {
        return;
      }
      this.gridApi.setRowData(this.allFieldsSelectedDevices);
      this.gridOptions.api.forEachNode( node => {
        if (this.getSelectedFields.filter( n => n.dataFieldName === node.data.dataFieldName).length > 0) {
          node.setSelected(true);
        }
      });
      this.getCheckedFields();
    },
  };

  var components = {
    AgGridVue,
  };

  var hideColumnsBySize = [
    // { 'maxSize': 320,  hideIds: ['dateEntered', 'whoEntered', 'pgPlanName' ]},
    // { 'maxSize': 425,  hideIds: ['dateEntered', 'whoEntered']},
    // { 'maxSize': 760,  hideIds: ['dateEntered', ] },
  ];


  export default {
    name: 'DataFieldsGrid',
    data,
    props,
    beforeMount,
    mounted,
    methods,
    computed,
    watch,
    components
  };

</script>

/*************************************************************/

<style scoped>
.grid-holder {
  /* needs to be separate from grid holder in device grid */
  width: 100%;
  flex: 1;
}
.data-fields-grid-body {
  width: 100%;
}
/* .disabled {
  opacity: .5;
  pointer-events: none;
} */

</style>
