import Vue from 'vue';
import Router from 'vue-router';
import Store from '../store/MainStore';

import ResourcesPage from '@/pages/ResourcesPage';
import AllOrganizationsPage from '@/pages/AllOrganizationsPage';
import BulkAddDevicesPage from '@/pages/BulkAddDevicesPage';
import DashboardPage from '@/pages/DashboardPage';
import DevicesPage from '@/pages/DevicesPage';
import DevToolsPage from '@/pages/DevToolsPage';
import ContactsPage from '@/pages/ContactsPage';
import OrganizationPage from '@/pages/OrganizationPage';
import DataPage from '@/pages/DataPage';
import ImagesPage from '@/pages/ImagesPage';
import BillingPage from '@/pages/BillingPage';
import UtilitiesPage from '@/pages/UtilitiesPage';
import SensorsPage from '@/pages/SensorsPage';
import InternalBillingPage from '@/pages/InternalBillingPage';
import TelemetryBillingPage from '@/pages/TelemetryBillingPage';
import BillingCommissionsPage from '@/pages/BillingCommissionsPage';
import TelemetryPreviousQuarterPage from '@/pages/TelemetryPreviousQuarterPage';
import AdHocReportPage from '@/pages/AdHocReportPage';

import SupportPage from '@/pages/SupportPage';
import PrivacyPolicyPage from '@/pages/PrivacyPolicyPage';
import AccessDeniedPage from '@/pages/AccessDeniedPage';

import UnderConstructionPage from '@/pages/UnderConstructionPage';
import PageNotFoundPage from '@/pages/PageNotFoundPage';

import MobileDevicesPage from '@/pages/mobile/MobileDevicesPage';
import MobileDeviceDetailsPage from '@/pages/mobile/MobileDeviceDetailsPage';
import MobileCommandsPage from '@/pages/mobile/MobileCommandsPage';
import MobileActivationsPage from '@/pages/mobile/MobileActivationsPage';

import { mapGetters, mapState, mapActions } from 'vuex';
import environmentSpecific from 'static/environmentSpecific';
import { EventBus } from '../EventBus';

/*
  Try to make each route endpoint a vue in /pages
  to make them easier to find.

  e.g. don't set a component of Chart, but rather to
  ChartPage which might only have one component, Chart.
*/

Vue.use(Router);

// Route Guard:  if requiresAuth = true, user must have at least one of the listed roles or claims to access the route
// Role PGAdmin can access anything.  An empty list means no restrictions.
// Examples:  roles: [], claims: [] ==> same as requiresAuth: false, anyone can acess
//    roles: ['customerAdmin'], claims: ['users:read'] ==> customer admins and anyone with users:read can access
//    roles: [], claims: ['Activations:read', 'Billing:read'] ==> anyone with either of these claims can access

var router = new Router({
  mode: 'history',
  // https://stackoverflow.com/questions/34623833/how-to-remove-hashbang-from-url
  routes: [
    {
      path: '/devices',
      name: 'Devices',
      component: DevicesPage,
      // requires auth (must be logged in), but no specific roles or claims needed
      meta: { pageTitle: 'Devices', requiresAuth: true, roles: [], claims: [] },
    },
    {
      path: '/org',
      name: 'Organization',
      component: OrganizationPage,
      meta: { pageTitle: 'Organization Administration', requiresAuth: true, roles: [], claims: ['Users:read'] },
    },
    {
      path: '/data',
      name: 'Data',
      component: DataPage,
      meta: { pageTitle: 'Data', requiresAuth: true, roles: [], claims: [] },
    },
    {
      path: '/images',
      name: 'Images',
      component: ImagesPage,
      meta: { pageTitle: 'Images', requiresAuth: true, roles: [], claims: [] },
    },
    {
      path: '/billing',
      name: 'Billing',
      component: BillingPage,
      meta: { pageTitle: 'Billing', requiresAuth: true, roles: [], claims: ['Developer:read'] },
    },
    {
      path: '/utilities',
      name: 'Utilities',
      component: UtilitiesPage,
      meta: { pageTitle: 'Utilities', requiresAuth: true, roles: [], claims: ['Developer:read'] },
    },
    {
      path: '/resources',
      name: 'Resources',
      component: ResourcesPage,
      meta: { pageTitle: 'Resources', requiresAuth: true, roles: [], claims: [] },
    },
    {
      path: '/support/:pagename',
      name: 'Support',
      component: SupportPage,
      meta: { pageTitle: 'Support', requiresAuth: true, roles: [], claims: [] },
    },
    {
      path: '/privacy',
      name: 'Privacy',
      component: PrivacyPolicyPage,
      meta: { pageTitle: 'Privacy Policy', requiresAuth: false, roles: [], claims: [] },
    },
    {
      path: '/pg/all-organizations',
      name: 'AllOrganizations',
      component: AllOrganizationsPage,
      meta: { pageTitle: 'All Organizations', requiresAuth: true, roles: ['PGStaff', 'PGAdmin'], claims: ['Organizations:read'] },
    },
    {
      path: '/pg/add-devices',
      name: 'AddDevices',
      component: BulkAddDevicesPage,
      meta: { pageTitle: 'Add Devices', requiresAuth: true, roles: ['PgStaff'], claims: ['Devices:create'] },
    },
    {
      path: '/pg/dev-tools',
      name: 'DevTools',
      component: DevToolsPage,
      meta: { pageTitle: 'Developer Tools', requiresAuth: true, roles: ['PgStaff'], claims: ['PgAdmin:read'] },
    },
    {
      path: '/pg/contacts',
      name: 'Contacts',
      component: ContactsPage,
      meta: { pageTitle: 'Contacts', requiresAuth: true, roles: ['PgStaff'], claims: ['PgStaff:read'] },
    },
    {
      path: '/pg/sensors',
      name: 'Sensors',
      component: SensorsPage,
      meta: { pageTitle: 'Sensors', requiresAuth: true, role: ['PgStaff'], claims: [] },
    },
    {
      path: '/pg/pg-billing',
      name: 'PGBilling',
      component: InternalBillingPage,
      meta: { pageTitle: 'PG Billing', requiresAuth: true, role: ['PgStaff'], claims: [] },
    },
    {
      path: '/pg/telemetry-billing',
      name: 'Telemetry Billing',
      component: TelemetryBillingPage,
      meta: { pageTitle: 'Telemetry Billing', requiresAuth: true, role: ['PgStaff'], claims: [] },
    },
    {
      path: '/pg/billing-commissions',
      name: 'Billing Commissions',
      component: BillingCommissionsPage,
      meta: { pageTitle: 'Carthe Commissions', requiresAuth: true, role: ['PgStaff'], claims: [], plainLayout: true },
    },
    {
      path: '/pg/ad-hoc-report',
      name: 'Ad Hoc',
      component: AdHocReportPage,
      meta: { pageTitle: 'Ad Hoc Report ', requiresAuth: true, role: ['PgStaff'], claims: [], plainLayout: true },
    },
    {
      path: '/pg/telemetry-previous-quarter',
      name: 'Telemetry Previous Quarter',
      component: TelemetryPreviousQuarterPage,
      meta: { pageTitle: 'Previous Quarter Report', requiresAuth: true, role: ['PgStaff'], claims: [], plainLayout: true },
    },
    {
      path: '/accessdenied',
      name: 'AccessDenied',
      component: AccessDeniedPage,
      meta: { pageTitle: 'Access Denied', requiresAuth: true, roles: [], claims: [] },
    },
    {
      path: '/login',
      name: 'LogIn',
      //redirect: '/static/login',
      beforeEnter() {
        location.href = '/static/login';
      },
      meta: { pageTitle: 'Log In', requiresAuth: false, roles: [], claims: [] },
    },
    {
      // not used, just an example of using redirect
      path: '/home',
      name: 'Home',
      redirect: '/',
      meta: { pageTitle: 'Home', requiresAuth: true, roles: [], claims: [] },
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: DashboardPage,
      meta: { pageTitle: 'Dashboard', requiresAuth: true, roles: [], claims: [] },
    },
    {
      path: '/',
      redirect: '/dashboard',
    },
    {
      path: '/maintenance',
      name: 'Maintenance',
      beforeEnter() {
        location.href = '/static/maintenance.html';
      },
      // redirect: '/static/maintenance.html',
      meta: { pageTitle: 'Maintenance', requiresAuth: false, roles: [], claims: [] },
    },

    // ToDo:          Set up roles & claims for mobile
    // ToDo:          Set up roles & claims for mobile
    // ToDo:          Set up roles & claims for mobile
    // ToDo:          Set up roles & claims for mobile
    // ToDo:          Set up roles & claims for mobile
    // ToDo:          Set up roles & claims for mobile
    // **** Mobile Specific ****
    {
      path: '/m',
      name: 'MobileHome',
      redirect: '/m/devices',
    },
    {
      path: '/m/devices',
      name: 'mDevices',
      component: MobileDevicesPage,
      meta: { pageTitle: 'Devices', requiresAuth: true, roles: [], claims: [] },
    },
    {
      path: '/m/device/details',
      name: 'mDeviceDetails',
      component: MobileDeviceDetailsPage,
      meta: { pageTitle: 'Device Details', requiresAuth: true, roles: [], claims: [] },
    },
    {
      path: '/m/device/activations',
      name: 'mDeviceActivations',
      component: MobileActivationsPage,
      meta: { pageTitle: 'Activation', requiresAuth: true, roles: [], claims: [] },
    },
    {
      path: '/m/device/commands',
      name: 'mDeviceCommands',
      component: MobileCommandsPage,
      meta: { pageTitle: 'Commands', requiresAuth: true, roles: [], claims: [] },
    },
    {
      // definded separately so other components can redirect to 404 if they want
      path: '/404',
      name: 'PageNotFound-404',
      component: PageNotFoundPage,
      meta: { pageTitle: 'Page Not Found', requiresAuth: false, roles: [], claims: [] },
    },
    {
      // Everything else
      path: '*',
      // redirect: '/404',
      name: 'PageNotFound',
      component: PageNotFoundPage,
      meta: { pageTitle: 'Page Not Found', requiresAuth: false, roles: [], claims: [] },
    },
  ],
});

var rightsHaveBeenSet = false;

function setAccessRights(userInfo) {
  // add meta data to each route so Nav knows what user can access
  if (rightsHaveBeenSet) {
    return;
  }

  for (var i = 0; i < router.options.routes.length; i++) {
    var route = router.options.routes[i];
    if (!route.meta) {
      route.meta = {};
    }
    route.meta.accessGranted = isAccessGranted(userInfo, route);
  } // end for
  rightsHaveBeenSet = true;
} // setAccessRights()

function isAccessGranted(userInfo, route) {
  var accessGranted = false;
  var isRequestingRole = route.meta && route.meta.roles && route.meta.roles.length > 0 ? true : false;
  var isRequestingClaim = route.meta && route.meta.claims && route.meta.claims.length > 0 ? true : false;
  if (!isRequestingRole && !isRequestingClaim) {
    // console.log('no roles or claims required to access ', to.path);
    accessGranted = true;
  } else if (userInfo && userInfo.roles && userInfo.roles.some(r => r.roleKey === 'PGAdmin')) {
    // console.log('PG Admin has access to everything');
    accessGranted = true;
  } else if (userInfo && userInfo.roles && userInfo.roles.some(r => route.meta.roles.includes(r.roleKey))) {
    // user can have many roles and many roles may have access to route
    // console.log('user logged in, and has necessary role');
    accessGranted = true;
  } else if (isRequestingClaim && userInfo && userInfo.claims) {
    userInfo.claims.forEach(function (userClaim) {
      // console.log('userClaim: ', userClaim.claimType + ':' + userClaim.claimValue);
      route.meta.claims.forEach(function (requiredClaim) {
        // console.log('required claim: ', requiredClaim);
        var requiredClaimParts = requiredClaim.split(':');
        if (requiredClaimParts.length === 2) {
          var reqType = requiredClaimParts[0];
          var reqValue = requiredClaimParts[1];
          if (userClaim.claimType === reqType && userClaim.claimValue.includes(reqValue)) {
            // console.log('User has appropriate claim [' + reqType + ':' + reqValue +']');
            accessGranted = true;
          }
        }
      }); // for each requiredClaim
    }); // foreach userClaim
  } // end if
  return accessGranted;
}

function getCookieValue(key) {
  var result;
  return (result = new RegExp('(?:^|; )' + encodeURIComponent(key) + '=([^;]*)').exec(document.cookie)) ? result[1] : null;
}

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  // if not logged in (no cookie), redirect to login
  let authCookie = getCookieValue(environmentSpecific.pgAuthCookieName);
  if (requiresAuth && !authCookie && !to.fullPath.contains('login')) {
    document.location.href = environmentSpecific.loginUrl;
    return;
  }

  if (requiresAuth) {
    setAccessRights(Store.state.userModule.currentUser);

    // if ( isAccessGranted(userInfo, to)) {
    if (to.meta.accessGranted) {
      next();
    } else {
      var isRequestingRole = to.meta && to.meta.roles && to.meta.roles.length > 0 ? true : false;
      var isRequestingClaim = to.meta && to.meta.claims && to.meta.claims.length > 0 ? true : false;
      console.warn('Does not have necessary role or claim. Rerouting to accessdenied.');
      if (isRequestingRole) {
        console.warn('User has roles: ', userInfo, ' and needs one of these roles: ', to.meta.roles);
      }
      if (isRequestingClaim) {
        console.warn('User has claims: ', userInfo.claims, ' and needs one of these claims: ', to.meta.claims);
      }
      next('accessdenied');
    }
  } else {
    next();
  }
});

router.setNavAccess = function () {
  // console.log('>> ROUTER setNavAccess()');
  setAccessRights(this.app.$pgGlobal.currentUser);
};

export default router;
