import axios from 'axios';
import environmentConfig from 'static/environmentSpecific';

const state = {
    deviceTypes: [],
    networks: [],
    serviceTypes: [],
};

const mutations = {
    updateDeviceTypes(state, deviceTypes) {
        state.deviceTypes = deviceTypes;
    },
    updateNetworks(state, networks) {
        state.networks = networks;
    },
    updateServiceTypes(state, serviceTypes) {
        state.serviceTypes = serviceTypes;
    },
};

const actions = {
    getDeviceTypes({ state, commit, rootState }) {
        var fullUrl = `${environmentConfig.apiBaseUrl}deviceTypes`;
        axios.get(fullUrl)
            .then((response) => { commit('updateDeviceTypes', response.data.data); });
    },
    getNetworks({ state, commit, rootState }) {
        var fullUrl = `${environmentConfig.apiBaseUrl}networks`;
        axios.get(fullUrl)
            .then((response) => { commit('updateNetworks', response.data.data); });
    },
    getServiceTypes({ state, commit, rootState }) {
        var serviceTypes = [
            {
                "serviceTypeCode": "full",
                "name": "Full",
                "description": "Regular data & telemetry"
            },
            {
                "serviceTypeCode": "telemetryOnly",
                "name": "Telemetry Only",
                "description": "Telemetry only"
            },
            {
                "serviceTypeCode": "processingOnly",
                "name": "Data Only",
                "description": "Data processing only - no telemetry"
            },
            {
                "serviceTypeCode": "other",
                "name": "Other",
                "description": "Irregular charges - SMS"
            }
        ];
        commit("updateServiceTypes", serviceTypes);
    },
};

var getters = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
