import Vue from 'vue';

export default Vue.extend({

	template: `<div class="custom-filter status-filter">
				      <div>
				        <div class="custom-filter-body-wrapper">
				          <div class="custom-filter-heading"><span>Filter by {{ filterTitle }}</span></div>
				          <div class="custom-filter-body">
                    <div class="form-group" v-on:keyup.enter="applyFilter">
                      <ul>
                        <li v-for="(option, index) in options" :key="index">
                          <input type="checkbox" v-model="checkedOptions" :value="option" :id="option" />
                          <label v-bind:for="option">{{ option }}</label>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
							  <div class="custom-filter-button-panel"><button @click="clearFilter">Clear Filter</button><button @click="applyFilter">Apply Filter</button></div>
					    </div>
              </div>`,
	data() {
		return {
			options: [],
			valueGetter: null,
			popup: null,
			filterTitle: null,
			checkedOptions: [],
		}
	},
	methods: {
		isFilterActive() {
			if (this.checkedOptions === undefined || this.checkedOptions === null || this.checkedOptions.length === 0) {
				return false;
			}
			return this.checkedOptions.length > 0;
		},
		doesFilterPass(params) {
			if (params === undefined) {
				return;
			}
			var passed = false;
			if (this.checkedOptions.includes(params.node.data.activationStatus) || this.checkedOptions.includes("Deployed") && params.node.data.deployed) {
				passed = true;
			}
			return passed;
		},
		getModel() {
			return { value: this.checkedOptions };
		},
		setModel(model) {
			if (model) {
				this.checkedOptions = model;
			} else if (model === null) {
				this.clearFilter();
			}
		},
		afterGuiAttached(params) {
			this.popup = params;
    },

    applyFilter() {
      this.doesFilterPass();
      this.params.filterChangedCallback();
			if (this.popup != null) { this.popup.hidePopup(); }
    },

		clearFilter() {
			this.checkedOptions = [];
			this.params.filterChangedCallback();
			if (this.popup != null) { this.popup.hidePopup(); }
		},
	},

	created() {
		this.valueGetter = this.params.valueGetter;
		this.filterTitle = this.params.filterTitle;
		this.options = this.params.values;
	},
	watch: {
		// checkedOptions: function (value) {
		// 	if (value === undefined ) {
		// 		return;
		// 	}
		// 	this.params.filterChangedCallback();
		// }
	},

})

