<template>
  <div class="organizations-list-grid">
    <div class="organizations-list-grid-body">
      <div class="pg-component-toolbar">
        <div class="pg-component-toolbar-left">
          Organizations
        </div>
        <div class="pg-component-toolbar-center"></div>
        <div class="pg-component-toolbar-right">
          <div class="header-icons">
            <span class="pg-component-toolbar-item" :class="{ 'disabled': isEditing || !dataIsFiltered }">
              <span class="fa-stack" @click="clearAllFilters" title="Clear all filters" :class="{ 'disabled': isEditing || !dataIsFiltered }">
                <i class="far fa-filter fa-stack-1x"></i>
                <i class="fas fa-slash fa-stack-1x"></i>
              </span>
            </span>
          </div>
          <div class="header-icons">
            <span class="pg-component-toolbar-item" v-if="userHasClaim('Organization', 'create')">
              <span @click="onAddOrg" title="Add Organization" class="far fa-plus-square fa-lg"></span>
            </span>
          </div>
        </div>
      </div>
      <!-- <div class="debug-grid-width">Grid width: {{ gridWidth }}</div> -->
      <ag-grid-vue
        style="width: 100%;"
        class="ag-theme-balham grid-holder"
        :gridOptions="gridOptions"
        :columnDefs="columnDefs"
        :modules="agGridModules"
        >
      </ag-grid-vue>
    </div>
  </div>
</template>

/*************************************************************/

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { AgGridVue } from "@ag-grid-community/vue";
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import agGridUtils from "@/utils/AgGridUtils.js";
import environmentSpecific from 'static/environmentSpecific';

var thisComponent = null;
var data = function() {
  return {
    columnDefs: null,
    rowData: null,
    gridOptions: null,
    gridApi: null,
    columnApi: null,
    agGridModules: AllCommunityModules,
    filterActivatedOnly: false,
    gridWidth: 0,
    updatedOrgId: null,
    dataIsFiltered: false,
  };
};

var props = ['isEditing', 'needFreshOrgData'];

var beforeMount = function() {
  thisComponent = this;
  this.gridOptions = {
    headerHeight: 25,
    rowData: this.rowData,
    columnDefs: this.columnDefs,
    onGridReady: this.onGridReady,
    onGridSizeChanged: this.onGridSizeChanged,
    onFilterChanged: this.onFilterChanged,
    rowSelection: "single",
    onSelectionChanged: this.onSelectionChanged,
    onColumnMoved: this.onColumnMoved,
    defaultColDef: { width: 100 },
    overlayLoadingTemplate: '<span class="ag-overlay-loading-center"></span>',
    defaultColDef: {
      sortable: true,
      filter: true,
      resizable: true,
    },
    suppressMenuHide: true,
    suppressNoRowsOverlay: true,
    icons: {
      menu: '<i class="far fa-filter"/>',
    },
    rowHeight: environmentSpecific.gridRowHeightPx,
    enableCellTextSelection: true,
    ensureDomOrder: true,
  };
  this.columnDefs = [
  {
    colId: "orgName",
    headerName: "Organization Name",
    field: "orgName",
    width: 350,
    minWidth: 150,
    maxWidth: 600,
    checkboxSelection: true,
    tooltipValueGetter: function(params) {
      return `Org Id: ${params.data.orgId}`;
    },
    filterParams: {
        applyButton: true,
        clearButton: true,
    },
  },
  {
    colId: "userStr",
    headerName: "Users",
    field: "userStr",
    width: 300,
    minWidth: 200,
    /*maxWidth: 800,*/
    tooltipValueGetter: function(params) {
      return `${params.data.userStr}`;
    },
    filterParams: {
      applyButton: true,
      clearButton: true,
    },
  },
{
    colId: "orgAddress1",
    headerName: "Address",
    field: "orgAddress1",
    width: 160,
    minWidth: 160,
    maxWidth: 200,
    filterParams: {
      applyButton: true,
      clearButton: true,
    },
  },
  {
    colId: "orgCity",
    headerName: "City",
    field: "orgCity",
    width: 115,
    minWidth: 100,
    maxWidth: 160,
    filterParams: {
      applyButton: true,
      clearButton: true,
    },
  },
  {
    colId: "orgStateProvCode",
    headerName: "State/Province",
    field: "orgStateProvCode",
    width: 125,
    minWidth: 110,
    maxWidth: 160,
    filterParams: {
      applyButton: true,
      clearButton: true,
    },
  },
  {
    colId: "orgPostalCode",
    headerName: "Postal Code",
    field: "orgPostalCode",
    width: 125,
    minWidth: 110,
    maxWidth: 160,
    filterParams: {
      applyButton: true,
      clearButton: true,
    },
  },
  {
    colId: "orgCountryCode",
    headerName: "Country Code",
    field: "orgCountryCode",
    width: 135,
    minWidth: 110,
    maxWidth: 160,
    filterParams: {
      applyButton: true,
      clearButton: true,
    },
  },
  ];
};

var mounted = function() {
  thisComponent.getOrganizationsWithUsers();
};

var beforeDestroy = function() {};

var methods = {
  ...mapActions("organizationModule", ["setSelectedOrganization", "setSelectedOrganizationByOrgId", 'getOrganizationsWithUsers']),
  onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.setRowData(this.orgsWithUserString);
    this.findOrgInGrid('middle');
  },
  onGridSizeChanged(params) {
    this.gridApi.sizeColumnsToFit();
  },
  onSelectionChanged() {
    var selectedRow = this.gridApi.getSelectedRows();
    if (selectedRow[0] !== undefined || selectedRow.length > 0) {
      this.setSelectedOrganizationByOrgId(selectedRow[0].orgId);
      this.$emit('onOrgSelection', true);
    } else {
      this.setSelectedOrganizationByOrgId(null); // so that details will empty
      this.$emit('onOrgSelection', false);
    }
  },
  onFilterChanged(params) {
    var colFilterActive = this.gridApi.isColumnFilterPresent();
    this.dataIsFiltered = colFilterActive ? true : false;
  },
  findOrgInGrid(position) {
    this.$nextTick(function() {
      this.gridOptions.api.forEachNode(node => {
        if(this.selectedOrganization.orgId === node.data.orgId) {
            node.setSelected(true);
            this.gridApi.ensureIndexVisible(node.rowIndex, position); // 'middle'
        }
      });
    });
  },
  // stackoverflow.com/questions/43138182/disable-ag-grid-when-showing-loading-or-any-overlay
  disableGrid() {
    this.gridOptions.api.showLoadingOverlay();
  },
  enableGrid() {
    this.gridOptions.api.hideOverlay();
  },
  clearAllFilters() {
    this.gridApi.setFilterModel(null);
  },
  onAddOrg() {
    if (this.isEditing) {
      return;
    }
    this.$emit('onAddOrgClicked', true);
  },
};

var computed = {
  ...mapState("organizationModule", ['selectedOrganization', 'orgsWithUserString']),
  ...mapGetters("userModule", ['userHasClaim']),
};

var watch = {
  orgsWithUserString: function(value) {
    if(this.gridApi) {
      this.gridApi.setRowData(this.orgsWithUserString);
      this.gridApi.sizeColumnsToFit();
      this.gridOptions.api.forEachNode(node => {
        if (this.selectedOrganization.orgId === node.data.orgId) {
          node.setSelected(true);
          this.gridApi.ensureIndexVisible(node.rowIndex, 'middle');
        }
      });
    }
  },

  isEditing: function(value) {
    if(value) {
      this.disableGrid();
    } else {
      this.enableGrid();
    }
  },
  // receives changes from the userprofile and selection change in the grid
  selectedOrganization(value) {
    this.findOrgInGrid();
  },
  needFreshOrgData(value) {
    console.log('org list looking for new org data', value)
    this.getOrganizationsWithUsers().then(response => {
      this.gridApi.setRowData(response.data.data);
      this.gridApi.sizeColumnsToFit();
      this.findOrgInGrid('middle');
    });
    this.hideSubmitCancelBtns = true;
  },
};

var components = {
  AgGridVue
};

export default {
  name: "OrganizationListGrid",
  data,
  props,
  beforeMount,
  beforeDestroy,
  mounted,
  methods,
  computed,
  watch,
  components
};
</script>

/*************************************************************/

<style scoped>
.organization-grid >>> .ag-overlay {
  pointer-events: auto;
}
.grid-holder {
  height: 600px;
}
.pg-component-toolbar {
  display: flex;
  font-size: 12px;
  font-weight: 600;
  flex-direction: row;
  align-items: center;
}
.pg-component-toolbar + div {
  flex: 1;
}
.header-icons {
  display: flex;
}

.pg-component-toolbar-item {
  /* border: none; */
  align-items: center;
}
/* .disabled {
  opacity: .5;
  pointer-events: none;
} */
</style>
