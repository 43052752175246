<template>
  <div class="users-list-grid">
      <div class="users-list-grid-body">
        <div class="pg-component-toolbar">
          <div class="pg-component-toolbar-left">Registered Users</div>
          <div class="pg-component-toolbar-center"></div>
          <div class="pg-component-toolbar-right">
            <div class="header-icons">
              <span class="pg-component-toolbar-item" :class="{ 'disabled': isEditing || !dataIsFiltered }">
                <span class="fa-stack" @click="clearAllFilters" title="Clear all filters" :class="{ 'disabled': isEditing || !dataIsFiltered }">
                  <i class="far fa-filter fa-stack-1x"></i>
                  <i class="fas fa-slash fa-stack-1x"></i>
                </span>
              </span>
            </div>
            <div class="header-icons">
              <span class="pg-component-toolbar-item" v-if="userHasClaim('Users', 'create')">
                <span @click="onAddUser" title="Add User" class="far fa-plus-square fa-lg"></span>
              </span>
              <span class="pg-component-toolbar-item" v-if="userHasClaim('Users', 'delete')">
                <span @click="onDeleteUser" title="Delete User" class="far fa-trash-alt fa-lg"></span>
              </span>
            </div>
          </div>
        </div>
      <!-- <div class="debug-grid-width">Grid width: {{ gridWidth }}</div> -->
        <ag-grid-vue
          style="width: 100%; "
          class="ag-theme-balham grid-holder"
          :gridOptions="gridOptions"
          :columnDefs="columnDefs"
          :modules="agGridModules"
          >
        </ag-grid-vue>
      </div>
    <!-- </transition> -->
  </div>
</template>

/*************************************************************/

<script>
  import { mapState, mapActions, mapGetters } from 'vuex';
  import { AgGridVue } from "@ag-grid-community/vue";
  import { AllCommunityModules } from '@ag-grid-community/all-modules';
  import agGridUtils from "@/utils/AgGridUtils.js";
  import environmentSpecific from 'static/environmentSpecific';

  var thisComponent = null;
  var props = ['isEditing', 'needFreshUserData', 'userCanceledDelete'];

  var data = function() {
    return {
      rowData: null,
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      agGridModules: AllCommunityModules,
      filterActivatedOnly: false,
      gridWidth: 0,
      hideSubmitCancelBtns: false,
      dataIsFiltered: false,
    };
  };

  var beforeMount = function() {
    thisComponent = this;
    this.columnDefs = [
      {
        colId: "userName",
        headerName: "User Name",
        field: "userName",
        width: 175,
        minWidth: 150,
        maxWidth: 350,
        checkboxSelection: true,
        tooltipValueGetter: function(params) {
          return `User ID: ${params.data.userId}`;
        },
        filterParams: {
          applyButton: true,
          clearButton: true,
        },
      },
      {
        colId: "firstName",
        headerName: "First Name",
        field: "firstName",
        width: 175,
        minWidth: 150,
        maxWidth: 350,
        filterParams: {
          applyButton: true,
          clearButton: true,
        },
      },
      {
        colId: "lastName",
        headerName: "Last Name",
        field: "lastName",
        width: 175,
        minWidth: 150,
        maxWidth: 350,
        filterParams: {
          applyButton: true,
          clearButton: true,
        },
      },
      {
        colId: "eMail",
        headerName: "Email",
        field: "eMail",
        width: 250,
        minWidth: 300,
        maxWidth: 500,
        filterParams: {
          applyButton: true,
          clearButton: true,
        },
      },
      {
        colId: "orgName",
        headerName: "Organization",
        field: "orgName",
        minWidth: 125,
        width: 150,
        maxWidth: 300,
        filterParams: {
          applyButton: true,
          clearButton: true,
        },
      },
    ];
    this.gridOptions = {
      headerHeight: 25,
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      onGridReady: this.onGridReady,
      onGridSizeChanged: this.onGridSizeChanged,
      onFilterChanged: this.onFilterChanged,
      rowSelection: "single",
      onSelectionChanged: this.onSelectionChanged,
      onColumnMoved: this.onColumnMoved,
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
      },
      suppressNoRowsOverlay: true,
      suppressMenuHide: true,
      icons: {
        menu: '<i class="far fa-filter"/>',
      },
      rowHeight: environmentSpecific.gridRowHeightPx,
      overlayLoadingTemplate: '<span class="ag-overlay-loading-center"></span>',
      enableCellTextSelection: true,
      ensureDomOrder: true,
    };
  };

  var mounted = function() {
    var orgId = this.selectedOrganization.orgId;
    if (orgId !== undefined) {
      this.getGridData(orgId);
    }
   };

  var methods = {
    ...mapActions('userModule', [
      'setSelectedUser',
      'getUsersByOrgId',
    ]),

    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.gridApi.setPopupParent(document.body);
    },

    onSelectionChanged(event) {
      var tempUser = this.getSelectedUser();
      if (tempUser != undefined) {
        this.setSelectedUser(tempUser.userId);
        this.$emit('onUserSelection', true);
      } else {
        this.setSelectedUser(null);
        this.$emit('onUserSelection', false);
      }
    },

    onGridSizeChanged(event) {
      this.gridApi.sizeColumnsToFit();
    },

    onFilterChanged(params) {
      var colFilterActive = this.gridApi.isColumnFilterPresent();
      this.dataIsFiltered = colFilterActive ? true : false;
    },

    getSelectedUser() {
      const selectedNodes = this.gridApi.getSelectedNodes();
      const selectedData = selectedNodes.map(node => node.data);
      return selectedData[0];
    },

    getGridData(orgId) {
      this.getUsersByOrgId(orgId)
        .then(response => {
          if (response.data.data.length === 0) {
            this.gridOptions.suppressNoRowsOverlay = false;
            this.gridApi.setRowData();
          } else {
            this.gridApi.setRowData(response.data.data);
            this.gridApi.sizeColumnsToFit();
            this.gridOptions.suppressNoRowsOverlay = true;
            this.findUserInGrid();
          }
      });
    },

    findUserInGrid(position) {
      this.$nextTick(function() {
        this.gridOptions.api.forEachNode(node => {
          if (this.selectedUser.userId === node.data.userId) {
            node.setSelected( true );
            this.gridApi.ensureIndexVisible(node.rowIndex, position);
          }
        });
      });
    },

    clearAllFilters() {
      this.gridApi.setFilterModel(null);
    },
    onAddUser() {
      if (this.isEditing) {
        return;
      }
      this.$emit('onAddUserClicked', true);
      this.hideSubmitCancelBtns = false;
    },
    onDeleteUser() {
      if (this.isEditing) {
        return;
      }
      if(isNaN(this.selectedUser.userId)) {
        alert('Please select a user to delete');
      } else {
        this.$emit('onDeleteUserClicked', true);
        this.hideSubmitCancelBtns = false;
      }
    },
    onUserCanceledDelete() {
      this.hideSubmitCancelBtns = true;
      this.onUserCanceledDelete = true;
    },

  };

  var computed = {
    ...mapState('organizationModule', ['selectedOrganization']),
    ...mapState('userModule', ['selectedUser','usersByOrg']),
    ...mapGetters('userModule', ['userHasClaim', ]),
  };

  var watch= {
    selectedOrganization: function(newValue, oldValue) {
      this.gridOptions.suppressNoRowsOverlay = true;
      this.gridApi.setRowData();
      this.columnApi.setColumnVisible('orgName', newValue.orgId === -1 ? true : false);

     if (newValue.orgId !== undefined) {
        this.getGridData(newValue.orgId);
        if (newValue.orgId === oldValue.orgId) {
          this.findUserInGrid();
        }
      } else {
        this.gridApi.setRowData();
      }
    },

    isEditing: function(value) {
      if (value) {
        this.gridOptions.api.showLoadingOverlay();
      } else {
        this.gridOptions.api.hideOverlay();
      }
    },
    needFreshUserData(value) {
      if (value !== null) {
        this.getGridData(value.orgId);
      } else {
        this.getGridData(this.selectedOrganization.orgId);
      }
      this.hideSubmitCancelBtns = true;
    },
  };

  var components = {
    AgGridVue,
  };


var hideColumnsBySize = [
  {
    maxSize: 320,
    hideIds: [  ]
  },
  {
    maxSize: 500,
    hideIds: [  ]
  },
  {
    maxSize: 760,
    hideIds: [  ]
  },
  {
    maxSize: 1024,
    hideIds: [  ]
  },
  {
    maxSize: 1200,
    hideIds: [  ]
  }
];

  export default {
    name: 'UsersListGrid',
    props,
    data,
    beforeMount,
    mounted,
    // destroyed,
    methods,
    computed,
    watch,
    components
  };

</script>

/*************************************************************/

<style scoped>

.users-list-grid >>> .ag-overlay {
  pointer-events: auto;
}
.grid-holder {
  height: 100%;
}
.pg-component-toolbar {
  display: flex;
  font-size: 12px;
  font-weight: 600;
  flex-direction: row;
  align-items: center;
}
.pg-component-toolbar + div {
  flex: 1;
}
.header-icons {
  display: flex;
}

.pg-component-toolbar-item {
  /* border: none; */
  align-items: center;
}

/* .disabled {
  opacity: .5;
  pointer-events: none;
} */
/* .expand-enter-active {
  animation-name: slideOutDown;
  animation-duration:  2s;
  animation-direction: normal;
}

.expand-leave-active {
  animation-name: slideOutUp;
  animation-duration:  2s;
  animation-direction: normal;
}

@keyframes slideOutDown {
from {
  transform: translate3d(0, -100%, 0);
}
to {
  transform: translate3d(0, 0, 0);
visibility: 100%
}
}
@keyframes slideOutUp {
from {
  transform: translate3d(0, 0, 0);
}
to {
  transform: translate3d(0, -100%, 0);

}
} */


</style>
