<template>
  <div class="google-map-component pg-component">
    <div id="map" class="map">
      <!--Map goes here...-->
    </div>
    <div class="dev-only user-note">Showing {{ numPoints }} track points</div>
    <div class="map-attributions">
      <div class="attribution" v-show="showGridLines">
        <a href="https://github.com/alexcheng1982/google-maps-gridlines/blob/master/src/google-maps-gridlines.js">Graticule</a> CC BY Bill Chadwick
      </div>
    </div>

    <map-popup-window
      :initialMarker="popupMarker"
      :trackColors="trackColors"
      :showingTracks="showingTracks"
      v-show="showMarkerWindow"
      class="draggable"
      @close="showMarkerWindow=false"
    ></map-popup-window>
    <div>
      numPoints: {{ numPoints }}, numMarkers: {{ numMarkers }}, numDeviceMarkers: {{ numDeviceMarkers }}
    </div>
  </div>
</template>

/*************************************************************/

<script>
  /*  Mobile/touch
    map option gestureHandling = 'cooperative' => one finger scolls whole page, not map
    https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions.gestureHandling
*/

  // Marker grouping/clusters
  // http://htmlpreview.github.io/?https://github.com/googlemaps/v3-utility-library/blob/master/markerclustererplus/docs/reference.html
  // Zoom to rectangle
  // http://htmlpreview.github.io/?https://github.com/googlemaps/v3-utility-library/blob/master/keydragzoom/docs/reference.html

  import Vue from 'vue';
  import environmentSpecific from 'static/environmentSpecific';
  import { EventBus } from '@/EventBus';
  import { mapGetters, mapState, mapActions } from 'vuex';
  import dataService from '@/services/DataService';
  import MapPopupWindow from '@/components/MapPopupWindow';
  // import pgUtils from "@/utils/PgUtils.js";
  import * as pgHelpers from '@/utils/PgHelpers.js';
  import MarkerClusterer from 'static/libs/marker-clusterer-plus/markerclusterer.js';

  var mapComponent;
  const MAX_CLUSTER_ZOOM = 20;

  export default {
    name: 'googleMap',

    data() {
      return {
        userNote: '',
        numPoints: 0,
        startDateTime: this.moment('1970-01-01').format('YYYY-MM-DD hh:mm'),
        endDateTime: this.moment.utc().format('YYYY-MM-DD 23:59'),

        mapInitialized: false,

        numMarkers: 0,
        numDeviceMarkers: 0,
        markers: [], // managed by marker clusterer
        mapOptions: null,
        mapStyles: null,
        minZoomForTrackPoints: 12, // don't show individual points when zoomed out too far
        trackColors: [
          '#000000', //  0 black
          '#0000ff', //  1 blue
          '#800080', //  2 purple
          '#ff0000', //  3 red
          '#ffff00', //  4 yellow
          '#ffa500', //  5 orange
          '#ff00ff', //  6 fushia

          '#ffffff', //  7 white
          '#8B0000', //  8 dark red
          '#00008b', //  9 dark blue
          '#008000', // 10 green
          '#808080', // 11 gray
          '#00ffff', // 12 cyan
          '#00ff00', // 13 lime
          '#f0c0cb', // 14 pink
          '#ffffe0', // 15 light yellow
        ],

        //trackPointIconUrl: "/static/images/map/track-point.png",
        trackPointIconUrl: {
          url: '/static/images/map/track-point-000000.png#',
        },

        // trackPointIconUrl : {
        //  // path from svg file
        //   path: "M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z",
        //   fillColor: '#fff',
        //   fillOpacity: 1,
        //   strokeColor: '#fff',
        //   strokeOpacity: 1,
        //   strokeWeight: 1,
        //   scale: 0.01,
        //   anchor: new google.maps.Point(5,5),
        // },

        showAllTracksFlag: false,
        trackPointIconUrlHighlighted: '/static/images/map/track-point-000000-highlight.png',
        trackPointsCurrentlyVisible: false,
        highlightedTrackPoint: null,
        trackPointClicked: false,

        gestureHandling: 'greedy',

        // popup window
        popupMarker: {},
        showMarkerWindow: false,
        zoomLevel: null,
        maxClusterZoom: null,
        panHighlightedPadding: 20,
        highlightedMarker: null,

        // clusters
        showClusters: true,
        markerClusterer: null,
        clusterOptions: {
          imagePath: '/static/libs/marker-clusterer-plus/m', // folder
          gridSize: 25, //40,
          // zoomOnClick: false,
          maxZoom: this.showClusters ? MAX_CLUSTER_ZOOM : -1,
          // null=cluster at all levels, -1=no clustering
          //calculator: mapComponent.makeTitle(markers),
        },

        // grid lines
        showGridLines: false,
        gridLines: null,
      };
    },

    props: {
      lat: {
        type: Number,
        default: 0,
      },
      lon: {
        type: Number,
        default: 0,
      },
      zoom: {
        type: Number,
        default: 8,
      },
      trackPointNum: {
        type: Number,
        default: 30,
      },
      trackPointUnit: {
        type: String,
        default: 'days',
      },
      mapTypeId: {
        type: String,
        default: 'terrain',
      },
    },

    created() {
      mapComponent = this;
      // this.endDateTime = this.moment('2019-05-20').format('YYYY-MM-DD hh:mm'); /******************* */
    },

    beforeMount() {
      let gmapScript = 'https://maps.googleapis.com/maps/api/js?key=' + environmentSpecific.googleMapsApiKey;
      // let clusterScript = "/static/libs/marker-clusterer-plus/markerclusterer.js";
      let gridlineScript = '/static/libs/google-maps-gridlines/google-maps-gridlines.js';
      let keydragzoomScript = '/static/libs/keydragzoom/keydragzoom_packed.js';
      this.$loadScript(gmapScript).then(() => {
        // this.$loadScript(clusterScript).then(() => {
        this.$loadScript(gridlineScript).then(() => {
          this.$loadScript(keydragzoomScript).then(() => {
            this.initMap();
          });
        });
        // });
      });
    },

    mounted() {},

    methods: {
      /* Event Listeners */
      createEvents: function() {
        this.$map.addListener('zoom_changed', function() {
          mapComponent.zoomLevel = mapComponent.$map.getZoom();
          mapComponent.showTrackMarkers();
          // console.log("Zoom: ", mapComponent.zoomLevel);
        });
        google.maps.event.addListener(this.markerClusterer, 'clusteringend', mapComponent.onClusteringEnd);

        EventBus.$on('MAP_SHOW_CLUSTERS', showOrNot => {
          this.showClusters = showOrNot;
          //console.log('>> We were told ', showOrNot);
          this.updateClusters();
        });

        EventBus.$on('MAP_SHOW_GRIDLINES', showOrNot => {
          this.showGridLines = showOrNot;
          this.updateGridLines();
        });

        EventBus.$on('MAP_TOGGLE_INFO_WINDOW', () => {
          this.showMarkerWindow = !this.showMarkerWindow;
          if (this.showMarkerWindow) {
            this.createMarkerWindow(this.highlightedMarker);
          }
        });

        EventBus.$on('MAP_SET_MAP_TYPE', maptype => {
          this.$map.setMapTypeId(maptype);
        });

        EventBus.$on('MAP_TOGGLE_RECTANGLE_ZOOM', () => {
          this.toggleKeyDragZoom();
        });

        EventBus.$on('MAP_SET_TRACK_COLOR', (deviceId, newColor) => {
          var marker = this.getMarkerByDeviceId(deviceId);
          if (!marker || !marker.trackColor) {
            // debugger;
          }
          marker.trackColor = newColor;
        });
        EventBus.$on('MAP_SHOW_TRACKS', deviceIds => {
          this.getTracks(deviceIds);
        });
        EventBus.$on('MAP_SHOW_ALL_TRACKS', () => {
          this.showAllTracks();
        });
        EventBus.$on('MAP_HIDE_TRACKS', deviceIds => {
          this.hideTracks(deviceIds);
        });
        EventBus.$on('MAP_HIDE_ALL_TRACKS', () => {
          this.hideAllTracks();
        });
        EventBus.$on('MAP_NUM_TRACK_UNITS', numUnits => {
          this.updateCurrentTracks();
        });

        EventBus.$on('MAP_ZOOM_AMOUNT', amount => {
          if (amount === 0) {
            // console.log('>> fitting map to markers');
            mapComponent.markerClusterer.fitMapToMarkers();
          } else {
            let currentZoom = this.$map.getZoom();
            let newZoom = currentZoom + amount;
            mapComponent.$map.setZoom(newZoom);
            // console.log('Old zoom ', currentZoom, ' changed by ', amount, ' and is now ', newZoom);
          }
          // console.log('>> changing zoom by ', amount);
          // console.log('>> zoom set to ', this.$map.getZoom());
        });

        // EventBus.$on("MAP_SELECT_PREVIOUS_DEVICE", () => {
        //   this.selectPreviousDevice();
        // });
        // EventBus.$on("MAP_SELECT_NEXT_DEVICE", () => {
        //   this.selectNextDevice();
        // });
        EventBus.$on('MAP_SELECT_FIRST_POINT', () => {
          this.selectFirstPoint();
        });
        EventBus.$on('MAP_SELECT_FINAL_POINT', () => {
          this.selectLastPoint();
        });
        EventBus.$on('MAP_SELECT_PREVIOUS_POINT', () => {
          this.selectPreviousPoint();
        });
        EventBus.$on('MAP_SELECT_NEXT_POINT', () => {
          this.selectNextPoint();
        });
        EventBus.$on('MAP_DESELECT_ALL_TRACK_POINTS', () => {
          mapComponent.unHighlightTrackPoint(mapComponent.highlightedTrackPoint);
        });

        // EventBus.$on('WINDOW_CLICK', (event) => {
        //   // user clicked elsewhere on page, close menu
        //   // if (event.srcElement.id !== "device-menu") {
        //     console.log('>> WINDOW clicked, not on marker', event);
        //     mapComponent.showContextMenuFlag = false;
        //   // }
        // });
      },

      // onShowInfoWindowClick() {
      //   this.popupMarker = this.highlightedMarker;
      //   this.showMarkerWindow = true;
      // },

      onClusteringEnd: function(evt) {
        // console.log('>> clustering end', evt);
        if (this.highlightedDeviceId) {
          setTimeout(function() {
            // console.log('>> highlight >>', mapComponent.highlightedDeviceId);
            var marker = mapComponent.getMarkerByDeviceId(mapComponent.highlightedDeviceId);
            mapComponent.highlightMarker(marker, true);
          }, 50);
        } else {
          // console.log('>> nothin to highlight');
        }
      },

      onSelectionChange(newDeviceIdList, oldDeviceIdList) {
        // user checked or unchecked a box
        if (newDeviceIdList.length === 0 && oldDeviceIdList.length === 0) {
          // console.log('No change');
          return;
        }

        // org selection changed from AllOrgs to something else
        if (newDeviceIdList.length > 0 && oldDeviceIdList.length > 350) {
          this.markerClusterer.clearMarkers();
          oldDeviceIdList = [];
        }

        // remove unselected
        var removedList = $(oldDeviceIdList)
          .not(newDeviceIdList)
          .get();
        mapComponent.removeDevicesFromMap(removedList);

        // add selected
        var addedList = $(newDeviceIdList)
          .not(oldDeviceIdList)
          .get();
        mapComponent.mapDevices(addedList);
      },

      /* Map */
      initMap: function() {
        // https://mapstyle.withgoogle.com/
        this.mapStyles = [
          {
            featureType: 'poi',
            stylers: [{ visibility: 'off' }],
          },
          // {
          //   featureType: "poi",
          //   elementType: "labels.text",
          //   stylers: [{ visibility: "off" }]
          // },
          // {
          //   featureType: "transit",
          //   stylers: [{ visibility: "off" }]
          // },
        ];

        // this.trackPointIcon = {
        //   url: '/static/images/map/track-point.png',
        //   size: new google.maps.Size(20, 20),
        //   anchor: new google.maps.Point(5, 5)
        // };

        // can not put in data() because google does not exist yet
        // this.trackPointIconUrl.size = new google.maps.Size(15, 20);
        // this.trackPointIconUrl.scaledSize = new google.maps.Size(5, 5);
        // anchor ()
        // this.trackPointIconUrl.anchor = new google.maps.Point(5, 15);

        this.mapOptions = {
          center: { lat: this.lat, lng: this.lon },
          zoom: this.zoom,

          mapTypeId: this.mapTypeId,
          styles: this.mapStyles,
          scaleControl: true,

          disableDefaultUI: true, //!this.$pgGlobal.hasMouse
          options: {
            gestureHandling: this.gestureHandling,
          },
        };
        this.$map = new google.maps.Map(document.getElementById('map'), this.mapOptions);

        this.markerClusterer = new MarkerClusterer(this.$map, [], this.clusterOptions);
        this.markerClusterer.setCalculator(this.makeTitle);
        this.maxClusterZoom = this.markerClusterer.getMaxZoom();

        this.createEvents();

        this.updateGridLines();
        this.$map.enableKeyDragZoom();

        this.mapInitialized = true;
        // console.log('from end of map ready', this.selectedDeviceIds);
        // check with Ted on this
        this.updateMap();
        google.maps.event.addListener(this.$map, 'tilesloaded', function() {
          EventBus.$emit('MAP_LOADED');
        });
      },

      updateMap: function() {
        if (!this.mapInitialized) {
          // console.warn("updateMap(), API not ready");
          return;
        }
        this.hideAllTracks();
        this.markerClusterer.clearMarkers();
        this.numDeviceMarkers = 0;

        let deviceIdsToMap = [];
        if (this.selectedDeviceIds.length > 0) {
          this.mapDevices(this.selectedDeviceIds);
        }
      },

      mapDevices(devicesToMapList) {
        var deviceIds = '';
        if (Array.isArray(devicesToMapList)) {
          deviceIds = devicesToMapList.join(',');
        }
        if (deviceIds.length === 0) {
          // this method called when list of selected devices changes
          // if devices removed (unchecked) then this list might be empty
          // no devices to add, so just return
          return;
        }

        dataService.getLastPositions(deviceIds, this.startDateTime, this.endDateTime).then(function(result) {
          let positions = result.data;
          for (let i = 0; i < positions.length; i++) {
            let position = positions[i];
            mapComponent.addDeviceMarker(position);
          }
          mapComponent.updateClusters();
        });
      },

      removeDevicesFromMap(deviceIdList) {
        var deviceIds = deviceIdList.join(',');
        // remove tracks
        mapComponent.hideTracks(deviceIds);

        // remove markers
        for (let i = 0; i < deviceIdList.length; i++) {
          var deviceId = deviceIdList[i];
          // console.log('>> Removing marker for deviceId ', deviceId);
          var marker = mapComponent.getMarkerByDeviceId(deviceId);
          // console.log('>> Removing marker ', marker);
          mapComponent.markerClusterer.removeMarker(marker);
          // should we re-zoom here to remaining markers?
        }
      },

      /* Markers */
      makeMarker(positionData, isTrackMarker, trackColor) {
        let iconUrl = '/static/images/map/' + positionData.iconFileName;
        let highlightIconUrl = iconUrl.replace('.', '-highlight.');
        // trackColor = trackColor || 'cyan';
        // let origin = new google.maps.Point(20, 20);
        let positionIcon = {
          url: iconUrl,
          originalUrl: iconUrl,
          highlightUrl: highlightIconUrl,
          // anchor (horizontal[+left -right], vertical[+up -down])
          anchor: new google.maps.Point(5, 5),
          // size: new google.maps.Size(13,13),
          //scaledSize: new google.maps.Size(13,13),
          labelOrigin: new google.maps.Point(10, 35),
        };

        let markerLabel = {
          text: positionData.deviceName,
          fontSize: '12px;',
          fillColor: 'red',
        };

        if (isTrackMarker) {
          positionIcon.url = mapComponent.makeTrackPointIconUrl(trackColor); // needed?
          markerLabel = null;
        }

        let deviceTitle =
          positionData.deviceName +
          ' ' +
          pgHelpers.dateTimeHelper.formatDate(positionData.deviceDateTime) +
          ' ' +
          pgHelpers.latLongDisplayHelper.formatLatLong(positionData.latitude, positionData.longitude);
        let marker = new google.maps.Marker({
          // for PG
          markerId: mapComponent.numDeviceMarkers,
          trackIndex: null,
          isTrackMarker: isTrackMarker,
          trackColor: trackColor,
          device: {
            deviceId: positionData.deviceId,
            deviceName: positionData.deviceName,
            orgName: positionData.orgName,
            comm1: positionData.comm1,
            deviceTypeName: positionData.deviceTypeName,
            deviceTypeIcon: iconUrl,
          },
          data: {
            dataId: positionData.dataId,
            deviceDateTime: positionData.deviceDateTime,
            latitude: positionData.latitude,
            longitude: positionData.longitude,
            gpsQuality: positionData.gpsQuality,
            battVoltage: positionData.battVoltage,
            sst: positionData.sst,
          },
          draggable: false,
          position: new google.maps.LatLng(positionData.latitude, positionData.longitude),
          icon: positionIcon,
          label: markerLabel,
          // map: this.$map,
          // title = tooltip
          title: deviceTitle,
        });

        marker.addListener('click', function(event) {
          // console.log('You clicked on ' , marker.device.deviceId);
          if (marker.device.deviceId === mapComponent.highlightedDeviceId) {
            mapComponent.createMarkerWindow(marker);
          }
          mapComponent.unHighlightTrackPoint(mapComponent.highlightedTrackPoint);

          if (marker.isTrackMarker) {
            mapComponent.highlightTrackPoint(marker);
            mapComponent.trackPointClicked = true;
          } else {
            mapComponent.trackPointClicked = false;
          }
          mapComponent.setHighlightedDeviceId(marker.device.deviceId).then(function() {
            mapComponent.trackPointClicked = false;
          });
        });

        return marker;
      },

      addDeviceMarker(positionData) {
        let markerId = mapComponent.numDeviceMarkers++;
        let trackColor = mapComponent.trackColors[markerId % mapComponent.trackColors.length];
        //   console.warn('>> Set track color to ', trackColor, ' for markerId ', markerId);
        let marker = mapComponent.makeMarker(positionData, false, trackColor);

        this.markerClusterer.addMarker(marker);
        mapComponent.markerClusterer.fitMapToMarkers();
        if (mapComponent.showMarkerWindow && marker.device.deviceId === mapComponent.highlightedDeviceId) {
          mapComponent.createMarkerWindow(marker);
          this.highlightMarker(marker, true);
        }
        if (mapComponent.showAllTracksFlag) {
          mapComponent.getTracks(positionData.deviceId);
        }
        // return marker;
      },

      /* Tracks */
      updateCurrentTracks() {
        // refresh any tracks currently displayed
        // can be triggered by changing # days to display
        var deviceIds = [];
        var deviceIdString = '';

        // get list of devices currently showing tracks
        var allMarkers = this.markerClusterer.getMarkers();
        if (this.showAllTracksFlag) {
          deviceIdString = this.selectedDeviceIds.join(',');
        } else {
          // just update the tracks we can already see
          for (var i = 0; i < allMarkers.length; i++) {
            var marker = allMarkers[i];
            // console.log(allMarkers[i]);
            if (marker && marker.trackLine && marker.trackLine.getMap()) {
              deviceIds.push(marker.device.deviceId);
            }
          }
          deviceIdString = deviceIds.join(',');
          // console.log('current tracks: ', deviceIdString);
        }

        // update those tracks
        this.getTracks(deviceIdString);
      },

      showAllTracks: function() {
        this.showAllTracksFlag = true;
        this.showClusters = false;
        this.updateClusters();
        var deviceIds = this.selectedDeviceIds.join(',');
        mapComponent.getTracks(deviceIds);
      },

      getTracks: function(deviceIds) {
        // console.log('getTracks(' + deviceIds + ')');
        if (deviceIds === '') {
          return;
        }
        if (!mapComponent) {
          console.error('What happened to the map component?');
          mapComponent = this;
        }
        // var startDate = this.moment()
        //   .utc()
        //   .subtract(this.computedTrackPointNum, this.trackPointUnit)
        //   .toISOString();
        // console.log('> getTracks() Calling API for ', deviceIds);
        dataService.getTrackData(deviceIds, this.computedTrackPointNum, this.endDateTime, true).then(function(result) {
          // console.log('> getTracks() Got API response for ', deviceIds);

          if (result.data.length === 0) {
            // console.log('No tracks to show since '+ startDate + ' for ['+deviceIds+']' );
            return;
          }
          let allPositions = _.orderBy(result.data, ['deviceId', 'deviceDateTime'], ['asc', 'asc']);
          let thisDeviceId = allPositions[0].deviceId;
          let marker = mapComponent.getMarkerByDeviceId(thisDeviceId);
          if (!marker || !marker.trackColor) {
            // debugger;
          }

          let trackColor = marker.trackColor;
          let trackPoints = [];
          let trackMarkers = [];
          // console.log('Getting tracks since '+ startDate + ' for ['+deviceIds+']' );
          // console.log('Adding ', allPositions.length, ' markers.');
          if (allPositions.length > 1000) {
            // console.log('this make take a bit...');
          }
          mapComponent.hideTracks(deviceIds);

          let trackIndex = 0;
          // all positions for all devices
          for (let i = 0; i < allPositions.length; i++) {
            let positionData = allPositions[i];
            if (positionData.deviceId !== thisDeviceId) {
              // new device
              if (thisDeviceId != 0) {
                // not first pass
                mapComponent.addTrackToMarker(marker, trackPoints, trackMarkers);
              }
              thisDeviceId = positionData.deviceId;
              marker = mapComponent.getMarkerByDeviceId(thisDeviceId);
              trackIndex = 0;
              // trackColor = mapComponent.trackColors[marker.markerId % mapComponent.trackColors.length];
              trackColor = marker.trackColor;
              trackPoints = [];
              trackMarkers = [];
            } // end different deviceid
            // ployline
            var latLon = new window.google.maps.LatLng(positionData.latitude, positionData.longitude);
            trackPoints.push(latLon);

            // track marker
            var trackMarker = mapComponent.makeMarker(positionData, true, marker.trackColor);
            Object.assign(trackMarker.device, marker.device);

            trackMarker.trackIndex = trackIndex++;
            trackMarkers.push(trackMarker);
          } // end loop
          marker = mapComponent.getMarkerByDeviceId(thisDeviceId);
          trackMarkers.pop(); // remove final marker, replaced with main device marker
          mapComponent.addTrackToMarker(marker, trackPoints, trackMarkers);
          mapComponent.showTrackMarkers();
          // console.log('device map done adding markers')
        });
        // .then(function(){
        //   console.log('device map after API call (then), trackPointNum=', mapComponent.trackPointNum)
        // });
      },

      showTrackMarkers() {
        // console.log('>> showTrackMarkers() ');
        mapComponent.numMarkers = mapComponent.numDeviceMarkers;
        if (mapComponent.trackMarkerZoomOk()) {
          var allMarkers = this.markerClusterer.getMarkers();
          for (var i = 0; i < allMarkers.length; i++) {
            var marker = allMarkers[i];
            if (marker && marker.trackMarkers) {
              for (let j = 0; j < marker.trackMarkers.length; j++) {
                marker.trackMarkers[j].setMap(mapComponent.$map);
                mapComponent.numMarkers++;
              }
            }
          }
        } else {
          mapComponent.hideAllTrackMarkers();
        }
      },

      addTrackToMarker: function(marker, trackPoints, trackMarkers) {
        // console.log(        "Adding track to ",        marker.device.deviceName,        ", color:",        marker.trackColor,        ", markerId:",        marker.markerId);
        // this.showClusters = false;
        var trackColor = marker.trackColor; // this.lineColors[marker.markerId % this.lineColors.length];
        var trackLine = new window.google.maps.Polyline({
          path: trackPoints,
          geodesic: true,
          strokeColor: trackColor,
          strokeOpacity: 0.5,
          strokeWeight: 1,
        });
        if (marker.trackLine) {
          // remove old track first
          marker.trackLine.setMap(null);
        }
        if (marker.trackMarkers) {
          for (let j = 0; j < marker.trackMarkers.length; j++) {
            marker.trackMarkers[j].setMap(null);
          }
        }
        marker.trackLine = trackLine;
        marker.trackMarkers = trackMarkers;
        marker.trackLine.setMap(this.$map);
        mapComponent.numPoints += trackPoints.length;
      },

      hideTracks: function(deviceIds) {
        //var marker = this.getMarkerByDeviceId(4030);

        deviceIds = deviceIds.toString();
        if (deviceIds === '') {
          return;
        }
        //   console.log('hideTracks() deviceIds =', deviceIds);
        var deviceIdArray = deviceIds.split(',');
        for (let i = 0; i < deviceIdArray.length; i++) {
          var deviceId = parseInt(deviceIdArray[i]);
          var marker = mapComponent.getMarkerByDeviceId(deviceId);
          if (marker && marker.trackLine) {
            mapComponent.numPoints -= marker.trackMarkers.length + 1;
            marker.trackLine.setMap(null);
            marker.trackLine = null;
            if (marker.trackMarkers) {
              for (let j = 0; j < marker.trackMarkers.length; j++) {
                marker.trackMarkers[j].setMap(null);
              }
              marker.trackMarkers = null;
            }
          }
        }
      },

      hideAllTracks: function() {
        // console.log(">> device map hide all tracks ");
        this.showAllTracksFlag = false;
        var allMarkers = this.markerClusterer.getMarkers();
        mapComponent.numPoints = 0;
        for (var i = 0; i < allMarkers.length; i++) {
          var marker = allMarkers[i];
          if (marker.trackLine) {
            marker.trackLine.setMap(null);
            marker.trackLine = null;
          }
          if (marker.trackMarkers) {
            for (let j = 0; j < marker.trackMarkers.length; j++) {
              marker.trackMarkers[j].setMap(null);
            }
            marker.trackMarkers = null;
          }
        }
      },

      hideAllTrackMarkers: function() {
        // console.log(">> device map hide all tracks ");
        var allMarkers = this.markerClusterer.getMarkers();
        for (var i = 0; i < allMarkers.length; i++) {
          var marker = allMarkers[i];
          if (marker.trackMarkers) {
            for (let j = 0; j < marker.trackMarkers.length; j++) {
              marker.trackMarkers[j].setMap(null);
            }
          }
        }
        mapComponent.numMarkers = mapComponent.numDeviceMarkers;
      },

      highlightTrackPoint(marker) {
        mapComponent.unHighlightTrackPoint(mapComponent.highlightedTrackPoint);
        if (marker) {
          // console.log('>> marker trackIndex:', marker.trackIndex, marker );
        } else {
          console.warn('>> what happened to the marker?', marker);
          return;
        }
        if (marker.isTrackMarker) {
          let newIcon = this.makeTrackPointIconUrl(marker.trackColor, true); //this.trackPointIconUrlHighlighted; // this.makeTrackPointIcon(marker.trackColor, 'yellow', 3);
          marker.setIcon(newIcon);
          this.highlightedTrackPoint = marker;
        }
      },

      unHighlightTrackPoint(marker) {
        if (marker && marker.isTrackMarker) {
          // debugger
          let newIcon = this.makeTrackPointIconUrl(marker.trackColor); // this.trackPointIconUrl; // this.makeTrackPointIcon(marker.trackColor, marker.trackColor, 1);
          marker.setIcon(newIcon);
          this.highlightedTrackPoint = null;
        }
      },

      /* Map Interactions */
      updateClusters: function() {
        if (!this.markerClusterer) {
          console.log('map not ready...');
          return;
        }

        this.markerClusterer.setMaxZoom(this.showClusters ? MAX_CLUSTER_ZOOM : -1);
        //this.markerClusterer.fitMapToMarkers();
        this.markerClusterer.repaint();
      },

      updateGridLines: function() {
        if (this.showGridLines) {
          if (this.gridLines) {
            this.gridLines.show();
          } else {
            this.gridLines = new Graticule(this.$map, false);
          }
        } else {
          // do not show grid lines
          if (this.gridLines) {
            this.gridLines.hide();
          } else {
            // already not there
          }
        }
      },

      toggleKeyDragZoom: function() {
        var myKeyDragZoom = this.$map.getDragZoomObject();
        myKeyDragZoom.hotKeyDown_ = !myKeyDragZoom.hotKeyDown_;
      },

      /* Popup Info Window */
      createMarkerWindow: function(marker) {
        if (!marker) {
          return;
        }
        this.popupMarker = marker;
        // console.log('show popup for marker:', marker);
        this.showMarkerWindow = true;
        $('.draggable').draggable({
          handle: '.popup-header',
          opacity: 0.5,
          containment: 'document',
        });
        $('.resizeable-bottom-right').resizable();
        // console.log('dataId:', marker.data.dataId);
      },

      scrollHighlighedDeviceIntoView: function(marker) {
        if (!marker) {
          return;
        }
        // make sure in map view, grid will take care of itself
        if (this.$map.getBounds().contains(marker.getPosition())) {
          // console.log('>> Highlighted marker is visible');
        } else {
          // console.log('>> Highlighted marker is NOT visible');
          var bounds = new google.maps.LatLngBounds(marker.getPosition(), marker.getPosition());
          this.$map.panToBounds(bounds, mapComponent.panHighlightedPadding);
        }
      },

      highlightMarker: function(marker, showHighlight) {
        mapComponent.unHighlightTrackPoint(mapComponent.highlightedTrackPoint);
        if (marker && marker.icon) {
          if (showHighlight) {
            this.highlightedMarker = marker;
            marker.icon.url = marker.icon.highlightUrl;
            marker.setIcon(marker.icon);
            if (mapComponent.showMarkerWindow) {
              mapComponent.createMarkerWindow(marker);
            }
            if (mapComponent.showClusters) {
              var clusters = mapComponent.markerClusterer.getClusters();
              for (var i = 0; i < clusters.length; i++) {
                var cluster = clusters[i];
                if (cluster.clusterIcon_.visible_) {
                  if (cluster.markers_.includes(marker) && cluster.clusterIcon_.div_) {
                    cluster.clusterIcon_.div_.classList.add('cluster-highlight');
                  }
                }
              }
            }
          } else {
            this.highlightedMarker = null;
            marker.icon.url = marker.icon.originalUrl;
            marker.setIcon(marker.icon);
            $('.cluster-highlight').removeClass('cluster-highlight');
          }
        }
      },

      // selectPreviousDevice: function() {
      //   console.log("map - select prev device");
      //   if (this.popupMarker.isTrackMarker) {
      //     this.unHighlightTrackPoint(this.popupMarker);
      //   }
      // },

      // selectNextDevice: function() {
      //   console.log("map - select next device");
      //   if (this.popupMarker.isTrackMarker) {
      //     this.unHighlightTrackPoint(this.popupMarker);
      //   }

      // },

      selectFirstPoint: function(deviceID) {
        // console.log("map - select first point ", this.popupMarker);
        if (this.popupMarker.isTrackMarker) {
          this.unHighlightTrackPoint(this.popupMarker);
        }
        let baseMarker = this.getMarkerByDeviceId(this.popupMarker.device.deviceId);
        let nextMarker = baseMarker.trackMarkers[0];
        this.popupMarker = nextMarker;
        this.highlightTrackPoint(this.popupMarker);
      },

      selectLastPoint: function() {
        // console.log("map - select last point");
        if (this.popupMarker.isTrackMarker) {
          this.unHighlightTrackPoint(this.popupMarker);
        }
        let baseMarker = this.getMarkerByDeviceId(this.popupMarker.device.deviceId);
        let nextMarker = baseMarker.trackMarkers[baseMarker.trackMarkers.length - 1];
        this.popupMarker = nextMarker;
        this.highlightTrackPoint(this.popupMarker);
      },

      selectPreviousPoint: function() {
        // console.log("map - select prev point ", this.popupMarker);
        if (this.popupMarker.isTrackMarker) {
          if (this.popupMarker.trackIndex > 0) {
            this.unHighlightTrackPoint(this.popupMarker);
            let baseMarker = this.getMarkerByDeviceId(this.popupMarker.device.deviceId);
            if (baseMarker && baseMarker.trackMarkers && baseMarker.trackMarkers.length > this.popupMarker.trackIndex) {
              let nextMarker = baseMarker.trackMarkers[this.popupMarker.trackIndex - 1];
              this.popupMarker = nextMarker;
              this.highlightTrackPoint(this.popupMarker);
            }
          } else {
            // console.log('Already at end');
          }
        } else {
          let baseMarker = this.getMarkerByDeviceId(this.popupMarker.device.deviceId);
          if (baseMarker && baseMarker.trackMarkers && baseMarker.trackMarkers.length > 0) {
            let nextMarker = baseMarker.trackMarkers[baseMarker.trackMarkers.length - 1];
            this.popupMarker = nextMarker;
            this.highlightTrackPoint(this.popupMarker);
          }
        }
      },

      selectNextPoint: function() {
        // console.log("map - select next point ", this.popupMarker.trackIndex, this.popupMarker);
        if (this.popupMarker && this.popupMarker.isTrackMarker) {
          this.unHighlightTrackPoint(this.popupMarker);
          let baseMarker = this.getMarkerByDeviceId(this.popupMarker.device.deviceId);
          if (this.popupMarker.trackIndex < baseMarker.trackMarkers.length - 1) {
            let nextMarker = baseMarker.trackMarkers[this.popupMarker.trackIndex + 1];
            this.popupMarker = nextMarker;
            this.highlightTrackPoint(this.popupMarker);
          } else {
            this.popupMarker = baseMarker;
          }
        } // else Next does nothing if not on track point
      },

      /* Utils */
      makeTitle: function(markers, numStyles) {
        // altered original function: https://github.com/gmaps-marker-clusterer/gmaps-marker-clusterer/blob/c9d448d62b5634db4e76b6b7e3d913703cbed303/src/markerclusterer.js#L458
        var maxRowsInTitle = 10;
        var styleIndex = 0;
        var count = markers.length;
        var dv = count;
        while (dv !== 0) {
          dv = parseInt(dv / 10, 10);
          styleIndex++;
        }
        styleIndex = Math.min(styleIndex, numStyles);
        var p = [],
          clusterTitle = '';
        for (var i = 0; i < Math.min(count, maxRowsInTitle); i++) {
          // console.log(markers[i])
          //p.push(markers[i].device.deviceName)
          clusterTitle += markers[i].device.deviceName + '\r\n';
        }
        if (count > maxRowsInTitle) {
          clusterTitle += '(' + (count - maxRowsInTitle) + ' more)';
        }

        // var clusterTitle = p.join('\r\n');

        return {
          text: count,
          index: styleIndex,
          title: clusterTitle,
        };
      },

      trackMarkerZoomOk: function() {
        // when showing tracks, polyline always shown
        // only when zoomed in, also show track markers +
        var currentZoom = this.$map.getZoom();
        // console.log("this.minZoomForTrackPoints=", this.minZoomForTrackPoints, ", currentZoom=", currentZoom);
        return currentZoom >= this.minZoomForTrackPoints;
      },

      makeTrackPointIconUrl(trackColor, highlighed) {
        var url = '/static/images/map/track-point-{color-number}.png';
        if (!trackColor) {
          console.error(' >> makeTrackPointIconUrl() trackColor not sent');
          trackColor = '#000000';
        }
        var colorNumber = trackColor.replace('#', '');
        url = url.replace('{color-number}', colorNumber);
        if (highlighed) {
          url = url.replace('.png', '-highlighted.png');
        }
        // console.log('icon URL: ', url);
        //this.trackPointIconUrl.url = url;
        return url; // this.trackPointIconUrl;
      },

      // makeTrackPointIcon(trackColor, strokeColor, strokeWeight) {
      // var newIcon = {
      //     // path from svg file
      //     path: "M51 88 c-1 -21 -7 -28 -28 -31 l-28 -4 27 -2 c21 -1 28 -7 31 -29 l4 -27 2 27 c1 21 7 28 29 31 l27 4 -27 2 c-21 1 -28 7 -31 29 l-4 27 -2 -27z",
      //     fillColor: trackColor,
      //     fillOpacity: 1,
      //     strokeColor: strokeColor,
      //     strokeOpacity: 1,
      //     strokeWeight: strokeWeight,
      //     scale: 0.1,
      //     anchor: new google.maps.Point(60,50),
      //   };
      // return newIcon;
      // },

      getMarkerByDeviceId(deviceId) {
        return this.markerClusterer.markers_.find(m => m.device.deviceId === deviceId);
      },

      ...mapActions('deviceModule', ['setHighlightedDeviceId']),
    },

    computed: {
      ...mapState('organizationModule', ['selectedOrganization']),
      ...mapState('deviceModule', ['selectedDeviceIds', 'deviceList', 'highlightedDeviceId']),
      ...mapGetters('deviceModule', ['getSelectedDevices', 'allDevicesCount', 'allDeviceIds']),
      mapReady: function() {
        return !!document.getElementById('gmaps');
      },
      showingTracks: function() {
        return this.numPoints > 0;
      },
      computedTrackPointNum: function() {
        if (this.trackPointNum != this.$parent.trackNum) {
          // this should never happen, why isn't child property updating?
          // child property does update after calling a promise, even an empty one
          console.warn("Why didn't child trackPointNum(", this.trackPointNum, ') update to parent value (', this.$parent.trackNum, ')?');
          console.warn('Using parent value.');
          return this.$parent.trackNum;
        }
        return this.trackPointNum;
      },
    },

    watch: {
      highlightedDeviceId: function(newValue, oldValue) {
        var marker = this.getMarkerByDeviceId(oldValue);

        this.highlightMarker(marker, false);
        marker = this.getMarkerByDeviceId(newValue);
        if (marker) {
          this.highlightMarker(marker, true);
          if (!this.trackPointClicked) {
            this.scrollHighlighedDeviceIntoView(marker);
          }
        }
      },

      selectedDeviceIds: function(newDeviceIdList, oldDeviceIdList) {
        this.onSelectionChange(newDeviceIdList, oldDeviceIdList);
      },
    },

    components: {
      MapPopupWindow,
    },
  };
</script>

/*************************************************************/

<style lang="scss">
  // .device-page {}
  .google-map-component {
    flex: 1;
    display: flex;
    flex-direction: column;

    .cluster-highlight {
      background-repeat: no-repeat;
      background-image: url(/static/images/map/cluster1highlight.png);
    }
    .grid-holder {
      height: 385px;
    }
    // .pg-component-toolbar {
    //   flex: 0 0 auto;
    //   .pg-component-toolbar-item {
    //     display: inline-block;
    //     cursor: pointer;
    //     border-right: 1px solid silver;
    //   }
    // }
    .map {
      flex: 1 1 auto;
    }
    .fullsize {
      height: 100%;
      width: 100%;
    }

    .map-attributions {
      position: absolute;
      display: flex;
      bottom: 0;
      left: 75px;
    }
    .attribution {
      color: rgb(68, 68, 68);
      background-color: rgba(250, 250, 250, 0.5);
      font-size: 10px;
      padding: 1px;
      font-family: Roboto, Arial, sans-serif;
    }
    .attribution a {
      text-decoration: none;
      color: rgb(68, 68, 68);
    }
    .user-note {
      position: absolute;
    }
  }
</style>
