<template>
  <div class="api-url pg-component">
    <p>API URL</p>
    <a id="api-url" v-bind:href="apiUrl" target="_blank">{{ apiUrl }}</a>
    <p>This URL can be used to pull data into MatLab or other software or to easily view your data online.</p>
  </div>
</template>

/*************************************************************/

<script>
  import { mapGetters, mapState, mapActions } from 'vuex';
  import environmentSpecific from "static/environmentSpecific";
  import helper from '@/utils/DateRangeHelper.js';

  var thisComponent = this;

  var props = {
    allDevices: false,
    dateFilter: {},
    fileFormat: null,
    // fieldList: Array,
    forDownload: null,
    compression: null,
    orgId: null,
    coordinateFormat: null,
  };

  var data = function() {
    return {
      apiUrl: '',
      selectedDevicesCommIDs: [],
      selectedDevices: [],
      //selectedAll: false,
    };
  };

  var beforeMount = function() {
    thisComponent = this;
    this.apiUrl = this.apiBaseUrl;
  };

  var mounted = function() {
    this.updateApiUrl();
  };

  var methods = {
    updateApiUrl(){
      this.apiUrl = environmentSpecific.userApiBaseUrl;
      this.apiUrl += '?apiKey=' + this.currentUser.apiKey;
      if (!this.allDevices && this.selectedDeviceIdsCount > 0) {
        this.apiUrl += '&commIDs=' + this.getSelectedCommIdString;
      }
      this.apiUrl += helper.dateQueryString(this.dateFilter);
      if (this.fileFormat) {
        this.apiUrl += '&fileFormat=' + this.fileFormat;
      }
      if (this.selectedFieldNames.length > 0) {
        this.apiUrl += '&fieldList=' + this.getSelectedFieldDisplayNames.join(',');
      }
      if (this.forDownload === 'Yes') {
        this.apiUrl += '&download=Yes';
      }
      if (this.compression === 'zip') {
        this.apiUrl += '&compression=zip';
      }
      if (this.orgId) {
        this.apiUrl += '&orgId=' + this.orgId;
      }
      if (this.coordinateFormat) {
          this.apiUrl += '&coordinateFormat=' + this.coordinateFormat;
      }
    },
  };

  var computed = {
    ...mapState('userModule', ['currentUser', ]),
    ...mapState('deviceModule', ['allFieldsSelectedDevices', 'selectedDeviceIds', 'selectedFieldNames' ]),
    ...mapGetters('deviceModule', ['selectedDeviceIdsCount', 'getSelectedCommIdString', 'getSelectedFieldDisplayNames' ]),
  };

  var watch = {
    allDevices: function(newVal, oldVal) {
      this.updateApiUrl();
    },
    selectedDeviceIds: function() {
      this.updateApiUrl();
    },
    dateFilter: {
        handler(val) {
          //console.warn('URL knows filter changed :)');
          this.updateApiUrl();
        },
        deep: true,
    },
    fileFormat: {
        handler(val) {
          //console.warn('URL knows fileFormat changed :)');
          this.updateApiUrl();
        },
        deep: true,
    },
    allFieldsSelectedDevices: function() {
      this.updateApiUrl();
    },
    selectedFieldNames: function(newVal, oldVal){
      this.updateApiUrl();
    },
    // fieldList: function() {
    //   console.log('fieldList changed');
    //   this.updateApiUrl();
    // },
    forDownload: function() {
      this.updateApiUrl();
    },
    compression: function() {
      this.updateApiUrl();
    },
    coordinateFormat: function() {
      this.updateApiUrl();
    },
  };

  var components = {  };

  export default {
    name: 'ApiUrl',
    data,
    beforeMount,
    mounted,
    methods,
    computed,
    watch,
    components,
    props,
  };

</script>

/*************************************************************/

<style scoped>
  #api-url {
    font-family: 'Courier New', Courier, monospace;
    font-size: 1.1em;
  }
</style>
