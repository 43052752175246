<template>
  <div id="page-header" class="page-header">
    <div class="page-header-content">
      <div class="menu-area">
        <a href="#page-nav" class="far fa-bars"></a>
        <span class="page-name">{{ this.$route.meta.pageTitle }}</span>
        <span v-if="showEnvironmentName" class="env-name">{{ environmentName }}</span>
        <span v-if="isDeveloper" class="theme-name">{{ currentTheme }}</span>
      </div>
      <div class="org-name" v-bind:title="showAddlVerbage ? 'Org ID: ' +selectedOrganization.orgId : ''">
        <span>
          <span v-if="showAddlVerbage" >Viewing</span>
          {{ organizationName || currentUser.orgName }}
          <span
            v-if="showAddlVerbage"
          >as Pacific Gyre staff</span>
        </span>
      </div>
      <div class="user-name">
        <i class="far fa-question-circle" title="Help for this page" v-show="false" />
        <span v-on:click="showProfile=!showProfile" class="clickable" id="show-profile-button">
          {{ currentUser.firstName}} {{ currentUser.lastName}}
          <i class="far fa-angle-down"/>
        </span>
      </div>
    </div>
    <user-profile
      v-show="showProfile"
      v-on:input="updateProfile($event)"
      @close="showProfile=false"
    />
    <div class="header-spacer"><!-- allows fixed top header --></div>
  </div>
</template>

/*************************************************************/

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import environmentSpecific from "static/environmentSpecific";
import UserProfile from "@/components/Common/UserProfile";
import { EventBus } from "../../EventBus.js";
import ProfileService from '@/services/ProfileService';

var data = function() {
  return {
    showProfile: false,
    onAnAllOrgsPage: false,
    showAddlVerbage: false,
    showEnvironmentName: false,
    environmentName: null,
    currentTheme: null,
  };
};

var props = ["currentUser"];

var beforeMount = function() {
  this.environmentName = environmentSpecific.environmentName.toUpperCase();
  if (this.environmentName != 'PRODUCTION') {
    this.showEnvironmentName = true;
  }
  this.currentTheme = ProfileService.getLocalSetting('theme', 'standard-theme');
};

var mounted = function() {
  EventBus.$on("landed-on-org-page", payload => {
    this.onAnAllOrgsPage = payload;
  });
};

var methods = {
  onRouteChange() {
    document.title = (this.$route.meta.pageTitle ? this.$route.meta.pageTitle : "Data Website") + " | Pacific Gyre";
    this.pageName = this.$route.meta.pageTitle ? this.$route.meta.pageTitle : "";
    this.$pgGlobal.currentPageTitle = this.pageName;
    var bodyId = this.$pgGlobal.currentPageTitle.toLowerCase() + '-body'
    $('body').attr('id', bodyId)
    //console.warn('onRouteChange this.$route', this.$route);
  },

  updateProfile(evt) {
    this.showProfile = evt.showProfile;
    this.currentTheme = this.$pgGlobal.currentTheme;
  }
};

var computed = {
  ...mapState("organizationModule", ["selectedOrganization"]),
  ...mapGetters("userModule", ["userHasRole"]),
  currentPath: function() {
    return this.$route.path;
  },
  isPgStaff: function() {
    return this.userHasRole("PGStaff");
  },
  isDeveloper: function() {
    return this.userHasRole('Developer');
  },
  organizationName: function() {
    var name = "";
    if (this.isPgStaff) {
      if (this.onAnAllOrgsPage || this.currentUser.orgId === this.selectedOrganization.orgId) {
        this.showAddlVerbage = false;
        name = this.currentUser.orgName;
      } else {
        this.showAddlVerbage = true;
        name = this.selectedOrganization.orgName;
      }
    }
    return name;
  }
};

var components = {
  UserProfile
};

var watch = {
  // easy way to watch for route changes
  currentPath: function() {
    this.onRouteChange();
  },
};

export default {
  name: "PageHeader",
  data,
  props,
  beforeMount,
  mounted,
  methods,
  computed,
  components,
  watch
};
</script>

/*************************************************************/

<style lang="scss">
  /* move to app.scss */

</style>
