var moment = require('moment');

const dateRangeHelper = {

  // var dateFilter = {
  //   rangeType: 'TimeSpan', // timespan | daterange
  //   numUnits: 5,           // any int
  //   timeUnit: 'hours',     // minutes | hours | days
  //   startDate: '',
  //   endDate: '',
  // };

  formattedStartDate(dateFilter) {
    if (dateFilter.rangeType.toLowerCase() === 'timespan' ) {
      return moment().utc().subtract(dateFilter.numUnits, dateFilter.timeUnit).format();
    } else {
      if (dateFilter.startDate) {
        if (moment.utc(dateFilter.startDate).isValid()) {
          return moment.utc(dateFilter.startDate).format('YYYY-MM-DD HH:mm');
        } else {
          console.error("What's with the date [", dateFilter.startDate, "]?");
          return null;
        }
      } else {
        return null;
      }
    }
  },

  formattedEndDate(dateFilter) {
    if (dateFilter.rangeType.toLowerCase() === 'timespan' ) {
      return null;
    } else {
      if (dateFilter.endDate) {
        if (moment.utc(dateFilter.endDate).isValid()) {
          return moment.utc(dateFilter.endDate).format('YYYY-MM-DD HH:mm');
        } else {
          console.error("What's with the date [", dateFilter.endDate, "]?");
          return null;
        }
      } else {
        return null;
      }
    }
  },

  dateQueryString(dateFilter) {
    var qs = '';
    if (dateFilter.rangeType === 'TimeSpan') {
      if (dateFilter.timeUnit === 'minutes') {
        qs += '&numMinutes=' + dateFilter.numUnits;
      } else if (dateFilter.timeUnit === 'hours') {
        qs += '&numMinutes=' + dateFilter.numUnits * 60;
      } else if (dateFilter.timeUnit === 'days') {
        qs += '&numDays=' + dateFilter.numUnits;
      } else {
        console.error("What's with the time unit [", dateFilter.timeUnit, "]?");
      }
    } else { // DateRange
      if (dateFilter.startDate && dateFilter.startDate.length > 0) {
        qs += '&startDate=' + dateFilter.startDate;
      }
      if (dateFilter.endDate && dateFilter.endDate.length > 0) {
        qs += '&endDate=' + dateFilter.endDate;
      }
    }
    return qs;
  },



}; // dateRangeHelper

export default dateRangeHelper;
