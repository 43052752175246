<template>
  <div id="command-history-grid" class="command-history-container">
    <div class="command-history-list-grid">
      <div class="component-title">
        <h4>
          Command History {{ isDeviceSelected ? " for the selected device" + (selectedCount > 1 ? 's ' : ' ') : " for all devices " }}{{
          selectedOrganization.orgId === -1 && !isDeviceSelected ? 'since ' + startDate : '' }}
        </h4>
      </div>
      <ag-grid-vue
        style="width: 100%;"
        class="ag-theme-balham grid-holder"
        :gridOptions="gridOptions"
        :columnDefs="columnDefs"
        :modules="agGridModules"
      >
      </ag-grid-vue>
    </div>
  </div>
</template>

/*************************************************************/

<script>
  import { AgGridVue } from '@ag-grid-community/vue';
  import { AllCommunityModules } from '@ag-grid-community/all-modules';
  import { mapGetters, mapState, mapActions } from 'vuex';
  import pgUtils from '@/utils/PgUtils.js';
  import agGridUtils from '@/utils/AgGridUtils.js';
  import environmentSpecific from 'static/environmentSpecific';
  var myDateFormat = 'YYYY-MM-DD';
  var commandHistoryDateFormat = 'YYYY-MM-DD HH:mm:ss '; // ISO  ISO.MSnp

  var data = function() {
    return {
      columnDefs: null,
      rowData: null,
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      agGridModules: AllCommunityModules,
      gridWidth: 0,
    };
  };

  var props = ['isEditing', 'commandHistoryData'];

  var beforeMount = function() {
    this.gridOptions = {
      headerHeight: 25,
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      onGridReady: this.onGridReady,
      onGridSizeChanged: this.onGridSizeChanged,
      onCellValueChanged: this.onCellValueChanged,
      onRowClicked: this.onRowClicked,
      onColumnMoved: this.onColumnMoved,
      defaultColDef: {
        width: 100,
        sortable: true,
        filter: true,
        resizable: true,
      },
      suppressNoRowsOverlay: true,
      rowHeight: environmentSpecific.gridRowHeightPx,
      enableCellTextSelection: true,
      ensureDomOrder: true,
    };

    this.columnDefs = [
      {
        colId: 'deviceName',
        headerName: 'Device Name',
        field: 'deviceName',
        minWidth: 125,
        width: 150,
        maxWidth: 200,
      },
      {
        colId: 'dateEntered',
        headerName: 'Date',
        field: 'dateEntered',
        minWidth: 110,
        width: 165,
        maxWidth: 175,
        valueFormatter: function(params) {
          // return pgUtils.formatDate(params.data.lastTransmission, commandHistoryDateFormat);
          return pgUtils.formatDate(params.data.dateEntered, commandHistoryDateFormat);
        },
      },
      {
        colId: 'messageName',
        headerName: 'Command',
        field: 'messageName',
        minWidth: 160,
        width: 190,
        maxWidth: 230,
      },
      {
        colId: 'status',
        headerName: 'Status',
        field: 'status',
        minWidth: 105,
        width: 105,
        maxWidth: 135,
      },
      {
        colId: 'whoEntered',
        headerName: 'Sent By',
        minWidth: 105,
        width: 105,
        maxWidth: 135,
        valueFormatter: function(params) {
          return `${params.data.firstName || ''} ${params.data.lastName || 'no name'}`;
        },
      },
    ];
  };

  var mounted = function() {};

  var methods = {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.gridApi.setRowData(this.commandHistoryByOrg);
      this.gridApi.setPopupParent(document.body);
    },
    onGridSizeChanged(params) {
      var gridWidth = document.getElementById('command-history-grid').offsetWidth;
      this.gridWidth = gridWidth; // to display during dev
      agGridUtils.showColumnsBySize(params, gridWidth, hideColumnsBySize);
    },
    setGridData(data) {
      this.gridApi.setRowData(data);
    },
    prepGridForNewData() {
      this.gridApi.hideOverlay();
      this.gridApi.setRowData([]);
    },
  };

  var computed = {
    selectedCount() {
      return this.selectedDeviceIds.length;
    },
    isDeviceSelected() {
      return this.selectedDeviceIds.length > 0;
    },
    startDate() {
      return this.moment()
        .subtract(environmentSpecific.maxNumberHistoryDays, 'days')
        .format(myDateFormat);
    },
    ...mapState('organizationModule', ['selectedOrganization']),
    ...mapState('commandModule', ['commandHistory']),
    ...mapState('deviceModule', ['selectedDeviceIds']),
  };

  var watch = {
    commandHistoryData: function(history) {
      if (this.gridApi != null) {
        this.prepGridForNewData();
        this.setGridData(history);
      }
    },

    selectedOrganization: function() {
      this.prepGridForNewData();
    },
  };

  var components = {
    AgGridVue,
  };

  var hideColumnsBySize = [
    { maxSize: 500, hideIds: ['status', 'whoEntered'] },
    { maxSize: 700, hideIds: ['status'] },
  ];

  export default {
    name: 'CommandHistoryGrid',
    data,
    beforeMount,
    mounted,
    methods,
    computed,
    watch,
    components,
    props,
  };
</script>

/*************************************************************/

<style scoped></style>
