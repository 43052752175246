<template>
  <div ref="all-organizations-page" class='all-organizations-page'>
    <div class="page-content">
      <div class="panel-container-vertical">
        <div class="panel-top">
          <div class="panel-contents">
            <div class="grid-container org-grid-container">
              <organization-list-grid
                :isEditing="isEditing"
                :needFreshOrgData="needFreshOrgData"
                @onOrgSelection="onOrgSelection"
                @onAddOrgClicked="onAddOrgClicked"
              />
            </div>
            <div class="org-details-holder">
              <organization-details-form
                ref="orgDetails"
                v-show="showOrgDetails"
                :isEditingUser="isEditingUser"
                class="pg-detail-component"
                @onSuccessfulOrgUpdate="onSuccessfulOrgUpdate"
                @onEditingOrg="onEditingOrg"
              />
            </div>
          </div>
          <div class="handle h-handle"></div>
        </div>

        <div class="panel-bottom">
          <div class="panel-contents">
            <div class="grid-container users-grid-container">
              <users-list-grid
                v-show="showUserDetails"
                :isEditing="isEditing"
                :needFreshUserData="needFreshUserData"
                :userCanceledDelete="userCanceledDelete"
                @onAddUserClicked="onAddUserClicked"
                @onDeleteUserClicked="onDeleteUserClicked"
                class="pg-detail-component"
              />
            </div>
            <div class="user-details-holder">
              <user-details-form
                ref="userDetails"
                :isEditingOrg="isEditingOrg"
                :expandUserDetails="expandUserDetails"
                v-show="showUserDetails"
                class="pg-detail-component"
                @onEditingUser="onEditingUser"
                @onSuccessfulUserUpdate="onSuccessfulUserUpdate"
                @onUserCanceledDelete="onUserCanceledDelete"
              />
            </div>
          </div>
        </div>
      </div>
    </div> <!-- End of page content -->
    <div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import OrganizationListGrid from '@/components/OrganizationListGrid';
import OrganizationDetailsForm from '@/components/OrganizationDetailsForm';
import UsersListGrid from '@/components/UsersListGrid';
import UserDetailsForm from '@/components/UserDetailsForm';
import { EventBus } from '../EventBus.js';
var thisComponent = null;


export default {
  name: 'AllOrganizationsPage',
  data() {
    return {
      showOrgDetails: true,
      showUserDetails: false,
      expandUserDetails: false,
      isEditing: false,
      isEditingUser: false,
      isEditingOrg: false,
      needFreshOrgData: {},
      needFreshUserData: {},
      userCanceledDelete: false,
    };
  },

  methods: {
    onEditingUser(value) {
      this.isEditingUser = value;
      this.isEditing = value;
    },
    onEditingOrg(value) {
      this.isEditingOrg = value;
      this.isEditing = value;
    },

    onSuccessfulOrgUpdate(value) {
      console.log('updated org', value)
      this.needFreshOrgData = value;
    },
    onSuccessfulUserUpdate(value) {
      console.log('updated user', value)
      this.needFreshUserData = value;
      this.needFreshOrgData = value;
    },
    onAddOrgClicked(value) {
      this.$refs.orgDetails.addOrganization();
    },
    onEditOrgClicked(value) {
      this.$refs.orgDetails.editOrganization();
    },
    onSubmitOrgClicked(value) {
      this.$refs.orgDetails.submit();
    },
    onCancelOrgClicked(value) {
      this.$refs.orgDetails.cancel();
    },
    onOrgSelection(value) {
      this.showUserDetails = value;
    },
    onAddUserClicked(value) {
      this.$refs.userDetails.addUser();
      this.showUserDetails = value;
    },
    onEditUserClicked(value) {
      this.$refs.userDetails.editUser();
    },
    onDeleteUserClicked(value) {
      this.$refs.userDetails.removeUser();
    },
    onSubmitUserClicked(value) {
      this.$refs.userDetails.submit();
    },
    onCancelUserClicked(value) {
      this.$refs.userDetails.cancel();
    },
    onResizeEnd() {
      var width = $('.panel-right').outerWidth();
      this.rightPanelCollapsed = (width <=3);
    },
    onUserCanceledDelete() {
      this.userCanceledDelete = true;
    },
    onResizeEnd() {
      // var width = $('.panel-right').outerWidth();
      // this.rightPanelCollapsed = (width <=3);
    },
  },

  beforeMount() {
    thisComponent = this;
  },

  mounted() {
    $(".panel-top").panelresizable({
      handleSelector: '> .h-handle',
      resizeWidth: false,
      resizeHeight: true,
      resizeHeightFrom: 'bottom',
      onDragEnd: function(evt){thisComponent.onResizeEnd('.panel-top', evt)},
    });
    // This removes additional header verbage while on All Orgs page
    EventBus.$emit('landed-on-org-page', true);

  },

  beforeDestroy() {
    EventBus.$emit('landed-on-org-page', false);
  },

  components: {
    OrganizationListGrid,
    OrganizationDetailsForm,
    UsersListGrid,
    UserDetailsForm,
  }
};
</script>

/*************************************************************/
<style  lang="scss">
  .all-organizations-page {
    /* PAGE */
    .panel-container-vertical {
      max-height: calc(100vh - 40px);
    }
    .panel-contents {
      display: flex;
      flex-direction: row;
      flex: 1 1 auto;
    }
    .panel-contents > div {
      padding: 10px 0 10px 10px;
    }
    .panel-contents > div:last-child {
      padding-right: 10px;
    }
    .panel-top {
      height: 50vh;
      padding: 0;
    }
    .panel-top.resizable {
      padding-bottom: 20px; // resizer
    }
    .panel-bottom {
      display: flex;
      padding: 0;
      overflow: hidden;
    }


    /* TOP - ORGS */
    .organizations-list-grid {
      width: 100%;
    }
    .org-grid-container {
      display: flex;
      flex: 1 1 auto;
      width: 30em;
    }
    .organizations-list-grid-body {
      height: 100%;
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .org-details-holder {
      flex: 0 0  auto;
    }


    /* BOTTOM - USERS  */
    .users-list-grid {
      width: 100%;
    }
    .users-grid-container {
      display: flex;
      flex: 1 1 auto;
      width: 30em;

    }
    .users-list-grid-body {
      height: 100%;
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .pg-component-toolbar {
      display: -webkit-box !important;
    }

    .user-details-holder {
      flex: 0 0 auto;
    }

    // .user-details-form {
    //   flex: 1 1 auto;
    //   width: 100%;
    // }

    // footer
    .footer {
      flex: 0 0 auto;
    }
}




</style>


























