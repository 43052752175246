<template>
  <div class="html-editor pg-component">
    <div id="editor-container" class="editor-container">
      <tinymce v-model="localNotes" :plugins="mcePlugins" :toolbar1="mceToolbar1" :other="mceOptions" />
    </div>
  </div>
</template>

/*************************************************************/

<script>
  import { mapGetters, mapState, mapActions } from 'vuex';
  import VueEasyTinyMCE from 'static/libs/vue-easy-tinymce/vue-easy-tinymce.js';

  var thisComponent;

  var data = function() {
    return {
      mcePlugins: [
        'importcss advlist autolink lists link image charmap print preview anchor textcolor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table contextmenu paste code directionality template colorpicker textpattern',
      ],
      mceToolbar1: 'fontselect fontsizeselect bold italic forecolor underline strikethrough link unlink',
      // mceToolbar1: 'fontselect fontsizeselect bold italic underline strikethrough forecolor backcolor ',
      // mceToolbar2: 'bullist numlist | outdent indent | undo redo | link unlink | code hr  ltr rtl | removeformat charmap',
      mceOptions: {
        menubar: false,
        // height: 400,
        body_class: 'dev-notes-body ', // + currentTheme,
        content_css: '/static/css/editor.css',
        browser_spellcheck: true,
      },
      currentTheme: null,
    };
  };

  var props = {
    notes: String,
    editClaim: { type: String, default: '' },
  };

  var created = function() {
    thisComponent = this;
  };

  var mounted = function() {};

  var methods = {};

  var computed = {
    ...mapGetters('userModule', ['userHasClaim']),
    localNotes: {
      get() {
        return this.notes;
      },
      set(val) {
        var notes = val.replace(/&nbsp;/gi, '');
        this.$emit('onUpdatingNotes', notes);
      },
    },
  };

  var components = {
    tinymce: VueEasyTinyMCE,
  };

  var watch = {};

  export default {
    name: 'billingHtmlEditor',
    data,
    props,
    created,
    mounted,
    computed,
    methods,
    components,
    watch,
  };
</script>

/*************************************************************/

<style scoped>
  /* .html-editor {
    position: relative;
    width: 99%;
    margin: 0;
  }

  #editor-container .mce-tinymce .mce-btn button {
    font-size: 12px !important;
    border-color: green;
  }
  .pg-save-indicator {
    position: absolute;
    right: 10px;
    top: 31px;
    color: #888888;
  } */
</style>
