// developer stuff
import axios from 'axios';
import environmentConfig from 'static/environmentSpecific';
import pgUtils from '../utils/PgUtils';

const state = {
  webLogItems: [],
};

/* mutations are the only way to modify state, no business logic         */
/* synchronous, "simple setters", not directly accessed by componets     */
const mutations = {
  updateWebLogItems(state, webLogItems) {
    state.webLogItems = webLogItems;
  },
};

/* actions contain business logic, can commit multiple mutations         */
/* asynchronous.  use commit to alter state, dispatch to call functions  */
const actions = {
  getWebLogItems( { commit }  ){
    var fullUrl = `${environmentConfig.apiBaseUrl}webLog/search?takeRecords=100`;
    axios.get(fullUrl)
      .then((response) => { commit('updateWebLogItems', response.data.data); });
  },

};

/* "computed properties for stores" - calculate data based on store state  */
const getters = {
};

const plugins = [
];

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  plugins,
};
