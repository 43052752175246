<template id="sensordata-delete-modal">
	<pg-modal :show="showConfirmModal">
		<div class="popup-window confirmation-modal">
			<div class="modal-body">
				<div class="popup-header">
					Please Confirm
					<i class="far fa-times-square closer" v-on:click="closeMe()" />
				</div>

				<div class="popup-content">
					<p>
						{{ bodyContent.message }}
					</p>
				</div>

				<div class="popup-footer popup-button-wrapper">
					<button class="floating-button" @click="close()">
						Cancel
					</button>
					<button class="floating-button" @click="confirm()">
						Confirm
					</button>
				</div>
			</div>
		</div>
	</pg-modal>
</template>

<script>
	import PgModal from '@/components/Common/PgModal';

	export default {
		name: 'SensorDataDeleteModal',
		props: ['showConfirmModal', 'bodyContent'],
		components: { PgModal },
		data() {
			return { msg: null };
		},
		methods: {
			close: function() {
				this.$emit('cancelledDelete');
			},
			confirm: function() {
				var id = this.bodyContent.sensorDataId;
				this.$emit('confirmedDelete', id);
			},
			closeMe: function() {
				this.$emit('cancelledDelete');
			},
		},
	};
</script>

/*************************************************************/

<style scoped>
	.confirmation-modal {
		padding: 0;
		width: 500px;
	}
	.modal-body {
		margin: 0;
	}
	.popup-content {
		padding: 10px;
	}
	.popup-content > div {
		padding-bottom: 5px;
	}
	.button-area {
		display: flex;
		justify-content: center;
	}
	.floating-button {
		text-decoration: none;
		border-radius: 2px;
		margin-top: 1em;
		padding: 0.7em 2em;
		border: 0;
		transition: all 0.15s ease-in-out;
		text-transform: uppercase;
		cursor: pointer;
	}
	.big-button:hover {
		background-color: #5eadff;
	}
</style>
