<template>
  <div id="billing-activity-grid" class="billing-activity-grid">
    <div class="pg-component-toolbar">
      <span class="pg-component-toolbar-left">
        <span class="pg-component-toolbar-item">
          <span @click="addFinalRow" title="Add transaction" class="">
            <i class="far fa-plus-square fa-lg"></i>
          </span>
        </span>
      </span>
    </div>
    <div class="billing-activity-grid-body">
      <ag-grid-vue
        class="ag-theme-balham grid-holder"
        style="width: 100%"
        :gridOptions="gridOptions"
        :columnDefs="columnDefs"
        :modules="agGridModules"
      >
      </ag-grid-vue>
    </div>
  </div>
</template>

/*************************************************************/

<script>
  import { AgGridVue } from '@ag-grid-community/vue';
  import { AgSelect, AllCommunityModules } from '@ag-grid-community/all-modules';
  import { mapGetters, mapState, mapActions } from 'vuex';
  import BillingActionCellEditor from '@/utils/BillingActionCellEditor.js';
  import * as pgHelpers from '@/utils/PgHelpers.js';

  var myDateFormat = 'MM/DD/YY';
  var thisComponent;
  var data = function () {
    return {
      columnDefs: null,
      rowData: null,
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      agGridModules: AllCommunityModules,
      gridWidth: 0,
      previousBalance: 0,
      lastRowIndex: -1,
      options: ['Payment', 'Credit', 'Charge'],
    };
  };

  var props = ['billingActivity', 'newBillingAdjustmentId'];

  var beforeMount = function () {
    thisComponent = this;
    this.previousBalance = 0;
    this.gridOptions = {
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      onGridReady: this.onGridReady,
      rowSelection: 'single',
      onSelectionChanged: this.onSelectionChanged,
      onCellValueChanged: this.onCellValueChanged,
      stopEditingWhenGridLosesFocus: true,
      onCellEditingStopped: this.onCellEditingStopped,
      suppressMenuHide: true,
      singleClickEdit: true,
      valueCache: true,
      rowHeight: 25,
      headerHeight: 25,
      icons: {
        menu: '<i class="far fa-filter"/>',
      },
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
      },
      context: {
        getBalance: this.getBalance,
        actionCellRenderer: this.actionCellRenderer,
        currencyFormatter: this.currencyFormatter,
        balanceCellRenderer: this.balanceCellRenderer,
        showStatement: this.showStatement,
        saveActionToDatabase: this.saveActionToDatabase,
        isRowEditable: this.isRowEditable,
      },
      enableCellTextSelection: true,
      ensureDomOrder: true,
    };
    this.columnDefs = [
      {
        colId: 'activityDate',
        headerName: 'Date',
        field: 'activityDate',
        width: 80,
        editable: this.isRowEditable,
        suppressSizeToFit: true,
        filter: false,
        valueFormatter: function (params) {
          var date = pgHelpers.dateTimeHelper.formatDate(params.data.activityDate, myDateFormat);
          return date;
        },
      },
      {
        colId: 'action',
        headerName: 'Action',
        field: 'action',
        width: 130,
        editable: this.isRowEditable,
        cellRenderer: thisComponent.actionCellRenderer,
        cellEditorFramework: 'BillingActionCellEditor',
      },
      {
        colId: 'amount',
        headerName: 'Amount',
        field: 'amount',
        width: 90,
        editable: this.isRowEditable,
        type: 'numericColumn',
        suppressSizeToFit: true,
        filter: false,
        valueGetter: function (params) {
          return params.data.amount.toFixed(2);
        },
        valueFormatter: thisComponent.currencyFormatter,
        valueParser: function (params) {
          return Number(params.newValue);
        },
      },
      {
        colId: 'balance',
        headerName: 'Balance',
        field: 'balance',
        width: 90,
        type: 'numericColumn',
        suppressSizeToFit: true,
        filter: false,
        valueGetter: function (params) {
          return params.data.balance.toFixed(2);
        },
        valueFormatter: thisComponent.currencyFormatter,
        cellRenderer: thisComponent.balanceCellRenderer,
      },
    ];
  };

  var mounted = function () {};

  var methods = {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    setGridData() {
      this.gridApi.setRowData(this.billingActivity);
      this.gridApi.setPopupParent(document.body);
      var id = (this.lastRowIndex - 1).toString();
      var node = this.gridApi.getRowNode(id);
      if (node != undefined) {
        node.setSelected(true, true);
        this.gridApi.ensureIndexVisible(node.rowIndex, top);
      }
      this.gridApi.sizeColumnsToFit();
    },
    addFinalRow() {
      var date = pgHelpers.dateTimeHelper.formatDate(new Date().toLocaleString(), myDateFormat);
      var newRow = {
        activityDate: date,
        action: 'Payment',
        amount: 0,
        balance: 0,
      };
      this.gridApi.updateRowData({ add: [newRow] });
    },

    // getBalance(params) {
    //   if (params.node.rowIndex === 0) {
    //     this.previousBalance = 0;
    //   } else {
    //     this.previousBalance += params.node.data.amount;
    //   }
    //   return this.previousBalance.toFixed(2);
    // },

    balanceCellRenderer(params) {
      var editable = params.context.isRowEditable(params);
      if (!editable) {
        var currency = params.context.currencyFormatter(params);
        return currency;
      } else {
        var cellDiv = document.createElement('div');
        // var saveIcon = `<span class="far fa-plus-circle fa-lg"></span>`;
        var saveIcon = `<span class="button">Save</span>`;

        cellDiv.innerHTML = saveIcon;
        var saveBtn = cellDiv.querySelectorAll('.button')[0];
        saveBtn.addEventListener('click', function () {
          var node = params.node;
          params.context.saveActionToDatabase(node);
        });
        return cellDiv;
      }
    },
    saveActionToDatabase(node) {
      if (node === undefined) {
        var node = this.gridApi.getRowNode(this.lastRowIndex);
      }

      var action = node.data.action;
      var amount = node.data.amount;

      if (amount == 0) {
        alert('Please enter an amount below');
        return;
      }
      amount = Math.abs(amount);
      if (action === 'Payment' || (action === 'Credit' && amount != 0)) {
        amount = -amount; // should be negative
      } else {
        // action === "Charge" should be positive
      }

      var actionToSave = {
        asOfDate: node.data.activityDate,
        description: action,
        amount: amount,
      };
      console.log('billing activity', this.billingActivity);
      this.$emit('onSaveNewAction', actionToSave);
    },

    onSelectionChanged() {
      var rowNode = this.gridApi.getSelectedNodes()[0];
      if (rowNode.rowIndex != this.lastRowIndex) {
        this.$emit('onActionSelection', rowNode.data);
      } else {
        this.$emit('onAddingNewAction', rowNode.data);
      }
    },

    onCellValueChanged(params) {
      this.$emit('onAddingNewAction', params.data);
    },
    onCellEditingStopped(params) {},

    actionCellRenderer(params) {
      var editable = params.context.isRowEditable(params);
      if (!editable) {
        if (params.data.pdfFolder === null) {
          return params.data.action;
        } else {
          var cellDiv = document.createElement('div');
          var pdfIcon = `<span>${params.data.action}</span><span class="far fa-file-pdf fa-lg"></span>`;
          cellDiv.innerHTML = pdfIcon;
          cellDiv.classList.add('pdfIcon');
          var pdfBtn = cellDiv.querySelectorAll('.fa-file-pdf')[0];
          pdfBtn.addEventListener('click', function () {
            var node = params.node;
            params.context.showStatement(node);
          });
        }
      } else {
        var cellDiv = document.createElement('div');
        var actionCell = `<span>${params.data.action}</span><span class="fas fa-caret-down fa-lg"></span>`;
        cellDiv.innerHTML = actionCell;
        cellDiv.classList.add('actionCell');
      }
      return cellDiv;
    },

    // duplicate of renderer on billing overview grid -- combine
    currencyFormatter(params) {
      var colId = params.colDef.colId;
      var balance = params.value;
      var amount = params.data.amount;
      var action = params.data.action;

      if (balance == undefined) {
        return;
      }
      if (colId === 'amount') {
        amount = Math.abs(amount).toFixed(2);
        if (action === 'Payment' || (action === 'Credit' && amount != '0.00')) {
          // if action is payment or credit - amount is neg (decreases the balance)
          return '($' + amount + ')';
        }
        //  else if (action === "Charge" || action.search("State") === 0 || action.search("Begin") === 0) {
        //   // if action is statement or charge - amount and balance are positive (increases the balance)
        //   return '$' + amount;
        // }
        else {
          return '$' + amount;
        }
      }
      if (colId === 'balance') {
        if (params.value.search('-0.00') != -1) {
          var zero = params.value.replace('-', '');
          return '$' + zero;
        } else if (params.value.search('-') != -1) {
          var negative = params.value.replace('-', '');
          return '($' + negative + ')';
        } else {
          return '$' + params.value;
        }
      }
    },

    showStatement(node) {
      var requestedPdf = {
        folder: node.data.pdfFolder,
        file: node.data.pdfFileName,
      };
      this.$emit('onPdfFileRequest', requestedPdf);
    },

    isRowEditable(params) {
      var currentRowIndex = params.node.rowIndex;
      if (currentRowIndex === this.lastRowIndex) {
        return true;
      }
      return false;
    },
    findRowByBillingId(id) {
      var node = null;
      this.gridApi.forEachNode(n => {
        if (id == n.data.id) {
          node = n;
        }
      });

      if (node != undefined && node != null) {
        node.setSelected(true, true);
        this.gridApi.ensureIndexVisible(node.rowIndex, top);
      }
    },
    ...mapActions('backOfficeModule', ['getBillingPdfStatement']),
  };

  var computed = {};

  var watch = {
    billingActivity: function (value) {
      this.lastRowIndex = value.length;
      this.setGridData(value);
      // this.addFinalRow();
    },
    newBillingAdjustmentId: function (id) {
      this.findRowByBillingId(id);
    },
  };

  var components = {
    AgGridVue,
    BillingActionCellEditor,
  };

  export default {
    name: 'BillingActivityGrid',
    data,
    props,
    beforeMount,
    mounted,
    methods,
    computed,
    watch,
    components,
  };
</script>

/*************************************************************/

<style lang="scss">
  .billing-activity-grid {
    .grid-holder {
      width: 100%;
      flex: 1;
      overflow: hidden;
      height: 100%;
    }
    .ag-cell-value {
      margin-top: 0;
      height: 26px;
    }
    .ag-row-last {
      border: none !important;
    }
    .ag-row-last .ag-cell {
      font-weight: bold !important;
      border: 1px solid !important;
    }

    .pdfIcon .fa-file-pdf,
    .actionCell .fa-caret-down {
      float: right;
      line-height: inherit;
    }
    .fa-file-pdf {
      cursor: pointer !important;
      padding-left: 10px;
    }

    span.button {
      cursor: pointer;
      padding-right: calc(50% - 8px);
      text-align: middle;
    }
  }
</style>
