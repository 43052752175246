import Vue from 'vue';
export default Vue.extend({

    template: `<span>{{ params.value }} </span>`,
    data() {
        return {
        }
    },
    created() {
    },
    beforeMount() { },
    mounted() { },

    methods: {},
    components: {},

});

