/*
  Shamelessly stolen from node_modules\@amcharts\amcharts4\.internal\themes\kelly.js
  Changed slightly to make more legible.
  We might want to add other themese later if we allow website themes so the lines will contrasts.
  https://www.amcharts.com/docs/v4/tutorials/creating-themes/
*/
import * as am4core from "@amcharts/amcharts4/core";

var theme = function (object) {
  if (object instanceof am4core.ColorSet) {
    // console.log('pgKelly theme', object);
    object.list = [
      am4core.color("#F3C300"), // golden poppy (goldenrod-ish)
      am4core.color("#875692"), // trendy pink (looks purple)
      am4core.color("#F38400"), // tangerine
      am4core.color("#A1CAF1"), // baby blue eyes
      am4core.color("#BE0032"), // cardinal (red)
      am4core.color("#C2B280"), // ecru (gold-ish)
      am4core.color("#848482"), // battleship gray
      am4core.color("#008856"), // unnamed green
      am4core.color("#E68FAC"), // (pepto pink)
      am4core.color("#0067A5"), // med persian blue
      am4core.color("#F99379"), // (reddish-orange)
      am4core.color("#604E97"), // (purple)
      am4core.color("#F6A600"), // (orange)
      am4core.color("#B3446C"), // (raspberry rose)
      am4core.color("#DCD300"), // (neon yellow green)
      am4core.color("#882D17"), // sienna
      am4core.color("#8DB600"), // apple green
      am4core.color("#654522"), // dark brown
      am4core.color("#E25822"), // flame (reddish-orange)
      am4core.color("#2B3D26"), // (dark dark green)
      // am4core.color("#F2F3F4"), // alice blue (damn near white)
      am4core.color("#222222")  // (almost black)
    ];
    object.minLightness = 0.2;
    object.maxLightness = 0.7;
    object.reuse = true;
  }
};
export default theme;
