<template>
  <div class="video-popup-window popup-window  resizeable-bottom-right">
    <div class="ui-draggable-handle popup-header">
      {{videoTitle || 'Video Player'}}
      <i class="far fa-times-square closer" v-on:click="closeMe()"></i>
    </div>
    <div class="pg-video-container popup-content" >
      <iframe id="player" class="youtube-video" type="text/html" width="100%" height="100%"
        v-bind:src="videoUrl" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>
      </iframe>
    </div>
  </div>
</template>

/*************************************************************/

<script>
  var videoPopupComponent = this;

  var data = function() {
    return {
      videoUrl: this.youTubeUrl('uBoxNsRvAt8'),
    };
  };

  var props =  [
    'videoId',
    'videoTitle',
  ];

  var beforeMount = function() {};

  var mounted = function() {

  };

  var methods = {
    closeMe() {
      this.$emit('close');
      this.videoUrl="";
    },

    playVideo(videoId){
      console.log('playing video ', videoId);
      this.videoUrl = this.youTubeUrl(videoId);
      // this.showVideoScreen = true;
    },
    youTubeUrl(videoId) {
      return 'https://www.youtube-nocookie.com/embed/'+videoId+'?&enablejsapi=1&version=3&playerapiid=ytplayer&origin=' + window.location.hostname.toString();
    }
  };

  var computed = {
    // access these getters from MainStore (which gets them from store Modules)
    //...mapState('storeName', ['getterName1', 'getterName2']),
  };

  var watch= {
    videoId: function(){
      console.log('popup: video id changed to ', this.videoId);
      this.playVideo(this.videoId);
    },
  };

  var components = {  };

  export default {
    name: 'VideoPopupWindow',
    data,
    props,
    beforeMount,
    mounted,
    methods,
    computed,
    watch,
    components
  };

</script>

/*************************************************************/
<style lang="scss" scoped>

  .video-popup-window {
    position: absolute;
    top: 50px;
    left: 50px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    height: 30vh;
    width: calc(30vh * 1.25);
  }
  .pg-video-container {
    display: block;
    padding: 0;
    width: 100%;
  }
  // iframe {
  //   // position: absolute;
  //   // left: 0;
  //   width: 100%;
  //   height: 100%;
  // }
  // .closer {
  //   position: absolute;
  //   right: 3px;
  //   top: 2px;
  //   font-size: 2em;
  //   cursor: pointer;
  // }
  .draggable .handle {
    margin: 0;
  }


</style>
