<template>
  <div class="access-denied-page">
    <img src="@/assets/images/access-denied.png" class="access-denied"/>
  </div>
</template>

<script>
export default {
  name: 'TestPage',
  data () {
    return { }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  img.access-denied {height: 100%;}
</style>
