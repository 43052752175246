<template>
  <div class="billing-commissions-page">
    <billing-commissions v-bind:allCommissionData="allCommissionData"></billing-commissions>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import BillingCommissions from '@/components/BillingCommissions';
  var thisComponent;
  export default {
    name: 'BillingCommissionsPage',
    data() {
      return {
        commissionsCopy: [],
        devicesByOrg: [],
        summariesByOrg: [],
        allCommissionData: [],
        activationTotal: 0,
        activationCostTotal: 0,
        monthlyFeeTotal: 0,
        monthlyCostTotal: 0,
        grossProfitTotal: 0,
      };
    },
    components: {
      BillingCommissions,
    },
    computed: {
      ...mapState('backOfficeModule', ['billingCommissions']),
    },
    methods: {
      // I'm thinking this is handled by the component
      getDistictOrgs(commissions) {
        var orgs = commissions.map(c => c.orgName);
        var distinctOrgs = _.uniq(orgs, true);
        return distinctOrgs;
      },
      calculateSummaryTotals(device) {
        this.activationTotal += device.activation;
        this.activationCostTotal += device.activationCost;
        this.monthlyFeeTotal += device.monthlyFee;
        this.monthlyCostTotal += device.monthlyCost;
      },
      formatForCurrency(amount) {
        var currency = '$' + amount.toFixed(2);
        return currency;
      },
      calculateGrossProfit(device) {
        var gp = device.activation - device.activationCost + (device.monthlyFee - device.monthlyCost);
        thisComponent.formatForCurrency(gp);
      },

      getSummariesByOrg(org) {
        var name = org;
        var devices = _.filter(this.commissionsCopy, function(c) {
          return c.orgName == name;
        });

        devices.forEach((device, index) => {
          thisComponent.calculateSummaryTotals(device);
          device.orgName = '';
          device.activation = thisComponent.formatForCurrency(device.activation);
          device.activationCost = thisComponent.formatForCurrency(device.activationCost);
          device.monthlyFee = thisComponent.formatForCurrency(device.monthlyFee);
          device.monthlyCost = thisComponent.formatForCurrency(device.monthlyCost);
          device.grossProfit = thisComponent.calculateGrossProfit(device);
          device.typeRow = 'detailRow';
          device.class = 'hide';
          device.groupId = index;
        });

        var summary = {
          orgName: name,
          deviceName: devices.length,
          activation: thisComponent.formatForCurrency(this.activationTotal),
          activationCost: thisComponent.formatForCurrency(this.activationCostTotal),
          monthlyFee: thisComponent.formatForCurrency(this.monthlyFeeTotal),
          monthlyCost: thisComponent.formatForCurrency(this.monthlyCostTotal),
          grossProfit: thisComponent.formatForCurrency(
            this.activationTotal - this.activationCostTotal + (this.monthlyFeeTotal - this.monthlyCostTotal),
          ),
          typeRow: 'summaryRow',
          class: 'show',
          groupId: devices[0].groupId,
        };
        return { summaryRow: summary, detailRows: devices };
      },
    },
    mounted() {
      thisComponent = this;

      // this.commissionsCopy = [...this.billingCommissions];
      this.allCommissionData = [...this.billingCommissions];
      // console.log(this.commissionsCopy);
      // var orgs = this.getDistictOrgs(this.commissionsCopy);
      // var arrayHolder = [];
      // orgs.forEach(org => {
      //   var data = this.getSummariesByOrg(org);
      //   var summaryEl = [];
      //   summaryEl.push(data.summaryRow);
      //   var orgData = summaryEl.concat(data.detailRows);
      //   arrayHolder.push(orgData);
      // });
      // thisComponent.allCommissionData.push(_.flatten(arrayHolder));
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
