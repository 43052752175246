<template>
  <div class="user-details-form pg-component pg-sidebar">
    <div class="pg-component-toolbar">
      <div class="pg-component-toolbar-left">User Details
      </div>
      <div class="pg-component-toolbar-center">
        <span v-if="!expandUserDetails" class="span-link" @click="showDetails = !showDetails" :class={disabled:isEditing}>
          <span v-if="!showDetails">Show Details</span><span v-else>Hide Details</span>
        </span>

      </div>
      <div class="pg-component-toolbar-right">
        <div class="header-icons" v-if="hideSubmitCancelBtns">
          <span class="pg-component-toolbar-item" v-if="userHasClaim('Users', 'update')">
            <span @click="editUser" title="Edit User Details" class="far fa-edit fa-lg"></span>
          </span>
        </div>
        <div class="header-icons" v-else>
          <span class="pg-component-toolbar-item" >
            <span @click="submit" title="Submit" class="far fa-check-circle fa-lg"></span>
          </span>
          <span class="pg-component-toolbar-item" >
            <span @click="cancel" title="Cancel" class="far fa-times-circle fa-lg"></span>
          </span>
        </div>
      </div>
    </div>
    <!-- https://bugs.chromium.org/p/chromium/issues/detail?id=468153#c164 -->
    <!-- https://bugs.chromium.org/p/chromium/issues/detail?id=587466#c75 -->
    <!-- https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill -->
    <!-- check out this hack!! invisible inputs.  what bs -->
    <div class="pg-form-wrapper  pg-component-content">
      <form @submit.prevent="submit">
        <!-- <input type="text" name="userName" style="display:none;" readonly/> -->
        <!-- <input type="password" style="display:none;" readonly /> -->
        <fieldset :disabled="formDisabled">

          <div class='pg-form vertical' :class="{'appearEditable': isEditing }">
            <div class="form-group" :class="{ 'form-group--error': localData.uniqueName===false ||$v.localData.userName.$error }">
              <div @click="chastiseUser">
                <input id="userName" ref="userNameInput" name="userName" type="text" v-model.trim="localData.userName" @input='delayApiCall' :disabled="!allowUserNameInput"/>
              </div>
              <label for="userName">Username:</label>
            </div>

            <div class="error-msg-div" v-if="isNewUser && !$v.localData.userName.required && $v.localData.userName.$dirty">
              <span>* Please enter a user name.</span>
            </div>
            <div class="error-msg-div" v-if="!localData.uniqueName && $v.localData.userName.$dirty">
              <span>* That username already exists.  Please try another.</span>
            </div>

            <div class="form-group">
              <div @click="chastiseUser">
                <input id="firstName" ref="firstNameInput" name="firstName" type="text" v-model.trim="localData.firstName" @input='updateInputValue' />
              </div>
              <label for="firstName">First Name:</label>
            </div>

            <div class="form-group">
              <div @click="chastiseUser">
                <input id="lastName" name="lastName" type="text" v-model.trim="localData.lastName" @input='updateInputValue' />
              </div>
              <label for="lastName">Last Name:</label>
            </div>

            <div class="form-group password-input" v-if="userHasClaim('Users', 'update') && showDetails || isEditing" :class="{ 'form-group--error': !$v.localData.password.required  && $v.localData.password.$dirty || passwordsDoNotMatch} ">
              <div @click="chastiseUser">
                <input class="" id="password" name="password" :type="passwordType" v-model.trim="localData.password" @change='updateInputValue' @blur="comparePasswords" v-on:focus="showEyeball=true" autocomplete="need-new-pass"/>
              </div>
              <i class="eye-icon far" v-show="isEditing && showEyeball" :class="[passwordIcon, {'appearEditable': isEditing}]" @click="hidePassword = !hidePassword" />
              <label for="password">{{ userIsSelected && !isNewUser ? "Reset Password" : "Password" }}</label>
            </div>

            <div class="error-msg-div" v-if="$v.localData.password.$invalid && $v.localData.password.$dirty">
              <span>* When creating a new user, a password is required.</span>
            </div>

            <div class="form-group" v-if="userHasClaim('Users', 'update') && showDetails || isEditing" :class="{'form-group--error' : passwordsDoNotMatch }">
              <div @click="chastiseUser">
                <input id="repeat-password" name="repeatPassword" :type="passwordType" v-model.trim="localData.repeatPassword" @input='updateInputValue' @blur="comparePasswords" />
              </div>
              <label for="repeat-password">Repeat Password</label>
            </div>

            <div class="form-group error-msg-div" v-if="passwordsDoNotMatch">
              <span>* The passwords do not match</span>
            </div>

            <div class="form-group" v-if="showDetails || isEditing">
              <div @click="chastiseUser">
                <input id="title" name="title" type="text" v-model.trim="localData.title" @input='updateInputValue' />
              </div>
              <label for="title">Title:</label>
            </div>

            <div class="form-group">
              <div @click="chastiseUser">
                <input id="email" name="eMail" type="text" v-model.trim="localData.eMail" @input='updateInputValue' />
              </div>
              <label for="email" class="form__label">Email:</label>
            </div>

            <div class="form-group">
              <div @click="chastiseUser">
                <input id="phone" name="phone" type="text" v-model.trim="localData.phone" @input='updateInputValue' />
              </div>
              <label for="phone">Phone:</label>
            </div>

            <div class="form-group">
              <div @click="chastiseUser">
                <input id="cell" name="cell" type="text" v-model.trim="localData.cell" @input='updateInputValue' />
              </div>
              <label for="cell">Cell:</label>
            </div>
            <div class="form-group"  v-if="showDetails || isEditing" :class="{'appearEditable' : isEditing}">
              <ul v-if="!isPgStaff">
                <li v-for="role in currentUser.roles" :key="role.roleId">
                  <input type="checkbox" v-model="selectedRoles" :value="role.roleId" :id="role.roleId" @click="rolesToggle" />
                  <label v-bind:for="role.roleId"><span>{{ role.roleId }} {{ role.roleName }}</span></label>
                </li>
              </ul>
              <ul v-if="isPgStaff">
                <li v-for="role in roles" :key="role.roleId">
                  <input type="checkbox" v-model="selectedRoles" :value="role.roleId" :id="role.roleId" @click="rolesToggle" />
                  <label v-bind:for="role.roleId"><span>{{ role.roleId }} {{ role.roleName }}</span></label>
                </li>
              </ul>
              <label for="roles">Roles:</label>
            </div>
            <!-- <div class="form-group" >
              <table v-if="localData.claims && localData.claims.length > 0">
                <thead><tr><th></th><th></th></tr></thead>
                <tbody>
                  <tr v-for="(claim, index) in localData.claims" :key="index">
                    <td> {{ claim.claimType }} </td>
                    <td> {{ claim.claimValues.join(', ') }} </td>
                  </tr>
                </tbody>
              </table>
              <span class="text-input-poser" v-if="localData.claims === undefined || localData.claims.length === 0"></span>
              <label for="roles">Permissions:</label>
            </div> -->
            <div class='form-group'>
              <span class="text-input-poser" id="apiKey" name="apiKey" type="text">{{localData.apiKey}}</span>
              <label for="apiKey">API key:</label>
            </div>

            <div class='form-group' v-if="isPgStaff && showDetails || isEditing">
              <pg-select
                ref="vms"
                id="orgId"
                :options='organizations'
                :value='selectedOrgDropdown'
                label='orgName'
                track-by='orgId'
                @input='onDropdownInput'
                :disabled="!isPgStaff"
              >
              </pg-select>
              <label for="organization">Organization:</label>
             <!-- <span class="text-input-poser" id="organization" name="organization" type="text">{{ localData.orgName }}</span> -->
            </div>
          </div>
        </fieldset>
      </form>
    </div>
    <div class="notification" v-if="showNotification">
        <span v-html="userMessage"></span>
    </div>
    <div class="modal-wrapper">
      <user-delete-confirm-modal
          v-show="showConfirmModal"
          @close="closeModal"
          @confirmed="onUserConfirmation"
          :userName="selectedUser.userName"
          :userId="selectedUser.userId"
      />
    </div>
     <!-- <pre>Invalid: {{$v.localData.$invalid}}   AnyDirty: {{$v.$anyDirty}} Dirty:  {{$v.$dirty}}</pre>
     <pre>{{$v.localData.userName}} </pre>
     <pre>{{$v.localData.password}} </pre>
     <pre>{{$v.localData.repeatPassword}} </pre>

     <pre>localData:
       {{localData}} </pre>
     <pre>$v.localData:
       {{$v.localData}} </pre>
      -->
  </div>
</template>

/*************************************************************/

<script>
  import { mapGetters, mapState, mapActions } from 'vuex';
  import { required, minLength, requiredIf, requiredUnless } from 'vuelidate/lib/validators'
  import PgSelect from "@/components/Common/PgSelect";
  import UserDeleteConfirmModal from "@/components/UserDeleteConfirmModal";

  var isUnique = function(value, vm) {
    // console.log('isUnique(): ', vm.uniqueName, value)
    if (vm === undefined || vm.uniqueName === undefined || value === '') {
      return true;
    }
    var unique = vm.uniqueName;
    return unique;
  }
  var timeoutId = 0;

  var data = function() {
    return {
      localData: {
        userId: null,
        orgId: null,
        userName: null,
        firstName: null,
        lastName: '',
        password: '',
        repeatPassword: '',
        title: '',
        eMail: '',
        phone: '',
        cell: '',
        roleIds: [],
        apiKey: null,
        claims: [],
        uniqueName: true,
      },
      formDisabled: true,
      isNewUser: false,
      selectedRoles: [],
      canSeeMe: false,
      isPgStaff: false,
      // allowUserNameInput: false,
      selectedClaims: [],
      checkNameDelayMs: 500,
      userIsSelected: false,
      expanded: this.showOnLoad,
      selectedOrgId: null,
      showConfirmModal: false,
      hidePassword: true,
      passwordsDoNotMatch: false,
      showEyeball: false,
      hideSubmitCancelBtns: true,
      showNotification: false,
      userMessage: '',
      isEditing: false,
      showDetails: false,
    };
  };

  var props = [ 'isEditingOrg', 'showUserDetails', 'expandUserDetails' ];

  var validations = function() {
      return {
        localData: {
          userId: {},
          orgId: {},
          firstName: {},
          lastName: {},
          title: {},
          eMail: {},
          phone: {},
          cell: {},
          roleIds: {},
          claims: {},
          userName: { required: requiredIf(function() {
            return this.newUser;
          }), isUnique },
          password: { required: requiredIf(function() {
            return this.newUser;
          }) },
          repeatPassword: { required: requiredIf(function() {
            return this.newUser;
          }),
          },
        }
      };
  };

  var beforeMount = function() {
    this.uniqueName = true;
    // this.$refs.vms.focus()
  };

  var mounted = function() {
    this.clearInputs();
    this.$v.$reset();
    this.getAllUserRoles();
    this.canSeeMe = this.userHasClaim('Users', 'read');
    this.isPgStaff = this.userHasRole('PGStaff');
    this.showDetails = this.expandUserDetails;
  };

  var methods = {
    ...mapActions('userModule', [
      'getAllUserRoles',
      'getAllClaims',
      'getUserByUserId',
      'createNewUser',
      'updateUser',
      'deleteUser',
      'checkUserNameAvailability']),

    addUser() {
      this.allowUserNameInput = true;
      this.selectedRoles = [];
      this.clearInputs();
      this.localData.orgId = this.selectedOrganization.orgId;
      this.localData.orgName = this.selectedOrganization.orgName;
      this.isNewUser = true;
      this.enableEditing(this.isNewUser);
    },

    editUser() {
      if (!this.userIsSelected || this.isEditingOrg) {
        this.chastiseUser();
        return;
      }
      this.isNewUser = false;
      this.enableEditing(this.isNewUser);
    },

    removeUser() {
      this.showConfirmModal = true;
    },

    updateInputValue(e) {
      this.localData[e.target.name] = e.target.value;
      this.$v.localData[e.target.name].$touch();
    },

    delayApiCall(e) {

      var name = e.srcElement.value;
      // console.log('name has changed', name)
      if (name == "") {
        this.localData.uniqueName = true;
        return;
      }
      clearTimeout(timeoutId);
      timeoutId = setTimeout(this.checkUserName, this.checkNameDelayMs, name);
    },

    checkUserName(name) {
      // console.log(name);
      this.checkUserNameAvailability(name)
        .then(response => {
          if(response.data.data.length === 1) {
            this.localData.uniqueName = false;
            this.$nextTick(() => this.$refs.userNameInput.select());
          } else {
            this.localData.uniqueName = true;
          }
          this.$v.localData.userName.$touch();
      });
    },
    comparePasswords(e) {
      if (this.$v.localData.password.$dirty && this.$v.localData.repeatPassword.$dirty) {
        if (this.$v.localData.password.$model != this.$v.localData.repeatPassword.$model) {
          this.passwordsDoNotMatch = true;
        } else {
          this.passwordsDoNotMatch = false;
        }
      }
    },

    rolesToggle(e) {
      // combine with claims toggle
      var checked = e.target.checked;
      var role = parseInt(e.target.id);
      this.$v.localData.roleIds.$touch();
    },

    enableEditing(addingUser) {
      var text = addingUser ? 'added' : 'edited';
      this.$emit('onEditingUser', true);
      this.disableAllOrgsInDropdown(true);
      this.hideSubmitCancelBtns = false;
      this.formDisabled = null;
      this.isEditing = true;
      this.userMessage= `User is being ${text}`;
      this.showNotification = true;
      this.showDetails = true;
      this.$nextTick(() => this.$refs.userNameInput.focus());
    },

    clearInputs() {
      this.showNotification = false;
      Object.keys(this.localData).forEach(key => {
        this.localData[key] = '';
      });
      this.selectedRoles = [];
      // this.selectedOrgId = this.selectedOrganization.orgId;
      // this.$refs.vms.$refs.search.blur();

    },

    disableEditing() {
      // this.$refs.userNameInput.focus();
      this.$emit('onEditingUser', false);
      this.isEditing = false;
      this.formDisabled = true;
      this.allowUserNameInput = false;
      this.isNewUser = false;
      this.hidePassword = true;
      this.passwordsDoNotMatch = false;
      this.disableAllOrgsInDropdown(false);
      this.hideSubmitCancelBtns = true;
      this.userMessage = '';
      this.showNotification = false;
    },
    chastiseUser() {
      if (!this.formDisabled) {
        return;
      }
      this.userMessage = '';
      if (this.isEditingOrg) {
        this.userMessage = 'Organization Details are currently being edited.'
          // if (this.isPgStaff) {
      } else if (this.userIsSelected) {
          this.userMessage += `To edit this user, please click the <i class="far fa-edit"></i> icon above.`
      } else {
          this.userMessage = `Please click the <i class="far fa-plus-square"></i> icon to add a new user.`;
      }
      this.showNotification = true;
  },

    setRoles(user) {
      this.selectedRoles = [];
      if (user.roleIds !== undefined) {
        this.selectedRoles = user.roleIds;
      }
    },

    getFormData() {
      if (this.isNewUser) {
        let userData = Object.assign({}, this.$v.localData.$model);
        delete userData.orgName;
        delete userData.roles;
        delete userData.repeatPassword;
        Object.assign(userData, { userId: 0 });
        return userData;
      }
      else {
        let userId = this.localData.userId;
        let userPatch = [];
        this.$v.localData.repeatPassword.$reset();
        Object.keys(this.$v.localData).forEach(element => {
          if (this.$v.localData[element].$dirty) {
            let dirtyInput = {
                op: 'replace',
                path: '/' + element,
                value: this.$v.localData[element].$model
              };
            userPatch.push(dirtyInput);
          }
        });
        userPatch.push({'userId' : userId });
        return userPatch;
      }
    },

    submit() {
      if (this.isNewUser) {
        this.$v.localData.userName.$touch();
      }
      // console.log(this.$v)
      this.$v.localData.password.$touch();
      if (this.passwordsDoNotMatch || this.$v.$invalid) {
        return;
      }
      let user = this.getFormData();

      if(this.isNewUser === true) {
        this.createNewUser(user)
        .then(response => {
          if (response.status === 200) {
            var text = response.data.data.userName + ' has been added';
            this.$snotify.success(text, 'Success', { showProgressBar: false });
            this.$emit('onSuccessfulUserUpdate', response.data.data);
          } else {
            var text = 'Unable to process your request at this time';
            this.$snotify.error(text, 'Failed');
          }
        });
      } else {
        this.updateUser(user)
        .then(response => {
           if (response.status === 200) {
            var text = response.data.data.userName + ' has been updated';
            this.$snotify.success(text, 'Success', { showProgressBar: false });
            this.$emit('onSuccessfulUserUpdate', response.data.data);
          } else {
            var text = 'Unable to process your request at this time';
            this.$snotify.error(text, 'Failed');
          }
        });
      }
      this.disableEditing();
      this.$v.$reset();
    },

    cancel() {
      this.clearInputs();
      this.disableEditing();
      this.$v.$reset();

      if (this.currentUserId) {
        this.getUserByUserId(this.currentUserId);
      }
    },
    toggleMe() {
      this.expanded = !this.expanded;
    },
    onUserConfirmation() {
      var userId = this.selectedUser.userId;
      var userName = this.selectedUser.userName;
      this.deleteUser(userId).then(response => {
        if (response.status === 204) {
          var text = userName + ' has been deleted';
          this.$snotify.success(text, 'Success', { showProgressBar: false });
          this.clearInputs();
          this.$emit('onSuccessfulUserUpdate', null);
        }
      });
    },
    closeModal() {
      this.showConfirmModal = false;
      this.$emit('onUserCanceledDelete', true);
    },
    disableAllOrgsInDropdown(setState) {
      this.organizations.forEach(org => { if (org.orgId === -1) { org.$isDisabled = setState; }});
    },

    onDropdownInput(value, id) {
      if (value === null) {
        return;
      }
      else if (id === 'orgId') {
          this.selectedOrgId = value.orgId;
          this.$v.localData.orgId.$model = value.orgId;
      }
    },
  };

  var computed = {
    ...mapState('userModule', ['roles', 'selectedUser', 'currentUser']),
    ...mapState("organizationModule", [ "selectedOrganization", 'organizations' ]),
    ...mapGetters('userModule', ['userHasClaim', 'userHasRole']),
    passwordType() {
      return this.hidePassword ? 'password' : 'text';
    },
    passwordIcon() {
      return this.hidePassword ? 'fa-eye' : 'fa-eye-slash';
    },
    disabled() {
      return this.formDisabled ? true : null;
    },
    newUser() {
      return this.isNewUser;
    },
    allowUserNameInput() {
      return this.isPgStaff || this.isNewUser;
    },
    selectedOrgDropdown: function() {
      return this.organizations.find(_ => _.orgId === this.selectedOrgId);
    },

  };

  var watch= {
    selectedOrganization: function(value) {
      this.clearInputs();
      this.currentUserId = null;
      this.userIsSelected = false;
      this.selectedOrgId = value.orgId;
    },

    selectedRoles: function(value) {
      this.localData.roleIds = value;
    },

    selectedUser: function(user) {
      this.clearInputs();
      if(user.userName === undefined) {
        this.userIsSelected = false;
      } else {
        this.userIsSelected = true;
      }
      var currentRoles = [];
      this.selectedRoles = [];

      if (user) {
        this.localData = Object.assign({}, user);
        if (user.claims) {
          this.localData.claims.sort(function(a, b) {
            if (a.claimType < b.claimType) return -1;
            if (a.claimType > b.claimType) return 1;
            return 0;
          });
        }
        this.localData.password = undefined;
        this.localData.repeatPassword = undefined;
        this.currentUserId = user.userId;
        this.selectedOrgId = user.orgId;
        this.setRoles(user);
        this.$v.$reset();
      }
      else {
        this.currentUserId = null;
        this.clearInputs();
        this.$v.$reset();
      }
    },
    isEditingOrg: function(value) {
      if (value && this.formDisabled) {
        this.userMessage = "Organization Details are currently being edited";
        this.showNotification = true;
        if (!this.expandUserDetails) {
          this.showDetails = false;
        }
      } else {
        this.userMessage = "";
        this.showNotification = false;
      }
    },
   };

  var components = {
    PgSelect,
    UserDeleteConfirmModal,
   };

  export default {
    name: 'UserDetailsForm',
    data,
    props,
    validations,
    beforeMount,
    mounted,
    methods,
    computed,
    watch,
    components
  };

</script>

/*************************************************************/

<style scoped>

.pg-component-toolbar {
  display: flex;
  font-size: 12px;
  font-weight: 600;
  flex-direction: row;
  align-items: center;
}
.pg-component-toolbar + div {
  flex: 1;
}
.header-icons {
  display: flex;
}

.pg-component-toolbar-item {
  /* border: none; */
  align-items: center;
}

/* .appearEditable ul {
  background-color: white;
} */
.user-details-form {
  height: 100%;
  overflow: scroll;
}

.password-input {
  width: 100%;
  position: relative;
}

.eye-icon {
  position: absolute;
  right: 5px;
  top: 4px;
  font-size: 1.2em;
}

input[type=checkbox] {
  display: none;
}
input[type=checkbox] + label:before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

input[type=checkbox] + label:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  font-size: 1.33em;
  content: "\f0c8";
  padding-right: 3px;

}

input[type=checkbox]:checked + label:before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  font-size: 1.33em;
  content: "\f14a";
  padding-right: 3px;
}
/* .span-link {
  color: purple;
  text-decoration: underline;
  cursor: pointer;
  font-style: italic;
  font-size: 10px;
} */
/* .span-link.disabled {
  pointer-events: none;
} */

</style>
