<template>
  <div class="telemetry-billing-page" v-if="isPgStaff">
    <div class="page-content">
      <div class="main-content panel-container-horizontal">
        <div class="panel-left">
          <billing-overview-for-orgs-grid
            :billingOverview="billingOverview"
            :clearFinishedCheckboxes="clearFinishedCheckboxes"
            :dueOnly="dueOnly"
            :updatedOrgData="updatedOrgData"
            :updatedOrgBalance="updatedOrgBalance"
            @onNewOrgSelection="onNewOrgSelection"
            @onFinishedDateChange="onFinishedDateChange"
            @onToggleDueOnly="onToggleDueOnly"
            @onClearCheckboxes="onClearCheckboxes"
            @onGenerateAdHocReport="onGenerateAdHocReport"
            @onGenerateCommissionsReport="onGenerateCommissionsReport"
          ></billing-overview-for-orgs-grid>
          <!-- <div class="resizer handle v-handle v-handle-left">
            <i class="fal fa-caret-left" v-bind:class="{'fa-rotate-180':leftPanelCollapsed}" v-on:click="togglePanel('.left', $event)"></i>
          </div> -->
        </div>
        <div class="panel-middle">
          <billing-activity-grid
            ref="activitygrid"
            :billingActivity="billingActivity"
            :newBillingAdjustmentId="newBillingAdjustmentId"
            @onPdfFileRequest="onPdfFileRequest"
            @onActionSelection="onActionSelection"
            @onAddingNewAction="onAddingNewAction"
            @onSaveNewAction="onSaveNewAction"
          ></billing-activity-grid>
          <!-- <div class="resizer handle v-handle v-handle-right">
            <i class="fal fa-caret-right" v-bind:class="{'fa-rotate-180':rightPanelCollapsed}" v-on:click="togglePanel('.right', $event)"></i>
          </div> -->
        </div>
        <div class="panel-right">
          <billing-notes-for-org
            :class="{'collapsed':rightPanelCollapsed}"
            :selectedOrg="selectedOrg"
            :mostRecentBillingActivity="mostRecentBillingActivity"
            :selectedAction="selectedAction"
            @onUpdatingInternalComment="onUpdatingInternalComment"
            @onNeedingNewBillingId="onNeedingNewBillingId"
            @onUpdatedBillingNoteForOrg="onUpdatedBillingNoteForOrg"
            @onUpdatedBillingDetailsForOrg="onUpdatedBillingDetailsForOrg"
          ></billing-notes-for-org>
        </div>
      </div>
      <billing-uncheck-all-confirm-modal v-show="showConfirmModal" @close="closeModal" @confirmed="onUserConfirmation" />
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapState, mapActions } from 'vuex';
  import * as pgHelpers from '@/utils/PgHelpers.js';
  import BillingOverviewForOrgsGrid from '@/components/BillingOverviewForOrgsGrid';
  import BillingActivityGrid from '@/components/BillingActivityGrid';
  import BillingNotesForOrg from '@/components/BillingNotesForOrg';
  import BillingUncheckAllConfirmModal from '@/components/BillingUncheckAllConfirmModal';
  var thisComponent;
  var myDateFormat = 'MM/DD/YY';

  export default {
    name: 'TelemetryBillingPage',
    data() {
      return {
        user: {},
        dueOnly: 1,
        billingOverview: [],
        billingActivity: [],
        selectedOrg: {},
        updatedOrgData: {},
        updatedOrgBalance: {},
        selectedAction: {},
        mostRecentBillingActivity: {},
        users: '',
        clearFinishedCheckboxes: false,
        showConfirmModal: false,
        prevQtrStartDate: null,
        prevQtrEndDate: null,
        // currentQtrStartDate: null,
        // currentEndDate: null,
        pendingPaymentNote: '',
        newBillingAdjustmentId: 0,
        leftPanelCollapsed: false,
        rightPanelCollapsed: false,
      };
    },
    mounted() {
      thisComponent = this;
      this.dueOnly = 1;
      // this.dueOnlyBtnText = 'Show All';
      this.showConfirmModal = false;
      this.getPageData();
      this.getDates();
      $('.panel-left').panelresizable({
        handleSelector: '.v-handle-left',
        resizeWidth: true,
        resizeHeight: false,
        resizeWidthFrom: 'right',
        onDragEnd: function (evt) {
          thisComponent.onResizeEnd('.panel-middle', evt);
        },
      });
      $('.panel-middle').panelresizable({
        handleSelector: '.v-handle-middle',
        resizeWidth: true,
        resizeHeight: false,
        resizeWidthFrom: 'right',
        onDragEnd: function (evt) {
          thisComponent.onResizeEnd('.panel-right', evt);
        },
      });

      $('.jq-resizable').resizable({
        handles: 's',
      });
    },
    computed: {
      ...mapGetters('userModule', ['userHasRole']),
      isPgStaff: function () {
        return this.userHasRole('PGStaff');
      },
    },
    methods: {
      onClearCheckboxes() {
        this.confirmClear();
      },

      confirmClear() {
        this.showConfirmModal = true;
      },

      closeModal() {
        this.showConfirmModal = false;
      },
      onUserConfirmation() {
        this.clearAllCheckboxes();
      },
      clearAllCheckboxes() {
        var data = {
          orgId: -1,
          billingGroupId: 0,
          billingFinishedDate: null,
        };
        this.updateFinishedBillingDate(data)
          .then(response => {
            if (response.status === 200) {
              this.getPageData();
            }
          })
          .catch(error => {
            alert('bad request');
          });
      },
      togglePanel(selector, evt) {
        if (selector === '.left') {
          this.leftPanelCollapsed = !this.leftPanelCollapsed;
          // remove width set by resizer
          $('.left').css('width', '');
        } else if (selector === '.right') {
          this.rightPanelCollapsed = !this.rightPanelCollapsed;
          // var newHeight = this.bottomPanelCollapsed ? '100%' : '50%';
          // $('.panel-top').css('height', newHeight);
          $('.right').css('width', '');
        }
      },
      onResizeEnd(collapsePanel, event) {
        console.log('resize', collapsePanel);
        if (collapsePanel === '.panel-middle') {
          var width = $(collapsePanel).width() - $('.v-handle').width();
          this.leftPanelCollapsed = width <= 3;
        } else if (collapsePanel === '.panel-right') {
          var width = $(collapsePanel).width() - $('.v-handle').width();
          this.rightPanelCollapsed = width <= 3;
        }
      },
      onGenerateCommissionsReport() {
        var endDate = this.prevQtrEndDate;
        this.getCartheCommissionsReport(endDate).then(response => {
          if (response.status === 200) {
            this.$router.push({ path: '/pg/billing-commissions', params: {} });
          }
        });
      },
      onGenerateAdHocReport() {
        // this.$router.push({ path: '/pg/ad-hoc-report' });
        let route = this.$router.resolve({ path: '/pg/ad-hoc-report' });
        window.open(route.href, '_blank');
        var start = this.currentQtrStartDate;
        var end = this.currentEndDate;
        this.setSelectedOrganization(this.selectedOrg);
      },

      onNewOrgSelection(data) {
        var sc = {
          orgId: data.orgId,
          billingGroupId: data.billingGroupId,
        };
        this.selectedOrg = data;
        this.getBillingActivityForOrg(sc).then(response => {
          if (response.length > 0) {
            var balance = 0;
            this.billingActivity = response;
            for (var x = 0; x < response.length; x++) {
              if (this.billingActivity[x].rowNumber === 1) {
                this.billingActivity[x].balance = this.billingActivity[x].amount;
                balance = this.billingActivity[x].amount;
              } else {
                balance += this.billingActivity[x].amount;
                this.billingActivity[x].balance = balance;
              }
            }
          }
        });
      },

      onActionSelection(data) {
        this.selectedAction = data;
      },

      onAddingNewAction(data) {
        data.id = 0;
        data.whoEntered = this.$pgGlobal.currentUser.firstName;
        this.selectedAction = Object.assign({}, data);
      },

      onNeedingNewBillingId(note) {
        this.pendingPaymentNote = note;
        this.$refs.activitygrid.saveActionToDatabase();
      },

      onUpdatingInternalComment(obj) {
        var payload = {
          id: obj.id,
          orgId: this.selectedOrg.orgId,
          description: obj.description,
          internalComments: obj.internalComments,
          qbInvoiceNumbers: obj.qbInvoiceNumbers,
          whoEntered: this.$pgGlobal.currentUser.firstName,
        };
        this.updateCommentsForBillingTransaction(payload).then(response => {
          var sc = {
            id: response.id,
            description: payload.description,
          };
          this.getBillingActivityByBillingId(sc).then(response => {
            this.selectedAction = response;

            for (var index = 0; index < this.billingActivity.length; index++) {
              if (this.billingActivity[index].id == response.id) {
                this.billingActivity.splice(index, 1, response);
              }
            }
          });
        });
      },

      onUpdatedBillingNoteForOrg(note) {
        var payload = {
          orgId: this.selectedOrg.orgId,
          billingNotes: note,
        };
        this.updateBillingNotesForOrg(payload).then(response => {
          var orgId = response.orgId;
          this.selectedOrg.billingNotes = response.billingNotes;
          var len = this.billingOverview.length;
          for (var i = 0; i < len; i++) {
            if (this.billingOverview[i].orgId === orgId) {
              this.billingOverview[i].billingNotes = this.selectedOrg.billingNotes;
            }
          }
        });
      },

      onUpdatedBillingDetailsForOrg(payload) {
        this.updateBillingDetailsForOrg(payload).then(response => {
          var updatedOrg = Object.assign(this.selectedOrg, response);
          this.selectedOrg = updatedOrg;
          this.updatedOrgData = updatedOrg;
        });
      },

      onPdfFileRequest(pdfObj) {
        var payload = { filepath: pdfObj.folder + pdfObj.file };
        this.getBillingPdfStatement(payload).then(response => {
          this.displayPdf(response, pdfObj.file);
        });
      },

      // https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
      displayPdf(data, fileName) {
        var decodedData = window.atob(data);

        const byteNumbers = new Array(decodedData.length);
        for (let i = 0; i < decodedData.length; i++) {
          byteNumbers[i] = decodedData.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        var file = new Blob([byteArray], { type: 'application/pdf' });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(file, fileName);
          return;
        }
        var fileURL = window.URL.createObjectURL(file);
        window.open(fileURL);

        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(fileURL);
        }, 100);
      },

      onSaveNewAction(data) {
        var newId = 0;
        var obj = {
          billingAdjustmentId: 0,
          orgId: this.selectedOrg.orgId,
          billingGroupId: this.selectedOrg.billingGroupId,
          amount: data.amount,
          description: data.description,
          internalComments: this.pendingPaymentNote,
          asOfDate: data.asOfDate,
          whoEntered: this.$pgGlobal.currentUser.firstName,
        };
        this.createBillingAdjustment(obj).then(response => {
          newId = response.billingAdjustmentId;
          var sc = {
            orgId: obj.orgId,
            billingGroupId: obj.billingGroupId,
          };
          this.getBillingActivityForOrg(sc).then(response => {
            // this.billingActivity = response;
            this.newBillingAdjustmentId = newId;
            if (response.length > 0) {
              var balance = 0;
              this.billingActivity = response;
              for (var x = 0; x < response.length; x++) {
                if (this.billingActivity[x].rowNumber === 1) {
                  this.billingActivity[x].balance = this.billingActivity[x].amount;
                  balance = this.billingActivity[x].amount;
                } else {
                  balance += this.billingActivity[x].amount;
                  this.billingActivity[x].balance = balance;
                }
              }
            }
          });
          this.getBillingOverviewForOrgs(sc).then(response => {
            this.updatedOrgBalance = response;
          });
        });
      },
      onFinishedDateChange(data) {
        this.updateFinishedBillingDate(data)
          .then(response => {
            if (response.status == 200) {
              // all's good
            }
          })
          .catch(error => {
            alert('bad request');
          });
      },
      onToggleDueOnly() {
        if (this.dueOnly === 1) {
          this.dueOnly = 0;
        } else {
          this.dueOnly = 1;
        }
        this.getPageData();
      },
      getPageData() {
        this.getBillingOverviewForOrgs({ dueOnly: this.dueOnly }).then(response => {
          this.billingOverview = response;
          this.billingOverview.forEach(org => {
            var userStr = this.convertUsersToString(org);
            org.userStr = userStr;
          });
          this.selectedOrg = response[0];
          var sc = {
            orgId: this.billingOverview[0].orgId,
            billingGroupId: this.billingOverview[0].billingGroupId,
          };
          this.getBillingActivityForOrg(sc).then(response => {
            if (response.length > 0) {
              var balance = 0;
              this.billingActivity = response;
              for (var x = 0; x < response.length; x++) {
                if (this.billingActivity[x].rowNumber === 1) {
                  this.billingActivity[x].balance = this.billingActivity[x].amount;
                  balance = this.billingActivity[x].amount;
                } else {
                  balance += this.billingActivity[x].amount;
                  this.billingActivity[x].balance = balance;
                }
              }
            }
          });
          this.getUsersByOrgId(this.selectedOrg.orgId).then(response => {
            this.users = response.data.data;
          });
        });
      },

      getDates() {
        // var currentDate = new Date(2020, 11, 31, 23, 59); // 12/31/20 23:59 + 8 for utc = 1/1/21 7:59
        var currentDate = new Date();
        var prevQtr = pgHelpers.dateTimeHelper.previousQtrDates(currentDate);
        this.prevQtrStartDate = prevQtr.startDate;
        this.prevQtrEndDate = prevQtr.endDate;
      },
      convertUsersToString(org) {
        var userStr = '';
        var numUsers = org.users.length; // number of entries in users array
        var output = [];

        for (var index = 0; index < numUsers; index++) {
          if (numUsers === 0) {
            return '';
          }
          var name = org.users[index]['fullName'].trim();
          if (name != '') {
            output.push(name);
          }
        }
        var userStr = output.join(', ');
        return userStr;
      },

      ...mapActions('userModule', ['getUsersByOrgId']),
      ...mapActions('organizationModule', ['setSelectedOrganization']),
      ...mapActions('backOfficeModule', [
        'getBillingOverviewForOrgs',
        'getBillingActivityForOrg',
        'getBillingActivityByBillingId',
        'updateFinishedBillingDate',
        'createBillingAdjustment',
        'getBillingPdfStatement',
        'updateBillingNotesForOrg',
        'updateCommentsForBillingTransaction',
        'updateBillingDetailsForOrg',
        'getCartheCommissionsReport',
      ]),
    },
    components: {
      BillingOverviewForOrgsGrid,
      BillingActivityGrid,
      BillingNotesForOrg,
      BillingUncheckAllConfirmModal,
    },
  };
</script>

<style lang="scss">
  .telemetry-billing-page {
    .main-content {
      flex-direction: row;
      display: flex;
      align-items: stretch;
      height: 100%;
    }
    .content-header {
      padding: 10px 0;
      margin-bottom: 10px;
      background-color: #dddddd;
    }
    .panel-container-horizontal {
      width: 100%;
      overflow: visible; // map popup anywhere on screen
    }

    // .panel-middle,
    // .panel-right {
    //   padding-top: 0;
    //   min-width: 0;
    //   max-width: 50%;
    //   overflow: hidden;
    // }
    .panel-middle.collapsed .panel-right.collapsed {
      padding: 0;
    }
    .organization-billing-grid {
      // margin-right: 10px;
      // margin-left: 10px;
      // flex: 1 1 30%;
      overflow: hidden;
      height: 100%;
    }

    .panel-left {
      // display: flex;
      // flex-direction: column;
      flex: 0 0 35%;
      padding: 0 10px 0 0; // 16 with resizer
      width: 80%;
      max-width: 100%;
    }
    .organization-billing-grid-body {
      height: 97%;
    }
    // .billing-activity-grid,
    .panel-middle {
      position: relative;
      flex: 1 1 80%;
      // padding: 0 16px 0 0; // resizer
      height: 100%;
    }
    .panel-right {
      position: relative;
      flex: 1 1 80%;
      padding: 0;
      height: 100%;
    }
    .billing-activity-grid,
    .billing-activity-grid-body {
      height: 98%;
    }
    .billing-notes-for-org {
      // flex: 1 1 80%;
      height: 100%;
      // max-width: 30%;
    }
    .popup-window.billing-uncheck-all-modal {
      width: 30em;
      border-width: 1px;
      border-style: solid;
      position: absolute;
      top: 100px;
      left: 0;
      right: 0;
      margin: auto;
    }
    .billing-uncheck-all-modal .modal-body {
      margin: 0;
    }
    .billing-uncheck-all-modal .popup-header {
      margin: 0;
    }
    .billing-uncheck-all-modal .popup-content,
    .billing-uncheck-all-modal .popup-footer.popup-button-wrapper {
      border: none;
    }
    .billing-uncheck-all-modal .popup-content {
      margin: 10px 10px 10px 15px;
    }
    .ag-numeric-cell .ag-cell-wrapper {
      justify-content: flex-end;
    }
    // .v-handle {
    //   position: relative;
    // }
  }
</style>
