<template>
  <nav id="page-nav" class="page-nav">
    <i class="far fa-chevron-left close-menu" v-on:click="closeMenu();"></i>
    <div>
      <div class="pg-logo">
        <img src="/static/images/pgswirl.png" alt="Pacific Gyre logo" />
        <div class="pg-font company-name">Pacific Gyre</div>
      </div>
      <ul>
        <li v-show="canUserAccess('/dashboard')">
          <router-link to="/dashboard" exact title>
            <div class="icon-wrapper">
              <i class="far fa-map" />
              <!-- fa-eye fa-globe-africa -->
            </div>
            Dashboard
          </router-link>
        </li>
        <li v-show="canUserAccess('/data')">
          <router-link to="/data" title>
            <div class="icon-wrapper">
              <i class="far fa-database" />
            </div>
            Data
          </router-link>
        </li>
        <li v-show="canUserAccess('/devices')">
          <router-link to="/devices" title="View and edit your devices">
            <div class="icon-wrapper">
              <i class="far fa-oil-temp" />
            </div>
            Devices
          </router-link>
        </li>
        <li v-show="canUserAccess('/images')">
          <router-link to="/images" title>
            <div class="icon-wrapper">
              <i class="far fa-images" />
            </div>
            Images
          </router-link>
        </li>
        <li v-show="canUserAccess('/billing') && userHasRole('developer')">
          <!-- limit to devs for now, not even pgAdmin -->
          <router-link to="/billing" title>
            <div class="icon-wrapper">
              <i class="far fa-file-invoice-dollar" />
            </div>
            Billing
          </router-link>
        </li>
        <li v-show="canUserAccess('/utilities') && this.userHasRole('developer')">
          <!-- limit to devs for now, not even pgAdmin -->
          <router-link to="/utilities" title>
            <div class="icon-wrapper">
              <i class="far fa-calculator" />
            </div>
            Utilities
          </router-link>
        </li>
        <li v-show="canUserAccess('/resources')">
          <router-link to="/resources" title>
            <div class="icon-wrapper">
              <i class="far fa-books" />
            </div>
            Resources
          </router-link>
        </li>
        <li v-show="canUserAccess('/org')">
          <router-link to="/org" title>
            <div class="icon-wrapper">
              <i class="far fa-users" />
            </div>
            Organization
          </router-link>
        </li>
        <li>
          <router-link to="/privacy" title>
            <div class="icon-wrapper">
              <i class="far fa-shield-check" />
            </div>
            Privacy Policy
          </router-link>
        </li>
        <li class v-if="false">
          <router-link to="/m/devices" title="Sample mobile page">
            <div class="icon-wrapper">
              <i class="far fa-mobile" />
            </div>
            Mobile (test)
          </router-link>
        </li>
        <li class="mm-listitem" v-if="isPgStaff" title="Only visible to PG staff" id="main-nav-pg-only">
          <span>
            <span class="icon-wrapper ">
              <i class="far fa-user-secret" />
            </span>
            Pacific Gyre Only
          </span>
          <!-- <div id="mm-1" class="mm-panel"> -->
          <ul class="mm-listview">
            <li v-if="false" class="mm-listitem">
              <router-link to="/pg/dev-notes">
                <div class="icon-wrapper">
                  <i class="far fa-edit" />
                </div>
                Developer Notes
              </router-link>
            </li>
            <li class="mm-listitem">
              <router-link to="/pg/contacts" title="Leads generated by the website">
                <div class="icon-wrapper">
                  <i class="far fa-address-book" />
                </div>
                Contacts
              </router-link>
            </li>
            <li class="mm-listitem">
              <router-link to="/pg/add-devices" title>
                <div class="icon-wrapper">
                  <i class="far fa-plus-circle" />
                </div>
                Bulk Add Devices
              </router-link>
            </li>
            <li class="mm-listitem">
              <router-link to="/pg/all-organizations" title>
                <div class="icon-wrapper">
                  <i class="far fa-building" />
                </div>
                All Organizations
              </router-link>
            </li>
            <li class="todo mm-listitem">
              <router-link to="/pg/sensors" title="Add/Edit Sensors">
                <div class="icon-wrapper">
                  <i class="far fa-code-merge" />
                </div>
                Sensors
              </router-link>
            </li>
            <li class="todo mm-listitem">
              <router-link to="/pg/pg-billing" title="Internal Billing">
                <div class="icon-wrapper">
                  <i class="far fa-code-merge" />
                </div>
                Reconcile Charges
              </router-link>
            </li>
            <li class="todo mm-listitem">
              <router-link to="/pg/telemetry-billing" title="Telemetry Billing">
                <div class="icon-wrapper">
                  <i class="far fa-file-invoice-dollar" />
                </div>
                Telemetry Billing
              </router-link>
            </li>
            <li class="mm-listitem">
              <router-link to="/pg/dev-tools" title>
                <div class="icon-wrapper">
                  <i class="far fa-bug" />
                </div>
                Developer Tools
              </router-link>
            </li>
          </ul>
          <!-- </div> -->
        </li>
      </ul>
    </div>
  </nav>
</template>

/* -------------------------------------------*/
<script>
  // https://mmenu.frebsite.nl/
  import { mapGetters } from 'vuex';

  var thisComponent;
  var props = ['currentUser'];

  var computed = {
    ...mapGetters('userModule', ['userHasRole']),
    isPgStaff: function() {
      return this.userHasRole('PGStaff');
    },
  };

  var beforeMount = function() {
    thisComponent = this;
    this.$router.setNavAccess();
  };

  var methods = {
    canUserAccess(path) {
      var thisPath = this.$router.options.routes.find(r => r.path === path);
      // console.warn('canUserAccess() ', thisPath);
      if (thisPath) {
        return thisPath.meta.accessGranted;
      }
      return false;
    },
    closeMenu() {
      if (this.mmenuApi) {
        this.mmenuApi.close();
      } else {
        console.warn('Menu API not set');
      }
    },
  };

  var mounted = function() {
    var pgMenu = $('#page-nav').mmenu(
      {
        // sidebar: {
        //   collapsed: "(min-width: 550px)",
        //   expanded: "(min-width: 700px)",
        // },
        slidingSubmenus: false,
        extensions: [
          'position-front',
          //"pagedim-black",
          //"all": ["shadow-page"],
          { '(max-width: 480px)': ['theme-dark', 'fullscreen'] },
        ],
        onClick: { close: true },
        navbar: {
          add: false,
        },
        navbars: [
          {
            position: 'bottom',
            content: ['<span>Last build: ' + this.pg_build_date + '</span>'],
          },
        ],
      },
      {
        // configuration
        offCanvas: {
          pageSelector: '#app',
        },
      },
    );
    this.mmenuApi = pgMenu.data('mmenu');
  };

  export default {
    name: 'PageNav',
    data() {
      return {
        // PG_BUILD_DATE set in vue.config.js
        pg_build_date: this.moment(process.env.VUE_APP_BUILD_DATE).format('YYYY-MM-DD HH:mm'),
        pg_app_version: process.env.VUE_APP_VERSION, // see package.json, vue.config.js
        mmenu: null,
        mmenuApi: null,
      };
    },
    beforeMount,
    mounted,
    computed,
    methods,
    props,
  };

  // $(document).ready(function() {
  // });
</script>

/* --------------------------------------------- */

<style>
  nav .icon-wrapper {
    display: inline-block;
    margin-right: 12px;
    width: 12px;
    text-align: center;
  }
  .nav li {
    font-size: 13px;
  }
  .mm-menu .pg-logo {
    display: unset;
    text-align: center;
    width: 200px;
  }
  .pg-logo img {
    width: 100%;
  }
  .pg-logo .company-name {
    font-family: 'Bank Gothic'; /* 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
    font-size: 22px;
    margin-top: -8px;
    text-shadow: 2px 2px grey;
  }
  .close-menu {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 2em;
  }
</style>
