<template>
  <div class="dashboard-page pg-page">
    <div class="page-content">
      <div class="panel-container-vertical">
        <div class="panel-top">
          <div class="panel-container-horizontal">
            <div class="panel-left">
              <device-map></device-map>

              <div class="resizer handle v-handle"></div>
            </div>
            <div class="panel-right" v-bind:class="{'collapsed':rightPanelCollapsed}">
              <dashboard-notifications
                class="notifications-container"
                :orgDevices="orgDevices"
                :orgActivations="orgActivations"
              ></dashboard-notifications>
            </div>
          </div>
          <!-- End of horizontal group  -->
          <div class="resizer handle h-handle">
            <i class="fal fa-caret-down" v-bind:class="{'fa-rotate-180':bottomPanelCollapsed}" v-on:click="togglePanel('.panel-bottom', $event)"></i>
          </div>
        </div>
        <!-- End of panel top -->

        <div class="panel-bottom">
          <div class="panel-contents">
            <div class="device-list-container grid-container">
              <device-list-grid
                :isEditing="isEditing"
                :orgDevices="orgDevices"
                :noShowColumns="noShowColumns"
                :selectActivatedDevices="selectActivatedDevices"
              />
            </div>
          </div>
          <!-- End of panel-contents -->
        </div>
        <!-- End of panel-bottom -->
      </div>
      <!-- End of panel-container-vertical -->
    </div>
    <!-- End of page content -->
  </div>
</template>

/*************************************************************/

<script>
  import Vue from 'vue';
  import { mapGetters, mapState, mapActions } from 'vuex';
  import ChartComponent from '@/components/ChartComponent';
  import DeviceListGrid from '@/components/DeviceListGrid';
  import DeviceMap from '@/components/DeviceMap';
  import DashboardNotifications from '@/components/DashboardNotifications';
  import { EventBus } from '@/EventBus';
  import environmentSpecific from 'static/environmentSpecific';

  // import ProfileService from "@/services/ProfileService";

  var dashboardPageComponent = null;

  export default {
    name: 'DashboardPage',
    data() {
      return {
        isEditing: false,
        noShowColumns: [],

        // device grid
        selectActivatedDevices: true,
        orgDevices: [],
        // dashboard notifications
        orgActivations: [],

        // resizer
        rightPanelCollapsed: false,
        bottomPanelCollapsed: false,
      };
    },

    methods: {
      ...mapActions('deviceModule', ['getDevices', 'getDeviceActivations', 'setSelectedDeviceIds']),

      togglePanel(selector, evt) {
        if (selector === '.panel-right') {
          this.rightPanelCollapsed = !this.rightPanelCollapsed;
          $('.panel-left').css('width', '');
        } else if (selector === '.panel-bottom') {
          this.bottomPanelCollapsed = !this.bottomPanelCollapsed;
          var newHeight = this.bottomPanelCollapsed ? '100%' : '60%';
          $('.panel-top').css('height', newHeight);
        }
      },
      onResizeEnd(collapsePanel, event) {
        if (collapsePanel === '.panel-right') {
          // console.log($(collapsePanel).width())
          var width = $(collapsePanel).width() - $('.v-handle').width();
          this.rightPanelCollapsed = width <= 3;
        } else if (collapsePanel === '.panel-bottom') {
          var height = $(collapsePanel).height();
          this.bottomPanelCollapsed = height <= 3;
        }
      },
      getDataForOrg() {
        var orgId = this.selectedOrganization.orgId;
        var params = {
          orgId: this.selectedOrganization.orgId,
          getOnlyActive: true,
          getCommands: false,
          getBillingPlans: false,
          getSensorPackages: false,
        };
        if (orgId === -1) {
          params.orgId = null;
          params.takeRecords = environmentSpecific.maxNumberDevices;
        }
        this.getDevices(params).then(response => {
          this.orgDevices = response;
        });
        var sc;
        if (orgId === -1) {
          var startDate = this.moment()
            .subtract(environmentSpecific.maxNumberHistoryDays, 'days')
            .format();
          sc = {
            startDateTime: startDate,
            orgId: orgId,
            currentStatusOnly: true,
          };
        } else {
          sc = { orgId: orgId, currentStatusOnly: true };
        }
        this.getDeviceActivations(sc).then(response => {
          this.orgActivations = response;
        });
      },
    },

    created() {
      dashboardPageComponent = this;
    },

    mounted() {
      if (this.isPgStaff && this.selectedOrganization.orgId === -1) {
        this.noShowColumns = ['destinations'];
      } else {
        this.noShowColumns = ['orgName', 'destinations'];
      }
      this.getDataForOrg();
      $('.panel-left').panelresizable({
        handleSelector: '.v-handle',
        resizeWidth: true,
        resizeHeight: false,
        resizeWidthFrom: 'right',
        onDragEnd: function(evt) {
          dashboardPageComponent.onResizeEnd('.panel-right', evt);
        },
      });
      $('.panel-top').panelresizable({
        handleSelector: '.h-handle',
        resizeWidth: false,
        resizeHeight: true,
        resizeHeightFrom: 'bottom',
        onDragEnd: function(evt) {
          dashboardPageComponent.onResizeEnd('.panel-bottom', evt);
        },
      });
    },

    computed: {
      isPgStaff: function() {
        return this.userHasRole('PGStaff');
      },
      highlightedDeviceName: function() {
        let deviceName = '...';
        if (this.highlightedDeviceId) {
          deviceName = this.highlightedDevice.deviceName;
        }
        return deviceName;
      },

      ...mapGetters('userModule', ['userHasRole']),
      ...mapGetters('deviceModule', ['highlightedDevice']),
      ...mapState('organizationModule', ['selectedOrganization']),
      ...mapState('deviceModule', ['highlightedDeviceId']),
    },

    watch: {
      selectedOrganization: function(value) {
        if (value.orgId === -1) {
          this.noShowColumns = ['destinations'];
        } else {
          this.noShowColumns = ['orgName', 'destinations'];
        }
        this.setSelectedDeviceIds([]);
        this.getDataForOrg();
      },
    },

    components: {
      ChartComponent,
      DeviceListGrid,
      DeviceMap,
      DashboardNotifications,
    },
  };
</script>

/*************************************************************/

<style lang="scss">
  .dashboard-page {
    .page-content {
      width: 100%;
    }
    .panel-contents {
      display: flex;
      flex-direction: row;
      flex: 1 1 auto;
    }
    .panel-contents > div {
      padding: 10px 0 10px 10px;
    }
    .panel-top {
      height: 60vh;
      padding: 0;
    }
    .panel-container-horizontal {
      width: 100%;
      overflow: visible; // map popup anywhere on screen
    }
    .panel-top {
      padding-bottom: 15px; // resizer
    }
    .panel-bottom {
      display: flex;
      padding: 0;
      overflow: hidden;
    }
    .panel-left {
      display: flex;
      flex-direction: column;
      flex: 0 0 auto;
      padding: 0 16px 0 0; // resizer
      width: 80%;
      max-width: 100%;
    }
    .panel-right {
      padding-top: 0;
      min-width: 0;
      max-width: 100%;
      overflow: hidden;
    }
    .panel-right.collapsed {
      padding: 0;
    }
    .panel-contents .device-list-container {
      display: flex;
      flex: 1 1 auto;
      padding: 0;
    }
    #bottom div,
    .device-list-grid {
      height: 100%;
      width: 100%;
    }
    #bottom div,
    .device-list-grid-body {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
</style>
