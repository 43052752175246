import Vue from 'vue';
import Vuex from 'vuex';
//import axios from 'axios';

import deviceModule from './DeviceStore';
import organizationModule from './OrganizationStore';
import backOfficeModule from './BackOfficeStore';
import miscItemsModule from './MiscItemsStore';
import commandModule from './CommandStore';
import sensorModule from './SensorStore';
import userModule from './UserStore';
import devModule from './DevStore';
import imageModule from './ImageStore';

Vue.use(Vuex);

const state = {
};

const modules = {
  deviceModule,
  organizationModule,
  backOfficeModule,
  miscItemsModule,
  sensorModule,
  commandModule,
  userModule,
  devModule,
  imageModule,
};

const mutations = {};

/* actions contain business logic, can commit multiple mutations           */
/* asynchronous.  use commit to alter state, dispatch to call functions    */
const actions = {};

/* "computed properties for stores" - calculate data based on store state  */
const getters = {};

const plugins = [
  //createPersistedState(),
];

export default new Vuex.Store({
  //namespaced: true,
  state: state,
  modules: modules,
  mutations: mutations,
  actions: actions,
  getters: getters,
  plugins: plugins,
});
