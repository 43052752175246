<template>
  <div class="bulk-add-devices-page page">
    <bulk-add-devices-form></bulk-add-devices-form>
  </div>
</template>

<script>
  import { mapGetters, mapState, mapActions } from 'vuex';
  import BulkAddDevicesForm from '@/components/BulkAddDevicesForm';
  import DeviceDetailsForm from '@/components/DeviceDetailsForm';

  export default {
    name: 'BulkAddDevicesPage',
    data() {
      return {
        msg: '',
      };
    },
    components: {
      BulkAddDevicesForm,
      DeviceDetailsForm,
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped></style>
<style lang="scss">
.bulk-add-devices-page {
 .bulk-add-devices-wrapper {
    display: flex;
    flex-direction: row;
    height: 100%;
  }

  .panel-left,
  .panel-right {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .panel-left {
    width: 40%;
  }

  .first,
  .second {
    padding: 5px;
    margin-top: 10px;
    height: 100%;
  }

  .grid-holder {
    height: 90%;
  }
  .panel-left .second {
    width: 50%;
  }
  .panel-right .first {
    width: 50%;
  }

  .panel-right .second {
    width: 50%;
  }

  .second .button-wrapper {
    float: right;
    margin-top: 20px;
  }

  .button-wrapper button {
    margin-right: 15px;
  }

  .pg-form-wrapper {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  form {
    width: inherit;
  }

  #deviceDescription {
    /* max-width: 275px;*/
  }
  /* .pg-validation-warning {
    font-style: italic;
    text-align: center;
  } */

  #imeiTextArea {
    /* width: 100%;*/
    min-width: 250px;
  }
  .block {
    display: block;
  }

  .needsAttn {
    border: red 1px solid;
  }

  .billing-group-popup {
    margin: 35px auto 0;
    width: 700px;
  }

  .billing-group-popup .popup-content {
    overflow: auto;
    height: 600px;
  }
  .billing-group-confirm-popup.popup-window {
    margin: 80px auto 0;
    width: 300px;
  }
  .billing-group-confirm-popup .popup-content {
    height: 250px;
  }
  .button.disabled {
    cursor: none;
  }
}
</style>
