<template>
  <div class="device-details-form pg-component pg-sidebar">
    <div class="pg-component-toolbar component-title">
      <h4>Device Details</h4>
      <div class="pg-component-toolbar-center"></div>
      <div class="pg-component-toolbar-right">
        <div class="header-icons" v-if="hideSubmitCancelBtns">
          <span class="pg-component-toolbar-item" v-if="userHasClaim('Devices', 'create')">
            <span @click="addDevice" title="Add device">
              <i class="far fa-plus-square fa-lg add-icon"></i>
            </span>
          </span>
          <span class="pg-component-toolbar-item" v-if="userHasClaim('Devices', 'update')">
            <span @click="editDevice" title="Edit Device" class="far fa-edit fa-lg edit-icon"></span>
          </span>
        </div>
        <div class="header-icons" v-else>
          <span class="pg-component-toolbar-item">
            <span @click="submit" title="Submit" class="far fa-check-circle fa-lg"></span>
          </span>
          <span class="pg-component-toolbar-item">
            <span @click="cancel" title="Cancel" class="far fa-times-circle fa-lg"></span>
          </span>
        </div>
      </div>
    </div>
    <div class="pg-form-wrapper">
      <form @submit.prevent="submit">
        <fieldset class="section-contents" :disabled="formDisabled">
          <div class="pg-form vertical">
            <!-- DeviceName customer can edit -->
            <div class="form-group" :class="{ 'form-group--error': $v.localDevice.deviceName.$error }">
              <div class="input-area" @click="chastiseUser">
                <input
                  :class="{ disabled : formDisabled }"
                  ref="deviceNameInput"
                  id="deviceName"
                  name="deviceName"
                  type="text"
                  v-model.trim="localDevice.deviceName"
                  @input="updateInputValue"
                  @blur="onInputBlur"
                />
              </div>
              <label for="deviceName">Device Name<span v-if="isPgStaff">*</span></label>
            </div>

            <!-- Device Desc customer can edit -->
            <div class="form-group">
              <div class="input-area" @click="chastiseUser">
                <input
                  :class="{ disabled : formDisabled }"
                  id="deviceDescription"
                  name="deviceDescription"
                  type="text"
                  v-model.trim="localDevice.deviceDescription"
                  @input="updateInputValue"
                />
              </div>
              <label for="deviceDescription">Device Description</label>
            </div>

            <!-- Type -->
            <div class="form-group" :class="{ 'form-group--error': $v.localDevice.deviceTypeId.$error }">
              <div class="input-area" @click="chastiseUser">
                <span class="text-input-poser disabled" v-if="!isPgStaff" name="deviceTypeSpan"
                  >{{ selectedDeviceType != undefined ? selectedDeviceType.deviceTypeName : '' }}</span
                >
                <pg-select
                  v-else
                  id="deviceTypeId"
                  :options="deviceTypes"
                  :value="selectedDeviceType"
                  label="deviceTypeName"
                  track-by="deviceTypeId"
                  @input="onDropdownInput"
                  :disabled="!isPgStaff"
                >
                </pg-select>
              </div>
              <label for="deviceTypeId">Device Type*</label>
            </div>

            <!-- Service Type -->
            <div class="form-group" :class="{ 'form-group--error': $v.localDevice.serviceTypeCode.$error }">
              <div class="input-area" @click="chastiseUser">
                <span class="text-input-poser disabled" v-if="!isPgStaff" name="serviceTypeSpan"
                  >{{ selectedServiceType != undefined ? selectedServiceType.name : '' }}</span
                >
                <pg-select
                  v-else
                  id="serviceTypeCode"
                  :options="serviceTypes"
                  :value="selectedServiceType"
                  :title="description"
                  label="name"
                  track-by="serviceTypeCode"
                  @input="onDropdownInput"
                  @remove="onDropdownRemoveItem"
                  :disabled="!isPgStaff"
                >
                </pg-select>
              </div>
              <label for="serviceType">Service Type*</label>
            </div>

            <!-- Network -->
            <div class="form-group" :class='{ "form-group--error": $v.localDevice.networkId.$error }'>
              <div class="input-area" @click="chastiseUser">
                <span class="text-input-poser disabled" v-if="!isPgStaff" name="NetworkSpan"
                  >{{ selectedNetwork != undefined ? selectedNetwork.networkName : '' }}</span
                >
                <pg-select
                  v-else
                  id="networkId"
                  :options="networks"
                  :value="selectedNetwork"
                  label="networkName"
                  track-by="networkId"
                  @input="onDropdownInput"
                  :disabled="!isPgStaff"
                >
                </pg-select>
              </div>
              <label for="network">Network*</label>
            </div>

            <!-- Billing -->
            <div class="form-group" :class="{ 'form-group--error': $v.localDevice.defaultBillingPriceId.$error }">
              <div class="input-area" @click="chastiseUser">
                <span class="text-input-poser disabled" v-if="!isPgStaff" name="billingPlanSpan"
                  >{{ selectedBillingPlan != undefined ? selectedBillingPlan.pgPlanName : '' }}</span
                >
                <pg-select
                  v-else
                  id="billingPriceId"
                  :options="localBillingPlans"
                  :value="selectedBillingPlan"
                  label="pgPlanName"
                  track-by="billingPriceId"
                  @input="onDropdownInput"
                >
                </pg-select>
              </div>
              <label for="selectedBillingPlan">Default Billing*</label>
            </div>

            <!-- Billing Group -->
            <div class="form-group">
              <div class="input-area" @click="chastiseUser">
                <pg-select
                  id="billingGroupId"
                  :showLabels="false"
                  :options="billingGroups"
                  :value="selectedBillingGroup"
                  label="billingGroupName"
                  @input="onDropdownInput"
                >
                </pg-select>
              </div>
              <label for="billingGroups">Billing Group</label>
            </div>

            <!-- Org -->
            <div v-if="isPgStaff" class="form-group" :class='{ "form-group--error": $v.localDevice.orgId.$error }'>
              <div class="input-area" @click="chastiseUser">
                <pg-select
                  id="orgId"
                  :options="organizations"
                  :value="selectedOrgDropdown"
                  label="orgName"
                  track-by="orgId"
                  @input="onDropdownInput"
                  :disabled="!isPgStaff"
                >
                </pg-select>
              </div>
              <label for="organization">Organization*</label>
            </div>

            <!-- SensorPkg -->
            <div class="form-group" :class="{ 'form-group--error': $v.localDevice.sensorPackageIds.$error }">
              <div class="input-area" @click="chastiseUser">
                <span class="text-input-poser disabled" v-if="!isPgStaff">
                  <ul>
                    <li class="disabled" v-for="sensor in localDevice.sensorPackages" v-bind:key="sensor.sensorId">{{sensor.sensorName}}</li>
                  </ul>
                </span>
                <sensor-section
                  v-if="isPgStaff"
                  :sensorPackages="localDevice.sensorPackages"
                  @onUpdate="updatedSensorsReceived"
                  :formDisabled="formDisabled"
                ></sensor-section>
              </div>
              <label v-if="localDevice.sensorPackages !== undefined" for="sensorPackages"
                >{{ localDevice.sensorPackages.length > 1 ? 'Sensor Packages*' : 'Sensor Package*' }}</label
              >
              <label v-else for="sensorPackages">Sensor Package*</label>
            </div>

            <!-- Comm1 -->
            <div class="form-group" :class='{ "form-group--error": $v.localDevice.comm1.$error }'>
              <div class="input-area" @click="chastiseUser">
                <input
                  :class="{ disabled : formDisabled || !isPgStaff }"
                  id="comm1"
                  name="comm1"
                  type="text"
                  v-model.trim="localDevice.comm1"
                  @input="updateInputValue"
                  :disabled="!isPgStaff"
                />
              </div>
              <label for="comm1">Comm Id*</label>
            </div>

            <!-- Comm2 -->
            <div class="form-group">
              <div class="input-area" @click="chastiseUser">
                <input
                  :class="{ disabled : formDisabled || !isPgStaff }"
                  id="comm2"
                  name="comm2"
                  type="text"
                  v-model.trim="localDevice.comm2"
                  @input="updateInputValue"
                  :disabled="!isPgStaff"
                />
              </div>
              <label for="comm2">Comm2</label>
            </div>

            <!-- Comm3 -->
            <div class="form-group">
              <div class="input-area" @click="chastiseUser">
                <input
                  :class="{ disabled : formDisabled || !isPgStaff }"
                  id="comm3"
                  name="comm3"
                  type="text"
                  v-model.trim="localDevice.comm3"
                  @input="updateInputValue"
                  :disabled="!isPgStaff"
                />
              </div>
              <label for="comm3">Comm3</label>
            </div>

            <!-- SIM -->
            <div class="form-group">
              <div class="input-area" @click="chastiseUser">
                <input
                  :class="{ disabled : formDisabled || !isPgStaff }"
                  id="sim"
                  name="sim"
                  type="text"
                  v-model.trim="localDevice.sim"
                  @input="updateInputValue"
                  :disabled="!isPgStaff"
                />
              </div>
              <label for="sim">SIM</label>
            </div>

            <!-- WMO customer can edit -->
            <div class="form-group">
              <div class="input-area" @click="chastiseUser">
                <input
                  :class="{ disabled : formDisabled }"
                  id="wmoId"
                  name="wmoId"
                  type="text"
                  v-model.trim="localDevice.wmoId"
                  @input="updateInputValue"
                />
              </div>
              <label for="wmoId">WMO ID</label>
            </div>

            <!-- Include in GTS customer can edit -->
            <div class="form-group horizontal" :class="{'appearEditable' : isEditing}">
              <span class="first-column">Heading: IOBX06</span>
              <div class="horizontal" @click="chastiseUser">
                <input
                  :class="{ disabled : formDisabled }"
                  id="includeInGts"
                  name="includeInGts"
                  type="checkbox"
                  :value="localDevice.includeInGts"
                  :checked="localDevice.includeInGts"
                  @input="toggleGtsCheckbox"
                />
                <label class="checkbox-label" for="includeInGts">Include in GTS</label>
              </div>
              <div class="push-right-padded subtle">{{localDevice.deviceId}}</div>
            </div>

            <div class="form-group" v-if="isPgStaff">
              <span class="go-right">*Required </span>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
    <div class="notification" v-if="showNotification">
      <span v-html="userMessage"></span>
    </div>
    <unsaved-changes-modal v-show="showUnsavedChangesModal" @submit="closeModal" @close="closeModal" />
    <!-- <pre>Invalid: {{$v.$invalid}}   AnyDirty: {{$v.$anyDirty}} Dirty:  {{$v.$dirty}}</pre> -->
    <!-- <pre>{{$v.localDevice.$model}} </pre> -->
    <!-- <pre>{{$v.localDevice}} </pre> -->
  </div>
</template>

/*************************************************************/

<script>
  import { required } from 'vuelidate/lib/validators';
  import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
  import PgSelect from '@/components/Common/PgSelect';
  import SensorSection from '@/components/SensorSection';
  import UnsavedChangesModal from '@/components/UnsavedChangesModal';
  import { EventBus } from '@/EventBus';
  // import { closeSync } from 'fs';
  var thisDetailsForm;

  var data = function() {
    return {
      selectedDeviceTypeId: null,
      selectedServiceTypeCode: null,
      selectedOrgId: null,
      selectedNetworkId: null,
      selectedBillingPriceId: null,
      selectedBillingPlan: null,
      selectedBillingGroup: null,
      selectedBillingGroupId: null,
      localDevice: {},
      currentDeviceId: null,
      formDisabled: true,
      isNewDevice: null,
      description: '',
      isPgStaff: false,
      hideSubmitCancelBtns: true,
      showUnsavedChangesModal: false,
      localBillingPlans: [],
      showNotification: false,
      billingGroupPlaceholder: '',
    };
  };

  var props = ['isEditing', 'deviceDetails', 'billingGroups'];

  var validations = {
    localDevice: {
      deviceName: { required },
      deviceDescription: {},
      deviceTypeId: { required },
      serviceTypeCode: { required },
      orgId: { required },
      networkId: { required },
      defaultBillingPriceId: { required },
      billingGroupId: {},
      sensorPackageIds: {},
      sensorPackages: {},
      comm1: { required },
      comm2: {},
      comm3: {},
      sim: {},
      wmoId: {},
      includeInGts: {},
      active: {},
      deployed: {},
    },
  };

  var created = function() {
    thisDetailsForm = this;
    window.thisDetailsForm = this; ////////////// debug only
  };

  var beforeMount = function() {};

  var mounted = function() {
    this.setUpEvents();
    this.isPgStaff = this.userHasRole('PGStaff');
    this.getDeviceTypes();
    this.getNetworks();
    this.getServiceTypes();
    this.getAllBillingPlans();
  };

  var beforeDestroy = function(e) {
    if (this.isEditing && this.$v.localDevice.$anyDirty) {
      this.showUnsavedChangesModal = true;
    }
  };

  var methods = {
    ...mapActions('miscItemsModule', ['getDeviceTypes', 'getNetworks', 'getServiceTypes']),
    ...mapActions('deviceModule', ['getDevices', 'updateDevice', 'createDevice']),
    ...mapActions('backOfficeModule', ['getBillingPricesByBillingPriceId', 'getBillingPricesByServiceTypeAndNetworkId', 'getAllBillingPlans']),

    addDevice() {
      if (this.justOneSelected) {
        var temp = {};
        temp.deviceTypeId = this.localDevice.deviceTypeId;
        temp.serviceTypeCode = this.localDevice.serviceTypeCode;
        temp.orgId = this.localDevice.orgId;
        temp.networkId = this.localDevice.networkId;
        temp.sensorPackageIds = this.localDevice.sensorPackageIds;
        temp.sensorPackages = this.localDevice.sensorPackages;
        temp.billingPriceId = this.localDevice.defaultBillingPriceId;
      }
      this.clearInputs();
      this.$v.$reset();
      this.enableEditing();

      if (this.justOneSelected) {
        Object.keys(temp).forEach(key => {
          this.onDropdownInput(temp, key);
        });
        this.localDevice.sensorPackages = temp.sensorPackages;
        this.localDevice.sensorPackageIds = temp.sensorPackageIds;
        this.$v.localDevice.sensorPackageIds.$touch();
      } else {
        if (this.selectedOrganization.orgId !== -1) {
          this.selectedOrgId = this.selectedOrganization.orgId;
          this.$v.localDevice.orgId.$model = this.selectedOrganization.orgId;
        }
      }

      this.isNewDevice = true;
      this.$nextTick(() => this.$refs.deviceNameInput.focus());
    },

    editDevice() {
      console.log('edit', this.isNewDevice);
      if (this.selectedDeviceIds.length != 1) {
        if (this.selectedDeviceIds.length === 0) {
          this.chastiseUser('noneSelected');
        } else {
          this.chastiseUser('tooManySelected');
        }
        return;
      } else {
        this.showNotification = false;
        this.isNewDevice = false;
        this.enableEditing();
        this.$nextTick(() => this.$refs.deviceNameInput.focus());
      }
    },

    onDropdownInput(value, id) {
      if (value === null) {
        return;
      }
      if (id === 'deviceTypeId') {
        this.selectedDeviceTypeId = value.deviceTypeId;
        this.$v.localDevice.deviceTypeId.$model = value.deviceTypeId;
      } else if (id === 'serviceTypeCode') {
        this.selectedServiceTypeCode = value.serviceTypeCode;
        this.$v.localDevice.serviceTypeCode.$model = value.serviceTypeCode;
        this.getBillingPlansByServiceTypeAndNetwork();
      } else if (id === 'orgId') {
        this.selectedOrgId = value.orgId;
        this.$v.localDevice.orgId.$model = value.orgId;
      } else if (id === 'networkId') {
        this.selectedNetworkId = value.networkId;
        this.$v.localDevice.networkId.$model = value.networkId;
        // this.filterBillingPlans(id, value.networkId);
        this.getBillingPlansByServiceTypeAndNetwork();
      } else if (id === 'billingPriceId') {
        this.selectedBillingPriceId = value.billingPriceId;
        this.$v.localDevice.defaultBillingPriceId.$model = value.billingPriceId;
        this.selectedBillingPlan = this.localBillingPlans.find(p => p.billingPriceId === this.selectedBillingPriceId);
      } else if (id === 'billingGroupId') {
        if (value.billingGroupId != null) {
          this.selectedBillingGroup = value;
          this.$v.localDevice.billingGroupId.$model = value.billingGroupId;
        } else {
          this.$emit('onViewBillingGroups');
        }
      }
    },

    onDropdownRemoveItem(option, id) {},

    updatedSensorsReceived(value) {
      // this.localDevice.sensorPackageIds.length = 0;
      this.localDevice.sensorPackageIds = value;
      this.$v.localDevice.sensorPackageIds.$model = value;
    },

    updateInputValue(e) {
      this.localDevice[e.target.name] = e.target.value;
      this.$v.localDevice[e.target.name].$touch();
    },

    toggleGtsCheckbox(e) {
      if (e.target.checked) {
        this.localDevice.includeInGts = 1;
      } else {
        this.localDevice.includeInGts = 0;
      }
      this.$v.localDevice[e.target.name].$touch();
    },

    onInputBlur(e) {
      if (this.$v.localDevice[e.target.name].$model === '') {
        this.$v.localDevice[e.target.name].$touch();
      }
    },

    clearInputs() {
      this.showNotification = false;
      Object.keys(this.localDevice).forEach(key => {
        if (key === 'includeInGts') {
          this.localDevice[key] = false;
        } else if (key !== 'sensorPackageIds') {
          this.localDevice[key] = null;
        } else {
          this.localDevice[key] = [];
          this.localDevice.sensorPackages = [];
        }
      });
      this.setDropdownValues({});
      this.selectedBillingPlan = null;
    },

    getFormData() {
      if (this.isNewDevice) {
        var newDevice = {};
        Object.keys(this.$v.localDevice).forEach(element => {
          if (this.$v.localDevice[element].$dirty) {
            newDevice[element] = this.$v.localDevice[element].$model;
          }
        });
        Object.assign(newDevice, { deviceId: 0 });
        return newDevice;
      } else {
        let deviceId = this.currentDeviceId;
        let devicePatch = [];
        Object.keys(this.$v.localDevice).forEach(element => {
          if (this.$v.localDevice[element].$dirty) {
            let dirtyInput = {
              op: 'replace',
              path: '/' + element,
              value: this.$v.localDevice[element].$model,
            };
            devicePatch.push(dirtyInput);
          }
        });
        devicePatch.push({ deviceId: deviceId });
        return devicePatch;
      }
    },

    submit() {
      if (this.$v.$invalid) {
        console.log('errors???');
        return;
      }
      let device = this.getFormData();
      if (this.isNewDevice) {
        this.createDevice(device)
          .then(response => {
            if (response.status === 200) {
              var text = response.data.data.deviceName + ' has been added';
              this.showSuccessToast(text);
              this.$emit('onNewDeviceInfo', response.data.data);
            }
          })
          .catch(error => {
            var text = `Unable to process your request at this time. ${error}`;
            console.log(error);
            this.showFailedToast(text);
          });
      } else {
        this.updateDevice(device)
          .then(response => {
            if (response.status === 200) {
              var text = response.data.data.deviceName + ' has been updated';
              this.showSuccessToast(text);
              this.$emit('onNewDeviceInfo', response.data.data);
            }
          })
          .catch(error => {
            var text = `Unable to process your request at this time. ${error}`;
            this.showFailedToast(text);
          });
      }
      this.disableEditing();
    },

    showSuccessToast(text) {
      this.$snotify.success(text, 'Success', { showProgressBar: false });
      this.$v.$reset();
    },

    showFailedToast(text) {
      this.$snotify.error(text, 'Failed');
      // highlight error
    },

    cancel() {
      this.clearInputs();
      this.setDropdownValues({});
      this.disableEditing();
      this.$v.$reset();
      if (this.justOneSelected) {
        this.setFormData(this.deviceDetails);
      }
    },

    enableEditing() {
      this.$emit('onEditing', true);
      this.formDisabled = null;
      this.hideSubmitCancelBtns = false;
      this.disableAllOrgsInDropdown(true);
    },

    disableEditing() {
      this.$emit('onEditing', false);
      this.formDisabled = true;
      this.isNewDevice = null;
      this.hideSubmitCancelBtns = true;
      this.disableAllOrgsInDropdown(false);
    },

    chastiseUser(err) {
      this.showNotification = false;
      this.userMessage = '';
      if (!this.formDisabled) {
        return;
      }

      if (err === 'noneSelected') {
        this.userMessage = "Please select a device to edit and then click the <i class='far fa-edit'></i> icon.";
      } else if (err === 'tooManySelected') {
        this.userMessage = 'Please select a single device to edit before clicking the <i class="far fa-edit"></i> icon.';
      } else {
        if (this.isPgStaff) {
          this.userMessage = `Please click the <i class="far fa-plus-square"></i> icon to add a new device.`;
        }
        this.userMessage += `To edit a device, please select a device from the grid and then click the <i class="far fa-edit"></i> icon to edit a device.`;
      }
      this.showNotification = true;
    },

    setDropdownValues(device) {
      this.selectedDeviceTypeId = device.deviceTypeId;
      this.selectedServiceTypeCode = device.serviceTypeCode;
      this.selectedOrgId = device.orgId;
      this.selectedNetworkId = device.networkId;
      this.selectedBillingPriceId = device.defaultBillingPriceId;
      this.selectedBillingGroup = {
        billingGroupId: device.billingGroupId,
        billingGroupName: device.billingGroupName,
      };
    },

    setFormData(data) {
      this.localDevice = Object.assign({}, data);
      Object.assign(this.localDevice, { sensorPackageIds: [] });
      this.setDropdownValues(this.localDevice);
      if (this.localDevice.sensorPackages != undefined) {
        this.localDevice.sensorPackageIds = this.localDevice.sensorPackages.map(x => x.sensorId);
      }
      this.getBillingPlansThisDevice();
    },

    getBillingPlansByServiceTypeAndNetwork() {
      this.localBillingPlans = [];
      // get plans based on form values
      if (this.selectedServiceTypeCode && this.selectedNetworkId) {
        this.getBillingPricesByServiceTypeAndNetworkId({ serviceTypeCode: this.selectedServiceTypeCode, networkId: this.selectedNetworkId }).then(
          response => {
            this.localBillingPlans = response.data.data;
          },
        );
      }
    },

    getBillingPlansThisDevice: async function() {
      thisDetailsForm.localBillingPlans = [];
      thisDetailsForm.selectedBillingPlan = null;

      // get plans based on form values
      if (thisDetailsForm.localDevice && thisDetailsForm.localDevice.billingPlans) {
        thisDetailsForm.localBillingPlans = _.cloneDeep(this.localDevice.billingPlans);
        // add current default even if not valid option so it will show in list
        if (thisDetailsForm.localDevice.defaultBillingPriceId) {
          if (!thisDetailsForm.localBillingPlans.some(p => p.billingPriceId === thisDetailsForm.localDevice.defaultBillingPriceId)) {
            // await thisDetailsForm.getBillingPricesByBillingPriceId(thisDetailsForm.localDevice.defaultBillingPriceId).then(response=>{
            //   console.log('Having to go get the plan...');
            // var selectedButInvalidPlan = response.data.data[0];
            var selectedButInvalidPlan = this.billingPrices.find(p => p.billingPriceId === thisDetailsForm.localDevice.defaultBillingPriceId);
            thisDetailsForm.localBillingPlans = _.concat(selectedButInvalidPlan, thisDetailsForm.localDevice.billingPlans);
            // });
          }
        }
        thisDetailsForm.selectedBillingPriceId = thisDetailsForm.localDevice.defaultBillingPriceId;

        if (thisDetailsForm.selectedBillingPriceId) {
          thisDetailsForm.selectedBillingPlan = thisDetailsForm.localBillingPlans.find(
            p => p.billingPriceId === thisDetailsForm.selectedBillingPriceId,
          );
        }
      }
    },

    closeModal() {
      this.showUnsavedChangesModal = false;
    },
    disableAllOrgsInDropdown(setState) {
      this.organizations.forEach(org => {
        if (org.orgId === -1) {
          org.$isDisabled = setState;
        }
      });
    },

    setUpEvents() {
      // from TabsComponent
      EventBus.$on('NOTIFY_USER', message => {
        thisDetailsForm.userMessage = message;
        thisDetailsForm.showNotification = true;
      });
    },
  };

  var computed = {
    ...mapState('miscItemsModule', ['deviceTypes', 'networks', 'serviceTypes']),
    ...mapState('deviceModule', ['deviceId', 'device', 'selectedDeviceIds']),
    ...mapGetters('deviceModule', ['selectedDeviceIdsCount', 'deviceByDeviceId']),
    ...mapState('organizationModule', ['selectedOrganization', 'organizations']),
    ...mapGetters('userModule', ['userHasClaim', 'userHasRole']),
    ...mapState('backOfficeModule', ['billingPrices']),

    canSeeMe() {
      return this.userHasClaim('Devices', 'update');
    },
    justOneSelected: function() {
      return this.selectedDeviceIdsCount == 1;
    },
    selectedDeviceType: function() {
      return this.deviceTypes.find(_ => _.deviceTypeId === this.selectedDeviceTypeId);
    },
    selectedServiceType: function() {
      return this.serviceTypes.find(_ => _.serviceTypeCode === this.selectedServiceTypeCode);
    },
    selectedOrgDropdown: function() {
      return this.organizations.find(_ => _.orgId === this.selectedOrgId);
    },
    selectedNetwork: function() {
      return this.networks.find(_ => _.networkId === this.selectedNetworkId);
    },
  };

  var watch = {
    deviceDetails: function(data) {
      this.currentDeviceId = data.deviceId;
      this.setFormData(data);
    },
    billingGroups: function(groups) {
      // update dropdown values
    },
    selectedOrganization(value) {
      if (value !== undefined) {
        this.currentDeviceId = null;
        this.clearInputs();
        this.$v.$reset();
        this.setDropdownValues({});
      }
    },
  };

  var components = {
    PgSelect,
    SensorSection,
    UnsavedChangesModal,
  };

  export default {
    name: 'DeviceDetailsForm',
    data,
    props,
    validations,
    created,
    beforeMount,
    mounted,
    beforeDestroy,
    methods,
    computed,
    watch,
    components,
  };
</script>

/*************************************************************/
<style lang="scss">
  .sensors-page {
    .pg-form {
      .form-group textarea {
        width: 23em;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .push-right-padded {
    position: absolute;
    right: 38px;
  }
  .subtle {
    opacity: 0.5;
    font-style: italic;
  }
  .go-right {
    position: relative;
    left: 85%;
  }
  .pg-component-toolbar {
    align-items: center;
  }
  .pg-component-toolbar + div {
    flex: 1;
  }
  .header-icons {
    display: flex;
  }

  .pg-component-toolbar-item {
    /* border: none;*/
    align-items: center;
  }

  .sensor-section {
    width: 100%;
  }
  // .form-group input[type="text"],
  // .form-group .multiselect  {
  //   width: calc(100% - 22px); // room for (+)
  //   max-width: unset;
  //   flex: none;
  // }
  .input-area {
    display: flex;
    flex: 1;
  }
  /* .disabled {
  pointer-events: none;
}*/
</style>
