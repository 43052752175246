<template>
  <div class="support-page page">
    <div class="page-content">
      <div class="iframe-container">
        <iframe v-bind:src="supportPageUrl" width="100%" height="100%">
        </iframe>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SupportPage',
  data () {
    return {
      supportBaseUrl: 'https://www.pacificgyre.com/support/',
    };
  },
  computed: {
    supportPageUrl: function(){
      return this.supportBaseUrl + this.$route.params.pagename;
    },
  },
  methods: {  },
  beforeMount: function() {},
  mounted: function() { },
  components: { }
}
</script>

/************************************************/

<style lang="scss">

  .support-page {

    .iframe-container {
      flex: 1 1 auto;
    }

  }

</style>
