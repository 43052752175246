import axios from 'axios';
import environmentSpecific from 'static/environmentSpecific';

function parseJsonDate(jsonDateString) {
  return new Date(jsonDateString);
}
function fixAllDates(dataArray) {
}

let tblDataFields = {
  // since these are all in one table, harder to link to tblDataFields
  dataId: {dataFieldName: 'dataId', displayName: 'DataId', unitDisplay: '', display: true, floatValue: null },
  deviceId: {dataFieldName: 'deviceId', displayName: 'DeviceId', unitDisplay: '', display: true, floatValue: null },
  deviceDateTime: {dataFieldName: 'deviceDateTime', displayName: 'DeviceDateTime', unitDisplay: '', display: true, floatValue: null },
  enteredDateTime: {dataFieldName: 'enteredDateTime', displayName: 'EnteredDateTime', unitDisplay: '', display: false, floatValue: null },
  globalstarDateTime: {dataFieldName: 'globalstarDateTime', displayName: 'GlobalstarDateTime', unitDisplay: '', display: false, floatValue: null },
  checksum: {dataFieldName: 'checksum', displayName: 'Checksum', unitDisplay: '', display: true, floatValue: null },
  ageMin: {dataFieldName: 'ageMin', displayName: 'AgeInMinutes', unitDisplay: ' min', display: true, floatValue: null },
  ageSec: {dataFieldName: 'ageSec', displayName: 'AgeInSeconds', unitDisplay: ' sec', display: true, floatValue: null },
  page: {dataFieldName: 'page', displayName: 'Page', unitDisplay: '', display: false, floatValue: null },
  latitude: {dataFieldName: 'latitude', displayName: 'Latitude', unitDisplay: '°', display: true, floatValue: null },
  longitude: {dataFieldName: 'longitude', displayName: 'Longitude', unitDisplay: '°', display: true, floatValue: null },
  satVis: {dataFieldName: 'satVis', displayName: 'SatellitesVisible', unitDisplay: '', display: true, floatValue: null },
  satTrack: {dataFieldName: 'satTrack', displayName: 'SatellitesTracked', unitDisplay: '', display: true, floatValue: null },
  recvStatus: {dataFieldName: 'recvStatus', displayName: 'ReceiveStatus', unitDisplay: '', display: true, floatValue: null },
  secondsToFix: {dataFieldName: 'secondsToFix', displayName: 'SecondsToFix', unitDisplay: ' sec', display: true, floatValue: null },
  mobitexRSSI: {dataFieldName: 'mobitexRSSI', displayName: 'SignalStrength', unitDisplay: '', display: true, floatValue: null },
  submergedBool: {dataFieldName: 'submergedBool', displayName: 'SubmergedBoolean', unitDisplay: '', display: true, floatValue: null },
  submergedPercent: {dataFieldName: 'submergedPercent', displayName: 'SubmergedPercent', unitDisplay: '%', display: true, floatValue: null },
  gpsQuality: {dataFieldName: 'gpsQuality', displayName: 'GpsQuality', unitDisplay: '', display: true, floatValue: null },
  battVoltage: {dataFieldName: 'battVoltage', displayName: 'BatteryVoltage', unitDisplay: 'V', display: true, floatValue: null },
  sst: {dataFieldName: 'SST', displayName: 'Temperature0cm', unitDisplay: '°C', display: true, floatValue: null },
  surfaceSalinity: {dataFieldName: 'surfaceSalinity', displayName: 'SurfaceSalinity', unitDisplay: '', display: true, floatValue: null },
  waterPress: {dataFieldName: 'waterPress', displayName: 'WaterPressure', unitDisplay: ' mH2O', display: true, floatValue: null },
  bPress: {dataFieldName: 'bPress', displayName: 'BarometricPressure', unitDisplay: ' mB', display: true, floatValue: null },
  bPressTend: {dataFieldName: 'bPressTend', displayName: 'BarometricPressureTendency', unitDisplay: ' mB', display: true, floatValue: null },
  windDir: {dataFieldName: 'windDir', displayName: 'WindDirection', unitDisplay: '°', display: true, floatValue: null },
  windSpeed: {dataFieldName: 'windSpeed', displayName: 'WindSpeed', unitDisplay: ' m/s', display: true, floatValue: null },
  processedOut: {dataFieldName: 'processedOut', displayName: 'processedOut', unitDisplay: '', display: false, floatValue: null },
  sensorDateTime: {dataFieldName: 'sensorDateTime', displayName: 'SensorDateTime', unitDisplay: '', display: true, floatValue: null },
  ageHour1: {dataFieldName: 'ageHour1', displayName: 'AgeHour1', unitDisplay: ' hr', display: false, floatValue: null },
  ageHour2: {dataFieldName: 'ageHour2', displayName: 'AgeHour2', unitDisplay: ' hr', display: false, floatValue: null },
  transmissionDuration: {dataFieldName: 'transmissionDuration', displayName: 'TransmissionDuration', unitDisplay: ' sec', display: true, floatValue: null },
  transmissionRetry: {dataFieldName: 'transmissionRetry', displayName: 'TransmissionRetry', unitDisplay: '', display: true, floatValue: null },
  messageID_Iridium: {dataFieldName: 'messageID_Iridium', displayName: 'messageID_Iridium', unitDisplay: '', display: false, floatValue: null },
  satelliteLatitude: {dataFieldName: 'satelliteLatitude', displayName: 'SatelliteLatitude', unitDisplay: '', display: true, floatValue: null },
  satelliteLongitude: {dataFieldName: 'satelliteLongitude', displayName: 'SatelliteLongitude', unitDisplay: '', display: true, floatValue: null },
  satelliteRadiusKm: {dataFieldName: 'satelliteRadiusKm', displayName: 'SatelliteRadiusKm', unitDisplay: '', display: true, floatValue: null },
  argosDataId: {dataFieldName: 'argosDataId', displayName: 'argosDataId', unitDisplay: '', display: false, floatValue: null },
  globalStarDataId: {dataFieldName: 'globalStarDataId', displayName: 'globalStarDataId', unitDisplay: '', display: false, floatValue: null },
  iridiumDataId: {dataFieldName: 'iridiumDataId', displayName: 'iridiumDataId', unitDisplay: '', display: false, floatValue: null },
  mobitexDataId: {dataFieldName: 'mobitexDataId', displayName: 'mobitexDataId', unitDisplay: '', display: false, floatValue: null },
  gtsStatusCode: {dataFieldName: 'gtsStatusCode', displayName: 'gtsStatusCode', unitDisplay: '', display: false, floatValue: null },
  gtsComment: {dataFieldName: 'gtsComment', displayName: 'gtsComment', unitDisplay: '', display: false, floatValue: null },
  orgId: {dataFieldName: 'orgId', displayName: 'OrgId', unitDisplay: '', display: false, floatValue: null },
  spotDataId: {dataFieldName: 'spotDataId', displayName: 'spotDataId', unitDisplay: '', display: false, floatValue: null },
};

export default  {

  // profile: {
  //   currentTheme: null,
  // },

  // Local settings - may vary by user's device
  // e.g. color might be different on PC and tablet

  emptyPromise() {
    return new Promise((resolve, reject)=>{
      resolve();
    });
  },

  callApi(urlSegment) {
    return new Promise((resolve, reject) => {
      var fullUrl = `${environmentSpecific.apiBaseUrl}${urlSegment}`;
      axios.get(fullUrl)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error('DataService callApi() caught error calling Url ['+fullUrl+']', error);
          reject(error);
        });
    });
  },

  callApiPost(urlSegment, data) {
    return new Promise((resolve, reject) => {
      var fullUrl = `${environmentSpecific.apiBaseUrl}${urlSegment}`;
      axios.post(fullUrl, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error('DataService callApiPost() caught error calling Url ['+fullUrl+']');
          console.error('   with data ', data, ' ERROR:', error);
          console.error('   ERROR:', error);
          reject(error);
        });
    });
  },

  getDataBySensor(deviceId, sensorName, startDateTime, endDateTime, numRecords) {
    return new Promise((resolve, reject)=>{
      var url = `data/searchBySensor?deviceId=${deviceId}&sensorShortName=${sensorName}&orderDirection=asc&takeRecords=${numRecords}`;
      if (startDateTime) {
        url += `&startDateTime=${startDateTime}`;
      }
      if (endDateTime) {
        url += `&endDateTime=${endDateTime}`;
      }

      this.callApi(url)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error('DataService getDataBySensor() caught error calling URL [', url, '] Error: ', error);
          reject(error);
        });
    });
  },

  getDataById(deviceId, dataId, getExtendedData) {
    return new Promise((resolve, reject)=>{
      let allPositionData = {};

      if (!deviceId) {
        console.warn('Device ID must be specified');
        reject("No device ID");
      }
      if (!dataId) {
        console.warn('Data ID must be specified');
        reject("No data ID");
      }

      var dataUrl = `data/${dataId}`;
      this.callApi(dataUrl)
        .then((response) => {
          var positionData = response.data.data;
          if (positionData.deviceId != deviceId) {
            console.warn(`Requested data for ${deviceId} but got data for ${positionData.deviceId}`);
            reject(`Requested data for ${deviceId} but got data for ${positionData.deviceId}`);
          }
          // copy tblData to object
          Object.assign(allPositionData, this.convertTblDataToObject(positionData));

        })
        .then(() =>{
          if (getExtendedData) {
            dataUrl = `dataExtended/search?dataId=${dataId}`;
            this.callApi(dataUrl)
              .then((response)=>{
                var extendedData = response.data.data;
                if (extendedData) {
                  Object.assign(allPositionData, this.makeDataObject(extendedData));
                }
                resolve(allPositionData);
              });
          } else {
            resolve(allPositionData);
          }
        })
        .catch((error) => {
          console.error('DataService getDataById() caught error', error);
          reject(error);
        });
    });
  },

  isValidData(dataElement) {
    if (
      dataElement
        && (!('display' in dataElement) || dataElement.display === true)
        && ('floatValue' in dataElement)
        && dataElement.floatValue !== null
      ){
        return true;
    } else {
      return false;
    }

  },

  getLastPositions(deviceIdList, startDateTime, endDateTime) {
    return new Promise((resolve, reject)=>{
      if (Array.isArray(deviceIdList)) {
        // instead of issuing an error, just fix it
        deviceIdList = deviceIdList.join(',');
      }

      if (deviceIdList.length == 0) {
        // console.warn('No device IDs in list');
        return reject('getLastPositions(',deviceIdList, ') No device IDs in list');
      }
      var fullUrl = 'data/getLastPosition';
      var query = {
        'deviceIdList': deviceIdList,
        'startDateTime': startDateTime,
        'endDateTime': endDateTime
      };
      this.callApiPost(fullUrl, query)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error('DataService getLastPositions() caught error', error);
          return reject(error);
        });
    });
  },

  getTrackData(deviceIdList, numDays, endDateTime, skipLastRecord) {
    return new Promise((resolve, reject)=>{
      if (deviceIdList.length == 0) {
        console.warn('No device IDs in list');
        reject("No device IDs in list");
      }
      // endDateTime = moment.utc().add(1, 'days').format('YYYY-MM-DD');
      // endDateTime = moment('5/20/2019').format('YYYY-MM-DD');
      var fullUrl = `data/GetPositionsUntil`;
      this.callApiPost(fullUrl, {
          'deviceIdList': deviceIdList,
          'maxDays': numDays,
          'endDateTime': endDateTime
        })
        .then((response) => {
          /*
            "metaData": {
                "numRows": 2618
              },
              "data": [{
                "deviceId": 2412,
                "deviceName": "300234063990880",
                "comm1": "300234063990880",
                "comm2": null,
                "sim": null,
                "orgName": "PG_SIO_SVPBI",
                "deviceTypeName": "SVPB",
                "iconFileName": "svpb-icon.png",
                "deviceDateTime": "2019-05-30T14:00:00.000Z",
                "latitude": -35.0454,
                "longitude": 117.8464,
                "battVoltage": 9.8,
                "gpsQuality": 3,
                "sst": 13.37,
                "dataId": 20665542
              },]
          */
          resolve(response.data);
        })
        .catch((error) => {
          console.error('DataService getTracks(',deviceIdList,', ',numDays,') caught error', error);
          reject(error);
        });
    });
  },

  makeDataObject(dataExtended) {
    let newObject = {};
    for (let key in dataExtended) {
      if (this.isValidData(dataExtended[key])) {
        newObject[dataExtended[key].dataFieldName] = dataExtended[key];
      }
    }
    return newObject;
  },

  convertTblDataToObject(tblData){
    let temp = {}, filteredTemp = {};
    Object.assign(temp, tblDataFields);
    // loop through data, add to object...
    for (var key in tblData) {
      if (temp.hasOwnProperty(key)) {
        // console.log(key, tblData[key]);
        temp[key].floatValue = tblData[key];
      } else {
        console.log('temp does not have key: ', key);
      }
    }
    for (var key2 in temp) {
      if (this.isValidData(temp[key2])) {
        filteredTemp[key2] = temp[key2];
      }
    }
    return filteredTemp;
  }

};


