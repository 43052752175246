<template>
  <div class="tabs-component  pg-component">
    <div class="tabs">
      <ul>
        <li v-for="tab in tabs" v-bind:key="tab.name" class="tab-selector" v-bind:class="{'selected' : tab.isSelected}" @click="selectTab(tab)">
          <a v-bind:href="tab.id">
            <i class="tab-icon" v-bind:class="tab.icon"></i>
            <span class="tab-name">{{ tab.name }}</span>
          </a>
        </li>
      </ul>
    </div>
    <div class="tab-content-area">
      <slot></slot>
    </div>
  </div>
</template>

/*************************************************************/

<script>
  // https://laracasts.com/series/learn-vue-2-step-by-step/episodes/11
  // import { mapGetters, mapState, mapActions } from 'vuex';
  import Tab from '@/components/Common/TabComponent'; // Import another component
  import { EventBus } from '@/EventBus';

  var data = function() {
    return {
      tabs: [],
      tab: null,
      currentTab: {},
    };
  };

  var props = {
    canChangeTabs: { type: Boolean, default: true },
    cantChangeTabReason: { type: String, default: 'Save or exit before changing tabs' },
  };

  var created = function() {
    // passed in on the slot
    this.tabs = this.$children;
  };

  var beforeMount = function() {};

  var mounted = function() {
    //console.log('Tab kiddies:', this.$children);
    // console.log('props', this.canChangeTabs, this.cantChangeTabReason);
  };

  var methods = {
    selectTab(selectedTab) {
      if (this.canChangeTabs) {
        this.tabs.forEach(tab => {
          tab.isSelected = tab.name === selectedTab.name;
          if (tab.isSelected) {
            this.currentTab = tab;
          }
        });
        this.$emit('onTabChange', this.currentTab);
      } else {
        EventBus.$emit('NOTIFY_USER', this.cantChangeTabReason);
      }
    },
    selectTabById(id) {
      if (this.canChangeTabs) {
        this.tabs.forEach(tab => {
          tab.isSelected = tab.id === id;
        });
      } else {
        EventBus.$emit('NOTIFY_USER', this.cantChangeTabReason);
      }
    },
  };

  var computed = {};

  var watch = {};

  var components = {
    Tab,
  };

  export default {
    name: 'TabsComponent',
    data,
    props,
    created,
    beforeMount,
    mounted,
    methods,
    computed,
    watch,
    components,
  };
</script>

/*************************************************************/

<style scoped>
  .tab-selector {
    min-width: 80px;
    display: inline-grid;
    text-align: center;
  }
</style>
