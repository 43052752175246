<template>
  <div class="data-page page">
    <div class="page-content">
      <div class="panel-container-horizontal">
        <div class="panel-left" v-bind:class="{'collapsed':leftPanelCollapsed}">
          <div class="accordion" id="accordion">
            <div class="accordion-panel fixed-height" v-bind:class="{'open':timeRangePanelOpen}">
              <!-- Date Range Panel -->
              <label class="accordion-label section-divider" v-on:click="timeRangePanelOpen=!timeRangePanelOpen;">
                Step 1: Enter Time Range
                <i class="far fa-chevron-circle-down pg-click-to-open"></i>
                <i class="far fa-chevron-circle-up   pg-click-to-close"></i>
              </label>
              <div class="accordion-content">
                <date-range-filter v-model="filterValues"></date-range-filter>
                <div class="row lat-long-row">
                  <span>Coordinate Format</span>
                  <input type="radio" name="latlong" id="degree" value="d" v-model="selectedCoordFormat" @change="formatCoordinates" /><label
                    for="deg"
                    >D</label
                  >
                  <input type="radio" name="latlong" id="deg-min" value="dm" v-model="selectedCoordFormat" @change="formatCoordinates" /><label
                    for="deg-min"
                    >DM</label
                  >
                  <input type="radio" name="latlong" id="deg-min-sec" value="dms" v-model="selectedCoordFormat" @change="formatCoordinates" /><label
                    for="deg-min-sec"
                    >DMS</label
                  >
                </div>
              </div>
            </div>

            <div class="accordion-panel variable-height" v-bind:class="{'open':devicePanelOpen}">
              <!-- Devices Panel -->
              <label class="accordion-label section-divider" v-on:click="devicePanelOpen=!devicePanelOpen;">
                Step 2: Select Devices
                <i class="far fa-chevron-circle-down pg-click-to-open"></i>
                <i class="far fa-chevron-circle-up   pg-click-to-close"></i>
              </label>
              <div class="accordion-content ">
                <device-list-grid
                  :orgDevices="orgDevices"
                  show-columns="deviceName,xmitDate,xmitText,status"
                  :hide-columns-by-size="hideColumnsBySizeDefault"
                  v-show="!allDevices"
                ></device-list-grid>
              </div>
            </div>

            <div class="accordion-panel variable-height" v-bind:class="{'open':sensorPanelOpen}">
              <!-- Sensors Panel -->
              <label class="accordion-label section-divider" v-on:click="sensorPanelOpen=!sensorPanelOpen;">
                Step 3: Select Sensors
                <i class="far fa-chevron-circle-down pg-click-to-open"></i>
                <i class="far fa-chevron-circle-up   pg-click-to-close"></i>
              </label>
              <div class="accordion-content">
                <data-fields-grid v-show="selectedDeviceIds.length > 0" :show-select-all="false"></data-fields-grid>
                <div class="notification" v-show="selectedDeviceIds.length===0">
                  Select one or more devices first. Any sensors that are in one or more of the selected devices will show up here.
                </div>
              </div>
            </div>

            <div class="accordion-panel fixed-height" v-bind:class="{'open':savedSettingsPanelOpen}">
              <!-- Saved Settings Panel -->
              <label class="accordion-label section-divider" v-on:click="savedSettingsPanelOpen=!savedSettingsPanelOpen;">
                Saved Settings
                <i class="far fa-chevron-circle-down pg-click-to-open"></i>
                <i class="far fa-chevron-circle-up   pg-click-to-close"></i>
              </label>
              <div class="accordion-content padded">
                <div v-if="savedSettings.length > 0 ">
                  <div class="hint top-pad bottom-pad">
                    Quickly restore settings from a previous session
                  </div>
                  <div v-for="(savedSetting, index) of savedSettings" :key="index" class="clickers">
                    <span class="clicker" v-on:click="applySavedSettings(index);">
                      {{ savedSetting.savedName }}
                    </span>
                    <span class="delete-me" v-bind:index="index" v-on:click="deleteSavedSetting(index);" title="Delete this saved setting">X</span>
                  </div>
                </div>

                <div class="notice top-pad bottom-pad" v-if="true /**/">
                  <div class="notice" v-html="newSettingsMessage"></div>
                </div>

                <div class="top-pad">
                  <div class="hint top-pad" v-show="true">
                    Optionally save current settings for future use
                  </div>
                  <div class="center-content bottom-pad">
                    <input type="text" v-model="newSettingName" placeholder="Name for these settings" autocomplete="pg-saved-settings" />
                    <button type="button" class="inline-button" v-on:click="addSavedSetting();">Save Settings</button>
                    <div class="error-message" v-html="newSettingErrorMessage"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="handle v-handle">
            <i class="fal fa-caret-left" v-bind:class="{'fa-rotate-180':leftPanelCollapsed}" v-on:click="togglePanel('.panel-left', $event)"></i>
          </div>
        </div>

        <div class="panel-right">
          <div class="panel-container-vertical">
            <div class="panel-top panel-top-right">
              <tabs ref="tabs">
                <tab name="Chart" :selected="true" icon="far fa-chart-line"
                  ><!-- chart tab -->
                  <div class="chart-component-holder">
                    <chart-component :date-range="filterValues"></chart-component>
                  </div>
                </tab>

                <tab name="Data Download" icon="far fa-cloud-download" class="data-download-tab-content"
                  ><!-- file format -->
                  File Format:
                  <pg-select id="fileFormat" :options="fileFormats" :value="selectedFormat" label="name" @input="onDropdownInput"></pg-select>

                  <!-- screen or download -->
                  <div class="display-row">
                    <input type="radio" id="screen" name="disposition" value="No" v-model="forDownload" />
                    <label for="screen">View on screen</label>
                    <input type="radio" id="download" name="disposition" value="Yes" v-model="forDownload" />
                    <label for="download">Download</label>
                  </div>

                  <!-- compression -->
                  <div v-show="forDownload=='Yes'">
                    Compression:
                    <pg-select
                      id="compression"
                      :options="compressionTypes"
                      :value="selectedCompression"
                      label="name"
                      track-by="name"
                      @input="onDropdownInput"
                    ></pg-select>
                  </div>
                  <!-- URL -->
                  <div>
                    <api-url
                      :all-devices="allDevices"
                      :date-filter="filterValues"
                      :file-format="selectedFormat.action"
                      :field-list="selectedFields"
                      :for-download="forDownload"
                      :compression="selectedCompression.action"
                      :org-id="apiOrgId"
                      :coordinateFormat="coordinateFormat"
                    />
                  </div>
                </tab>

                <tab name="Device Map" icon="far fa-globe-europe"
                  ><!-- map -->
                  <device-map></device-map>
                </tab>

                <!--
                <tab name="Data Table" icon="fal fa-table">
                  <h2>The plan is to put a data grid here.  Stay tuned.</h2>
                </tab> -->
              </tabs>
              <div class="handle h-handle">
                <i
                  class="fas fa-caret-down"
                  v-bind:class="{'fa-rotate-180':bottomPanelCollapsed}"
                  v-on:click="togglePanel('.panel-bottom', $event)"
                />
              </div>
            </div>

            <div class="panel-bottom panel-bottom-right"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

/*********************************************************************/

<script>
  import { mapGetters, mapState, mapActions } from 'vuex';
  import DeviceListGrid from '@/components/DeviceListGrid';
  import ApiUrl from '@/components/ApiUrl';
  import DateRangeFilter from '@/components/DateRangeFilter';
  import DataFieldsGrid from '@/components/DataFieldsGrid';
  import PgSelect from '@/components/Common/PgSelect';
  import Tabs from '@/components/Common/TabsComponent';
  import Tab from '@/components/Common/TabComponent';
  import ChartComponent from '@/components/ChartComponent';
  import ProfileService from '@/services/ProfileService';
  import * as pgHelpers from '@/utils/PgHelpers.js';
  import DeviceMap from '@/components/DeviceMap';
  import environmentSpecific from 'static/environmentSpecific';

  var thisComponent = null;

  var data = function() {
    return {
      // control accordion visability
      timeRangePanelOpen: true,
      devicePanelOpen: true,
      sensorPanelOpen: true,
      savedSettingsPanelOpen: true,

      hideColumnsBySize: [],
      showCoordButtons: false,
      allDevices: false,
      // pgDateRangeParameters = {rangeType: 'TimeSpan', numUnits: 5, timeUnit: 'hours', startDate: '', endDate: '',};
      filterValues: DateRangeFilter.pgDateRangeParameters,
      fileFormats: [
        { action: 'html', name: 'HTML (.html)' },
        { action: 'csv', name: 'Comma Separated Values (.csv)' },
        { action: 'xlsx', name: 'Excel (.xlsx)' },
        { action: 'xml', name: 'XML (.xml)' },
        { action: 'json', name: 'JSON (.json)' },
        { action: 'kml', name: 'Google Earth (.kml)' },
        { action: 'fixed-width', name: 'Fixed width (.txt)' },
      ],
      selectedFormat: { action: 'html', name: 'HTML (.html)' },
      selectedFields: [],
      selectedCoordFormat: '',
      // coordinateFormat: null,
      forDownload: 'No',
      compressionTypes: [
        { action: '', name: '' },
        { action: '', name: 'None' },
        { action: 'zip', name: 'zip (*.zip)' },
      ],
      selectedCompression: { action: '', name: '' },
      leftPanelCollapsed: false,
      bottomPanelCollapsed: false,
      orgDevices: [],
      // deviceName,xmitDate,xmitText,status
      hideColumnsBySizeDefault: [
        {
          maxSize: 400,
          hideIds: ['status', 'xmitDate'],
        },
        {
          maxSize: 500,
          hideIds: ['xmitDate'],
        },
      ],

      newSettingName: null,
      newSettingsMessage: '',
      newSettingErrorMessage: '',
      savedSettings: [],
      savedSettingTemplate: {
        savedName: '',
        timeRange: {
          rangeType: 'TimeSpan',
          numUnits: 24,
          timeUnit: 'hours',
        },
        coordinateFormat: 'd',
        selectedDeviceIds: [],
        selectedFieldNames: [],
      }, // end savedSettingTemplate
      /*
    savedSettingTemplateSample:
      {
        savedName: 'Test',
        timeRange: {
          rangeType: 'TimeSpan',
          numUnits: 24,
          timeUnit: 'hours',
        },
        coordinateFormat: 'dm',
        selectedDeviceIds: [1000,1001],
        selectedFieldNames: ['battVoltage'],
      }, // end savedSettingTemplate
      */
    };
  };

  var methods = {
    ...mapActions('deviceModule', ['getDevices', 'setDeviceId', 'getDeviceData', 'setSelectedDeviceIds', 'setSelectedFields']), // 'getAllFieldsForSelectedDevices',

    onDropdownInput(value, id) {
      if (id === 'fileFormat') {
        this.selectedFormat = value;
      }
      if (id === 'compression') {
        this.selectedCompression = value;
      }
    },

    togglePanel(selector, evt) {
      if (selector === '.panel-left') {
        this.leftPanelCollapsed = !this.leftPanelCollapsed;
        // remove width set by resizer
        $('.panel-left').css('width', '');
      } else if (selector === '.panel-bottom') {
        this.bottomPanelCollapsed = !this.bottomPanelCollapsed;
        var newHeight = this.bottomPanelCollapsed ? '100%' : '50%';
        $('.panel-top').css('height', newHeight);
      }
    },

    onResizeEnd(collapsePanel, event) {
      if (collapsePanel === '.panel-left') {
        var width = $(collapsePanel).width() - $('.v-handle').width();
        this.leftPanelCollapsed = width <= 3;
      } else if (collapsePanel === '.panel-bottom') {
        var height = $(collapsePanel).height();
        this.bottomPanelCollapsed = height <= 3;
      }
    },

    getDevicesForOrg() {
      var orgId = this.selectedOrganization.orgId;
      var params = {
        orgId: this.selectedOrganization.orgId,
        getCommands: false,
        getBillingPlans: false,
        getSensorPackages: false,
      };
      if (orgId === -1) {
        var numRecords = environmentSpecific.environmentName === 'local' ? 500 : 6000;
        params.orgId = null;
        params.takeRecords = numRecords;
      }
      this.getDevices(params).then(response => {
        this.orgDevices = response;
      });
    },

    formatCoordinates() {
      ProfileService.saveLocalSetting('coordinateFormat', this.selectedCoordFormat);
      // console.log(this.selectedCoordFormat)
    },

    saveSettingsToDb() {
      return new Promise((resolve, reject) => {
        // console.log('saveSettingsToDb');
        ProfileService.saveDbSetting(this.$pgGlobal.currentUser.userId, 'data-page-settings', this.savedSettings, null) // data-query
          .then(response => {
            // console.log('Settings saved');
            resolve(response);
          });
      });
    },

    restoreSettingsFromDb() {
      return new Promise((resolve, reject) => {
        ProfileService.getDbSetting(this.$pgGlobal.currentUser.userId, 'data-page-settings', null) // data-query
          .then(response => {
            // console.log('Profile sevice found dbsetting ', response);
            if (Array.isArray(response)) {
              this.savedSettings = response;
            } else {
              this.savedSettings = [];
            }
            resolve(this.savedSettings);
          });
      });
    },

    addSavedSetting() {
      // console.log('Saving ', this.newSettingName, '...');
      // validate name
      if (!this.newSettingName || this.newSettingName.length === 0) {
        this.newSettingErrorMessage = 'A name is needed to save settings';
        return;
      }
      if (
        this.savedSettings.length > 0 &&
        this.savedSettings.find(setting => setting.savedName.toLowerCase() === this.newSettingName.toLowerCase())
      ) {
        this.newSettingErrorMessage =
          'The name [' + this.newSettingName + '] is already in use <br>To replace it, first click the red X to delete the old one.';
        return;
      }

      this.newSettingErrorMessage = '';

      // create new setting object
      var newSettings = {};
      Object.assign(newSettings, this.savedSettingTemplate);
      newSettings.savedName = this.newSettingName;
      newSettings.timeRange = this.filterValues;
      newSettings.coordinateFormat = this.selectedCoordFormat;
      newSettings.selectedDeviceIds = this.selectedDeviceIds;
      newSettings.selectedFieldNames = this.selectedFieldNames;

      // add to start of savedSettings array
      // before API call because it uses this data
      thisComponent.savedSettings.unshift(newSettings);
      // save array to DB
      thisComponent
        .saveSettingsToDb()
        .then(function(result) {
          // clear input box
          thisComponent.newSettingName = '';
        })
        .catch(err => {
          // didn't save, remove from array
          thisComponent.savedSettings.splice(0, 1);
        });
      this.newSettingsMessage = 'Saved';
    },

    deleteSavedSetting(index) {
      if (index > -1) {
        thisComponent.savedSettings.splice(index, 1);
        thisComponent
          .saveSettingsToDb()
          .then(function() {})
          .catch(err => {
            thisComponent.newSettingErrorMessage = 'Problem saving';
            console.error(err);
          });
      }
    },

    async applySavedSettings(index) {
      var settingsMessage = '';
      var newSetting = this.savedSettings[index];

      // console.log('Applying ', newSetting.savedName);
      settingsMessage = 'Restored "' + newSetting.savedName + '" settings as:<br>';

      // set time range
      Object.assign(this.filterValues, newSetting.timeRange);
      settingsMessage += pgHelpers.dateTimeHelper.dateRangeObjectToText(newSetting.timeRange) + ' ';

      // set Coordinate format
      this.selectedCoordFormat = newSetting.coordinateFormat;
      var coordinateFormatText = pgHelpers.latLongDisplayHelper.dmsFormatToText(this.selectedCoordFormat);
      settingsMessage += 'using ' + coordinateFormatText + '<br>';

      // select any matching devices
      var matchingDevices = _.intersection(newSetting.selectedDeviceIds, this.allDeviceIds);
      // make sure field list is updated before continuing...
      await this.setSelectedDeviceIds(matchingDevices);
      var maxDevicesToShow = 5;
      var deviceNames = '';
      if (this.selectedDeviceNames.length <= maxDevicesToShow) {
        deviceNames = this.selectedDeviceNames.join(', ');
      } else {
        deviceNames = this.selectedDeviceNames.slice(0, maxDevicesToShow).join(', ');
        deviceNames += ' and ' + (this.selectedDeviceNames.length - maxDevicesToShow) + ' more...';
      }
      settingsMessage += deviceNames + '<br>';

      // select any matching fields
      var test = this.selectedDeviceIds;
      var test2 = this.allFieldsSelectedDevices;
      var allDataFieldNames = this.allFieldsSelectedDevices.map(d => d.dataFieldName);
      var matchingDataFieldNames = _.intersection(newSetting.selectedFieldNames, allDataFieldNames);
      this.setSelectedFields(matchingDataFieldNames);
      // convert to display names
      var matchingDataFieldDisplayNames = this.getSelectedFieldDisplayNames;

      var maxFieldsToShow = 5;
      var dataFieldDisplayNames = '';
      if (matchingDataFieldDisplayNames.length <= maxFieldsToShow) {
        dataFieldDisplayNames = matchingDataFieldDisplayNames.join(', ');
      } else {
        dataFieldDisplayNames = matchingDataFieldDisplayNames.slice(0, maxFieldsToShow).join(', ');
        dataFieldDisplayNames += ' and ' + (matchingDataFieldDisplayNames.length - maxFieldsToShow) + ' more...';
      }
      settingsMessage += dataFieldDisplayNames + '<br>';

      // show text of what we did
      console.log(settingsMessage);
      this.newSettingsMessage = settingsMessage;
    },
  };

  var computed = {
    ...mapState('deviceModule', ['selectedDeviceIds', 'allFieldsSelectedDevices', 'selectedFieldNames']),
    ...mapState('organizationModule', ['selectedOrganization']),
    ...mapGetters('deviceModule', [
      'selectedDeviceNames',
      'allDeviceIds',
      'getSelectedFieldDisplayNames',
      'getSelectedFields',
      'selectedDeviceIdsCount',
    ]),

    apiOrgId: function() {
      var orgId = null;
      if (this.$pgGlobal.currentUser.orgId != this.selectedOrganization.orgId) {
        // change to isPgStaff
        // only use orgID in URL if different from user's
        orgId = this.selectedOrganization.orgId;
      }
      return orgId;
    },

    coordinateFormat: function() {
      return this.selectedCoordFormat != 'd' ? this.selectedCoordFormat : null;
    },
  };

  var created = function() {
    thisComponent = this;
    this.restoreSettingsFromDb();
    // set defaults for date-range-filter component
    var savedFilterValues = ProfileService.getLocalSetting('timeRange', null);

    if (savedFilterValues) {
      this.filterValues = Object.assign(this.filterValues, savedFilterValues);
    } else {
      this.filterValues.rangeType = 'TimeSpan';
      this.filterValues.numUnits = 24;
      this.filterValues.timeUnit = 'hours';
    }
    this.selectedCoordFormat = ProfileService.getLocalSetting('coordinateFormat', 'd');
  };

  var beforeMount = function() {};

  var mounted = function() {
    this.getDevicesForOrg();
    // this.selectedFormat = this.fileFormats[0];
    $('.panel-left').panelresizable({
      handleSelector: '.v-handle',
      resizeWidth: true,
      resizeHeight: false,
      resizeWidthFrom: 'right',
      onDragEnd: function(evt) {
        thisComponent.onResizeEnd('.panel-right', evt);
      },
    });

    $('.panel-top').panelresizable({
      handleSelector: '.h-handle',
      resizeWidth: false,
      resizeHeight: true,
      resizeHeightFrom: 'bottom',
      onDragEnd: function(evt) {
        thisComponent.onResizeEnd('.panel-bottom', evt);
      },
    });

    $('.jq-resizable').resizable({
      handles: 's',
    });

    // if #tabname in url open that tab
    var requestedTab = this.$route.hash;
    if (requestedTab) {
      this.$refs.tabs.selectTabById(requestedTab);
    }
  };

  var watch = {
    selectedOrganization: function(value) {
      this.getDevicesForOrg();
    },
    // selectedDeviceIds: function(value) {
    //   console.log('DataPage selected deviceIds changed to: ', value)
    // },

    filterValues: {
      handler(value) {
        ProfileService.saveLocalSetting('timeRange', this.filterValues);
        // console.log('date filter changed to ', value);
      },
      deep: true,
    },
  };

  var components = {
    DeviceListGrid,
    ApiUrl,
    DateRangeFilter,
    DataFieldsGrid,
    PgSelect,
    Tabs,
    Tab,
    ChartComponent,
    DeviceMap,
  };

  export default {
    name: 'DataPage',
    data,
    created,
    beforeMount,
    mounted,
    methods,
    computed,
    components,
    watch,
  };
</script>

/*********************************************************************/
<style lang="scss">
  .data-page {
    /* device list grid */
    .device-list-grid {
      width: 100%;
      display: flex;
      flex-direction: row;
    }
    .device-list-grid-body {
      height: 100%;
      display: flex;
      flex-direction: column;
      /* min-height: 198px; ****************************** */
    }
    .device-list-grid-container {
      flex: 1 1 auto;
      display: flex;
      width: 100%;
    }

    /* data fields grid */
    .data-fields-grid {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
    }
    .data-fields-grid-body {
      /* min-height: 199px; *************************************** */
      display: flex;
      flex-direction: column;
    }
    .data-fields-grid-container {
      flex: 1 1 auto;
      display: flex;
      //min-height: 6em;
    }

    /* date range */
    .date-range-filter {
      flex: 0 0 auto;
      padding: 1em 1em 0 1em;
      overflow: auto; /* hidden */
    }

    .datafields-grid {
      flex: 1 1 auto;
    }
    .panel-container-horizontal {
      flex: 1 1 auto;
    }
    .panel-container-vertical {
      max-height: calc(100vh - 40px);
    }
    .panel-top {
      padding: 20px 0 0 0;
    }

    .panel-top-left {
      height: auto;
      max-height: 75%;
      padding-bottom: 25px;
      padding-right: 25px;
      flex-direction: column;
    }
    .panel-top-right {
      height: 95%;
      max-height: 100%;
      padding-bottom: 20px;
    }
    .panel-left {
      width: 362px;
      min-width: 16px;
      max-width: 100%;
    }
    .panel-bottom-left {
      display: flex;
      flex-direction: column;
      padding-right: 25px;
    }
    .panel-bottom-right {
      display: flex;
      flex-direction: column;
      padding-right: 20px;
    }
    .panel-contents {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    .panel-left.collapsed {
      padding: 0;
      width: 17px;
    }
    .download-tab-contents {
      flex-direction: column;
      padding: 20px 10px 10px 10px;
    }

    .chart-component-holder {
      width: 100%;
      height: 100%;
    }
    .lat-long-row {
      margin: 0 auto;
      padding: 5px 5px 10px 5px;
      line-height: 18px;
      width: 100%;
      text-align: center;
    }
    .lat-long-row input[type='radio'],
    .display-row input[type='radio'] {
      display: inline;
      vertical-align: text-bottom;
    }

    .extra-top-space-panel {
      margin-top: 20px;
    }
    .hint {
      font-style: italic;
      text-align: center;
    }
    .center-content {
      margin: 0 auto;
      display: table;
    }
    .bottom-pad {
      padding-bottom: 5px;
    }
    .top-pad {
      padding-top: 5px;
    }
    .padded {
      padding: 5px;
    }
    .clickers {
      padding: 5px 20px;
      display: flex;
      justify-content: space-between;
    }
    .clicker {
      text-decoration: underline;
      cursor: pointer;
    }
    .delete-me {
      color: red;
      cursor: pointer;
    }
    .notice {
      font-style: italic;
      color: purple;
      padding: 2px 10px;
    }
    .error-message {
      margin-top: 5px;
    }

    .accordion-panel {
      .pg-click-to-open {
        display: inline-block;
      }
      .pg-click-to-close {
        display: none;
      }
    }
    .accordion-panel.open {
      .pg-click-to-open {
        display: none;
      }
      .pg-click-to-close {
        display: inline-block;
      }
    }
    .accordion .accordion-content {
      height: 100%;
      display: none;
    }
    .accordion .open .accordion-content {
      // height: 100%;
      display: block;
    }
    .accordion-panel.variable-height.open {
      flex: 1;
    }

    .data-download-tab-content {
      padding: 50px 10px 10px 10px;
    }
  }
</style>
