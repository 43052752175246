import axios from 'axios';
import environmentConfig from 'static/environmentSpecific';
import { isAssertionExpression } from 'typescript';
import pgUtils from '../utils/PgUtils';

const state = {
  contactList: [],
  billingPrices: [],
  billingActivityByOrg: [],
  billingOverviewForOrgs: [],
  billingCommissions: [],
  billingGroups: [],
};

/* mutations are the only way to modify state, no business logic         */
/* synchronous, "simple setters", not directly accessed by componets     */
const mutations = {
  updateContactList(state, contactList) {
    state.contactList = contactList;
    //console.log('state mutation called to update contactList $$$$$$$$$$$$$$$');
  },
  updateBillingPrices(state, billingPrices) {
    state.billingPrices = billingPrices;
  },
  setBillingOverviewForOrgs(state, billingOverviews) {
    state.billingOverviewForOrgs = billingOverviews;
  },
  setBillingActivityForOrg(state, billingActivity) {
    state.billingActivityByOrg = billingActivity;
  },
  setBillingCommissions(state, billingCommissions) {
    state.billingCommissions = billingCommissions;
  },
  updateBillingGroups(state, billingGroups) {
    state.billingGroups = billingGroups;
  },
};

/* actions contain business logic, can commit multiple mutations         */
/* asynchronous.  use commit to alter state, dispatch to call functions  */
const actions = {
  getContactList({ state, commit, rootState }) {
    var fullUrl = `${environmentConfig.apiBaseUrl}contacts`;
    axios.get(fullUrl).then(response => {
      commit('updateContactList', response.data.data);
    });
  },

  updateContact({ rootState }, data) {
    var dateUpdated = moment().format();
    var jsonPatch = [
      pgUtils.makeJsonPatch('/status', data.status),
      pgUtils.makeJsonPatch('/notes', data.notes),
      pgUtils.makeJsonPatch('/dateUpdated', dateUpdated),
    ];
    //console.log('updating API with patch=', jsonPatch );
    var url = `${environmentConfig.apiBaseUrl}contacts/${data.contactId}`;
    axios.patch(url, jsonPatch).then(response => {
      /* commit('updateOrganization', response.data.data);*/
    });
  },

  getBillingPricesByDeviceId({ state, commit, rootState }, deviceId) {
    var fullUrl = `${environmentConfig.apiBaseUrl}billingPrices/search?deviceId=${deviceId}`;
    return new Promise((resolve, reject) => {
      axios
        .get(fullUrl)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getBillingPricesByBillingPriceId({ state }, billingPriceId) {
    var fullUrl = `${environmentConfig.apiBaseUrl}billingPrices/search?billingPriceId=${billingPriceId}`;
    return new Promise((resolve, reject) => {
      axios
        .get(fullUrl)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getBillingPricesByServiceTypeAndNetworkId({ state }, { serviceTypeCode, networkId }) {
    var fullUrl = `${environmentConfig.apiBaseUrl}billingPrices/search?serviceTypeCode=${serviceTypeCode}&networkId=${networkId}`;
    return new Promise((resolve, reject) => {
      axios
        .get(fullUrl)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  getAllBillingPlans({ commit, rootState }) {
    var fullUrl = `${environmentConfig.apiBaseUrl}billingPrices/search`;
    return new Promise((resolve, reject) => {
      axios.get(fullUrl).then(response => {
        commit('updateBillingPrices', response.data.data);
        resolve(response.data.data);
      });
    });
  },
  getBillingGroups({ commit }, orgId) {
    console.log(orgId);
    var fullUrl = `${environmentConfig.apiBaseUrl}billingGroups/search`;
    if (orgId > -1) {
      fullUrl += `?orgId=${orgId}`;
    }
    return new Promise((resolve, reject) => {
      axios
        .get(fullUrl)
        .then(response => {
          commit('updateBillingGroups', response.data.data);
          resolve(response.data.data);
        })
        .catch(error => {
          console.log('BackOfficeStore getBillingGroups() caught error');
          reject(error);
        });
    });
  },

  getBillingGroupById({ commit }, billingGroupId) {
    var fullUrl = `${environmentConfig.apiBaseUrl}billingGroups/search`;
    fullUrl += `?billingGroupId=${billingGroupId}`;
    return new Promise((resolve, reject) => {
      axios
        .get(fullUrl)
        .then(response => {
          commit('updateBillingGroups', response.data.data);
          resolve(response.data.data);
        })
        .catch(error => {
          console.log('BackOfficeStore getBillingGroupById() caught error');
          reject(error);
        });
    });
  },

  setDeviceBillingGroup({ commit }, { billingGroupId, deviceIds }) {
    var fullUrl = `${environmentConfig.apiBaseUrl}devices/setBillingGroup`;
    return new Promise((resolve, reject) => {
      axios
        .post(fullUrl, { billingGroupId, deviceIds })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log('BackOfficeStore setDeviceBillingGroup() caught error');
          reject(error);
        });
    });
  },

  createBillingGroup({}, newGroup) {
    var fullUrl = `${environmentConfig.apiBaseUrl}billingGroups`;

    return new Promise((resolve, reject) => {
      axios
        .post(fullUrl, newGroup)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          console.log('BackOfficeStore createBillingGroup() caught error');
          reject(error);
        });
    });
  },

  updateBillingGroup({}, updates) {
    var id = updates.pop();
    var fullUrl = `${environmentConfig.apiBaseUrl}billingGroups/${id}`;
    return new Promise((resolve, reject) => {
      axios
        .patch(fullUrl, updates)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(error => {
          console.log('BackOfficeStore updateBillingGroup() caught error');
          reject(error);
        });
    });
  },

  deleteBillingGroup({}, billingGroupId) {
    var id = billingGroupId;
    var fullUrl = `${environmentConfig.apiBaseUrl}billingGroups/${id}`;
    return new Promise((resolve, reject) => {
      axios
        .delete(fullUrl)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log('BackOfficeStore deleteBillingGroup() caught error');
          reject(error);
        });
    });
  },

  getBillingOverviewForOrgs({ commit }, searchCriteria) {
    var fullUrl = `${environmentConfig.apiBaseUrl}billingTelemetry/search`;
    return new Promise((resolve, reject) => {
      axios
        .post(fullUrl, searchCriteria)
        .then(response => {
          commit('setBillingOverviewForOrgs', response.data);
          resolve(response.data);
        })
        .catch(error => {
          console.log('BackOfficeStore getBillingOverviewForOrgs() caught error');
          reject(error);
        });
    });
  },

  getBillingActivityForOrg({ commit }, searchCriteria) {
    var fullUrl = `${environmentConfig.apiBaseUrl}billingTelemetry/searchBillingActivity`;
    return new Promise((resolve, reject) => {
      axios
        .post(fullUrl, searchCriteria)
        .then(response => {
          commit('setBillingActivityForOrg', response.data.data);
          resolve(response.data.data);
        })
        .catch(error => {
          console.log('BackOfficeStore getBillingActivityForOrg() caught error');
          reject(error);
        });
    });
  },
  getBillingActivityByBillingId({ commit }, searchCriteria) {
    var fullUrl = `${environmentConfig.apiBaseUrl}billingTelemetry/searchBillingActivityById`;
    return new Promise((resolve, reject) => {
      axios
        .post(fullUrl, searchCriteria)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(error => {
          console.log('BackOfficeStore getBillingActivityByBillingId() caught error');
          reject(error);
        });
    });
  },
  getTelemetryBillingDetailsForOrg({ commit }, searchCriteria) {
    var fullUrl = `${environmentConfig.apiBaseUrl}billingTelemetry/searchTelemetryBillingDetailsForOrg`;
    return new Promise((resolve, reject) => {
      axios
        .post(fullUrl, searchCriteria)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(error => {
          console.log('BackOfficeStore getTelemetryBillingDetailsForOrg() caught error');
          reject(error);
        });
    });
  },

  getTelemetryBillingOverviewForOrg({ commit }, searchCriteria) {
    var fullUrl = `${environmentConfig.apiBaseUrl}billingTelemetry/searchTelemetryBillingForOrg`;
    return new Promise((resolve, reject) => {
      axios
        .post(fullUrl, searchCriteria)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(error => {
          console.log('BackOfficeStore getTelemetryBillingOverviewForOrg() caught error');
          reject(error);
        });
    });
  },

  getBillingAdjustmentsForOrgAndId({ commit }, searchCriteria) {
    var fullUrl = `${environmentConfig.apiBaseUrl}billingTelemetry/searchBillingAdjustmentsByOrgAndId`;
    return new Promise((resolve, reject) => {
      axios
        .post(fullUrl, searchCriteria)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(error => {
          console.log('BackOfficeStore getBillingAdjustmentsForOrgAndId() caught error');
          reject(error);
        });
    });
  },

  getPreviousBalanceForAdHocReport({ commit }, searchCriteria) {
    var fullUrl = `${environmentConfig.apiBaseUrl}billingTelemetry/searchPreviousQuarterBalanceForOrg`;
    return new Promise((resolve, reject) => {
      axios
        .post(fullUrl, searchCriteria)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(error => {
          console.log('BackOfficeStore getPreviousBalanceForAdHocReport() caught error');
          reject(error);
        });
    });
  },

  getDeviceActivationsForAdHocReport({ commit }, searchCriteria) {
    var fullUrl = `${environmentConfig.apiBaseUrl}billingTelemetry/searchTelemetryBillingActivationsForOrg`;
    return new Promise((resolve, reject) => {
      axios
        .post(fullUrl, searchCriteria)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(error => {
          console.log('BackOfficeStore getDeviceActivationsForAdHocReport() caught error');
          reject(error);
        });
    });
  },

  getMonthlyFeesForAdHocReport({ commit }, searchCriteria) {
    var fullUrl = `${environmentConfig.apiBaseUrl}billingTelemetry/searchCurrentTelemetryMonthlyFeesForOrg`;
    return new Promise((resolve, reject) => {
      axios
        .post(fullUrl, searchCriteria)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(error => {
          console.log('BackOfficeStore getMonthlyFeesForAdHocReport() caught error');
          reject(error);
        });
    });
  },

  getSuspendedFeesForAdHocReport({ commit }, searchCriteria) {
    var fullUrl = `${environmentConfig.apiBaseUrl}billingTelemetry/searchCurrentTelemetrySuspendedFeesForOrg`;
    return new Promise((resolve, reject) => {
      axios
        .post(fullUrl, searchCriteria)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(error => {
          console.log('BackOfficeStore getSuspendedFeesForAdHocReport() caught error');
          reject(error);
        });
    });
  },

  getIridiumWithDataFeesForAdHocReport({ commit }, searchCriteria) {
    var fullUrl = `${environmentConfig.apiBaseUrl}billingTelemetry/searchCurrentTelemetryIridiumWithDataFeesForOrg`;
    return new Promise((resolve, reject) => {
      axios
        .post(fullUrl, searchCriteria)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(error => {
          console.log('BackOfficeStore getIridiumWithDataFeesForAdHocReport() caught error');
          reject(error);
        });
    });
  },

  getIridiumWithoutDataFeesForAdHocReport({ commit }, searchCriteria) {
    var fullUrl = `${environmentConfig.apiBaseUrl}billingTelemetry/searchCurrentTelemetryIridiumWithoutDataFeesForOrg`;
    return new Promise((resolve, reject) => {
      axios
        .post(fullUrl, searchCriteria)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(error => {
          console.log('BackOfficeStore getIridiumWithoutDataFeesForAdHocReport() caught error');
          reject(error);
        });
    });
  },

  getRudicsFeesForAdHocReport({ commit }, searchCriteria) {
    var fullUrl = `${environmentConfig.apiBaseUrl}billingTelemetry/searchCurrentRudicsFeesForOrg`;
    return new Promise((resolve, reject) => {
      axios
        .post(fullUrl, searchCriteria)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(error => {
          console.log('BackOfficeStore getRudicsFeesForAdHocReport() caught error');
          reject(error);
        });
    });
  },

  getRudicsTelemetryOnlyFeesForAdHocReport({ commit }, searchCriteria) {
    var fullUrl = `${environmentConfig.apiBaseUrl}billingTelemetry/searchCurrentRudicsTelemetryOnlyFeesForOrg`;
    return new Promise((resolve, reject) => {
      axios
        .post(fullUrl, searchCriteria)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(error => {
          console.log('BackOfficeStore getRudicsTelemetryOnlyFeesForAdHocReport() caught error');
          reject(error);
        });
    });
  },

  getSmsFeesForAdHocReport({ commit }, searchCriteria) {
    var fullUrl = `${environmentConfig.apiBaseUrl}billingTelemetry/searchCurrentSmsFeesForOrg`;
    return new Promise((resolve, reject) => {
      axios
        .post(fullUrl, searchCriteria)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(error => {
          console.log('BackOfficeStore getSmsFeesForAdHocReport() caught error');
          reject(error);
        });
    });
  },

  getGlobalstarFeesForAdHocReport({ commit }, searchCriteria) {
    var fullUrl = `${environmentConfig.apiBaseUrl}billingTelemetry/searchCurrentGlobalstarFeesForOrg`;
    return new Promise((resolve, reject) => {
      axios
        .post(fullUrl, searchCriteria)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(error => {
          console.log('BackOfficeStore getGlobalstarFeesForAdHocReport() caught error');
          reject(error);
        });
    });
  },

  getProcessingOnlyFeesForAdHocReport({ commit }, searchCriteria) {
    var fullUrl = `${environmentConfig.apiBaseUrl}billingTelemetry/searchCurrentProcessingOnlyFeesForOrg`;
    return new Promise((resolve, reject) => {
      axios
        .post(fullUrl, searchCriteria)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(error => {
          console.log('BackOfficeStore getProcessingOnlyFeesForAdHocReport() caught error');
          reject(error);
        });
    });
  },

  updateFinishedBillingDate({ commit }, data) {
    var fullUrl = `${environmentConfig.apiBaseUrl}billingTelemetry/updateBillingFinishedDate`;
    console.log(data);
    return new Promise((resolve, reject) => {
      axios
        .post(fullUrl, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log('BackOfficeStore updateFinishedBilling() caught error');
          reject(error);
        });
    });
  },

  createBillingAdjustment({}, data) {
    var fullUrl = `${environmentConfig.apiBaseUrl}billingTelemetry/saveBillingAdjustment`;
    return new Promise((resolve, reject) => {
      axios
        .post(fullUrl, data)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(error => {
          console.log('BackOfficeStore updateFinishedBilling() caught error');
          reject(error);
        });
    });
  },

  getBillingPdfStatement({ commit }, payload) {
    var fullUrl = `${environmentConfig.apiBaseUrl}billingTelemetry/getPdfStatementAsByteArray`;
    return new Promise((resolve, reject) => {
      axios
        .post(fullUrl, payload)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(error => {
          console.log('BackOfficeStore getBillingPdfStatement() caught error', error);
          reject(error);
        });
    });
  },
  getCartheCommissionsReport({ commit }, endDate) {
    var fullUrl = `${environmentConfig.apiBaseUrl}billingTelemetry/getCartheCommissions?endDate=${endDate}`;
    return new Promise((resolve, reject) => {
      axios
        .get(fullUrl)
        .then(response => {
          commit('setBillingCommissions', response.data.data);
          resolve(response);
        })
        .catch(error => {
          console.log('BackOfficeStore getCartheCommissions() caught error', error);
          reject(error);
        });
    });
  },
  updateBillingNotesForOrg({}, payload) {
    var fullUrl = `${environmentConfig.apiBaseUrl}billingTelemetry/updateBillingNotes`;
    return new Promise((resolve, reject) => {
      axios
        .post(fullUrl, payload)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(error => {
          console.log('BackOfficeStore updateBillingNotesForOrg() caught error', error);
          reject(error);
        });
    });
  },
  updateCommentsForBillingTransaction({}, payload) {
    var fullUrl = `${environmentConfig.apiBaseUrl}billingTelemetry/updateBillingComments`;
    return new Promise((resolve, reject) => {
      axios
        .post(fullUrl, payload)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(error => {
          console.log('BackOfficeStore updateBillingComments() caught error', error);
          reject(error);
        });
    });
  },
  updateBillingDetailsForOrg({}, payload) {
    var fullUrl = `${environmentConfig.apiBaseUrl}billingTelemetry/updateBillingDetails`;
    return new Promise((resolve, reject) => {
      axios
        .post(fullUrl, payload)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(error => {
          console.log('BackOfficeStore updateBillingDetails() caught error', error);
          reject(error);
        });
    });
  },
};

/* "computed properties for stores" - calculate data based on store state  */
const getters = {
  filteredBillingPlans:
    state =>
    ({ serviceTypes, networkIds }) => {
      var results = [];
      var finalResults = [];
      results = state.billingPrices.filter(plan => {
        if (serviceTypes.indexOf(plan.serviceTypeCode) != -1) return plan;
      });
      finalResults = results.filter(result => {
        if (networkIds.indexOf(result.networkId) != -1) {
          return result;
        }
      });
      // console.log('total 22', finalResults);
      return finalResults;
    },
};

const plugins = [];

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  plugins,
};
