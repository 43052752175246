<template>
  <div class="pg-component billing-commissions-wrapper">
    <div class="">
      <div class="large-header">
        <h1>Carthe Commissions Report</h1>
        <img class="logo" src="/static/images/pglogo.png" alt="Pacific Gyre logo" style="width: 10%; height: 10%" />
      </div>
      <div class="sub-header">
        <h2 v-if="endDate != null">For {{ endDate }}</h2>
        <h2 v-else>The web page was reloaded. Click <a @click="$router.go(-1)">here</a> to return to the previous page.</h2>
      </div>
    </div>
    <div class="main-content">
      <table v-show="endDate != null" class="commissions-table">
        <thead>
          <tr>
            <th>Organization</th>
            <th>Devices</th>
            <th>Activation</th>
            <th>Activation Cost</th>
            <th>Monthly Fee</th>
            <th>Monthly Cost</th>
            <th>Gross Profit</th>
          </tr>
        </thead>
        <tbody class="table-with-summaries">
          <tr id="row-template">
            <td>{orgName}</td>
            <td>{deviceName}</td>
            <td class="currency">${activation}</td>
            <td class="currency">${activationCost}</td>
            <td class="currency">${monthlyFee}</td>
            <td class="currency">${monthlyCost}</td>
            <td class="currency">${grossProfit}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import * as pgHelpers from '@/utils/PgHelpers.js';

  var myDateFormat = 'MM/DD/YYYY';

  export default {
    name: 'BillingCommissions',
    mounted() {
      console.log(this.endDate);
    },
    data() {
      return {
        endDate: null,

        activationTotal: 0,
        activationCostTotal: 0,
        monthlyFeeTotal: 0,
        monthlyCostTotal: 0,
        grossProfitTotal: 0,
        numDevices: 0,
        groupNum: 0,
        currentOrgName: '',
      };
    },
    methods: {
      prepData(commissionData) {
        var rows = commissionData;
        var detailRows = '';
        console.log('commission', commissionData);
        if (commissionData[0] != undefined) {
          this.endDate = pgHelpers.dateTimeHelper.formatDate(commissionData[0].periodEnding, myDateFormat);
        }

        for (const row in rows) {
          var rowData = rows[row];
          var summaryRow = '';
          var detailRow = '';

          if (rowData['orgName'] != this.currentOrgName) {
            if (this.currentOrgName != '') {
              summaryRow = this.makeSummaryRow();
              $('tbody').append(summaryRow).append(detailRows);
              detailRows = '';
            }
            this.activationTotal = 0;
            this.activationCostTotal = 0;
            this.monthlyFeeTotal = 0;
            this.monthlyCostTotal = 0;
            this.grossProfitTotal = 0;
            this.numDevices = 0;
            this.currentOrgName = rowData['orgName'];
          }

          this.numDevices++;
          var detailRow = $('<div>')
            .append($('#row-template').clone().addClass('detail display-none').attr('group', this.groupNum).removeAttr('id'))
            .html();

          for (var key in rowData) {
            var token = '{' + key + '}';
            if (key === 'orgName') {
              detailRow = detailRow.replace(token, '');
            } else if (key === 'activation' || key === 'activationCost' || key === 'monthlyFee' || key === 'monthlyCost' || key === 'grossProfit') {
              detailRow = detailRow.replace(token, rowData[key].toFixed(2));
            } else {
              detailRow = detailRow.replace(token, rowData[key]);
            }
          }

          this.activationTotal += rowData['activation'] * 1;
          this.activationCostTotal += rowData['activationCost'] * 1;
          this.monthlyFeeTotal += rowData['monthlyFee'] * 1;
          this.monthlyCostTotal += rowData['monthlyCost'] * 1;
          this.grossProfitTotal += rowData['grossProfit'] * 1;
          detailRows += detailRow;
        } // end for

        summaryRow = this.makeSummaryRow();
        $('tbody').append(summaryRow).append(detailRows);
        $('.summary').on('click', function (el) {
          var group = this.attributes.group.value;
          $('.detail[group=' + group + ']').toggleClass('display-none');
        });
      },

      makeSummaryRow() {
        var summaryRow = '';
        summaryRow = $('<div>')
          .append(
            $('#row-template')
              .clone()
              .attr('title', 'Click row to see details')
              .addClass('summary')
              .attr('group', this.groupNum++)
              .removeAttr('id'),
          )
          .html();
        summaryRow = summaryRow.replace('{orgName}', this.currentOrgName);
        summaryRow = summaryRow.replace('{deviceName}', this.numDevices + ' devices');
        summaryRow = summaryRow.replace('{activation}', this.activationTotal.toFixed(2));
        summaryRow = summaryRow.replace('{activationCost}', this.activationCostTotal.toFixed(2));
        summaryRow = summaryRow.replace('{monthlyFee}', this.monthlyFeeTotal.toFixed(2));
        summaryRow = summaryRow.replace('{monthlyCost}', this.monthlyCostTotal.toFixed(2));
        summaryRow = summaryRow.replace('{grossProfit}', this.grossProfitTotal.toFixed(2));
        return summaryRow;
      },
    },
    props: ['allCommissionData'],
    watch: {
      allCommissionData: function (value) {
        this.prepData(value);
      },
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #row-template {
    display: none;
  }

  .billing-commissions-wrapper {
    margin: 5em auto;
    font-size: 14px;
  }

  .large-header,
  .sub-header {
    display: flex;
    height: 5em;
    align-items: center;
    justify-content: center;
  }
  .sub-header {
    height: 3em;
  }

  .large-header h1 {
    font-size: 26px;
    margin: 0;
  }
  img.logo {
    position: absolute;
    right: 22em;
    top: 1em;
  }
  .sub-header {
    align-items: center;
    justify-content: center;
  }

  table {
    font-family: 'Trebuchet MS', Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    margin: 0 auto;
  }
  tr {
    display: table-row;
  }
  td,
  th {
    /* border: 1px solid; */
    padding: 8px;
  }

  td.currency {
    text-align: right;
  }

  th {
    padding-top: 14px;
    padding-bottom: 14px;
    text-align: left;
  }
  #row-template {
    display: none;
  }
  .display-none {
    display: none;
  }
  .summary {
    cursor: pointer;
  }
</style>
