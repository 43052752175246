<template>
  <div class="pg-image-viewer  pg-component">
    <link rel="stylesheet" href="/static/libs/viewerjs/viewer.css">

    <!-- Content -->
    <div class="container flex-container">
      <!--
      <button type="button" class="button" v-on:click="showImageViewer()">Image Viewer</button>
      -->
      <div >
        <div >
          <div class="docs-gallery">
            <ul class="docs-pictures flex-container ">
              <li v-for="(image, index) in images" :key="image.fileName">
                <div class="image-container">
                  <div class="image">
                    <img class="thumbnail"
                      v-bind:src="baseUrl + image.imagePath + image.fileName"
                      v-bind:alt="image.deviceName + ' ' + image.deviceDateTime + ' ' + image.latitude + ', ' + image.longitude"
                      v-bind:geo="image.latitude + ', ' + image.longitude"
                      v-on:click="showImageViewer();"
                      />
                  </div>
                  <div class="device-info">
                    <div class="device-name">
                      {{ image.deviceName }}
                    </div>
                    <div>
                      Location: {{ image.latlong }}
                    </div>
                    <div>
                      UTC: {{ image.deviceDateTime }}
                    </div>
                    <div>
                      <a v-bind:href="baseUrl + image.imagePath + image.fileName" target="_blank">{{ image.fileName }}</a>
                    </div>
                </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="viewer-container viewer-backdrop viewer-fixed viewer-fade
    viewer-transition viewer-hide" touch-action="none"
      style="z-index: 2015;"></div>

  </div>
</template>

/*************************************************************/

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { EventBus } from '@/EventBus.js';
import * as pgHelpers from "@/utils/PgHelpers.js";

var data = function() {
  return {
    baseUrl: "https://www.pacificgyre.com",
    viewer: null,
  };
};

var created = function() {
};

var mounted = function() {
  EventBus.$on('SHOW_IMAGE_VIEWER', payload => {
    //this.$snotify.error(msg);
    // console.log('  !!! we caught the bus !!! ');
    this.showImageViewer();
  });
};

var methods = {
  loadImageViewer() {
    var Viewer = window.Viewer;
    var pictures = document.querySelector(".docs-pictures");
    var imageViewerOptions = {
      interval: 250,
      transition: false,
      hidden: function(){ this.viewer.destroy();},
      title: [1, (image, imageData) => `${image.alt}`],
      //addToolbar: {title:'help', tooltip: 'Help', click="this.toggleClass(this.querySelector('#viewer-help'), 'viewer-hide')"},

    };
    this.viewer = new Viewer(pictures, imageViewerOptions);
  },
  showImageViewer() {
    this.loadImageViewer();
    this.viewer.show(true);
    $('.viewer-container .viewer-close').insertAfter('viewer-canvas img');
  },
};

var computed = {
  ...mapState("imageModule", ["images"]),
};

var watch = { };

var components = {};

export default {
  name: "ImageViewer",
  data,
  created,
  mounted,
  methods,
  computed,
  watch,
  components
};
</script>

/*************************************************************/

<style lang="scss">
.viewer-button.viewer-close {
  border-radius: 0;
  top: 0;
  right: 0;
  width: 50px;
  height: 40px;
  display: flex;
  justify-content: center; /* align horizontal */
  font-size: 1.7em;
  align-items: center;
}
.viewer-button.viewer-close::before {
  background-image: none;
}
.viewer-title {
  padding: 10px;
  font-size: 2em;
  border-radius: 10px;
}
.viewer-container .viewer-toolbar  li:before {
  // control buttons at bottom
  font-size: inherit;
  line-height: inherit;
}
#viewer-help {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
#viewer-help td {
  padding-right: 10px;
}

</style>

<style scoped>

.docs-gallery {
  padding-left: 1em;
}

.docs-pictures {
  margin: -10px 0 0 0;
  padding: 0;
  list-style: none;
}

.docs-pictures > li {
  margin: 10px;
}

.docs-pictures .image img {
  width: 25em;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}
.docs-pictures .device-info {
  padding: .25em;
}

.thumbnail {
  float: left;
}

.image-container {
  display: flex;
  flex-direction: column;
}

</style>
