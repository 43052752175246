<template>
  <div class="privacy-policy-page pg-component">
    <privacy-policy />
  </div>
</template>

/*************************************************************/

<script>
  // import { mapGetters, mapState, mapActions } from 'vuex';
  import PrivacyPolicy from '@/components/Common/PrivacyPolicy';

  var data = function() {
    return { msg: '' };
  };

  var beforeMount = function() {};

  var mounted = function() {};

  var methods = {
    // access these actions from MainStore
    // e.g. setDeviceName('Bob') is the same as
    // this.$store.dispatch('setDeviceName', 'Bob')
    //...mapActions('storeName', ['action1','action2', ]),
  };

  var computed = {
    // access these getters from MainStore (which gets them from store Modules)
    //...mapState('storeName', ['getterName1', 'getterName2']),
  };

  var watch= {  };

  var components = {
    PrivacyPolicy,
  };

  export default {
    name: 'MyComponent',
    data,
    beforeMount,
    mounted,
    methods,
    computed,
    watch,
    components
  };

</script>

/*************************************************************/

<style scoped>
</style>
