<!-- template for the modal component -->
<template id="user-delete-confirm-modal">
  <pg-modal :show="show" @close="close">
    <div class="modal-header">
      <h3>Please confirm the following action:</h3>
    </div>
    <div class="modal-body">
      <span v-if="userName !== undefined">
        Delete {{ userName}} (Id: {{ userId }})
      </span>
    </div>
    <div class="modal-footer">
      <button class="modal-default-button" @click="confirm()">
          Confirm
      </button>
      <button class="modal-default-button" @click="close()">
          Cancel
      </button>
    </div>
  </pg-modal>
</template>

<script>
import PgModal from "@/components/Common/PgModal";

export default {
  name: "UserDeleteConfirmModal",
  props: ["show", "userName", "userId"],
  components: { PgModal },
  data: function() {
    return { };
  },
  methods: {
    close: function() {
      this.$emit("close");
    },
    confirm: function() {
      this.$emit('confirmed')
      this.close();
    }
  }
};
</script>


/*************************************************************/

<style scoped>
</style>