<template>
  <div class="sensors-page" v-if="isPgStaff">
    <div class="page-content">
      <div class="content-header">
        <div class="pg-component-toolbar-left">
          <div class="form-group" :class="{ disabled: inEditMode }">
            <pg-select
              id="sensorId"
              :options="sensors"
              :value="selectedSensor"
              label="sensorName"
              track-by="sensorId"
              @input="onDropdownInput"
              :placeholder="placeholder"
              class="sensor-select"
            >
            </pg-select>
          </div>
        </div>
        <div class="pg-component-toolbar-right">
          <!-- <span class="pg-component-toolbar-item">
						<span @click="runScript" title="forTesting" class="far fa-calculator"></span>
					</span> -->
          <div class="header-icons" v-if="!inEditMode">
            <span class="pg-component-toolbar-item">
              <span @click="create" title="Create new sensor package" class="far fa-plus-square fa-lg"></span>
            </span>
            <span class="pg-component-toolbar-item" :class="{ disabled: disableCopyEditBtns }">
              <span @click="edit" title="Edit existing package" class="far fa-edit fa-lg"></span>
            </span>
            <span class="pg-component-toolbar-item" :class="{ disabled: disableCopyEditBtns }">
              <span @click="copy" title="Create new package from existing" class="far fa-copy fa-lg"></span>
            </span>
          </div>
          <div class="header-icons" v-else>
            <span class="pg-component-toolbar-item" :class="{ disabled: !enableSubmitBtn }">
              <span @click="save" title="Submit" class="far fa-check-circle fa-lg"></span>
            </span>
            <span class="pg-component-toolbar-item">
              <span @click="cancel" title="Cancel" id="cancelBtnSensorPage" class="far fa-times-circle fa-lg"></span>
            </span>
          </div>
        </div>
      </div>
      <div class="details-container">
        <sensor-detail-form
          :updatedBytes="updatedBytes"
          :userAction="userAction"
          :sensorDetails="sensorDetails"
          :notify="notify"
          :sensorDetailsChanged="sensorDetailsChanged"
          @onNewSensorIdRequest="onNewSensorIdRequest"
          @onDetailsPatchRequest="onDetailsPatchRequest"
          @onDetailsPutRequest="onDetailsPutRequest"
          @userClickingOnDisabledPage="userClickingOnDisabledPage"
        />
      </div>
      <div class="grid-container">
        <sensor-grid
          :sensorData="sensorData"
          :userAction="userAction"
          :updatedDataItem="updatedDataItem"
          @onUpdatedBytes="onUpdatedBytes"
          @onNewDataRequest="onNewDataRequest"
          @onDataPutRequest="onDataPutRequest"
          @onDataDeleteRequest="onDataDeleteRequest"
          @onRemoveDataFromCancelledOp="onRemoveDataFromCancelledOp"
          @userClickingOnDisabledPage="userClickingOnDisabledPage"
        />
      </div>
    </div>
  </div>
</template>

/*************************************************************/

<script>
  import { mapGetters, mapState, mapActions } from 'vuex';
  import SensorGrid from '@/components/SensorGrid';
  import SensorDetailForm from '@/components/SensorDetailForm';
  import PgSelect from '@/components/Common/PgSelect';

  export default {
    name: 'SensorsPage',
    data() {
      return {
        updatedBytes: null,
        currentId: null,
        inEditMode: false,
        disableCopyEditBtns: true,
        enableSubmitBtn: false,
        sensorDropdownDisabled: false,
        userAction: { action: 'view', id: null },
        placeholder: 'Select sensor to view, edit or copy',
        sensors: [],
        selectedSensor: null,
        sensorDetails: null,
        sensorData: null,
        sensorDetailsClone: null,
        sensorDataClone: null,
        updatedDataItem: null,
        addedDataItems: [],
        deletedDataItems: [],
        modifiedDataItems: [],
        notify: {
          userNotification: '',
          showNotification: false,
        },
        sensorDetailsChanged: false,
      };
    },

    methods: {
      runScript() {
        // var sensor = this.sensors.filter(el => el.sensorId === 109);
        // this.onDropdownInput(sensor[0]);
        // this.edit();
      },

      onUpdatedBytes(value) {
        this.updatedBytes = Object.assign({}, value);
      },

      onNewSensorIdRequest(name) {
        var obj = {
          sensorId: 0,
          sensorName: name,
        };
        this.createNewSensorPackage(obj).then(response => {
          this.sensorDetails = response;
          this.currentId = response.sensorId;
          this.userAction = { action: 'create', sensorId: response.sensorId };
          this.sensorDetailsChanged = true;
        });
      },

      onDetailsPatchRequest(details) {
        this.updateSensorPackage(details).then(response => {
          this.sensorDetails = response;
          this.enableSubmitBtn = true;
          this.sensorDetailsChanged = true;
        });
      },

      onDetailsPutRequest(details) {
        console.log('should be emiting ');
        this.updateAllSensorDetails(details).then(response => {
          this.sensorDetails = response;
          this.sensorDetailsChanged = false;
        });
      },

      onNewDataRequest(payload) {
        this.createSensorData(payload).then(response => {
          this.updatedDataItem = {
            type: 'new',
            response: response.data,
          };
          this.addedDataItems.push(response.data.sensorDataId);
          this.enableSubmitBtn = true;
        });
      },

      onDataPutRequest(payload) {
        this.updateSensorData(payload).then(response => {
          this.updatedDataItem = {
            type: 'update',
            response: response.data,
          };
          this.modifiedDataItems.push(response.data.sensorDataId);
          this.enableSubmitBtn = true;
        });
      },

      onDataDeleteRequest(dataId) {
        this.deleteSensorData(dataId).then(response => {
          if (response.status === 204) {
            this.updatedDataItem = {
              type: 'delete',
              response: { status: response.status, sensorDataId: dataId },
            };
            this.deletedDataItems.push(dataId);
          }
        });
      },

      onRemoveDataFromCancelledOp(sensorDataIdArray) {
        var numItems = sensorDataIdArray.length;
        var count = 0;
        sensorDataIdArray.forEach(dataId => {
          this.deleteSensorData(dataId).then(response => (response === 204 ? count++ : count));
          // what if there's an error?
        });
      },

      userClickingOnDisabledPage(component) {
        this.setNotificationMessage(component);
      },

      create() {
        this.userAction = { action: 'create', sensorId: null };
        this.currentId = 0;
        this.sensorDetail = null;
        this.sensorData = null;
        this.selectedSensor = null;
        this.inEditMode = true;
        this.setNotificationMessage('clearit');
      },

      edit() {
        this.currentId = this.selectedSensor.sensorId;
        this.cloneData();
        this.userAction = { action: 'edit', sensorId: this.currentId };
        this.inEditMode = true;
        this.setNotificationMessage('clearit');
      },

      copy() {
        var oldId = this.selectedSensor.sensorId;
        this.getCopyOfSensorPackage(oldId).then(response => {
          this.currentId = response.sensorId;
          this.sensorDetails = Object.assign({}, response);
          this.inEditMode = true;
          this.setNotificationMessage('clearit');
          this.getSelectedSensorData(this.currentId).then(response => {
            this.sensorData = response;
            this.cloneData();
            this.userAction = { action: 'copy', id: this.currentId };
          });
        });
      },

      save() {
        // editing completed
        this.currentId = this.sensorDetails.sensorId;
        this.userAction = { action: 'save', id: this.currentId };
        this.inEditMode = false;
        this.disableCopyEditBtns = false;
        this.enableSubmitBtn = false;
        this.getSensors();
        this.getSelectedSensorData(this.currentId).then(response => {
          this.sensorData = response;
        });
        this.clearTrackingArrays();
        // this.sensorDetailsChanged = false;
      },

      cloneData() {
        this.sensorDetailsClone = _.cloneDeep(this.sensorDetails);
        this.sensorDataClone = _.cloneDeep(this.sensorData);
      },

      cancel() {
        var changes = {
          additions: this.addedDataItems,
          modifications: _.uniq(this.modifiedDataItems),
          deletions: this.deletedDataItems,
        };
        var id = this.sensorDetails === null ? null : this.sensorDetails.sensorId; // newly created
        var lastAction = this.userAction.action;
        this.userAction = { action: 'cancel', id: id };
        this.enableSubmitBtn = false;
        this.inEditMode = false;
        this.disableCopyEditBtns = lastAction !== 'edit' ? true : false;
        this.findSensorInDropdown();
        if (id !== null) {
          this.handleSensorDataOnCancel(changes, id);
        }
        // this.sensorDetailsChanged = false;
      },

      onDropdownInput(value) {
        if (value === 'null' || value.sensorName === '') {
          this.userAction = { action: null, id: null };
          return;
        }
        this.disableCopyEditBtns = false;
        this.selectedSensor = Object.assign({}, value);
        this.sensorDetails = Object.assign({}, value);
        this.userAction = { action: 'view', id: this.sensorDetails.sensorId };
        this.getSelectedSensorData(this.selectedSensor.sensorId).then(response => {
          this.sensorData = response;
        });
      },

      handleSensorDataOnCancel(changes, id) {
        var requestNewData = false;
        var additions = changes.additions;
        var modifications = changes.modifications;
        var deletions = changes.deletions;
        var addCount = additions.length;
        var modCount = modifications.length;
        var delCount = deletions.length;
        var sensorId = id;
        requestNewData = addCount + delCount + modCount === 0;

        if (additions.length > 0) {
          // delete them
          for (var x = 0; x < additions.length; x++) {
            this.deleteSensorData(additions[x]).then(response => {
              --addCount;
              requestNewData = addCount + delCount + modCount === 0;
              if (requestNewData) {
                this.getFreshSensorData(sensorId);
              }
            });
          }
        }
        if (deletions.length > 0) {
          // add em back
          for (var x = 0; x < deletions.length; x++) {
            var addback = this.sensorDataClone.filter(el => el.sensorDataId === deletions[x]);
            addback[0].sensorDataId = 0;
            this.createSensorData(addback[0]).then(response => {
              --delCount;
              requestNewData = addCount + delCount + modCount === 0;
              if (requestNewData) {
                this.getFreshSensorData(sensorId);
              }
            });
          }
        }
        if (modifications.length > 0) {
          // change em back
          for (var x = 0; x < modifications.length; x++) {
            var changeback = this.sensorDataClone.filter(el => el.sensorDataId === modifications[x]);
            this.updateSensorData(changeback[0]).then(response => {
              --modCount;
              requestNewData = addCount + delCount + modCount === 0;
              if (requestNewData) {
                this.getFreshSensorData(sensorId);
              }
            });
          }
        }
        this.clearTrackingArrays();
        this.sensorDataClone = [];
      },

      setNotificationMessage(component) {
        var show = true;
        if (component === 'grid') {
          if (this.sensorData !== null) {
            var msg = `This grid is currently disabled.  If you'd like to edit the data, please click the <i class="far fa-edit"></i> above.`;
          }
        } else if (component === 'form') {
          if (this.sensorDetails !== null) {
            var msg = `This form is currently disabled.  If you'd like to edit it, please click the <i class="far fa-edit"></i> above.`;
          } else {
            var msg = `If you'd like to add a new sensor, please click the <i class='far fa-plus-square'></i>  above.`;
          }
        } else {
          var msg = '';
          show = false;
        }
        this.notify.userNotification = msg;
        this.notify.showNotification = show;
      },

      getFreshSensorData(sensorId) {
        this.getSelectedSensorData(sensorId).then(response => {
          this.sensorData = response;
        });
      },

      clearTrackingArrays() {
        this.addedDataItems = [];
        this.modifiedDataItems = [];
        this.deletedDataItems = [];
      },

      // on save or edit cancel
      findSensorInDropdown() {
        if (this.sensorDetails === null) {
          return;
        }
        if (this.currentId !== null || this.currentId !== 0) {
          this.selectedSensor = this.sensors.find(sensor => sensor.sensorId === this.currentId);
        } else {
          this.selectedSensor = null;
        }
      },

      ...mapActions('sensorModule', [
        'getSensors',
        'getSelectedSensorData',
        'getDataFields',
        'getUnits',
        'getDataTypes',
        'createSensorData',
        'updateSensorData',
        'deleteSensorData',
        'createNewSensorPackage',
        'updateSensorPackage',
        'getCopyOfSensorPackage',
        'updateAllSensorDetails',
      ]),
    },

    computed: {
      ...mapGetters('sensorModule', ['sensorsGetter']),
      ...mapGetters('userModule', ['userHasRole']),
      isPgStaff: function() {
        return this.userHasRole('PGStaff');
      },
    },

    mounted() {
      this.getSensors();
      this.sensorDetails = null;
      this.sensorData = null;
      this.updatedDataItem = null;
      this.modifiedDataItems, this.addedDataItems, (this.deletedDataItems = []);
    },

    components: {
      SensorDetailForm,
      SensorGrid,
      PgSelect,
    },
    watch: {
      sensorsGetter: function(value) {
        this.sensors = [];
        this.sensors = value;
        this.findSensorInDropdown();
      },
    },
  };
</script>

/*************************************************************/

<style lang="scss">
  .sensors-page {
    .page-content {
      width: 100%;
      display: flex;
    }
    .content-header {
      display: flex;
      flex-direction: row;
      height: 40px;
    }
    .header-icons {
      display: flex;
    }
    .sensor-select {
      width: 415px;
    }
    .form-group.disabled .multiselect__tags,
    .form-group.disabled .multiselect__select {
      opacity: 0.3;
    }
  }
</style>
