import Vue from 'vue';

export default Vue.component('pg-select-tooltip', {
    render: function (createElement) {
        return createElement('span',
            {
            attrs: {
                title: '',
                label: '',
            }
        }
    )
    },
    props: {

    }
})