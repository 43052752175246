import Vue from 'vue';
import PgSelect from '@/components/Common/PgSelect';

export default Vue.extend({
  // h/t stackoverflow.com/questions/3656467/is-it-possible-to-focus-on-a-div-using-javascript-focus-function Thanks Fenton!
  template: `<div v-if="sensorDataTypes != undefined" ref="dataTypeWrapper" id="dataTypeWrapper" tabindex=-1>
                <pg-select
                  ref="sensorDataType"
                  id="sensorDataType"
                  :options='sensorDataTypes'
                  :value='selectedSensorDataType'
                  label='sensorDataTypeName'
                  track-by='index'
                  v-on:input="onDropdownInput"
                  v-on:close="onDropdownClose"
									:close-on-select="closeOnSelect"
                >
                </pg-select>
            </div>`,
  data() {
    return {
      sensorDataTypes: [],
      selectedSensorDataType: {},
      closeOnSelect: null,
      ref: null,
    };
  },

  beforeMount() {
    this.sensorDataTypes = this.params.sensorDataTypes;
    var name = this.params.node.data.sensorDataName;
    var id = this.params.node.data.sensorDataTypeId;
    var temp = this.sensorDataTypes.filter(el => el.sensorDataTypeId === id && el.sensorDataTypeName === name);
    this.selectedSensorDataType = temp[0];
  },

  beforeDestroy() {
    var y = document.getElementById('sensorDataType');
    if (y === document.activeElement) {
      document.getElementById('sensorDataType').blur();
    }
    // this.ref.$parent.$el.focus();
  },

  mounted() {
    var width = this.params.colDef.width;
    this.closeOnSelect = true;
    this.ref = this.$refs.sensorDataType;
    this.$nextTick(() => {
      var parent = this.$refs.dataTypeWrapper.parentNode;
      parent.style.width = width + 'px';
    });
  },

  methods: {
    onDropdownInput(value) {
      this.ref.$parent.$el.focus();

      this.$nextTick(() => {
        if (value === null) {
          return;
        }
        this.selectedSensorDataType = value;
        this.selectedSensorDataType.sensorDataTypeName = value.sensorDataTypeName;
        this.params.node.setDataValue('sensorDataTypeId', value.sensorDataTypeId);
        this.params.node.setDataValue('sensorDataName', value.sensorDataTypeName);
        this.params.node.setDataValue('dataFieldId', value.defaultDataFieldId);
        this.params.node.setDataValue('unitId', value.unitId);
        this.params.stopEditing();
      });
    },

    addTag(newTag) {
      console.log(newTag);
    },

    onDropdownClose(value, id) {},

    isPopup() {
      return true;
    },

    getValue() {
      if (this.selectedSensorDataType != null) {
        var selected = this.selectedSensorDataType.sensorDataTypeName;
        return selected;
      }
    },
  },
  components: {
    PgSelect,
  },
});
