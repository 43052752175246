/*

USE:
import * as pgHelpers from "@/utils/PgHelpers.js";
let formattedPosition = pgHelpers.latLongDisplayHelper.formatLatLong(33.000, -117.123);
optional format: 'D' / 'DM' / 'DMS'


*/

// this should come from user preferences
let myDefaultCoordinateFormat = 'D';

const latLongDisplayHelper = {
  convertDegree(dd, format, lng) {
    var absdeg = Math.abs(dd);
    var degree = Math.floor(absdeg);
    var minfloat = (absdeg - degree) * 60;
    var min = Math.floor(minfloat);
    var secfloat = (minfloat - min) * 60;
    var sec = Math.round(secfloat);
    var dir = dd < 0 ? lng ? 'W' : 'S': lng ? 'E':'N';
    var formattedDegree = '';
    format = format.toLowerCase();

    if (format === 'dms') {
      // degrees, minutes, seconds
      min = min < 10 ? '0' + min : min;
      sec = sec < 10 ? '0' + sec : sec;
      formattedDegree =  degree + '\xB0' + min + "'" + sec + "\x22" + dir;
    }
    else if (format === 'dm') {
      min = min < 10 ? '0' + minfloat.toFixed(4) : minfloat.toFixed(4);
      formattedDegree = degree + '\xB0' + min + "'" + dir;
    }
    else /* format === 'd' */ {
      formattedDegree = dd + "\xB0";
    }
    return formattedDegree;
  },

  formatLatLong(lat, long, format) {
    format = format || myDefaultCoordinateFormat;
    var Lat = this.convertDegree(lat, format, false);
    var Long = this.convertDegree(long, format, true);
    return Lat + ', ' + Long;
  },

  dmsFormatToText(dmsFormat) {
    var text=dmsFormat.split('').join('/').replace('s', 'seconds').replace('m','minutes').replace('d', 'degrees');
    return text;
  },

};

export { latLongDisplayHelper };
