<template>
  <div>
    <div v-if="$pgGlobal.hasMouse">
      We've got mice!
    </div>
    <div v-else>
      No mice on this device.
    </div>
    <web-log-grid />
    <div class="icon-container">
        icon samples: <br />
        <span v-for="icon in icons">
            {{ icon }}: <i class="far" v-bind:class="'fa-' + icon"></i>
        </span>
    </div>
    <a href="https://fontawesome.com/icons?d=gallery" target="_blank">All Icons</a>
  </div>
</template>

/*************************************************************/

<script>

  import WebLogGrid from '@/components/WebLogGrid'

  // import { mapGetters, mapState, mapActions } from "vuex";

  $(document).ready(function(){
    // console.log('$doc.ready()');
  });

  var data = function() {
    return {
      // isTouchDevice: false,
      icons: [
        'archive', 'atom', 'barcode', 'bolt', 'book', 'book-open', 'books',
        'broadcast-tower', 'bullseye', 'calculator', 'camera', 'chart-area', 'chart-bar',
        'clipboard', 'clipboard-list', 'dollar-sign', 'exclamation', 'file', 'file-alt',
        'folder-open', 'function', 'github-alt', 'globe', 'heart-rate', 'image', 'images',
        'info', 'info-circle', 'question-circle',
        'life-ring', 'list', 'luchador', 'map', 'monkey', 'oil-temp', 'pencil', 'search',
        'satellite', 'satellite-dish',
        'server', 'shipping-fast',  'star', 'tachometer',
        'university', 'user', 'user-secret', 'users', 'wind',
      ],
    };
  };

  var beforeMount = function() {
    //console.log('isTouch=', this.$pgGlobal.isTouchDevice);
    // isTouchDevice = this.$pgGlobal.isTouchDevice;
    // if (this.$pgGlobal.isTouchDevice) {
    //   console.log('This is a touch device');
    // } else {
    //   console.log('This is NOT a touch device. ', PgGlobals.isTouchDevice);
    // }
  };

  var mounted = function() {
    //   console.log('mounted');
  };

  var methods = {
    // access these actions from MainStore
    // e.g. setDeviceName('Bob') is the same as
    // this.$store.dispatch('setDeviceName', 'Bob')
    //...mapActions("storeName", ["action1","action2", ]),
  };

  var computed = {
    // access these getters from MainStore (which gets them from store Modules)
    //...mapState("storeName", ["getterName1", "getterName2"]),
  };

  var watch= {  };

  var components = {
    WebLogGrid
  };

  export default {
    name: "DevToolsPage",
    data,
    beforeMount,
    mounted,
    methods,
    computed,
    watch,
    components: components
  };

</script>

/*************************************************************/
<style scoped lang="scss">
</style>

<style scoped>
  i {
    padding-right: 10px;
  }
  .icon-container {
    padding: 10px;
    font-size: 2em;
  }
</style>
