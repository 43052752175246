<template>
  <div class="reconcile-charges-component  pg-component">
    <h2>SPOT/Trace Reconciliation</h2>

    <div class="upper-wrapper">
      <!-- <div class="wrap-container date-range-container"> -->
      <div class="flatpickr">
        <span class="from-to">From</span>
        <input type="text" id="start-date" placeholder="Select starting date..." class="start-date datetime" data-input autocomplete="off" />
        <a class="input-button" title="toggle" data-toggle v-on:click="toggleCalendar(startCalendar)">
          <i class="far fa-calendar"></i>
        </a>
        <a class="input-button" title="clear" data-clear v-on:click="startCalendar.clear();">
          <i class="far fa-times-circle"></i>
        </a>
      </div>
      <div class="flatpickr">
        <span class="from-to">To</span>
        <input type="text" id="end-date" placeholder="Select ending date..." class="end-date datetime" data-input autocomplete="off" />
        <a class="input-button" title="toggle" data-toggle v-on:click="toggleCalendar(endCalendar);">
          <i class="far fa-calendar"></i>
        </a>
        <a class="input-button" title="clear" data-clear v-on:click="endCalendar.clear();">
          <i class="far fa-times-circle"></i>
        </a>
      </div>
      <!-- </div> -->
      <div class="info-span">
        <span v-show="showCount">Number of devices activated during this period: {{count}}</span>
      </div>
    </div>

    <div class="grid-wrapper">
      <ag-grid-vue
        id="activations-grid"
        style="width: 100%;"
        class="ag-theme-balham grid-holder"
        :gridOptions="gridOptions"
        :columnDefs="columnDefs"
        :modules="agGridModules"
      >
      </ag-grid-vue>

      <!-- <date-range-filter @onDateRangeSet="onDateRangeSet" v-model="filterValues"></date-range-filter> -->
    </div>
  </div>
</template>

/*************************************************************/

<script>
  import { mapGetters, mapState, mapActions } from 'vuex';
  import DateRangeFilter from '@/components/DateRangeFilter';
  import { AgGridVue } from '@ag-grid-community/vue';
  import { AllCommunityModules } from '@ag-grid-community/all-modules';
  import moment from 'moment';

  var thisComponent;

  var data = function() {
    return {
      startCalendar: '',
      endCalendar: '',
      value: {
        startDate: '',
        endDate: '',
      },
      dateObject: {
        startDateTime: null,
        endDateTime: null,
        billingPriceId: 0,
        action: '',
      },
      activations: [],
      columnDefs: null,
      rowData: null,
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      agGridModules: AllCommunityModules,
      showCount: false,
      count: null,
    };
  };

  var beforeMount = function() {
    thisComponent = this;
    this.gridOptions = {
      headerHeight: 25,
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      onGridReady: this.onGridReady,
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        editable: false,
      },
      suppressDragLeaveHidesColumns: true,
      enableCellTextSelection: true,
      ensureDomOrder: true,
    };
    this.columnDefs = [
      {
        headerName: 'Device Name',
        field: 'deviceName',
        width: 140,
      },
      {
        headerName: 'Comm Id',
        field: 'commIdentifier',
        width: 100,
      },
      {
        headerName: 'Date Enterered Utc',
        field: 'dateEnteredUtc',
        width: 190,
      },
      {
        headerName: 'Action Date Utc',
        field: 'actionDateUtc',
        width: 190,
      },
      {
        headerName: 'Activated',
        field: 'activated',
        width: 100,
      },

      {
        headerName: 'Destination',
        field: 'destinations',
        width: 100,
      },
      {
        headerName: 'GeoDate',
        field: 'geoData',
        width: 100,
      },
      {
        headerName: 'Org Name',
        field: 'orgName',
        width: 270,
      },
      {
        headerName: 'PG Plan Name',
        field: 'pgPlanName',
        width: 120,
      },
      {
        headerName: 'Processed By',
        field: 'processedBy',
        width: 120,
      },
      {
        headerName: 'Comments',
        field: 'comments',
        width: 150,
      },
    ];
  };

  var mounted = function() {
    this.dateObject.billingPriceId = 19;
    this.dateObject.action = 'activate';
    this.count = null;
    this.showCount = false;
    var today = new Date();
    flatpickrOptions.defaultDate = this.value.startDate;
    // flatpickrOptions.defaultDate = '2020-01-01';

    this.startCalendar = flatpickr('#start-date', flatpickrOptions);
    flatpickrOptions.defaultDate = today;
    this.endCalendar = flatpickr('#end-date', flatpickrOptions);
  };

  var methods = {
    toggleCalendar: function(calendar) {
      var wasOpen = calendar.isOpen;
      setTimeout(function() {
        if (wasOpen) {
          calendar.close();
        } else {
          calendar.open();
        }
      }, 0);
    },

    requestData(dates) {
      this.dateObject.startDateTime = dates.startDate;
      this.dateObject.endDateTime = dates.endDate;
      this.getDeviceActivations(this.dateObject).then(response => {
        this.prepDataForGrid(response);
      });
    },

    prepDataForGrid(data) {
      var activations = data.filter(el => el.status === 'Completed');
      console.log(data.length, activations.length)
      activations.forEach(el => {
        el.dateEnteredUtc = this.updateDateFormat(el.dateEnteredUtc);
        el.actionDateUtc = this.updateDateFormat(el.actionDateUtc);
      });
      activations.sort(this.sortByDate);
      this.count = activations.length > 0 ? activations.length : 'none';
      this.showCount = true;
      this.gridApi.setRowData(activations);
      this.gridOptions.rowData = activations;
    },
    updateDateFormat(el) {
      var str = el.replace('T', ' ').substr(0, 16);
      return str;
    },
    sortByDate(a, b) {
      var a = a.actionDateUtc;
      var b = b.actionDateUtc;

      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },

    ...mapActions('deviceModule', ['getDeviceActivations']),
  };

  var computed = {};

  var watch = {};

  var components = {
    AgGridVue,
  };

  var saveDates = function(instance, dateStr) {
    thisComponent.value.startDate = $('#start-date').val();
    thisComponent.value.endDate = $('#end-date').val();
    if (thisComponent.value.startDate.length > 0) {
      thisComponent.requestData(thisComponent.value);
    }
  };

  // https://flatpickr.js.org/options/
  var flatpickrOptions = {
    allowInput: true,
    enableTime: false,
    onChange: saveDates,
    onClose: saveDates,
    plugins: [
      new confirmDatePlugin({
        showAlways: true,
      }),
    ],
  };

  export default {
    name: 'ReconcileCharges',
    data,
    beforeMount,
    mounted,
    methods,
    computed,
    watch,
    components,
  };
</script>

/*************************************************************/

<style>
  .pg-app,
  .reconcile-charges-component {
    height: 100%;
  }
  .reconcile-charges-component .grid-wrapper,
  #activations-grid {
    height: 90%;
  }
  .upper-wrapper {
    display: flex;
    flex-direction: row;
  }
  .upper-wrapper div {
    padding: 10px;
  }
  .upper-wrapper .flatpickr span,
  .upper-wrapper .flatpickr a {
    padding: 5px;
  }
</style>
