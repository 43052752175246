<template>
  <div class="images-page page">
    <div class="page-content">
      <div class="panel-container-horizontal">
        <div class="panel-left" v-bind:class="{'collapsed':leftPanelCollapsed}">
          <div class="accordion">
            <div class="accordion-panel">
              <input type="radio" name="accordion-button" id="accordion-button-1" checked="checked" />
              <label class="accordion-label section-divider" for="accordion-button-1">
                Step 1: Enter Time Range
                <i class="far fa-chevron-down pg-click-to-open"></i>
                <i class="far fa-chevron-up   pg-click-to-close"></i>
              </label>
              <div class="accordion-content">
                <date-range-filter v-model="dateFilterValues"></date-range-filter>
                <!-- <div class="radio-wrapper"> -->
                <div class="row lat-long-row">
                  <span>Coordinate Format</span>
                  <input type="radio" name="latlong" id="degree" value="d" v-model="selectedCoordFormat" @change="formatCoordinates" /><label
                    for="deg"
                    >D</label
                  >
                  <input type="radio" name="latlong" id="deg-min" value="dm" v-model="selectedCoordFormat" @change="formatCoordinates" /><label
                    for="deg-min"
                    >DM</label
                  >
                  <input type="radio" name="latlong" id="deg-min-sec" value="dms" v-model="selectedCoordFormat" @change="formatCoordinates" /><label
                    for="deg-min-sec"
                    >DMS</label
                  >
                </div>
                <!-- </div> -->
              </div>
            </div>

            <div class="accordion-panel">
              <input type="radio" name="accordion-button" id="accordion-button-2" />
              <label class="accordion-label section-divider" for="accordion-button-2">
                Step 2: Select Sort Order
                <i class="far fa-chevron-down pg-click-to-open"></i>
                <i class="far fa-chevron-up   pg-click-to-close"></i>
              </label>
              <div class="accordion-content">
                <div id="image-sort-order" class="input-section">
                  <div class="order-selector">
                    <label> <input type="radio" id="desc" value="desc" v-model="sort" /> Newest First </label>

                    <label> <input type="radio" id="asc" value="asc" v-model="sort" /> Oldest First </label>
                  </div>
                  <label class="input-row">
                    Maximum Number of Images:
                    <input type="number" id="num-images" value="20" class="number" v-model="maxImages" />
                  </label>
                </div>
              </div>
            </div>

            <div class="accordion-panel">
              <input type="radio" name="accordion-button" id="accordion-button-3" />
              <label class="accordion-label section-divider" for="accordion-button-3">
                Step 3: Select Devices
                <i class="far fa-chevron-down pg-click-to-open"></i>
                <i class="far fa-chevron-up   pg-click-to-close"></i>
              </label>
              <div class="accordion-content">
                <div class="device-list-grid-container grid-container">
                  <device-list-grid
                    show-columns="deviceName, xmitText"
                    :orgDevices="orgDevices"
                    :org-id="selectedOrganization.orgId"
                    :imageOnly="true"
                  />
                </div>
                <div v-show="devicesHaveCameras.length > 0 && selectedDeviceIds.length > 0">
                  <div class="notification" v-if="noImages">
                    No images available for the current selections
                  </div>
                </div>
                <div class="notification" v-show="devicesHaveCameras.length === 0">
                  No devices equipped with cameras
                </div>
              </div>
            </div>
            <!--button type="button" class="button" v-on:click="downloadZip();">Download selected images in a zip file</button-->
            <button v-show="numImages>0" type="button" class="button padded-center" v-on:click="showImageViewer()">Show Image Viewer</button>
          </div>
          <div class="handle v-handle">
            <i class="fas fa-caret-left" v-bind:class="{'fa-rotate-180':leftPanelCollapsed}" v-on:click="togglePanel('.panel-left', $event)"></i>
          </div>
        </div>

        <div class="panel-right">
          <div class="image-viewer-component-holder">
            <image-viewer class="image-viewer-box" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

/*********************************************************************/

<script>
  import { mapGetters, mapState, mapActions } from 'vuex';
  import ImageViewer from '@/components/ImageViewer';
  import PgSelect from '@/components/Common/PgSelect';
  import DeviceListGrid from '@/components/DeviceListGrid';
  import DateRangeFilter from '@/components/DateRangeFilter';
  import { EventBus } from '@/EventBus.js';
  import * as pgHelpers from '@/utils/PgHelpers.js';
  import ProfileService from '@/services/ProfileService';
  import environmentSpecific from 'static/environmentSpecific';

  var thisComponent;

  var data = function() {
    return {
      dateFilterValues: DateRangeFilter.pgDateRangeParameters,
      sort: 'desc',
      maxImages: 100,
      leftPanelCollapsed: false,
      noImages: false,
      latlong: '',
      selectedCoordFormat: '',
      showCoordButtons: false,
      orgDevices: [],
    };
  };

  var props = {};

  var methods = {
    // ...mapActions("organizationModule", [ ]),
    // ...mapActions("userModule", ["getCurrentUser"]),
    ...mapActions('imageModule', ['getImages']),
    ...mapActions('deviceModule', ['getDevices']),

     showImageViewer() {
      EventBus.$emit('SHOW_IMAGE_VIEWER');
    },
    getDevicesForOrg() {
      var orgId = this.selectedOrganization.orgId;
      var params = {
        getCommands: false,
        getBillingPlans: false,
        getSensorPackages: false,
      };
      orgId === -1 ? params.takeRecords = environmentSpecific.maxNumberOfDevices : params.orgId = orgId;
      
      this.getDevices(params).then(response => {
        this.orgDevices = response;
      });
    },
    getImageData() {
      var orgId = this.selectedOrganization.orgId;
      var startDate = null;
      var endDate = null;
      var timeRange = null;
      var skipRecords = 0;
      var deviceIds = this.selectedDeviceIds ? this.selectedDeviceIds.join(',') : '';
      if (this.dateFilterValues.rangeType === 'TimeSpan') {
        timeRange = this.dateFilterValues.numUnits + this.dateFilterValues.timeUnit; // e.g. 30minutes
      } else {
        if (this.dateFilterValues.startDate) {
          startDate = this.dateFilterValues.startDate;
        }
        if (this.dateFilterValues.endDate) {
          endDate = this.dateFilterValues.endDate;
        }
      }
      this.getImages({
        orgId,
        deviceIds,
        startDate,
        endDate,
        timeRange,
        sortOrder: this.sort,
        skipRecords,
        takeRecords: this.maxImages,
      }).then(response => {
        this.noImages = response.data.data.length < 1;
        this.formatCoordinates();
      });
    },
    formatCoordinates() {
      this.updateCoordFormat(this.selectedCoordFormat);
      ProfileService.saveLocalSetting('coordinateFormat', this.selectedCoordFormat);
    },
    downloadZip() {
      alert('Please implement download function :)');
    },

    // resize panels
    togglePanel(selector, evt) {
      if (selector === '.panel-left') {
        this.leftPanelCollapsed = !this.leftPanelCollapsed;
        // remove width set by resizer
        $('.panel-left').css('width', '');
      } else if (selector === '.panel-bottom') {
        this.bottomPanelCollapsed = !this.bottomPanelCollapsed;
        var newHeight = this.bottomPanelCollapsed ? '100%' : '50%';
        $('.panel-top').css('height', newHeight);
      }
    },
    onResizeEnd(collapsePanel, event) {
      if (collapsePanel === '.panel-left') {
        var width = $(collapsePanel).width() - $('.v-handle').width();
        this.leftPanelCollapsed = width <= 3;
      } else if (collapsePanel === '.panel-bottom') {
        var height = $(collapsePanel).height();
        this.bottomPanelCollapsed = height <= 3;
      }
    },
  };

  var computed = {
    ...mapState('organizationModule', ['selectedOrganization']),
    ...mapGetters('userModule', ['userHasRole']), //'userHasClaim',
    ...mapGetters('imageModule', ['numImages', 'updateCoordFormat']),
    ...mapGetters('deviceModule', ['devicesHaveCameras']),
    ...mapState('deviceModule', ['selectedDeviceIds']),
    ...mapState('imageModule', ['images']),
    isPgStaff: function() {
      return this.userHasRole('PGStaff');
    },
  };

  var created = function() {
    thisComponent = this;
    this.selectedCoordFormat = ProfileService.getLocalSetting('coordinateFormat', 'd');
  };

  var mounted = function() {
    this.getDevicesForOrg();
    this.dateFilterValues.rangeType = 'DateRange';
    $('.panel-left').panelresizable({
      handleSelector: '.v-handle',
      resizeWidth: true,
      resizeHeight: false,
      resizeWidthFrom: 'right',
      onDragEnd: function(evt) {
        thisComponent.onResizeEnd('.panel-left', evt);
      },
    });
  };

  var watch = {
    selectedOrganization: function(value) {
      this.getDevicesForOrg();
    },
    selectedDeviceIds: function() {
      this.getImageData();
    },
    dateFilterValues: {
      handler(val) {
        this.getImageData();
      },
      deep: true, // required to watch objects
    },
    sort: function() {
      this.getImageData();
    },
    maxImages: function() {
      this.getImageData();
    },
  };

  var components = {
    ImageViewer,
    PgSelect,
    DeviceListGrid,
    DateRangeFilter,
  };

  export default {
    name: 'ImagesPage',
    data,
    props,
    methods,
    computed,
    created,
    mounted,
    watch,
    components,
  };
</script>

/*********************************************************************/
<style lang="scss">
  .images-page {
    #num-images {
      width: 7em;
    }
    .input-row {
      padding-left: 5px;
    }

    /* device list grid */
    .device-list-grid {
      width: 100%;
      display: flex;
      flex-direction: row;
      min-height: 70vh; // horrible hack to fix grid height in accordion
    }
    .device-list-grid-body {
      // height: 100%;
      display: flex;
      flex-direction: column;
    }
    .device-list-grid-container {
      flex: 1 1 auto;
      display: flex;
    }
    .input-section {
      flex: 0 0 auto;
    }

    /* date range */
    .date-range-filter {
      flex: 0 0 auto;
      padding: 1em 1em 0 1em;
      overflow: auto; /* hidden */
    }

    .panel-container-horizontal {
      flex: 1 1 auto;
    }
    .panel-left {
      width: 300px;
      min-width: 16px;
      max-width: 100%;
    }
    .panel-contents {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    .panel-left.collapsed {
      padding: 0;
      width: 17px;
    }
    .lat-long-row {
      margin: 0 auto;
      padding: 5px 5px 10px 5px;
      line-height: 18px;
      width: 100%;
      text-align: center;
    }
    .lat-long-row input[type='radio'] {
      display: inline;
      vertical-align: text-bottom;
    }
    .padded-center {
      width: fit-content;
      align-self: center;
      margin-top: 10px;
      cursor: pointer;
    }
  }
</style>
