<template>
  <div class="ad-hoc-report-page">
    <billing-report-header
      :selectedOrg="selectedOrg"
      :remitToOrg="remitToOrg"
      :startDate="startDate"
      :endDate="endDate"
      :showAdHocNotes="showAdHocNotes"
    ></billing-report-header>
    <billing-report-ad-hoc-overview
      :selectedOrg="selectedOrg"
      :startDate="startDate"
      :endDate="endDate"
      :billingOverview="billingOverview"
      :showActivationInOverview="showActivationInOverview"
      :showAdHocNotes="showAdHocNotes"
    ></billing-report-ad-hoc-overview>
    <billing-report-ad-hoc-details
      :activationDetail="activationDetail"
      :monthlyDetail="monthlyDetail"
      :suspendedDetail="suspendedDetail"
      :iridiumWithDataDetail="iridiumWithDataDetail"
      :iridiumWithoutDataDetail="iridiumWithoutDataDetail"
      :rudicsDetail="rudicsDetail"
      :rudicsTelemetryOnlyDetail="rudicsTelemetryOnlyDetail"
      :globalstarDetail="globalstarDetail"
      :processingOnlyDetail="processingOnlyDetail"
      :smsDetail="smsDetail"
      :unusedDevices="unusedDevices"
    ></billing-report-ad-hoc-details>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import * as pgHelpers from '@/utils/PgHelpers.js';
  import ProfileService from '@/services/ProfileService';
  import BillingReportHeader from '@/components/BillingReportHeader';
  import BillingReportAdHocOverview from '@/components/BillingReportAdHocOverview';
  import BillingReportAdHocDetails from '@/components/BillingReportAdHocDetails';

  var myDateFormat = 'MM/DD/YY';

  export default {
    name: 'AdHocReportPage',
    data() {
      return {
        startDate: null,
        endDate: null,
        selectedOrg: {},
        remitToOrg: {},
        billingOverview: {
          startingBalance: 0,
          currentCharges: 0,
          adjustmentsTotal: 0,
          billingAdjustments: [
            {
              amount: 0,
              asOfDate: null,
              billingGroupId: 0,
              description: '',
              orgId: 0,
            },
          ],
          totalDue: 0,
          activations: 0,
          monthly: 0,
          suspended: 0,
          iridiumWithData: 0,
          iridiumWithoutData: 0,
          rudics: 0,
          rudicsTelemetryOnly: 0,
          processingOnly: 0,
          globalstar: 0,
          sms: 0,
        },
        activationDetail: [],
        activationTotal: null,
        monthlyDetail: [],
        monthlyTotal: null,
        suspendedDetail: [],
        suspendedTotal: null,
        iridiumWithDataDetail: [],
        iridiumWithDataTotal: null,
        iridiumWithoutDataDetail: [],
        iridiumWithoutDataTotal: null,
        rudicsDetail: [],
        rudicsTotal: null,
        rudicsTelemetryOnlyDetail: [],
        rudicsTelemetryOnlyTotal: null,
        globalstarDetail: [],
        globalstarTotal: null,
        processingOnlyDetail: [],
        processingOnlyTotal: null,
        smsDetail: [],
        smsTotal: null,

        unusedDevices: null,

        currentChargesCount: 0,

        showActivationInOverview: false,
        showAdHocNotes: true,
      };
    },
    components: {
      BillingReportHeader,
      BillingReportAdHocOverview,
      BillingReportAdHocDetails,
    },
    computed: {},
    methods: {
      ...mapActions('organizationModule', ['getOrganizationById']),
      ...mapActions('backOfficeModule', [
        'getBillingAdjustmentsForOrgAndId',
        'getPreviousBalanceForAdHocReport',
        'getDeviceActivationsForAdHocReport',
        'getMonthlyFeesForAdHocReport',
        'getSuspendedFeesForAdHocReport',
        'getIridiumWithDataFeesForAdHocReport',
        'getIridiumWithoutDataFeesForAdHocReport',
        'getRudicsFeesForAdHocReport',
        'getRudicsTelemetryOnlyFeesForAdHocReport',
        'getSmsFeesForAdHocReport',
        'getGlobalstarFeesForAdHocReport',
        'getProcessingOnlyFeesForAdHocReport',
      ]),
      ...mapActions('deviceModule', ['getDevicesActiveButNotTransmitting']),

      getDates() {
        var currentDate = new Date();
        var currQtr = pgHelpers.dateTimeHelper.currentQtrDates(currentDate);
        var endDate = pgHelpers.dateTimeHelper.formatDate(currentDate.toString(), 'M/D/YYYY');
        this.startDate = currQtr.startDate;
        this.endDate = endDate;
      },
      getSelectedOrg() {
        this.selectedOrg = ProfileService.getLocalSetting('selectedOrg');
      },
      getRemitToOrg() {
        this.getOrganizationById(1).then(response => {
          this.remitToOrg = response;
        });
      },
      getPageData() {
        if (this.selectedOrg.hasOwnProperty('orgId') && this.selectedOrg.orgId > -1) {
          var sc = {
            orgId: this.selectedOrg.orgId,
            billingGroupId: this.selectedOrg.billingGroupId,
            startDate: this.startDate,
            endDate: this.endDate,
          };

          this.getPreviousBalanceForAdHocReport(sc).then(response => {
            this.billingOverview.startingBalance = response.endingBalance;
          });

          this.getDeviceActivationsForAdHocReport(sc).then(response => {
            this.activationDetail = response;
            this.activationTotal = this.getActivationTotal(response);
            this.billingOverview.activations = this.activationTotal;
            this.billingOverview.currentCharges += this.activationTotal;
            this.currentChargesCount++;
            this.getCurrentChargesTotal();
            // show activations if no fee is charged?
            this.showActivationInOverview = this.billingOverview.activations > 0 || this.activationDetail.length > 0 ? true : false;
          });

          this.getMonthlyFeesForAdHocReport(sc).then(response => {
            this.monthlyDetail = response;
            this.monthlyTotal = this.getMonthlyFeeTotal(response);
            this.billingOverview.monthly = this.monthlyTotal;
            this.billingOverview.currentCharges += this.monthlyTotal;
            this.currentChargesCount++;
            this.getCurrentChargesTotal();
          });

          this.getSuspendedFeesForAdHocReport(sc).then(response => {
            this.suspendedDetail = response;
            this.suspendedTotal = this.getSuspendedFeeTotal(response);
            this.billingOverview.suspended = this.suspendedTotal;
            this.billingOverview.currentCharges += this.suspendedTotal;
            this.currentChargesCount++;
            this.getCurrentChargesTotal();
          });

          this.getIridiumWithDataFeesForAdHocReport(sc).then(response => {
            this.iridiumWithDataDetail = response;
            this.iridiumWithDataTotal = this.getIridiumWithDataFeeTotal(response);
            this.billingOverview.iridiumWithData = this.iridiumWithDataTotal;
            this.billingOverview.currentCharges += this.iridiumWithDataTotal;
            this.currentChargesCount++;
            this.getCurrentChargesTotal();
          });

          this.getIridiumWithoutDataFeesForAdHocReport(sc).then(response => {
            // iridium without data/iridium telemetry only
            this.iridiumWithoutDataDetail = response;
            this.iridiumWithoutDataTotal = this.getIridiumWithoutDataTotal(response);
            this.billingOverview.iridiumWithoutData = this.iridiumWithoutDataTotal;
            this.billingOverview.currentCharges += this.iridiumWithoutDataTotal;
            this.currentChargesCount++;
            this.getCurrentChargesTotal();
          });

          this.getRudicsFeesForAdHocReport(sc).then(response => {
            this.rudicsDetail = response;
            this.rudicsTotal = this.getRudicsTotal(response);
            this.billingOverview.rudics = this.rudicsTotal;
            this.billingOverview.currentCharges += this.rudicsTotal;
            this.currentChargesCount++;
            this.getCurrentChargesTotal();
          });

          this.getRudicsTelemetryOnlyFeesForAdHocReport(sc).then(response => {
            this.rudicsTelemetryOnlyDetail = response;
            this.rudicsTelemetryOnlyTotal = this.getRudicsTelemetryOnlyTotal(response);
            this.billingOverview.rudicsTelemetryOnly = this.rudicsTelemetryOnlyTotal;
            this.billingOverview.currentCharges += this.rudicsTelemetryOnlyTotal;
            this.currentChargesCount++;
            this.getCurrentChargesTotal();
          });

          this.getSmsFeesForAdHocReport(sc).then(response => {
            // fix sms -- make sure grabbing the correct total
            this.smsDetail = response;
            this.smsTotal = this.getSmsTotal(response);
            this.billingOverview.sms = this.smsTotal;
            this.billingOverview.currentCharges += this.smsTotal;
            this.currentChargesCount++;
            this.getCurrentChargesTotal();
          });

          this.getGlobalstarFeesForAdHocReport(sc).then(response => {
            this.globalstarDetail = response;
            this.globalstarTotal = this.getGlobalstarTotal(response);
            this.billingOverview.globalstar = this.globalstarTotal;
            this.billingOverview.currentCharges += this.globalstarTotal;
            this.currentChargesCount++;
            this.getCurrentChargesTotal();
          });

          this.getProcessingOnlyFeesForAdHocReport(sc).then(response => {
            this.processingOnlyDetail = response;
            this.processingOnlyTotal = this.getProcessinglyOnlyTotal(response);
            this.billingOverview.processingOnly = this.processingOnlyTotal;
            this.billingOverview.currentCharges += this.processingOnlyTotal;
            this.currentChargesCount++;
            this.getCurrentChargesTotal();
          });

          this.getBillingAdjustmentsForOrgAndId(sc).then(response => {
            for (var x = 0; x < response.length; x++) {
              if (response[x].asOfDate != null) {
                this.billingOverview.billingAdjustments[x].asOfDate = response[x].asOfDate;
                this.billingOverview.billingAdjustments[x].amount = response[x].amount;
                this.billingOverview.billingAdjustments[x].billingGroupId = response[x].billingGroupId;
                this.billingOverview.billingAdjustments[x].description = response[x].description;
                this.billingOverview.billingAdjustments[x].orgId = response[x].orgId;
                this.billingOverview.adjustmentsTotal += response[x].amount;
              }
            }
            if (this.billingOverview.billingAdjustments.length > 0) {
              this.billingOverview.billingAdjustments.forEach(el => {
                if (el.asOfDate != null) {
                  var year = el.asOfDate.substring(0, 4);
                  if (el.asOfDate.substring(5, 6) == '0') {
                    var month = el.asOfDate.substring(6, 7);
                  } else {
                    var month = el.asOfDate.substring(5, 7);
                  }
                  if (el.asOfDate.substring(8, 9) == '0') {
                    var date = el.asOfDate.substring(9, 10);
                  } else {
                    var date = el.asOfDate.substring(8, 10);
                  }
                  var formattedDate = month + '/' + date + '/' + year;
                  el.asOfDate = formattedDate;
                }
              });
            }

            this.sortAdjustments(this.billingOverview.billingAdjustments);
          });

          this.getDevicesActiveButNotTransmitting(sc).then(response => {
            console.log('money saving tips from ad hoc report', response);
            this.unusedDevices = response;
          });
        }
      },
      getActivationTotal(data) {
        var activationTotal = 0;
        for (var x = 0; x < data.length; x++) {
          activationTotal += data[x].activationFee;
        }
        return activationTotal;
      },
      getMonthlyFeeTotal(data) {
        var monthlyTotal = 0;
        for (var x = 0; x < data.length; x++) {
          monthlyTotal += data[x].monthlyFee;
        }
        return monthlyTotal;
      },
      getSuspendedFeeTotal(data) {
        var suspendedTotal = 0;
        for (var x = 0; x < data.length; x++) {
          suspendedTotal += data[x].monthlySuspendedFee;
        }
        return suspendedTotal;
      },
      getIridiumWithDataFeeTotal(data) {
        var iridiumWithDataTotal = 0;
        for (var x = 0; x < data.length; x++) {
          iridiumWithDataTotal += data[x].total;
        }
        return iridiumWithDataTotal;
      },
      getIridiumWithoutDataTotal(data) {
        var iridiumWithoutDataTotal = 0;
        for (var x = 0; x < data.length; x++) {
          iridiumWithoutDataTotal += data[x].addlBytesCharge;
        }
        return iridiumWithoutDataTotal;
      },
      getRudicsTotal(data) {
        var rudicsFeesTotal = 0;
        for (var x = 0; x < data.length; x++) {
          rudicsFeesTotal += data[x].total;
        }
        return rudicsFeesTotal;
      },
      getRudicsTelemetryOnlyTotal(data) {
        var rudicsTelemetryOnlyTotal = 0;
        for (var x = 0; x < data.length; x++) {
          rudicsTelemetryOnlyTotal += data[x].total;
        }
        return rudicsTelemetryOnlyTotal;
      },
      getSmsTotal(data) {
        var smsTotal = 0;
        for (var x = 0; x < data.length; x++) {
          smsTotal += data[x].total;
        }
        return smsTotal;
      },
      getGlobalstarTotal(data) {
        var globalstarTotal = 0;
        for (var x = 0; x < data.length; x++) {
          globalstarTotal += data[x].total;
        }
        return globalstarTotal;
      },
      getProcessinglyOnlyTotal(data) {
        var processingOnlyTotal = 0;
        for (var x = 0; x < data.length; x++) {
          processingOnlyTotal += data[x].total;
        }
        return processingOnlyTotal;
      },
      calculateAdjustments(sortedAdj) {
        var adjustmentsTotal = 0;
        for (var x = 0; x < sortedAdj.length; x++) {
          adjustmentsTotal += sortedAdj[x].amount;
          sortedAdj[x].amount = '$' + sortedAdj[x].amount.toFixed(2);
        }
        this.paymentTotal = adjustmentsTotal;
      },
      sortAdjustments(adjustments) {
        if (adjustments.length > 1) {
          adjustments.sort((a, b) => {
            if (a.asOfDate < b.asOfDate) {
              return -1;
            }
            if (a.asOfDate > b.asOfDate) {
              return 1;
            }
            return 0;
          });
        }
      },
      getCurrentChargesTotal() {
        if (this.currentChargesCount === 10) {
          this.billingOverview.totalDue =
            this.billingOverview.startingBalance + this.billingOverview.currentCharges + this.billingOverview.adjustmentsTotal;
          // add bools to show components
        }
      },
    },

    created() {
      this.getDates();
      this.getSelectedOrg();
      this.getRemitToOrg();
      this.getPageData();
    },

    beforeMount() {},

    mounted() {},
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .ad-hoc-report-page {
    margin: 0 auto;
    width: 750px;
    position: relative;
    font-size: 1em;
    font-family: Arial, Helvetica, sans-serif;
    padding-bottom: 30px;
  }
</style>
