<template>
  <div class="organization-page" >
    <div class="page-content" v-bind:class="{'user-is-selected':isUserSelected}">
      <div class="section no-grow">
        <organization-details-form @onEditingOrg="onEditingOrg" :isEditingUser="isEditingUser" />
      </div>

      <div class="section grow wrap-em">

        <div class="section grow users-list-section">
          <users-list-grid
            :isEditing="isEditing"
            :needFreshUserData="needFreshUserData"
            :userCanceledDelete="userCanceledDelete"
            @onAddUserClicked="onAddUserClicked"
            @onDeleteUserClicked="onDeleteUserClicked"
            @onUserSelection="onUserSelection"
          />
        </div>
        <div class="section no-grow">
          <user-details-form
            ref="userDetails"
            :isEditingOrg="isEditingOrg"
            :expandUserDetails="expandUserDetails"
            v-show="showUserDetails"
            class="pg-detail-component"
            @onEditingUser="onEditingUser"
            @onSuccessfulUserUpdate="onSuccessfulUserUpdate"
            @onUserCanceledDelete="onUserCanceledDelete"
          />
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import PgSelect from "@/components/Common/PgSelect";
import UsersListGrid from "@/components/UsersListGrid";
import UserDetailsForm from "@/components/UserDetailsForm";
import OrganizationDetailsForm from '@/components/OrganizationDetailsForm';

export default {

  name: "OrganizationPage",
  data() {
    return {
      isEditing: false,
      isEditingOrg: false,
      isEditingUser: false,
      needFreshUserData: {},
      isUserSelected: false,
      expandUserDetails: true,
      showUserDetails: false,
      userCanceledDelete: false,
      expandUserDetails: true,
    };
  },

  computed: {
    // ...mapGetters('userModule', ['userIsSelected', ]),
  },

  methods: {
    onEditingOrg(value) {
      this.isEditing = value;
      this.isEditingOrg = value;
    },
    onEditingUser(value) {
      this.isEditing = value;
      this.isEditingUser = value;
    },
    onSuccessfulUserUpdate(data) {
      // if data is null - user has been deleted
      this.needFreshUserData = data;
    },
    onUserSelection(isUserSelected) {
      this.showUserDetails = isUserSelected;
      this.isUserSelected = isUserSelected;
    },
    onAddUserClicked(value) {
      this.$refs.userDetails.addUser();
      this.showUserDetails = value;
    },
    onDeleteUserClicked(value) {
      this.$refs.userDetails.removeUser();
    },
    onUserCanceledDelete() {
      this.userCanceledDelete = true;
    },
    onResizeEnd() {
      var width = $('.panel-right').outerWidth();
      this.rightPanelCollapsed = (width <=3);
    },

  },

  beforeMount() { },

  mounted() {
    $(".panel-left").resizable({
      handleSelector: '.v-handle',
      resizeWidth: true,
      resizeHeight: false,
      resizeWidthFrom: 'right',
      onDragEnd: function(evt){thisComponent.onResizeEnd('.panel-right', evt)},
    });
  },

  watch: {  },

  components: {
    PgSelect,
    UserDetailsForm,
    UsersListGrid,
    OrganizationDetailsForm,
  }
};
</script>

/*************************************************************/

<style lang="scss">
  .organization-page {

    .page-content {
      flex-direction: row;
    }
    .section {
      margin: 1em;
    }
    .wrap-em {
      flex-wrap: wrap;
      margin: 0;
    }
    .section.grow {
      display: flex;
      flex: 1 1 auto;
      // without width, will grow but not shrink
      width: 10em;
    }
    .section.no-grow {
      flex: 0 0 auto;
    }
    .users-list-section {
      flex-basis: 14em;
      margin: 1em 0;
    }
    .users-list-grid {
      width: 100%;
    }
    .users-list-grid-body {
      display: flex;
      flex-direction: column;
      // border: 6px dotted darkseagreen;
      flex: 1;
    }

    // footer
    .footer {
      flex: 0 0 auto;
    }
}

</style>
