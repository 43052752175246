import Vue from 'vue';
export default Vue.extend({

    template: `<span>{{ params.data.overrideStartDate }}</span>`,
    data() {
        return {}
    },
    created() { },
    beforeMount() { },
    mounted() { },
    methods: { },
});

