<!-- template for the modal component -->
<template id="billing-group-confirm-modal">
  <pg-modal :show="show" :group="group" @close="close">
    <div class="popup-window billing-group-confirm-popup">
      <div class="popup-header">
        Confirm action
        <i class="far fa-times-square closer" v-on:click="close()" />
      </div>
      <div class="popup-content text-padding">
        <div class="">
          <span>Do you wish to delete the billing group named "{{ group.billingGroupName }}"?</span>
        </div>
      </div>

      <div class="popup-footer popup-button-wrapper">
        <button class="" @click="close()">
          Cancel
        </button>
        <button class="" @click="confirm()">
          Confirm
        </button>
      </div>
    </div>
  </pg-modal>
</template>
<script>
import PgModal from "@/components/Common/PgModal";

export default {
  name: "BillingGroupConfirmModal",
  props: ["show", "group"],
  components: { PgModal },
  data() {
    return { 
      // localGroup: this.group.map(x=> ({...x})) 
    };
  },
  mounted() {
    // this.localGroup = this.group;
    // console.log(this.localGroup)
  },
  methods: {
    close: function() {
      this.$emit("close");
    },
    confirm: function() {
      console.log(this.group)
      this.$emit('confirmed', this.group);
      this.close();
    },
  }
};
</script>

