function forceUTC(someDate) {
  var newDate = Date.UTC(someDate.getFullYear(), someDate.getMonth(), someDate.getDate());
  return newDate;
}


const agGridUtils = {
  dateOnlyCompare(filterDate, cellValue) {
    // compare only date portion, ignoring time
    //var dateAsString = cellValue;
    // console.log('dateOnlyCompare',filterDate, cellValue);
    if (cellValue == null) return 0;

    filterDate = forceUTC(filterDate);
    var cellDate = new Date(Date.parse(cellValue));
    cellDate.setUTCHours(0,0,0,0);

    // console.log('comparing',filterDate, cellDate);
    // Now that both parameters are Date objects, we can compare
    if (cellDate.valueOf() < filterDate.valueOf()) {
        return -1;
    } else if (cellDate.valueOf() > filterDate.valueOf()) {
        return 1;
    } else {
        return 0;
    }
  },

  stringDateCompare(filterDate, cellValue) {

    var dateAsString = cellValue;
    if (dateAsString == null) return 0;

    var cellDate = new Date(Date.parse(cellValue));

    // Now that both parameters are Date objects, we can compare
    if (cellDate < filterDate) {
        return -1;
    } else if (cellDate > filterDate) {
        return 1;
    } else {
        return 0;
    }
  },


  showColumnsBySize(params, gridWidth, hideColumnsBySize, limitToTheseColumns, neverShowTheseColumns) {
    // used by ag-Grid components
    // var hideColumnsBySize = [
    //   { "maxSize": 800,  hideIds: ["networkName", "xmitDate", "deviceDescription","wmoId", "billingGroupName",  ] },
    //   { "maxSize": 1024, hideIds: ["networkName", "xmitDate", "deviceDescription", ] },
    //   { "maxSize": 1200, hideIds: ["networkName", "xmitDate", ] },
    // ];
    // onGridSizeChanged(params) {
    //   var gridWidth = document.getElementById('device-list-grid-container').offsetWidth;
    //   showColumnsBySize(params, gridWidth, hideColumnsBySize, this.showColumns);
    // },
    // if limitToTheseColumns is not '', then only columns in that list will ever show
    if (gridWidth === 0) {
      return;
    }

    var columnsToHide = [];
    var allColumns = params.columnApi.getAllColumns().map(c => c.colId);
    var columnsToShow = allColumns;

    if (hideColumnsBySize !== undefined && hideColumnsBySize !== null && hideColumnsBySize.length > 0) {
      columnsToHide = hideColumnsBySize.filter( n => n.maxSize > gridWidth );
    }
    if (columnsToHide.length > 0) {
      columnsToHide = columnsToHide.reduce((prev, current) => (prev.maxSize < current.maxSize) ? prev : current, [] );
      columnsToHide = columnsToHide.hideIds;
    }
    // ['orgName', 'destinations']
    if (neverShowTheseColumns) {
        columnsToHide = _.uniq(columnsToHide.concat(neverShowTheseColumns));
    }
    if (limitToTheseColumns && limitToTheseColumns.length>0) {
      // limit list if asked to
      columnsToShow = limitToTheseColumns.split(',').map(c => c.trim());
      columnsToShow = columnsToShow.filter(c => allColumns.indexOf(c) != -1);

      var columnsNotInList = allColumns.filter(c => columnsToShow.indexOf(c) == -1);
      columnsToHide = columnsToHide.concat(columnsNotInList);
    }
    if (columnsToHide) {
      columnsToShow = columnsToShow.filter( n => columnsToHide.indexOf(n) == -1 );
    } else {
      columnsToHide = [];
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  },

};

export default agGridUtils;
