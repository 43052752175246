<template>
  <div class="activation-form pg-component pg-sidebar" v-if="isDeviceSelected && userHasClaim('Activations', 'update')">
    <div class="component-title">
      <h4>Activations</h4>
    </div>

    <div class="pg-form-wrapper">
      <form @submit.prevent="submit">
        <fieldset :disabled="isEditing">
          <div class="pg-form vertical">
            <div class="form-group vertical">
              <span class="warning" v-if="showTelemetryWarning">Changes to Telemetry-only devices must be done in separate actions</span>
            </div>
            <div class="form-group" v-if="isPgStaff && !areMismatchedDeviceBillingPlans">
              <input id="manuallyActivate" name="manuallyActivate" type="checkbox" v-model="manuallyActivate" @change="toggleManuallyActivate" />
              <label class="checkbox-label" for="manuallyActivate">Manually</label>
            </div>
            <div class="form-row">
              <div class="left-column" v-if="areMismatchedDeviceBillingPlans">
                <div class="form-group">
                  You have selected devices with different activation options. To make activation changes, please select only similar devices.
                </div>
              </div>

              <div class="left-column" v-if="!areMismatchedDeviceBillingPlans">
                <div class="form-group" :class="{ 'form-group--error': actionMissing }">
                  <pg-select
                    id="activationActions"
                    :showLabels="false"
                    :options="validActivationActions"
                    :value="selectedAction"
                    label="name"
                    @input="onDropdownInput"
                    placeholder="Select action"
                  >
                  </pg-select>
                  <label for="activationActions">Change activation to:</label>
                </div>

                <div class="form-group" :class="{ 'form-group--error': billingMissing }" v-if="canShowBillingPlan() ">
                  <pg-select
                    id="billingPrices"
                    :showLabels="false"
                    :options="availableBillingPlans"
                    :value="selectedBillingPlan"
                    label="pgPlanName"
                    @input="onDropdownInput"
                    :placeholder="billingPlaceholder"
                  >
                  </pg-select>
                  <label for="billingPrices">Using:</label>
                </div>
                <div class="form-group">
                  <pg-select
                    id="billingGroups"
                    :showLabels="false"
                    :options="billingGroups"
                    :value="selectedBillingGroup"
                    label="billingGroupName"
                    @input="onDropdownInput"
                    :placeholder="billingGroupPlaceholder"
                  >
                  </pg-select>
                  <label for="billingGroups">Update billing group:</label>
                </div>
                <!-- <div class="form-group">
                <button :disabled="isEditing || showTelemetryWarning" type="button" @click="showModal">Apply</button>
              </div> -->
              </div>
              <div class="form-group right-column">
                <table>
                  <thead>
                    <tr>
                      <th>Device</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(device, index) in devicesWithBilling" :key="index">
                      <td>{{ device.deviceName }}</td>
                      <td>{{ device.activationStatus }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="form-group" v-if="canShowTelemetryOnlyDetails()">
              <input id="includeGeoData" name="includeGeodata" type="checkbox" v-model="formData.geoData" @change="toggleIncludeGeoData" />
              <label for="includeGeoData" class="checkbox-label" title="Adds estimated latitude & longitude.  This is generally turned on."
                >Include Geo Data</label
              >
              <div>
                <!-- <span class="warning" v-if="showGeoDataWarning">Include Geo Data for all devices?</span> -->
              </div>
            </div>
            <div class="form-group" v-if="canShowTelemetryOnlyDetails()">
              <input id="ringAlert" name="ringAlert" type="checkbox" v-model="formData.ringAlert" @change="toggleRingAlert" />
              <label for="ringAlert" class="checkbox-label">Ring Alert</label>
              <div>
                <!-- <span class="warning" v-if="showRingAlertWarning">Request a Ring Alert?</span> -->
              </div>
            </div>
            <div class="form-group" v-if="canShowTelemetryOnlyDetails()">
              <div>
                <div class="center-text">
                  <p>
                    <b>Destination.</b> Enter up to 5 email addresses and/or IP address and port numbers where you would like the data sent. Any
                    existing destinations will be replaced by these.
                  </p>
                </div>

                <div class="center-text">
                  <span class="warning" v-if="showDestinationWarning"
                    >The destinations for the selected devices are not the same. All destinations for the selected devices will be replaced.</span
                  >
                </div>
                <div class="destinations">
                  <div class="form-group destination" v-for="(destination, index) in currentDestinations" :key="index">
                    <input
                      type="text"
                      class=""
                      placeholder="Ex. user@ex.com OR data.example.com:7070"
                      :class="{commonToAll : destination.commonToAll}"
                      v-model="destination.destination"
                    />
                    <span
                      v-if="currentDestinations.length > 5"
                      @click="onRemoveDestination(index)"
                      title="Remove destination"
                      class="far fa-trash-alt fa-lg"
                    ></span>
                  </div>
                </div>
                <div class="center-text">
                  <span class="warning" v-if="currentDestinations.length > 5">Only 5 addresses are supported. Please update.</span>
                </div>
                <div class="center-text">
                  <p>
                    <b>NOTE: IP addresses must be arranged with Iridium before they can be used.</b> You will also need a Direct IP compatible
                    application to accept the data. IP addresses should be in the format address:port.
                  </p>
                </div>
                <div class="center-text">
                  <p>Iridium changes scheduled for today will usually take effect within 1 hour. Others will take effect the next business day.</p>
                </div>
              </div>
            </div>
            <!-- end isTelemetryOnlyServiceType or isPgStaff -->
            <div class="form-group button-row" v-if="!areMismatchedDeviceBillingPlans">
              <button class="floating-button" :disabled="isEditing || showTelemetryWarning" type="button" @click="showModal">Apply</button>
              <button class="floating-button" :disabled="isEditing || showTelemetryWarning" type="button" @click="clearInputs">Cancel</button>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
    <!-- end pg-form  -->
    <activation-confirm-modal
      v-show="showConfirmModal"
      @close="closeModal"
      @confirmed="onUserConfirmation"
      :action="action"
      :deviceNames="deviceNames"
      :billingInfo="billingInfo"
    />
  </div>
</template>

/*************************************************************/

<script>
  import PgSelect from '@/components/Common/PgSelect';
  import ActivationConfirmModal from '@/components/ActivationConfirmModal';
  import { mapGetters, mapState, mapActions } from 'vuex';
  import pgUtils from '@/utils/PgUtils.js';

  var my;
  var data = function () {
    return {
      showConfirmModal: false,
      action: null, // for confirm modal
      deviceNames: [], // for confirm modal
      billingInfo: {}, // for confirm modal
      areMismatchedDeviceBillingPlans: false,

      isTelemetryOnlyServiceType: false,
      isProcessingOnlyServiceType: false,
      isPgStaff: false,

      showTelemetryWarning: false,
      showDestinationWarning: false,
      showGeoDataWarning: false,
      showRingAlertWarning: false,
      userMessage: '',

      haveDifferentStatus: false,
      userName: '',
      billingPrices: [], // ?
      availableBillingPlans: [],
      allDestinations: [],

      selectedAction: null, // dropdown selected
      selectedBillingPlan: null, // dropdown selected
      selectedBillingGroup: null,
      actionMissing: false,
      billingMissing: false,
      billingPlaceholder: '',
      billingGroupPlaceholder: '',

      // form inputs
      manuallyActivate: false,
      geoData: true,
      ringAlert: false,
      activationActions: [
        { action: 'Activate', name: 'Activate' },
        { action: 'Deactivate', name: 'Deactivate' },
        { action: 'Suspend', name: 'Suspend' },
        { action: 'Unsuspend', name: 'Unsuspend' },
        { action: 'Update', name: 'Update' },
      ],
      // actions valid for selected plan
      validActivationActions: [],

      currentDestinations: [{ destination: '', commonToAll: false }],

      formData: {
        deviceActivationLogId: 0,
        deviceId: 0,
        commIdentifier: '',
        networkId: 0,
        orgId: 0,
        dateEntered: null,
        activated: null,
        action: '',
        billingPriceId: 0,
        geoData: true,
        ringAlert: false,
        destinations: [],
        whoEntered: '',
        actionDate: null,
        actionDateUtc: null,
        updateDate: null,
        processedBy: '',
        status: '',
        comments: '',
      },
    };
  };

  var props = ['isEditing', 'deviceData', 'billingGroups', 'devicesWithBilling'];

  var validations = {
    formData: {
      deviceId: {},
      manuallyActivate: {},
      destinations: {},
      selectedBillingPlan: {},
      selectedAction: {},
      selectedBillingGroup: {},
    },
  };

  var beforeMount = function () {
    my = this;
    window.my = this;
  };

  var mounted = function () {
    this.isPgStaff = this.userHasRole('PGStaff');
    this.actionMissing = false;
    this.billingMissing = false;
    this.billingPlaceholder = 'Choose billing plan';
    this.billingGroupPlaceholder = 'Update billing group';
  };

  var methods = {
    canShowBillingPlan() {
      return (
        ((this.availableBillingPlans.length > 1 || this.isTelemetryOnlyServiceType || this.isProcessingOnlyServiceType) &&
          this.canShowTelemetryOnlyDetails()) ||
        this.isPgStaff
      );
    },

    canShowTelemetryOnlyDetails() {
      if (!this.isTelemetryOnlyServiceType) {
        return false;
      }
      if (this.selectedAction == null) {
        return true;
      }
      return _.includes(['Activate', 'Update'], this.selectedAction.action);
    },

    onUserConfirmation() {
      this.submitRequest();
    },

    submitRequest() {
      if (this.selectedBillingGroup !== null) {
        this.updateDeviceBillingGroup();
      }
      var devices = this.collectFormData();
      var allGood = true;
      for (let x = 0; x < devices.length; x++) {
        this.createDeviceActivationLog(devices[x])
          .then(response => {
            var text = `Request to ${response.data.data.action} (${response.data.data.commIdentifier}) is Pending`;
            this.$snotify.success(text, 'Activation');
            this.clearInputs();
          })
          .catch(error => {
            console.error('we have an issue with activations');
            allGood = false;
          })
          .finally(() => {
            if (x === devices.length - 1 && allGood) {
              this.$emit('onNewActivation');
            }
          });
      }
    },

    clearInputs() {
      this.isTelemetryOnlyServiceType = false;
      this.isProcessingOnlyServiceType = false;
      this.selectedAction = null;
      this.selectedBillingPlan = null;
      this.selectedBillingGroup = null;
      this.manuallyActivate = false;
      this.geoData = true;
      this.ringAlert = false;
      this.showTelemetryWarning = false;
      this.showDestinationWarning = false;
      this.showGeoDataWarning = false;
      this.showRingAlertWarning = false;
      this.actionMissing = false;
      this.billingMissing = false;
      this.currentDestinations = [{ destination: '', commonToAll: false }];
      for (var x = 1; x < 5; x++) {
        this.currentDestinations.push({ destination: '', commonToAll: false });
      }
      this.$v.formData.$reset();
    },

    showModal() {
      if (this.selectedBillingGroup != null && this.selectedAction == null) {
        this.updateDeviceBillingGroup();
        return;
      }
      if (this.selectedAction === null) {
        this.actionMissing = true;
        return;
      } else if (this.selectedBillingPlan === null && this.selectedAction.name != 'Deactivate') {
        this.billingMissing = true;
        return;
      } else {
        this.action = this.selectedAction.name;
        if (this.action != 'Deactivate') {
          this.billingInfo.activationFee = this.selectedBillingPlan.activationFee;
          this.billingInfo.monthlyFee = this.selectedBillingPlan.monthlyFee;
          this.billingInfo.monthlySuspendedFee = this.selectedBillingPlan.monthlySuspendedFee;
        }
        this.deviceNames = [
          ...new Set(
            this.devicesWithBilling.map(item => {
              return item.deviceName;
            }),
          ),
        ];

        if (this.action === 'Update') {
          this.billingInfo.chargeForUpdate = this.$v.formData.selectedBillingPlan.$dirty ? true : false;
        }

        this.showConfirmModal = true;
      }
    },

    closeModal() {
      // user canceled
      this.clearInputs();
      this.showConfirmModal = false;
    },

    onDropdownInput(value, id) {
      if (value === null) {
        return;
      }
      if (id === 'activationActions') {
        this.selectedAction = value;
        this.actionMissing = false;
        if (this.isPgStaff || this.telemetryOnly) {
          this.billingPlaceholder = this.selectedAction.name === 'Update' ? 'Update billing plan' : 'Choose billing plan';
        }
        if (this.selectedAction.name === 'Update' && this.billingMissing) {
          this.billingMissing = false;
        }
      } else if (id === 'billingPrices') {
        this.selectedBillingPlan = value;
        this.billingMissing = false;
        this.$v.formData.selectedBillingPlan.$touch();
        this.setAvailableActionsDropdown(this.selectedBillingPlan);
      } else if (id === 'billingGroups') {
        if (value.billingGroupId === null) {
          // 'Add group'
          this.$emit('onViewBillingGroups');
        } else {
          this.selectedBillingGroup = value;
          this.$v.formData.selectedBillingGroup.$touch();
        }
      }
    },

    collectFormData() {
      var deviceId = 0;
      var nowUtc = pgUtils.formatDate(this.moment().format(), 'ISO');
      var destinations = this.currentDestinations.map(x => x.destination);
      var destination = destinations
        .filter(x => {
          return x != '';
        })
        .join(';');

      var selectedAction = this.selectedAction.name;
      var geoData = this.geoData;
      var ringAlert = this.ringAlert;
      var manuallyActivate = this.manuallyActivate;
      var activationData = [];
      var i = 0;
      var whoEntered = this.$pgGlobal.currentUser.userName;
      var billingPriceId = 0;

      this.selectedDeviceIds.forEach(function (id) {
        var device = my.devicesWithBilling.find(el => el.deviceId === id);

        if (device.serviceTypeCode === 'full') {
          destination = 'PG_DIRECT_IP';
        }
        if (selectedAction === 'Suspend' || selectedAction === 'Unsuspend') {
          billingPriceId = device.defaultBillingPriceId;
        } else {
          billingPriceId =
            my.selectedBillingPlan === null || my.selectedBillingPlan === undefined
              ? device.defaultBillingPriceId
              : my.selectedBillingPlan.billingPriceId;
        }
        activationData[i] = {};
        activationData[i].deviceActivationLogId = 0;
        activationData[i].deviceId = device.deviceId;
        activationData[i].commIdentifier = device.comm1;
        activationData[i].networkId = device.networkId;
        activationData[i].orgId = device.orgId;
        // activationData[i].dateEntered = nowUtc;
        activationData[i].activated = device.active; // check to make sure active is working
        activationData[i].action = selectedAction;
        activationData[i].billingPriceId = billingPriceId;
        // activationData[i].billingPlan = billingPlan;
        activationData[i].geoData = geoData;
        activationData[i].ringAlert = ringAlert;
        activationData[i].destinations = destination;
        activationData[i].whoEntered = whoEntered;
        // activationData[i].actionDate = nowLocal;
        activationData[i].actionDateUtc = nowUtc;
        // activationData[i].whenCompleted = nowUtc;
        // activationData[i].updateDate = nowUtc;
        activationData[i].processedBy = manuallyActivate ? whoEntered : '';
        activationData[i].status = manuallyActivate ? 'Completed' : 'Pending';
        activationData[i].comments = manuallyActivate ? 'Manual activation' : '';
        i++;
      });

      return activationData;
    },

    updateDeviceBillingGroup() {
      var deviceIds = this.selectedDeviceIds.join();
      var billingGroupId = this.selectedBillingGroup.billingGroupId;
      this.$emit('onUpdatingDeviceBillingGroup', billingGroupId, deviceIds);
      this.selectedBillingGroup = null;
    },

    checkDeviceServiceTypes(devices) {
      var uniqueTypes = [...new Set(devices.map(d => d.serviceTypeCode))];

      if (uniqueTypes.includes('processingOnly')) {
        if (uniqueTypes.length === 1) {
          this.isProcessingOnlyServiceType = true;
        }
      }

      if (uniqueTypes.includes('telemetryOnly')) {
        if (uniqueTypes.length === 1) {
          this.isTelemetryOnlyServiceType = true;
        } else {
          this.showTelemetryWarning = true;
        }
      }
      return this.isTelemetryOnlyServiceType;
    },

    checkBillingOptionsForDevices() {
      // if >1 device selected, confirm same plan options
      var len = this.devicesWithBilling.length;
      if (len > 1) {
        var firstDevicePlanIds = _.sortBy(this.devicesWithBilling[0].billingPlans.map(plan => plan.billingPriceId));
        for (var i = 1; i < len; i++) {
          var thisDevicePlanIds = _.sortBy(this.devicesWithBilling[i].billingPlans.map(plan => plan.billingPriceId));
          if (!_.isEqual(firstDevicePlanIds, thisDevicePlanIds)) {
            this.areMismatchedDeviceBillingPlans = true;
            this.availableBillingPlans = [];
            return;
          }
        }
      }
      this.areMismatchedDeviceBillingPlans = false;
      this.availableBillingPlans = this.devicesWithBilling[0].billingPlans;
    },

    getDefaultBillingForDevices() {
      var ids = _.uniqBy(this.devicesWithBilling.map(device => device.defaultBillingPriceId));
      var plan = null;
      if (ids.length === 1 && !_.isNull(ids[0])) {
        plan = _.find(this.availableBillingPlans, function (bp) {
          return bp.billingPriceId === ids[0];
        });
      }

      if (plan == undefined) {
        // defaultBillingPriceId not found in availablePlans (ex deviceIds 4229 and 4230)
        plan = null;
      }
      this.setAvailableActionsDropdown(plan);
      this.setBillingDropdown(plan);
    },

    setAvailableActionsDropdown(plan) {
      if (plan === null || (this.selectedAction != null && !plan.availableActions.includes(this.selectedAction.action))) {
        this.selectedAction = null;
      }
      var bp = plan;
      var actions = '';
      var actionArray = [];
      if (bp === null) {
        actions = 'Activate;Deactivate';
      } else {
        actions = bp.availableActions;
      }
      actionArray = actions.split(';');
      this.validActivationActions = _.clone(this.activationActions);
      this.validActivationActions = this.activationActions.filter(a => actions.includes(a.action));
    },

    setBillingDropdown(plan) {
      this.selectedBillingPlan = plan;
    },

    convertDestinationsToArray() {
      if (this.isTelemetryOnlyServiceType) {
        this.devicesWithBilling.forEach(act => {
          if (act.destinations !== null && act.destinations !== undefined) {
            var temp = act.destinations.split(';').filter(n => n);
            act.destinationArray = temp;
          } else {
            act.destinations = '';
            act.destinationArray = [];
          }
        });
        this.processDestinations(this.devicesWithBilling);
      }
    },

    processDestinations(arr) {
      var allDests = '';
      var allDestsA = [];
      var uniqDest = [];
      var commonToAll = true;
      var common = [];
      arr.forEach(device => {
        allDests += device.destinations + ';';
      });
      allDestsA = allDests.split(';').sort();
      uniqDest = _.uniq(allDestsA, true);
      for (var x = 0; x < uniqDest.length; x++) {
        var re = new RegExp(uniqDest[x], 'g');
        var res = allDests.match(re);
        commonToAll = res.length === arr.length ? true : false;
        common.push({ destination: uniqDest[x], commonToAll: commonToAll });
        if (!commonToAll && uniqDest[x] != '') {
          this.showDestinationWarning = true;
        }
      }
      this.currentDestinations = common.filter(c => c.destination != '');
      this.currentDestinations.sort(function (a, b) {
        if (a.commonToAll > b.commonToAll) {
          return -1;
        }
        if (a.commonToAll < b.commonToAll) {
          return 1;
        }
        if (a.commonToAll == b.commonToAll) {
          if (a.destination < b.destination) {
            return -1;
          }
          if (a.destination > b.destination) {
            return 1;
          }
          return 0;
        }
      });
      if (this.currentDestinations.length < 5) {
        for (var x = this.currentDestinations.length; x < 5; x++) {
          this.currentDestinations.push({ destination: '', commonToAll: false });
        }
      }
    },

    onRemoveDestination(index) {
      if (this.currentDestinations.length > 5) {
        this.currentDestinations.splice(index, 1);
      } else {
        this.currentDestinations[index].destination = '';
        this.currentDestinations[index].commonToAll = false;
      }
    },

    toggleManuallyActivate(event) {
      this.manuallyActivate = event.target.checked;
    },

    toggleIncludeGeoData(event) {
      this.geoData = event.target.checked;
    },

    toggleRingAlert(event) {
      this.ringAlert = event.target.checked;
    },

    ...mapActions('deviceModule', ['createDeviceActivationLog']),
  };

  var computed = {
    ...mapState('organizationModule', ['selectedOrganization']),
    ...mapState('deviceModule', ['selectedDeviceIds']),
    ...mapGetters('userModule', ['userHasClaim', 'userHasRole']),

    isDeviceSelected() {
      return this.selectedDeviceIds.length > 0;
    },
  };

  var watch = {
    devicesWithBilling: function (devices) {
      this.clearInputs();
      if (devices.length > 0) {
        this.isTelemetryOnlyServiceType = this.checkDeviceServiceTypes(devices);
        if (this.isTelemetryOnlyServiceType) {
          this.convertDestinationsToArray();
        }
        if (devices[0].billingPlans != null) {
          this.checkBillingOptionsForDevices();
          this.getDefaultBillingForDevices();
        }
      }
    },
    billingGroups: function (groups) {
      // update dropdown values
    },

    selectedOrganization: function (value) {
      this.clearInputs();
    },

    isEditing: function (value) {
      if (value) {
        this.userMessage = 'Device details are being edited.';
      } else {
        this.userMessage = '';
      }
    },
  };

  var components = {
    PgSelect,
    ActivationConfirmModal,
  };

  export default {
    name: 'ActivationForm',
    data,
    props,
    validations,
    beforeMount,
    mounted,
    methods,
    computed,
    watch,
    components,
  };
</script>

/*************************************************************/

<style scoped>
  .activation-form {
    margin-bottom: 10px;
  }
  .pg-form {
    padding: 10px;
  }
  .pg-form.vertical {
    flex-direction: column;
  }
  .center-text {
    width: 100%;
    display: flex;
  }
  .center-text p {
    margin: 10px auto;
    text-align: center;
  }
  .warning {
    border: solid 1px black;
    background-color: lightyellow;
    padding: 3px 3px;
    margin: 0 auto 5px;
  }

  .destinations {
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    align-items: center;
    width: 100%;
  }
  .destination {
    width: 275px;
  }

  .destination input {
    width: 100%;
    order: -1;
    color: gray;
    margin-right: 5px;
  }

  .destination .commonToAll {
    color: black;
  }

  .form-group table {
    margin: 0 auto;
  }
  .form-row {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
  }
  .left-column,
  .right-column {
    /* width: 50%; */
    margin: 0 auto;
  }
  .center-text p {
    margin: 10px auto;
    text-align: center;
  }
  td {
    padding: 0 10px;
  }
</style>
