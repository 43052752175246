<template>
  <div class="page-not-found-page">
    <h2>I could not find that page.</h2>
    <p>Were you maybe looking for one of these?</p>
    <p><a href="/devices">Your devices and their settings</a></p>
    <p><a href="/data">Your data</a></p>
  </div>
</template>

<script>
export default {
  name: 'PageNotFoundPage',
  data () {
    return { }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .page-not-found-page {
    padding: 30px;
  }
</style>
