<template>
  <div class="html-editor pg-component" v-if="canSeeMe">
    <div>
      <span class="component-title">{{ title }}</span>
      <a v-on:click="toggleEditor();"> [Toggle Edit]</a>
    </div>
    <div v-bind:class="editorClass" class="editor-container">
      <tinymce v-model="devNotes" :plugins="mcePlugins" :toolbar1="mceToolbar1" :toolbar2="mceToolbar2" :other="mceOptions" :inline="inline" />
    </div>
    <div id="devNotesDisplay" v-show="!editMode"></div>
    <div class="pg-save-indicator">{{ savedStatus }}</div>
  </div>
</template>

/*************************************************************/

<script>
  /*
    To use the HTML editor:
    <template>
      <html-editor section="any unique name" />

    <script>
      import HtmlEditor from '@/components/HtmlEditor';

      components: {
        HtmlEditor,
      }

  */
  import axios from 'axios';
  import environmentConfig from 'static/environmentSpecific';
  import { mapGetters, mapState, mapActions } from 'vuex';
  import ProfileService from '@/services/ProfileService';
  import VueEasyTinyMCE from 'static/libs/vue-easy-tinymce/vue-easy-tinymce.js';

  var thisComponent;

  var data = function() {
    return {
      mcePlugins: ['advlist autolink lists link image charmap print preview anchor textcolor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table contextmenu paste code directionality template colorpicker textpattern'],
      mceToolbar1: 'undo redo | bold italic strikethrough | forecolor backcolor | template link | bullist numlist | ltr rtl | removeformat',
      mceToolbar2: '',
      mceOptions : {
        height: 400,
        body_class: 'dev-notes-body ', // + currentTheme,
        content_css: '/static/css/editor.css',
        browser_spellcheck: true,
      },
      devNotes: 'loading...',
      firstLoad: true,
      saveDelayMs: 2000,
      savedStatus: 'Saved',
      editMode: false,
      canSeeMe: false,
      editorClass: 'inactive',
      currentTheme: null,
    };
  };

  var props = {
    'section': String,
    'title' : {type: String, default: 'Developer Notes'},
    'editClaim' : {type: String, default: ''},
    'inline' : {type: Boolean, default: false},
  }

  var created = function(){
    thisComponent = this;
    if (this.editClaim.includes(':')) {
      var claimType = this.editClaim.split(':')[0];
      var claimSetting = this.editClaim.split(':')[1];
      this.canSeeMe = this.userHasClaim(claimType, claimSetting);
    }
    if (!this.canSeeMe) {
      return;
    }
    this.currentTheme = ProfileService.getLocalSetting('theme', 'standard-theme');
    this.mceOptions.body_class = 'dev-notes-body ' + this.currentTheme;
    this.getNotes();
  };

  var mounted = function() {
    //console.warn('required claim:', this.editClaim, ' can see me=', this.canSeeMe, '<---- this is undefined unless Current User runs first');
  };

  var methods = {
    // Ted - I was going to change this to get the user fromt the store state,
    // but I don't think we'll need to access the current user at all...
    // ...mapActions("userModule", ['getCurrentUser']),

    // do not use store because we want every instance to have it's own data
    getNotes() {
      var fullUrl = `${environmentConfig.apiBaseUrl}devNote/search?section=${this.section}`;
      axios.get(fullUrl)
        .then((response) => {
          if (response.data && response.data.data && response.data.data.notes ) {
            var d = response.data.data;
            thisComponent.devNotes = d.notes;
          } else {
            console.log('Expected notes, got this back instead: ', response.data);
          }
        }, error => {
          console.log(`ERROR getting notes for section ${this.section} `, error);
        });
    },
    saveNotes() {
      var section = this.section;
      var notes = JSON.stringify(this.devNotes);
      var url = `${environmentConfig.apiBaseUrl}devNote/${section}`;
      //console.log('saving "' , notes, '" to ', url);
      //var data = JSON.stringify({"notes":notes});
      if (notes != null) {
        axios.put(url, notes)
          .then((result) => {
            this.savedStatus = 'Saved';
          })
          .catch((error) => {console.log(error);} );
      }
    },
    toggleEditor()  {
      this.editMode = !this.editMode;
      if (this.editMode) {
        this.editorClass = 'active';
      } else {
        this.editorClass = 'inactive';
      }

      this.setEditorMode();
    },
    setEditorMode() {
      if (this.editMode) {
        this.getNotes(); // refresh in case someone else is editing
        tinymce.activeEditor.setMode('design');
      } else {
        tinymce.activeEditor.setMode('readonly');
      }
    },

  };

  var computed = {
    ...mapGetters('userModule', ['userHasClaim']),
  };

  var components = {
    tinymce: VueEasyTinyMCE,
    //tinymce,
  };


  var timeoutId = 0;
  var watch = {
    devNotes: function(){
      if (this.firstLoad) {
        this.firstLoad = false;
      } else {
        this.savedStatus = 'Changed';
        //save only after saveDelayMs of no keyboard activity
        clearTimeout(timeoutId);
        timeoutId = setTimeout(thisComponent.saveNotes, thisComponent.saveDelayMs);
      }
    },
  };


  export default {
    name: 'HtmlEditor',
    data,
    props,
    created,
    //beforeMount,
    mounted,
    computed,
    methods,
    components,
    watch,
  };

</script>

/*************************************************************/

<style scoped>
  .html-editor {
    position: relative;
    width: 99%;
  }
  .mce-tinymce {
    height: 100%;
    min-height: 200px;
  }
  .pg-save-indicator {
    position: absolute;
    right: 10px;
    top: 31px;
    color: #888888;
  }
</style>
