import Vue from 'vue';
export default Vue.extend({

    template: `<span>{{params.data.sensorDataName }}</span>`,
    data() {
        return { }
    },
    created() { },
    beforeMount() { },
    mounted() { },

    methods: {


    },
    components: { },

});

