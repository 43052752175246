<template>
  <div class="pg-app">
    <reconcile-charges></reconcile-charges>
  </div>
</template>

<script>
  import ReconcileCharges from '@/components/ReconcileCharges';
  export default {
    name: 'InternalBilling',
    data() {
      return {};
    },
    components: {
      ReconcileCharges,
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
