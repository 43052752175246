<template>
  <div class="utilities-page">
    <div class="under-construction">
      <img src="@/assets/images/under_construction.jpg" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'UtilitiesPage',
  data () {
    return { }
  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .under-construction {width: 100%; height: 100px; background-color: black;text-align: center;}
  .under-construction img {height: 100%;}
</style>
