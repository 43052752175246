import Vue from 'vue';
import App from './App.vue';
import router from './router/router';
import store from './store/MainStore';

import Vuelidate from 'vuelidate';
import Snotify from 'vue-snotify';

import axios from 'axios';
import { EventBus } from './EventBus';
import environmentSpecific from 'static/environmentSpecific';
import LoadScript from 'vue-plugin-load-script';
import moment from 'moment';

var hasMouse = matchMedia('(pointer:fine)').matches || matchMedia('(hover:hover)').matches;
var mouseClass="no-mouse";
if (hasMouse) {
  mouseClass = "has-mouse";
}
$('body').addClass(mouseClass);

Vue.prototype.moment = moment;
// create global variable that can be access anywhere as this.$pgGlobal.someParameter
// https://vuejs.org/v2/cookbook/adding-instance-properties.html
Vue.prototype.$pgGlobal = {
  hasMouse: hasMouse,
  isLoadingData: false,
  currentUser: {},
  currentTheme: null,
};
var pgGlobal = Vue.prototype.$pgGlobal;

// keep track of any open requests
var openRequestCount = 0;
var setLoading = function(isLoading) {
  if (isLoading) {
    openRequestCount++;
    // console.log('Getting data ', openRequestCount);
  } else {
    if (openRequestCount>0) {
      openRequestCount--;
    }
    // console.log('Got data ', openRequestCount);
  }
  pgGlobal.isLoadingData = (openRequestCount>0);
  if (pgGlobal.isLoadingData) {
    // console.log('main.js we are waiting on ', openRequestCount, ' requests...');
    EventBus.$emit('DATA_IS_LOADING', openRequestCount);
  } else {
    // console.log('main.js we are not waiting for data...');
    EventBus.$emit('DATA_IS_NOT_LOADING', openRequestCount);
  }
};

Vue.use(Snotify, {
  toast: {
    timeout: 3000,
  }
});

// https://www.npmjs.com/package/vue-plugin-load-script
// Used to load Google Maps
Vue.use(LoadScript);
//import '~swiper/css/swiper.min.css';

import '@/styles/styles.scss';

// 3rd party utils installed with npm
import 'lodash';

Vue.use(Vuelidate);

Vue.config.productionTip = environmentSpecific.productionTip;

var eventBus = EventBus;

axios.interceptors.request.use(function(ajaxConfig){
  setLoading(true);
  if (typeof window === 'undefined') {
    console.log('axios.interceptors.request - window is undefined');
    // running on server
    return ajaxConfig;
  }
  if (ajaxConfig.method.toLowerCase()==='post' && ajaxConfig.data === undefined) {
    // arnold requires some data be sent with posts
    ajaxConfig.data = {};
    ajaxConfig.headers['Content-Type'] = 'application/json';
  }

  // withCredentials needed to send cookie
  ajaxConfig.withCredentials = true;

  return ajaxConfig;
}, function(error){
  setLoading(false);
  console.warn('ERROR axios.interceptors.request ', error);
});

axios.interceptors.response.use(function(response){
  setLoading(false);
  return response;
}, function(error){
  setLoading(false);
  var msg = error;
  var pgCode = -1;
  if (error.response && error.response.status) {
    msg += '  Status code: ' + error.response.status;
    pgCode = error.response.status;
  } else {
    console.warn('API error: ', error);
    msg += '  The server did not respond. ';
    pgCode = 521;
    window.location.href = '/static/maintenance.html?pgcode=' + pgCode;
  }
  console.warn('  >> interceptor intercepted error <<', msg);
  eventBus.$emit('USER_NOTIFICATION', {type:'ERROR', msg: msg});
  return Promise.reject(error);
});


var dimeStore = store;
dimeStore.dispatch('userModule/getCurrentUser').then(
  userInfo => {
    pgGlobal.currentUser = userInfo;
    if (userInfo.roles.some(role => (role.roleKey === 'PGStaff'))) {
      pgGlobal.currentUser.isPgStaff = true;
    }

    // don't load Vue until we have the user info
    /* eslint-disable no-new */
    new Vue({
      router,
      store,
      render: h => h(App),
    }).$mount('#app');
  }
);


