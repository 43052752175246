// Environment specific configuration goes here
// Things like the API URL, map keys, etc.

// defaults
var environmentSpecific = {
  apiBaseUrl: '** API URL not set in environmentSpecific.js',
  environmentName: '** Environment Name not set',
  productionTip: false,
  googleMapsApiKey: 'not set',
  maxRecordsPerSeriesOnChart: 100000,
  gridRowHeightPx: 22,
  maxNumberDevices: 6000,
  maxNumberHistoryDays: 60,

  // contact us
  contactUsReplyEmail: {
    subjectLine: 'Thank you for contacting Pacific Gyre',
    body: 'We will get back to you soon. \n\nPacific Gyre',
    cc: 'cc@aol.com',
    bcc: 'bcc@aol.com',
    linkText: null, // if null, will use user's email address
  },

  // auth
  pgAuthCookieName: 'PG_AUTH_COOKIE_NAME_NOT_SET',
  loginUrl: '/static/login',
};

const hostName = window.location.hostname.toString().toLowerCase();
// Set up a section for each environment that we will deploy to
// Feel free to add more if you want custom settings

// console.log(`Using ${hostName} to configure environment`)

// * * * * * * This script is visible to user -- NO SENSITIVE INFO!! * * * * * *

/* ------------ LOCAL ------------- */
if (hostName === 'oliver.britford.com') {
  // Ted
  console.log(`Using Teds's settings for ${hostName}`);
  // environmentSpecific.apiBaseUrl = 'https://arnoldtest.pacificgyre.com/api/v1/';
  environmentSpecific.apiBaseUrl = 'http://arnold.britford.com:5555/api/v1/';
  // environmentSpecific.apiBaseUrl = 'https://arnolddev.pacificgyre.com/api/v1/';
  environmentSpecific.userApiBaseUrl = 'https://apidev.pacificgyre.com/api2/getData.aspx';
  // environmentSpecific.userApiBaseUrl = 'http://localhost:1025/api2/getData.aspx';
  environmentSpecific.environmentName = 'local';
  environmentSpecific.productionTip = true;
  environmentSpecific.pgAuthCookieName = 'PG_Prototype';
  environmentSpecific.googleMapsApiKey = 'AIzaSyBtpWUu35dOeI41gDkzwdNFCEww2C_xqiY';
} else if (
  hostName === 'ap.yoursoftwarepartner.com' ||
  hostName === 'localhost.pacificgyre.com' ||
  hostName === 'andreaspc.pacificgyre.com' ||
  hostName === 'laptop.yoursoftwarepartner.com'
) {
  // Andrea
  console.log(`Using Andrea's settings for ${hostName}`);
  // environmentSpecific.apiBaseUrl = 'https://arnolddev.pacificgyre.com/api/v1/';
  environmentSpecific.apiBaseUrl = 'http://ap.yoursoftwarepartner.com:5555/api/v1/';
  // environmentSpecific.apiBaseUrl = 'http://laptop.yoursoftwarepartner.com:5555/api/v1/';
  environmentSpecific.billingPdfBaseFolder = 'http://dev4.pacificgyre.com/billing/PDFs/';
  environmentSpecific.userApiBaseUrl = 'https://api.pacificgyre.com/api2/getData.aspx';
  environmentSpecific.environmentName = 'local';
  environmentSpecific.productionTip = true;
  environmentSpecific.pgAuthCookieName = 'PG_Prototype';
  environmentSpecific.googleMapsApiKey = 'AIzaSyBtpWUu35dOeI41gDkzwdNFCEww2C_xqiY';
  environmentSpecific.maxNumberDevices = 500;

  /* ------------ DEV4 ------------- */
} else if (hostName === 'datadev.pacificgyre.com') {
  console.log(`Using Dev4 settings for ${hostName}`);
  environmentSpecific.apiBaseUrl = 'https://arnolddev.pacificgyre.com/api/v1/';
  environmentSpecific.userApiBaseUrl = 'https://apidev.pacificgyre.com/api2/getData.aspx';
  environmentSpecific.environmentName = 'dev';
  environmentSpecific.productionTip = true;
  environmentSpecific.pgAuthCookieName = 'PG_Dev';
  environmentSpecific.googleMapsApiKey = 'AIzaSyAr0cvJW-3E0gab1ivrhKsQRSSkZ_Ti-Z8';

  /* ------------ TEST ------------- */
} else if (hostName === 'datatest.pacificgyre.com') {
  console.log(`Using TEST settings for ${hostName}`);
  environmentSpecific.apiBaseUrl = 'https://arnoldtest.pacificgyre.com/api/v1/';
  environmentSpecific.userApiBaseUrl = 'https://apitest.pacificgyre.com/api2/getData.aspx';
  environmentSpecific.environmentName = 'test';
  environmentSpecific.productionTip = false;
  environmentSpecific.pgAuthCookieName = 'PG_Test';
  environmentSpecific.googleMapsApiKey = 'AIzaSyAr0cvJW-3E0gab1ivrhKsQRSSkZ_Ti-Z8';

  /* ------------ PRODUCTION ------------- */
} else if (hostName === 'data.pacificgyre.com') {
  environmentSpecific.requireAuth = true;
  environmentSpecific.apiBaseUrl = 'https://arnold.pacificgyre.com/api/v1/';
  environmentSpecific.userApiBaseUrl = 'https://api.pacificgyre.com/api2/getData.aspx';
  environmentSpecific.environmentName = 'production';
  environmentSpecific.productionTip = false;
  environmentSpecific.pgAuthCookieName = 'PG';
  environmentSpecific.googleMapsApiKey = 'AIzaSyAr0cvJW-3E0gab1ivrhKsQRSSkZ_Ti-Z8';
} else {
  console.error('environmentSpecific.js could not match ', hostName);
}

console.log(`EnvironmentSpecific.js environment: ${environmentSpecific.environmentName}`);

//console.log('envSpec.js', environmentSpecific);

export default environmentSpecific;
