<template>
  <div class="devices-page row">
    <div class="page-heading">
      <div class="pg-page-toolbar">
        <span class="pg-page-toolbar-left">
          <span class="">Devices</span>
        </span>
        <span class="pg-page-toolbar-right">
          <router-link tag="button" class="button" to="/m/devices">Back</router-link>
          <router-link tag="button" class="button" to="/m/device/details" v-show="numDevicesSelected==1" >Details</router-link>
          <router-link tag="button" class="button" to="/m/device/activations">Activations</router-link>
        </span>
      </div>
    </div>
    <div class="">
        <command-history-grid class="pg-detail-component"/>
    </div>
    <div class="footer">
    </div>
  </div>
</template>

/*************************************************************/

<script>

import { mapGetters, mapState, mapActions } from 'vuex';
import CommandHistoryGrid from '@/components/CommandHistoryGrid';

var data = function() {
  return { };
};
var mounted = function() {
};
var computed = {
  // access these getters from MainStore (which gets them from store Modules)
  ...mapState('deviceModule', ['deviceId', 'deviceList', 'selectedDeviceIds']),
  numDevicesSelected: function(){ return this.selectedDeviceIds.length },
};

export default {
  name: "MobileCommandsPage",
  data,
  mounted,
  computed,
  components: {
    CommandHistoryGrid,
  }
};
</script>

/*************************************************************/

<style scoped>
</style>
