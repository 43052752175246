<template>
  <div class="chart-component">
    <div class="add-on-chart-buttons plot-chart">
      <button
        type="button"
        class="chart-button"
        v-show="false /*hideRefreshIcon*/"
        @click="refreshChart()"
        title="Reload data and create a new chart"
      >
        <i class="far fa-sync-alt"></i> Refresh
      </button>
      <button
        type="button"
        class="chart-button"
        v-on:click="plotChart(true)"
        title="Update chart based on criteria selected"
        v-bind:class="{ 'needs-refresh': selectionIsDirty }"
        v-show="selectedFieldsCount > 0"
      >
        <i class="far fa-chart-line"></i> Plot
      </button>
      <span class="plot-helper" v-bind:class="{ 'move-plot-helper': showPlotReminder }" v-show="!hidePlotIcon"
        ><i class="far fa-hand-pointer"></i>
      </span>
    </div>
    <div class="chartwrapper">
      <div id="chartdiv" style="height: 100%">this should be replaced by chart...</div>
      <div id="legenddiv"></div>
    </div>

    <!-- https://stackoverflow.com/questions/25891931/amcharts-how-to-push-new-chart-data-into-chart-without-reloading-of-whole-chart -->
    <!--
    <div class="notes">
      <p>NOTES:</p>
      <p>
        Can now display multiple sensors from multiple devices. Axes are shared when it makes sense,
        so if two sensors meaure °C, we only need one axes for the pair. If one of those sensors is removed,
        the °C axis remains because it is needed by the other sensor. If that sensor is removed, the °C
        axis is no longer needed and is thrown in the bit bucket.
      </p>
    </div>
    -->
  </div>
</template>

/*************************************************************/

<script>
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_dataviz from '@amcharts/amcharts4/themes/dataviz';
import am4themes_dark from '@amcharts/amcharts4/themes/amchartsdark';
import am4themes_pgkelly from 'static/themes/amcharts4/am4themes_pgKelly';

import dataService from '@/services/DataService';
import environmentSpecific from 'static/environmentSpecific';
import dateHelper from '@/utils/DateRangeHelper.js';
import * as pgHelpers from '@/utils/PgHelpers.js';

// import chartService from "../services/ChartService.js";
import { mapGetters, mapState, mapActions } from 'vuex';
import { EventBus } from '@/EventBus';

var thisComponent;
//var chartChangedTimoutId = null;
var plotReminderTimeoutId = null;

function log(...msg) {
  console.log('>>> ', msg);
}
function warn(...msg) {
  console.warn('>>> ', msg);
}

export default {
  name: 'ChartComponent',
  data() {
    return {
      // https://github.com/amcharts/amcharts4/issues/785
      chartBugIsFixed: false,
      chart: null,
      seriesList: [], // [ {seriesName: "1234-SST", deviceId: 1234, sensorName: "SST", axisName: "AxisUnitId15", series: seriesObject}]
      axisList: [], // [ {axisName:"AxisUnitId14", axis: axisObject} ]
      lineWidth: 2,
      //smoothedAmount: 1,
      maxRecordsPerSeries: environmentSpecific.maxRecordsPerSeriesOnChart,
      startDate: null,
      endDate: null,
      chartTitle: null,
      chartSubtitle: null,
      hideRefreshIcon: false,
      hidePlotIcon: false,

      displayedDevices: [],
      displayedSensors: [],
      displayedDateRange: {},
      selectionIsDirty: false,
      showPlotReminder: false,
    };
  },

  props: {
    dateRange: null,
    coordinateFormat: null,
  },

  created() {
    // console.clear();
    thisComponent = this;
    window.thisComponent = this; // troubleshooting only
    this.createEvents();
  },

  mounted() {
    this.startDate = dateHelper.formattedStartDate(this.dateRange);
    this.endDate = dateHelper.formattedEndDate(this.dateRange);
    this.makeEmptyChart();
    this.selectionIsDirty = false;
  },

  methods: {
    plotChart(isTotalRefresh) {
      // this.removeAllSeries();
      // EventBus.$emit('REFRESH_DEVICE_LIST_DATA');

      this.hidePlotIcon = true;
      this.showPlotReminder = false;
      this.selectionIsDirty = false;
      var deviceChanges = false;
      var fieldChanges = false;
      var redrawEverything = false;
      var addedDevices = _.difference(this.selectedDeviceIds, this.displayedDevices);
      var removedDevices = _.difference(this.displayedDevices, this.selectedDeviceIds);
      this.displayedDevices = _.clone(this.selectedDeviceIds);

      var addedFields = _.difference(this.selectedFieldNames, this.displayedSensors);
      var removedFields = _.difference(this.displayedSensors, this.selectedFieldNames);
      this.displayedSensors = _.clone(this.selectedFieldNames);

      if (!_.isEqual(this.dateRange, this.displayedDateRange) || isTotalRefresh) {
        this.displayedDateRange = _.clone(this.dateRange);
        this.onDateRangeChange();
        return;
      }
      if (addedDevices.length > 0 || removedDevices.length > 0) {
        this.onDevicesChange(addedDevices, removedDevices);
      }
      if (addedFields.length > 0 || removedFields.length > 0) {
        this.onFieldsChange(addedFields, removedFields);
      }
    },

    refreshChart() {
      EventBus.$emit('REFRESH_DEVICE_LIST_DATA');
      this.removeAllSeries();
      console.log('refreshing chart');
      this.addDevices(this.getSelectedDevices);
      this.plotChart(true);
    },

    // Setup
    makeEmptyChart() {
      if (this.chart) {
        return; // already have a chart
      }

      am4core.useTheme(am4themes_animated);
      // am4core.useTheme(am4themes_pgkelly);
      // TODO - change chart theme when site theme is dark
      this.setTheme(this.$pgGlobal.currentTheme);

      // Create chart instance
      var chart = am4core.create('chartdiv', am4charts.XYChart);
      this.chart = chart;

      // last title created is on top, so define subTitle first
      this.chartSubtitle = chart.titles.create();
      this.chartSubtitle.text = this.dateRangeText;
      this.chartSubtitle.fontSize = 12;

      this.chartTitle = chart.titles.create();
      this.chartTitle.text = this.selectedOrganization.orgName;

      this.chartTitle.fontSize = 15;

      // Increase contrast by taking evey second color
      // not needed with kelly theme
      //chart.colors.step = 2;

      chart.dateFormatter.dateFormat = 'yyyy-MM-dd HH:mm:ss';
      chart.dateFormatter.inputDateFormat = 'yyyy-MM-ddTHH:mm:ss.SSSZ';
      //chart.dateFormatter.inputDateFormat = "i";

      // chart.legend = new am4charts.Legend();
      // Create legend
      chart.legend = new am4charts.Legend();

      // Create a separate container to put legend in
      var legendContainer = am4core.create("legenddiv", am4core.Container);
      legendContainer.width = am4core.percent(100);
      legendContainer.height = am4core.percent(100);
      chart.legend.parent = legendContainer;
      chart.legend.scrollable = true;
      chart.legend.propertyFields.hidden = true;

      chart.cursor = new am4charts.XYCursor();
      chart.scrollbarY = new am4core.Scrollbar();
      chart.scrollbarX = new am4core.Scrollbar();
      chart.scrollbarX.dy = -9;
      // chart.scrollbarX = new am4charts.XYChartScrollbar();

      if (this.chartBugIsFixed) {
        chart.scrollbarX.series.events.on('removed', function (removed) {
          // https://stackoverflow.com/a/54050950/1116812
          thisComponent.chart.scrollbarX.scrollbarChart.series.removeValue(removed.oldValue.clones.getIndex(0));
        });
      }

      // Create X axes - all charts have common X axis
      var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.minGridDistance = 60;

      this.chart.exporting.menu = new am4core.ExportMenu();
      // don't show scrollbars in images
      chart.scrollbarX.exportable = false;
      chart.scrollbarY.exportable = false;

      // exclude exporting data since our data is in each series
      // and the chart data element is empty
      // <i class="far fa-file-download fa-2x"></i>
      chart.exporting.menu.items = [
        {
          label: 'Export',
          menu: [
            {
              label: 'Image',
              menu: [
                { type: 'png', label: 'PNG' },
                { type: 'jpg', label: 'JPG' },
                { type: 'gif', label: 'GIF' },
                { type: 'svg', label: 'SVG' },
                { type: 'pdf', label: 'PDF' },
              ],
              // }, {
              //   "label": "Data",
              //   "menu": [
              //     { "type": "json", "label": "JSON" },
              //     { "type": "csv", "label": "CSV" },
              //     { "type": "xlsx", "label": "XLSX" }
              //   ]
            },
            {
              label: 'Print',
              type: 'print',
            },
          ],
        },
      ];

      this.addPgLogo();
      this.hideIcon();
    },

    addPgLogo() {
      let watermark = new am4core.Image();
      watermark.href = window.location.origin + '/static/images/pgswirl.png';
      this.chart.tooltipContainer.children.push(watermark);
      watermark.height = 30;
      //watermark.width = 40;
      watermark.align = 'right';
      watermark.valign = 'bottom';
      watermark.opacity = 0.2;
      watermark.marginRight = 0;
      watermark.marginBottom = 0;
      watermark.tooltipText = 'Pacific Gyre';
      // watermark.url = "https://www.pacificgyre.com/";
      // watermark.urlTarget = "_blank";
    },

    hideIcon() {
      this.hideRefreshIcon = true;
    },

    createEvents() {
      EventBus.$on('THEME_CHANGED', newTheme => {
        // this.setTheme(newTheme);
        // this.plotChart(true);
      });
    },

    // Event handlers
    setTheme(newTheme) {
      console.log('Theme changed to ', newTheme);
      if (newTheme === 'dark-theme') {
        am4core.useTheme(am4themes_dark);
      } else {
        am4core.useTheme(am4themes_pgkelly);
      }
    },
    onDevicesChange(addedDevices, removedDevices) {
      for (var i = 0; i < removedDevices.length; i++) {
        this.removeSeriesByDeviceId(removedDevices[i]);
      }

      for (var d = 0; d < addedDevices.length; d++) {
        for (var f = 0; f < this.getSelectedFields.length; f++) {
          var fieldInfo = this.getSelectedFields[f];
          var device = this.getSelectedDevices.find(dev => dev.deviceId === addedDevices[d]);
          this.addSeries(
            device.deviceId,
            device.deviceName,
            fieldInfo.dataFieldName,
            fieldInfo.displayName,
            fieldInfo.unitAbbrev,
            this.startDate,
            this.endDate,
            this.maxRecordsPerSeries,
          );
        }
      }
    },

    onFieldsChange(addedFields, removedFields) {
      //removed fields
      for (var i = 0; i < removedFields.length; i++) {
        this.removeSeriesBySensorName(removedFields[i]);
      }
      // added fields
      for (var f = 0; f < addedFields.length; f++) {
        var fieldInfo = this.getSelectedFields.find(fld => fld.dataFieldName === addedFields[f]);
        if (!fieldInfo) {
          // debugger;
        }
        for (var d = 0; d < this.getSelectedDevices.length; d++) {
          var device = this.getSelectedDevices[d];
          this.addSeries(
            device.deviceId,
            device.deviceName,
            fieldInfo.dataFieldName,
            fieldInfo.displayName,
            fieldInfo.unitAbbrev,
            this.startDate,
            this.endDate,
            this.maxRecordsPerSeries,
          );
        }
      }
    },

    onDateRangeChange() {
      // console.log('ChartComponent onDateRangeChange()', this.dateRange);
      this.startDate = dateHelper.formattedStartDate(this.dateRange);
      this.endDate = dateHelper.formattedEndDate(this.dateRange);
      this.removeAllSeries();
      this.addDevices(this.getSelectedDevices);
      this.chartSubtitle.text = this.dateRangeText;
    },

    // add devices
    addDevices(deviceList) {
      for (var d = 0; d < deviceList.length; d++) {
        for (var f = 0; f < this.getSelectedFields.length; f++) {
          var fieldInfo = this.getSelectedFields[f];
          var device = deviceList[d];
          this.addSeries(
            device.deviceId,
            device.deviceName,
            fieldInfo.dataFieldName,
            fieldInfo.displayName,
            fieldInfo.unitAbbrev,
            this.startDate,
            this.endDate,
            this.maxRecordsPerSeries,
          );
        }
      }
    },

    getAxisName(unitId) {
      var axisName = 'AxisUnitId' + unitId;
      return axisName;
    },

    getAxisNameFromFieldName(fieldName) {
      var field = this.allFieldsSelectedDevices.find(f => f.dataFieldName === fieldName);
      if (!field) {
        return null;
      }
      return this.getAxisName(field.unitId);
    },

    // Data Series
    addSeries(deviceId, deviceName, sensorName, sensorFriendlyName, unitAbbrev, startDate, endDate, numRecords) {
      // maybe convert degrees to dm or dms after api cal in dataService.js and add param/arg?
      dataService.getDataBySensor(deviceId, sensorName, startDate, endDate, numRecords).then(function (response) {
        // if (sensorName === "latitude") {
        //   response.data.forEach(p => {
        //     console.log(thisComponent.coordinateFormat);
        //     var lat = pgHelpers.latLongDisplayHelper.convertDegree(p.latitude, thisComponent.coordinateFormat, false);
        //     console.log(p.latitude, lat)
        //     p.latitude = lat; });
        // }
        // console.log(response.data.length)
        thisComponent.drawSeries(deviceId, deviceName, sensorName, sensorFriendlyName, unitAbbrev, startDate, endDate, numRecords, response.data);
      });
    },
    drawSeries(deviceId, deviceName, sensorName, sensorFriendlyName, unitAbbrev, startDate, endDate, numRecords, data) {
      var axisName = this.getAxisNameFromFieldName(sensorName);
      // console.log("Adding series for " + deviceName + " (" + deviceId + ") with sensor " + sensorName);
      var valueAxis;
      if (this.axisList.some(a => a.axisName === axisName)) {
        valueAxis = this.axisList.find(a => a.axisName === axisName).axis;
        // console.log("using existing axis " + axisName);
      } else {
        valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
        var abbr = this.unitDisplay(unitAbbrev) || '';
        this.chart.paddingTop = 40;
        var padding = 5 + abbr.length;
        valueAxis.paddingLeft = padding;
        valueAxis.paddingRight = padding;
        valueAxis.title.text = abbr;
        valueAxis.title.rotation = 0;
        valueAxis.title.align = 'center';
        valueAxis.title.valign = 'top';
        valueAxis.title.dy = -60;
        // valueAxis.title.fontWeigh = 600;
        this.axisList.push({ axisName: axisName, axis: valueAxis });
        // console.log("adding axis " + axisName);
      }

      var series = this.chart.series.push(new am4charts.LineSeries());
      var seriesName = deviceId + '-' + sensorName;
      this.seriesList.push({
        seriesName: seriesName,
        deviceId: deviceId,
        sensorName: sensorName,
        axisName: axisName,
        series: series,
      });
      series.name = deviceName + ' ' + sensorFriendlyName;
      // Use {dataFieldName} for fields in series.data, use ${someVariable} for regular JS vars..
      // https://www.amcharts.com/docs/v4/tutorials/tooltips-with-rich-html-content/
      series.tooltipHTML = this.toolTipHtml(sensorFriendlyName, this.unitDisplay(unitAbbrev));
      //series.tooltip.pointerOrientation = "vertical";  // req'd since we have a button in tooltip
      //series.tooltip.label.interactionsEnabled = true; // req'd since we have a button in tooltip

      series.strokeWidth = this.lineWidth;
      // series.bullets.push(new am4charts.CircleBullet());
      // https://www.amcharts.com/docs/v4/concepts/bullets/#Using_images_as_bullets -- use custom images as bullets

      //series.tensionX = this.smoothedAmount;
      series.yAxis = valueAxis;
      series.data = data;
      series.dataFields.valueY = sensorName;
      series.dataFields.dateX = 'deviceDateTime';

      if (this.chartBugIsFixed) {
        if (this.chart.scrollbarX && this.chart.scrollbarX.series) {
          this.chart.scrollbarX.series.push(series);
        }
      }

      series.segments.template.interactionsEnabled = true;
      series.segments.template.events.on(
        'hit',
        ev => {
          var item = ev.target.dataItem.component.tooltipDataItem.dataContext;
          console.log('clicked on: ', ev, item);
        },
        this,
      );
      // console.log("created " + seriesName + " on " + axisName);
    },
    removeSeries(seriesListItem) {
      // remove series from chart
      this.chart.series.removeValue(seriesListItem.series);

      // remove series from the scrollbar
      // automatically handled above with .on("removed", ...
      // if (this.chart.scrollbarX && this.chart.scrollbarX.series && this.chart.scrollbarX.series.length > 0) {
      //this.chart.scrollbarX.scrollbarChart.series.removeValue(seriesListItem.series);
      // These two lines below were uncommented - error 07/08/19
      // var index = this.chart.scrollbarX.series.indexOf(seriesListItem.series);
      // console.log('removing series ' + index);
      //this.chart.scrollbarX.series.removeIndex(index); //.dispose();
      //this.chart.scrollbarX.series.removeValue(seriesListItem.series);
      // }

      // remove series from our list
      var beforeCount = this.seriesList.length;
      this.seriesList = this.seriesList.filter(s => s.seriesName !== seriesListItem.seriesName);

      var axisStillInUse = this.seriesList.some(s => s.axisName === seriesListItem.axisName);
      if (!axisStillInUse) {
        var axis = this.axisList.find(a => a.axisName === seriesListItem.axisName).axis;
        this.chart.yAxes.removeValue(axis);
        this.axisList = this.axisList.filter(a => a.axisName !== seriesListItem.axisName);
      }
    },
    removeSeriesByDeviceId(deviceId) {
      this.seriesList
        .filter(s => s.deviceId === deviceId)
        .forEach(s => {
          this.removeSeries(s);
        });
    },
    removeSeriesBySensorName(sensorName) {
      this.seriesList
        .filter(s => s.sensorName === sensorName)
        .forEach(s => {
          this.removeSeries(s);
        });
    },
    removeAllSeries() {
      if (this.seriesList.length > 0) {
        // since removeSeries alters seriesList, we keep checking what is
        // there instead of a normal for i=0 to length
        while (this.seriesList.length > 0) {
          this.removeSeries(this.seriesList[0]);
        }
      }
    },

    // utils
    toolTipHtml(sensorFriendlyName, unitDisplay) {
      return `
        <div class="device-name">{deviceName}:</div>
        <span style="font-size: smaller">{deviceDateTime}</span><br>
        ${sensorFriendlyName}: <b>{valueY}${unitDisplay}</b>`;
      // <center><input type="button" value="More info" onclick="thisComponent.onDataItemClicked('{dataId}')" title="Click to remove this point from this chart" /></center>
      // `;
    },

    onDataItemClicked(dataId) {
      dataId = dataId.replace(/,/g, ''); // amCharts adds comma separators
      console.log('Clicked on ', dataId);
      //this.chart.invalidateData();
    },

    unitDisplay(unitAbbrev) {
      if (unitAbbrev === 'Deg') {
        return '°';
      }
      if (unitAbbrev === 'Deg C') {
        return '°C';
      }
      if (unitAbbrev === 'V') {
        return 'VDC';
      }
      // GpsQuality is sending null
      if (unitAbbrev === null) {
        return '';
      }
      if (unitAbbrev === 'count') {
        return '';
      }
      return unitAbbrev;
    },

    updateChart() {
      // figure out what changed
      // optionally call:
      //   dateRangeChange
      //   deviceChange
      //   sensorChange
    },

    triggerPlotReminder() {
      clearTimeout(plotReminderTimeoutId);
      if (this.selectedFieldsCount > 0 && this.selectionIsDirty) {
        plotReminderTimeoutId = setTimeout(function () {
          thisComponent.showPlotReminder = true;
          setTimeout(function () {
            thisComponent.showPlotReminder = false;
          }, 5000); // how long to show
        }, 10000); // wait to show
      }
    },
  },

  computed: {
    ...mapGetters('deviceModule', ['selectedDeviceIdsCount', 'selectedFieldsCount', 'getSelectedFields', 'getSelectedDevices']),
    ...mapState('deviceModule', ['selectedDeviceIds', 'selectedFieldNames', 'allFieldsSelectedDevices']),
    ...mapState('organizationModule', ['selectedOrganization']),

    dateRangeText: function () {
      var dateText = '';
      if (this.startDate) {
        if (this.endDate) {
          // beginning and ending dates
          dateText = `${this.startDate} through ${this.endDate}`;
        } else {
          // beginning date only
          dateText = `Data since ${this.startDate}`;
        }
      } else {
        if (this.endDate) {
          // ending date only
          dateText = `Data through ${this.endDate}`;
        } else {
          // no date selected
          dateText = '';
        }
      }
      return dateText;
    },
  },

  watch: {
    // // These were used to auto refresh chart when data changed
    selectedDeviceIds: function (newList, oldList) {
      this.selectionIsDirty = true;
      this.triggerPlotReminder();
      // var addedDevices = _.difference(newList, oldList);
      // var removedDevices = _.difference(oldList, newList);
      // this.onDevicesChange(addedDevices, removedDevices);
    },
    selectedFieldNames: function (newList, oldList) {
      this.selectionIsDirty = true;
      this.triggerPlotReminder();
      // var addedFields = _.difference(newList, oldList);
      // var removedFields = _.difference(oldList, newList);
      // this.onFieldsChange(addedFields, removedFields);
    },
    dateRange: {
      handler(val) {
        this.selectionIsDirty = true;
        this.triggerPlotReminder();
        // console.log('watcher saw ', val, ' change');
        // debounce so if user types 1234 we don't call API
        // for 1, 12, 123, 1234.  Give user a sec to finish typing.
        // clearTimeout(chartChangedTimoutId);
        // chartChangedTimoutId = setTimeout(function(){
        // console.log('watcher applied ', val, ' change');
        // thisComponent.onDateRangeChange();
        // }, 500);
      },
      deep: true,
    },
    selectedOrganization: function (value) {
      this.chartTitle.text = value.orgName;
    },
  },

  beforeDestroy() {
    if (this.chart) {
      // console.log("Disposing of chart...");
      this.chart.dispose();
    }
  },
};
</script>

/*************************************************************/
<style>
li.amcharts-amexport-item {
  width: auto !important;
  padding: 8px !important;
  height: auto;
}
li.amcharts-amexport-item a {
  padding: 0 !important;
}
ul.amcharts-amexport-menu.amcharts-amexport-menu-level-0.amcharts-amexport-menu-root.amcharts-amexport-right.amcharts-amexport-top {
  right: 61px;
  top: 9px;
}

#legenddiv svg > g > g:nth-child(2) > g:nth-child(2) {
    display: none;
}

</style>

<style scoped lang="scss">
.amcharts-amexport-item.amcharts-amexport-item-level-0 {
  width: auto !important;
  padding: 7px 5px;
}

.chart-component {
  height: 100%;
  display: flex;
  flex-direction: column;
  /* Added for refresh button */
  position: relative;
}
.refreshwrapper {
  position: absolute;
  left: 5px;
  top: 5px;
  z-index: 1;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.refresh-icon {
  font-size: 2em;
}
.notes {
  flex: 0 0 auto;
}

.chartwrapper {
  flex: 1 1 auto;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%; /* temporary fix for no show */
}
#chartdiv {
  // position: absolute;
  width: 100%;
  flex: 1 1 auto;
}
#legenddiv {
  width: 100%;
  border: 1px dotted #c99;
  margin: 1em 0;
  height: 150px;
  max-height: 120px;
}

.plot-chart {
  position: absolute;
  top: 10px;
  left: 50px;
  cursor: pointer;
  z-index: 1;
}
.chart-button,
.chart-button:focus {
  padding: 8px;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
}

@keyframes slideLeft {
  0% {
    opacity: 0;
    margin-left: 100px;
  }
  90% {
    opacity: 1;
    margin-left: -15px;
  }
  100% {
    margin-left: 0;
  }
}
.plot-helper {
  display: none;
}
.plot-helper i {
  font-size: 2em;
  transform: rotate(90deg) scaleY(-1);
}
.move-plot-helper {
  display: inline-block;
  animation: slideLeft 2s ease-out;
}
</style>
