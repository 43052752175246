<template>
  <div id='web-log-grid' class='web-log-grid pg-component'>
    <div class='web-log-list-grid'>
      <h1>Web Log Grid</h1>
      <div class="pg-component-toolbar">
        <h4>dev-log History</h4>
        <!-- <span class="right">
          <input type="button" v-on:click="dev-logButtonClicked" value="dev-logs Icon" v-show="selectedDeviceIdsCount>0" />
        </span> -->
      </div>
      <ag-grid-vue
        style='width: 100%; height: 400px; '
        class='ag-theme-balham resizable'
        :gridOptions='gridOptions'
        :modules="agGridModules"
        :columnDefs='columnDefs'
        >
      </ag-grid-vue>
    </div>
  </div>
</template>

/*************************************************************/

<script>
  import { AgGridVue } from "@ag-grid-community/vue";
  import { AllCommunityModules } from '@ag-grid-community/all-modules';
  import { mapGetters, mapState, mapActions } from 'vuex';
  import pgUtils from '@/utils/PgUtils.js';
  import agGridUtils from '@/utils/AgGridUtils.js';

  var myDateFormat = 'MM/DD/YY'; // 'YYYY-MM-DD HH:mm:ss '; // ISO  ISO.MSnp

  var data = function() {
    return {
      columnDefs: null,
      rowData: null,
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      agGridModules: AllCommunityModules,
      gridWidth: 0,
    };
  };

  var beforeMount = function() {
    this.getWebLogItems();
    this.gridOptions = {
      rowData: this.webLogItems,
      columnDefs: this.columnDefs,
      enableFilter: true,
      enableSorting: true,
      enableColResize: true,
      onGridReady: this.onGridReady,
      onGridSizeChanged: this.onGridSizeChanged,
      onCellValueChanged: this.onCellValueChanged,
      onRowClicked: this.onDeviceRowClicked,
      onColumnMoved: this.onColumnMoved,
      defaultColDef: { width: 100, },
    };

    this.columnDefs = [
      { colId: 'webLogId', headerName: 'WebLogId', field: 'webLogId', minWidth: 80, sort: 'desc' },
      { colId: 'source', headerName: 'Source', field: 'source', minWidth: 80,  },
      { colId: 'level', headerName: 'Level', field: 'level', minWidth: 80,  },
      { colId: 'timestamp', headerName: 'TimeStamp', field: 'timeStamp', minWidth: 120, },
      { colId: 'message', headerName: 'Message', field: 'message', minWidth: 200,  width: 500, },
      { colId: 'exception', headerName: 'Exception', field: 'exception', minWidth: 100, },
    ];
  };

  var mounted = function() {};

  var methods = {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.gridApi.setRowData(this.webLogItems);
      this.gridApi.sizeColumnsToFit();
      $('.resizable').resizable();
    },
    ...mapActions('devModule', ['getWebLogItems', ]),
  };

  var computed = {
    ...mapState('devModule', ['webLogItems',]),
  };

  var watch= {
    'webLogItems': function(newValue) {
      this.gridApi.setRowData(newValue);
    },
  };

  var components = {
    AgGridVue,
  };

  export default {
    name: 'WebLogGrid',
    data,
    beforeMount,
    mounted,
    methods,
    computed,
    watch,
    components
  };

</script>

/*************************************************************/

<style scoped>
</style>
