<template>
  <div class="telemetry-previous-quarter-page">
    <billing-report-header :selectedOrg="selectedOrg" :remitToOrg="remitToOrg" :startDate="startDate" :endDate="endDate"></billing-report-header>
    <billing-report-overview
      :selectedOrg="selectedOrg"
      :startDate="startDate"
      :endDate="endDate"
      :billingOverview="billingOverview"
      :billingAdjustments="billingAdjustments"
      :showActivationInOverview="showActivationInOverview"
    ></billing-report-overview>
    <billing-report-details :billingDetails="billingDetails" :unusedDevices="unusedDevices"></billing-report-details>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import * as pgHelpers from '@/utils/PgHelpers.js';
  import BillingReportHeader from '@/components/BillingReportHeader';
  import BillingReportOverview from '@/components/BillingReportOverview';
  import BillingReportDetails from '../components/BillingReportDetails.vue';

  var thisComponent;
  export default {
    name: 'TelemetryPreviousQuarterPage',
    data() {
      return {
        selectedOrg: {},
        remitToOrg: {},
        startDate: null,
        endDate: null,
        billingOverview: {},
        billingDetails: [],
        billingAdjustments: [],
        showActivationInOverview: false,
        showAdHocNotes: false,
        unusedDevices: [],
      };
    },
    props: {},
    components: {
      BillingReportHeader,
      BillingReportOverview,
      BillingReportDetails,
    },
    computed: {},
    methods: {
      ...mapActions('organizationModule', ['getOrganizationById']),
      ...mapActions('backOfficeModule', [
        'getBillingGroupById',
        'getTelemetryBillingOverviewForOrg',
        'getBillingAdjustmentsForOrgAndId',
        'getTelemetryBillingDetailsForOrg',
      ]),
      ...mapActions('deviceModule', ['getDevicesActiveButNotTransmitting']),
      getDates() {
        var currentDate = new Date();
        var prevQtr = pgHelpers.dateTimeHelper.previousQtrDates(currentDate);
        this.startDate = prevQtr.startDate;
        this.endDate = prevQtr.endDate;

        return prevQtr;
      },
      getRequestedOrg() {
        var orgId = this.$route.query.orgid;
        var org = {};
        var billingGroupId = this.$route.query.billinggroupid;
        this.getOrganizationById(orgId).then(response => {
          org = response;
          this.getBillingGroupById(billingGroupId).then(response => {
            var billingGroupName = response[0].billingGroupName;
            this.selectedOrg = org;
            this.selectedOrg.billingGroupName = billingGroupName;
            this.selectedOrg.billingGroupId = billingGroupId;
          });
        });
        return org;
      },
      getRemitToOrg() {
        this.getOrganizationById(1).then(response => {
          this.remitToOrg = response;
        });
      },
      getPageData() {
        if (this.selectedOrg.hasOwnProperty('orgId') && this.selectedOrg.orgId > -1) {
          var sc = {
            orgId: this.selectedOrg.orgId,
            billingGroupId: this.selectedOrg.billingGroupId,
            startDate: this.startDate,
            endDate: this.endDate,
          };
          this.getTelemetryBillingOverviewForOrg(sc).then(response => {
            this.billingOverview = response[0];
          });

          this.getBillingAdjustmentsForOrgAndId(sc).then(response => {
            this.billingAdjustments = response;
            this.billingAdjustments.forEach(el => {
              var year = el.asOfDate.substring(0, 4);
              if (el.asOfDate.substring(5, 6) == '0') {
                var month = el.asOfDate.substring(6, 7);
              } else {
                var month = el.asOfDate.substring(5, 7);
              }
              if (el.asOfDate.substring(8, 9) == '0') {
                var date = el.asOfDate.substring(9, 10);
              } else {
                var date = el.asOfDate.substring(8, 10);
              }
              var formattedDate = month + '/' + date + '/' + year;
              el.asOfDate = formattedDate;
            });
          });
          this.getTelemetryBillingDetailsForOrg(sc).then(response => {
            this.billingDetails = response;
            var showActivation = this.billingDetails.findIndex(d => d.feeType == 'Activation');
            this.showActivationInOverview = showActivation >= 0 ? true : false;
          });

          this.getDevicesActiveButNotTransmitting(sc).then(response => {
            this.unusedDevices = response;
            console.log('devices', this.unusedDevices);
          });
        }
      },
    },
    beforeMount() {
      var dates = this.getDates();
      var org = this.getRequestedOrg();
      this.getRemitToOrg();
      setTimeout(() => {
        this.getPageData();
      }, 2000);
    },
    mounted() {
      thisComponent = this;
      this.showAdHocNotes = false;
      // this.getPageData();
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .telemetry-previous-quarter-page {
    margin: 0 auto;
    width: 750px;
    position: relative;
    font-size: 1em;
    font-family: Arial, Helvetica, sans-serif;
    padding-bottom: 30px;
  }
</style>
