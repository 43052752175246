<template>
  <div id="app">
    <div v-bind:class="['app-container', loadingDataClass,]" v-if="!$route.meta.plainLayout">
      <page-header v-bind:currentUser="currentUser" />
      <page-nav v-bind:currentUser="currentUser" />
      <div class="app-content">
        <router-view v-bind:currentUser="currentUser" />
      </div>
      <vue-snotify></vue-snotify>
      <div class="loading-indicator"><i class="far fa-spinner fa-spin"></i></div>
      <div id="page-footer" class="app-footer"></div>
    </div>
    <div v-if="$route.meta.plainLayout" v-bind:currentUser="currentUser">
      <router-view />
    </div>
  </div>
</template>

/* ------------------------------------*/

<script>
  import PageNav from '@/components/Common/PageNav';
  import PageHeader from '@/components/Common/PageHeader';
  import environmentSpecific from 'static/environmentSpecific';
  import { mapGetters, mapState, mapActions } from 'vuex';
  import { EventBus } from './EventBus';
  import ProfileService from '@/services/ProfileService';
  import * as pgHelpers from '@/utils/PgHelpers.js';

  var thisApp;
  var msToSeconds = 1000;

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  export default {
    name: 'App',
    methods: {
      ...mapActions('userModule', ['getCurrentUserInfo']),
      ...mapActions('organizationModule', [
        'setSelectedOrganization',
        'setSelectedOrganizationDefault',
        'getOrganizations',
        'getSelectedOrganization',
      ]),
      loggit(msg) {
        this.$snotify.error(msg);
      },

      checkAuth() {
        var pgAuthCookie = pgHelpers.cookieHelper.getCookieValue(environmentSpecific.pgAuthCookieName);
        var loggedIn = false;

        if (pgAuthCookie) {
          var decoded = decodeURIComponent(pgAuthCookie);
          var authObj = JSON.parse(decoded);
          var authExpires = Date.parse(authObj.ExpiresUtc);
          var loginExpiresSeconds = authExpires - Date.now();
          if (loginExpiresSeconds > 0) {
            loggedIn = true;
            // console.log('Login expires in ', loginExpiresSeconds, ' seconds at ', authObj.ExpiresUtc)
          }
        }

        if (loggedIn) {
          // all is cool.  check again in a little while.
          setTimeout(function() {
            thisApp.checkAuth();
          }, 60 * msToSeconds);
        } else {
          window.location.replace(environmentSpecific.loginUrl);
        }
      }, // checkAuth()

      setUpEvents() {
        EventBus.$on('DATA_IS_LOADING', () => {
          // payload is # of outstanding requests
          // console.log('Bus says data is loading');
          thisApp.loadingDataClass = 'data-is-loading';
        });
        EventBus.$on('DATA_IS_NOT_LOADING', () => {
          // console.log('Bus all loaded');
          thisApp.loadingDataClass = 'data-loaded';
        });
      },
    },

    computed: {},

    data() {
      return {
        currentUser: {},
        // currentTheme: null,
        // headerClass: null,
        // environmentName: null,
        // touchClass: null,
        loadingDataClass: '',
      };
    },

    created() {
      // console.log('app.vue created');
      thisApp = this;
      window.router = this.$router;
      var savedTheme = ProfileService.getLocalSetting('theme', 'standard-theme');
      thisApp.$pgGlobal.currentTheme = savedTheme;
      $('body')
        .removeClassRegex('-theme$')
        .addClass(savedTheme);

      var headerClass = 'fixed-header';
      var environmentName = 'env-' + environmentSpecific.environmentName;
      this.$pgGlobal.currentPageTitle = this.$route.meta.pageTitle ? this.$route.meta.pageTitle : "";

      $('body')
        .addClass(headerClass)
        .addClass(environmentName);

      var bodyId = this.$pgGlobal.currentPageTitle.toLowerCase() + '-body'
      $('body').attr('id', bodyId)

      thisApp.currentUser = thisApp.$pgGlobal.currentUser;

      var isPgStaff = thisApp.$pgGlobal.currentUser.roles.some(role => role.roleKey === 'PGStaff');
      if (isPgStaff) {
        thisApp.getOrganizations();
        thisApp.setSelectedOrganizationDefault();
      } else {
        thisApp.setSelectedOrganization(thisApp.$pgGlobal.currentUser.organization);
      }
    },

    beforeMount() {},

    mounted() {
      // EventBus.$on('USER_NOTIFICATION', payload => {
      //   this.loggit('  !!! App.vue caught EventBus !!! ' + payload.msg);
      // });
      this.checkAuth();
      this.setUpEvents();
    },

    components: {
      PageHeader,
      PageNav,
    },
  };
</script>

/* ------------------------------------*/

<style lang="scss">
  @import './assets/themes/themes.scss';
</style>
