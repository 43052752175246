<template>
  <div class="billing-notes-for-org right">
    <div class="billing-notes-container">
      <div class="statement section pg-component">
        <div class="section-header pg-component-toolbar component-title">
          <span>{{paymentSummary}}</span>
          <div class="pg-component-toolbar-right">
            <span class="far fa-edit fa-lg" id="payment" @click="toggleEditor"></span>
          </div>
        </div>
        <div :class='{ "editing": showStatementEditor, "readonly": !showStatementEditor }'>
          <div class="internal-notes" v-if="!showStatementEditor" v-html="internalComments"></div>
          <billingNotesEditor v-else :notes="internalComments" @onUpdatingNotes="onUpdatingNotes"></billingNotesEditor>
          <div class="move-down" v-if="showQBInvoiceField">
            <label for="qbInvoiceInput">QB Invoice Number(s) </label>
            <input
              ref="qbInvoiceInput"
              id="qbInvoiceInput"
              type="text"
              v-model="qbInvoiceNumbers"
              name="qbInvoiceInput"
              @change="onUpdatingNotes"
              :disabled="!showStatementEditor"
              :class="{disabled : !showStatementEditor}"
            />
          </div>
        </div>
      </div>
      <div class="notes section pg-component">
        <div class="section-header pg-component-toolbar component-title">
          <span>Company Notes</span>
          <div class="pg-component-toolbar-right">
            <span class="far fa-edit fa-lg" id="billing" @click="toggleEditor"></span>
          </div>
        </div>
        <div v-if="!showNotesEditor" v-html="billingNotes"></div>
        <billingNotesEditor v-else :notes="billingNotes" @onUpdatingNotes="onUpdatingNotes"></billingNotesEditor>
      </div>
      <div class="details section org-details-wrapper org-details-form pg-component">
        <div class="section-header pg-component-toolbar component-title">
          <span>Company Details</span>
          <div class="pg-component-toolbar-right">
            <span class="far fa-edit fa-lg" @click="editOrgDetails"></span>
          </div>
        </div>
        <form @submit.prevent="submit">
          <fieldset :disabled="formDisabled">
            <div class="pg-form vertical">
              <div class="form-group">
                <input ref="orgNameInput" id="orgName" type="text" v-model="localOrg.orgName" name="orgName" />
                <label for="orgName">Organization Name:</label>
              </div>

              <div class="form-group">
                <input ref="qbCustomerNameInput" id="qbCustomerName" type="text" v-model="localOrg.qbCustomerName" name="orgName" />
                <label for="qbCustomerName">QB Customer Name:</label>
              </div>

              <div class="form-group">
                <input ref="assignedToInput" id="assignedTo" type="text" v-model="localOrg.assignedTo" name="assignedTo" />
                <label for="assignedTo">Assigned To:</label>
              </div>

              <div class="form-group">
                <input id="orgAddress1" type="text" v-model="localOrg.orgAddress1" name="orgAddress1" />
                <label for="orgAddress1">Address 1:</label>
              </div>

              <div class="form-group">
                <input id="orgAddress2" type="text" v-model="localOrg.orgAddress2" name="orgAddress2" />
                <label for="orgAddress2">Address 2:</label>
              </div>

              <div class="form-group">
                <input id="orgAddress3" type="text" v-model="localOrg.orgAddress3" name="orgAddress3" />
                <label for="orgAddress3">Address 3:</label>
              </div>

              <div class="form-group">
                <input id="orgCity" type="text" v-model="localOrg.orgCity" name="orgCity" />
                <label for="orgCity">City:</label>
              </div>

              <div class="form-group">
                <input id="orgStateProvCode" type="text" v-model="localOrg.orgStateProvCode" name="orgStateProvCode" />
                <label for="orgStateProvCode">State/Province:</label>
              </div>

              <div class="form-group">
                <input id="orgPostalCode" type="text" v-model="localOrg.orgPostalCode" name="orgPostalCode" />
                <label for="orgPostalCode">Postal Code:</label>
              </div>

              <div class="form-group">
                <input id="orgCountryCode" type="text" v-model="localOrg.orgCountryCode" name="orgCountryCode" />
                <label for="orgCountryCode">Country:</label>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
      <div class="users section pg-component">
        <div class="section-header pg-component-toolbar component-title"><span>UserList</span></div>
        <div class="form-group">
          <input id="userListInput" type="text" v-model="localOrg.userStr" name="userList" disabled class="disabled" />
        </div>
      </div>
    </div>
  </div>
</template>

/*************************************************************/

<script>
  import * as pgHelpers from '@/utils/PgHelpers.js';
  import BillingNotesEditor from '@/components/BillingHtmlEditor';
  var myDateFormat = 'MM/DD/YY h:mm A';

  var data = function () {
    return {
      formDisabled: true,
      localOrg: {},
      userList: '',
      paymentSummary: '',
      internalComments: '',
      billingNotes: '',
      showStatementEditor: false,
      showNotesEditor: false,
      showQBInvoiceField: false,
      qbInvoiceNumbers: '',
      paymentDetailsId: null,
      updatedNote: '',
      noteUpdated: false,
    };
  };

  var props = ['selectedOrg', 'selectedAction'];

  var beforeMount = function () {};

  var mounted = function () {
    this.showStatementEditor = false;
    this.showNotesEditor = false;
    this.showQBInvoiceField = false;
    this.noteUpdated = false;
  };

  var methods = {
    populateUserList(value) {
      this.userList = '';
      for (var x = 0; x < value.length; x++) {
        this.userList += (value[x].firstName + ' ' + value[x].lastName).trim();
        if (value.length > 1 && value.length != x + 1) {
          this.userList += ', ';
        }
      }
      return this.userList;
    },

    htmlDecode(value) {
      if (value == null) {
        return;
      }
      if (value.length > 0) {
        var comments = value
          .replace(/&amp;/gi, '&')
          .replace(/&quot;/gi, '"')
          .replace(/&#39;/gi, "'")
          .replace(/&lt;/gi, '<')
          .replace(/&gt;/gi, '>')
          .replace(/&amp;nbsp;/gi, '');
        return comments;
      }
      return '';
    },

    populatePaymentDetailSection(actionObj) {
      if (actionObj.id > 0) {
        var date = pgHelpers.dateTimeHelper.formatDate(actionObj.whenEntered, myDateFormat);
        this.paymentSummary = actionObj.action + ' entered by ' + actionObj.whoEntered + ' ' + date;
        this.internalComments = this.htmlDecode(actionObj.internalComments);
        this.qbInvoiceNumbers = actionObj.qbInvoiceNumbers;
      } else {
        this.paymentSummary = 'New ' + actionObj.action + ' being entered by ' + actionObj.whoEntered;
        this.internalComments = '';
      }
      this.paymentDetailsId = actionObj.id;
      this.showQBInvoiceField = this.paymentSummary.startsWith('State') ? true : false;
    },

    toggleEditor(e) {
      var whichEditor = e.srcElement.id;
      if (whichEditor === 'payment') {
        this.showStatementEditor = !this.showStatementEditor;
        if (this.noteUpdated) {
          if (!this.showStatementEditor) {
            if (this.paymentDetailsId == 0) {
              this.$emit('onNeedingNewBillingId', this.updatedNote);
              this.showStatementEditor = !this.showStatementEditor;
            } else {
              this.internalComments = this.htmlDecode(this.updatedNote);
              this.$emit('onUpdatingInternalComment', {
                id: this.paymentDetailsId,
                qbInvoiceNumbers: this.qbInvoiceNumbers,
                internalComments: this.internalComments,
                description: this.paymentSummary,
              });
            }
          }
        }
      } else {
        this.showNotesEditor = !this.showNotesEditor;
        if (this.noteUpdated) {
          if (!this.showNotesEditor) {
            this.billingNotes = this.htmlDecode(this.updatedNote);
            this.$emit('onUpdatedBillingNoteForOrg', this.billingNotes);
          }
        }
      }
      this.noteUpdated = false;
    },
    onUpdatingNotes(data) {
      this.noteUpdated = true;
      this.updatedNote = data;
    },
    editOrgDetails() {
      this.formDisabled = !this.formDisabled;
      if (this.formDisabled) {
        this.processDetailChanges();
      }
    },
    processDetailChanges() {
      var payload = {
        orgId: this.localOrg.orgId,
        orgName: this.localOrg.orgName,
        qbCustomerName: this.localOrg.qbCustomerName,
        orgAddress1: this.localOrg.orgAddress1,
        orgAddress2: this.localOrg.orgAddress2,
        orgAddress3: this.localOrg.orgAddress3,
        orgCity: this.localOrg.orgCity,
        orgStateProvCode: this.localOrg.orgStateProvCode,
        orgCountryCode: this.localOrg.orgCountryCode,
        orgPostalCode: this.localOrg.orgPostalCode,
        assignedTo: this.localOrg.assignedTo,
      };
      this.$emit('onUpdatedBillingDetailsForOrg', payload);
    },
  };

  var computed = {};

  var watch = {
    selectedOrg: function (value) {
      for (var prop in value) {
        if (value[prop] == 'NULL') {
          value[prop] = '';
        }
      }
      this.localOrg = Object.assign({}, value);
      this.billingNotes = this.htmlDecode(this.localOrg.billingNotes);
    },

    selectedAction(action) {
      this.showStatementEditor = false;
      this.showNotesEditor = false;
      if (action != undefined) {
        this.populatePaymentDetailSection(action);
      }
    },
  };

  var components = {
    BillingNotesEditor,
  };

  export default {
    name: 'BillingNotesForOrg',
    data,
    props,
    beforeMount,
    mounted,
    methods,
    computed,
    watch,
    components,
  };
</script>

/*************************************************************/

<style lang="scss">
  .billing-notes-for-org {
    .billing-notes-container {
      display: flex;
      flex-direction: column;
    }
    .section {
      border: 1px solid;
      margin: 0 10px 10px 10px;
    }
    .section div {
      padding-left: 5px;
      white-space: normal;
    }
    .section .html-editor,
    .statement div:nth-child(2),
    .html-editor .editor-container {
      padding-left: 0;
    }
    .pg-component-toolbar-right {
      align-items: center;
    }

    .html-editor {
      height: 80%;
      width: inherit;
      margin: 0;
    }
    .html-editor #editor-container * {
      font-size: 12px;
      padding: 0;
    }
    .html-editor .mce-container button i {
      text-align: center;
    }

    .html-editor .mce-container button span {
      margin-left: 3px;
    }

    .statement.section,
    .notes.section {
      min-height: 100px;
      position: relative;
    }
    .form-group {
      input {
        width: 60%;
      }
    }
    #userListInput {
      width: 100%;
    }
    .section-header {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      font-weight: 600;
    }
    .form-group:first-child {
      padding-top: 10px;
    }
    .users .form-group {
      padding: 8px;
    }

    .billing-section-content {
      position: relative;
    }
    .editing .move-down {
      position: relative;
      bottom: 3px;
      margin-top: 5px;
      left: 5px;
    }
    .readonly .move-down {
      position: absolute;
      bottom: 3px;
      margin-top: 5px;
      left: 5px;
    }
  }
</style>
