<template>
  <div class="sensor-grid">
    <!-- <pg-select-tooltip /> -->
    <div class="pg-component-toolbar">
      <div class="pg-component-toolbar-left">
        <div class="header-icons">
          <span class="pg-component-toolbar-item" :class="{ disabled: !allowUserInteraction }">
            <span @click="addEmptyRow" title="Add row" class="far fa-plus-circle"></span>
          </span>
          <span class="pg-component-toolbar-item" :class="{ disabled: !allowUserInteraction }">
            <span @click="sortGridByDefaults" title="Default sort" class="far fa-exchange fa-rotate-90"></span>
          </span>
        </div>
      </div>
    </div>
    <ag-grid-vue
      id="sensor-grid"
      style="width: 100%"
      class="ag-theme-balham grid-holder"
      :gridOptions="gridOptions"
      :columnDefs="columnDefs"
      :modules="agGridModules"
    >
    </ag-grid-vue>
    <sensor-data-delete-modal
      :showConfirmModal="showConfirmModal"
      @cancelledDelete="cancelledDelete"
      @confirmedDelete="confirmedDelete"
      :bodyContent="bodyContent"
    />
  </div>
</template>

/*************************************************************/

<script>
  import { AgGridVue } from '@ag-grid-community/vue';
  import { AllCommunityModules } from '@ag-grid-community/all-modules';
  import { mapGetters, mapState, mapActions } from 'vuex';
  import SensorDataTypeCellEditor from '@/utils/SensorDataTypeCellEditor.js';
  import SensorDataTypeCellRenderer from '@/utils/SensorDataTypeCellRenderer.js';
  import SensorDataFieldCellEditor from '@/utils/SensorDataFieldCellEditor.js';
  import SensorDataFieldCellRenderer from '@/utils/SensorDataFieldCellRenderer.js';
  import SensorCustomCalcCellEditor from '@/utils/SensorCustomCalcCellEditor.js';
  import SensorCustomCalcCellRenderer from '@/utils/SensorCustomCalcCellRenderer.js';
  import SensorDateCellEditor from '@/components/SensorDateCellEditor';
  import SensorDateCellRenderer from '@/utils/SensorDateCellRenderer.js';
  import environmentSpecific from 'static/environmentSpecific';
  import SensorDataDeleteModal from '@/components/SensorDataDeleteModal';
  import calculations from 'static/files/sensor-calculations.json';

  import PgSelectTooltip from '@/utils/PgSelectTooltip.js';

  var thisComponent = null;

  var editableColumns = [
    'sensorDataName',
    'dataFieldName',
    'bitOffset',
    'bitLength',
    'slope',
    'offset',
    'min',
    'max',
    'sensorDataShortName',
    'sensorDataDescription',
    'comments',
    'page',
    'timeArrayTypeId',
    'timeArrayDataId',
    'timeArrayFixedOffset',
    'timeArrayFixedOffsetTypeId',
    'offsetValueDataId',
    'depthInMeters',
    'depthDataFieldId',
    'maxIsNull',
    'isBCD',
    'isLittleEndian',
    'display',
    'processingOrder',
    'customCalculation',
    'overrideDeviceId',
    'overrideStartDate',
    'saveToDatabase',
  ];

  var rowIconCellRenderer = function (params) {
    var cellDiv = document.createElement('div');
    var deleteIcon = `<span title="Delete row" alt="Delete row" class="far fa-times-circle fa-lg"></span>`;
    var undoIcon = `<span title="Undo changes" alt="Undo changes" class="far fa-undo fa-lg"></span>`;
    if (params.context.isEditingRow) {
      cellDiv.innerHTML = undoIcon;
      var undoBtn = cellDiv.querySelectorAll('.fa-undo')[0];
      undoBtn.addEventListener('click', function () {
        var node = params.node;
        params.context.undoRowChange(node);
      });
    } else {
      cellDiv.innerHTML = deleteIcon;
      var deleteBtn = cellDiv.querySelectorAll('.fa-times-circle')[0];

      deleteBtn.addEventListener('click', function () {
        var node = params.node;
        params.context.deleteRow(node, event);
      });
    }
    return cellDiv;
  };

  var props = ['userAction', 'sensorData', 'updatedDataItem'];

  var data = function () {
    return {
      columnDefs: null,
      rowData: null,
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      agGridModules: AllCommunityModules,
      hiddenColumns: [],
      sensorDataTypes: [],
      sensorDataFields: [],
      dataTypes: [],
      units: [],
      params: {},
      isEditingRow: false,
      allowUserInteraction: false,
      lastUserAction: null,
      sensorId: null,
      dataClone: [],
      fieldsChanged: [],
      lastUserAction: null,
      showConfirmModal: false,
      bodyContent: {},
      cellFlashDelay: null,
      cellFadeDelay: null,
    };
  };

  var created = function () {
    thisComponent = this;
  };

  var beforeMount = function () {
    this.rowData = [];
    this.formulas = calculations;

    this.cellFlashDelay = 30000;
    this.cellFadeDelay = 500;

    this.getSensorDataTypes({ includeNonstandardSensorDataTypes: true }).then(response => {
      response.forEach((r, index) => (r.index = index));
      this.sensorDataTypes = response;
    });
    this.getDataFields().then(response => {
      this.sensorDataFields = response;
    });
    this.getDataTypes().then(response => {
      this.dataTypes = response;
    });
    this.getUnits().then(response => {
      this.units = response;
    });

    this.gridOptions = {
      headerHeight: 25,
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      onGridReady: this.onGridReady,
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        // enableCellChangeFlash: true,
      },
      context: {
        undoRowChange: this.undoRowChange,
        deleteRow: this.deleteRow,
        isEditingRow: this.isEditingRow,
      },
      rowSelection: 'single',
      onCellEditingStarted: this.onCellEditingStarted,
      onCellEditingStopped: this.onCellEditingStopped,
      onCellValueChanged: this.onCellValueChanged,
      // cellFlashDelay: this.cellFlashDelay,
      // cellFadeDelay: this.cellFadeDelay,
      suppressDragLeaveHidesColumns: true,
      rowHeight: environmentSpecific.gridRowHeightPx,
      onRowDoubleClicked: function () {
        if (!thisComponent.allowUserInteraction) {
          thisComponent.$emit('userClickingOnDisabledPage', 'grid');
        }
      },
      enableCellTextSelection: true,
      ensureDomOrder: true,
      stopEditingWhenGridLosesFocus: true,
      suppressKeyboardEvent: this.suppressKeyboardEvent,
      frameworkComponents: { sensorCustomCalcCellEditor: SensorCustomCalcCellEditor },
    };

    // https://www.ag-grid.com/javascript-grid-pinning/#example-pinning
    this.columnDefs = [
      {
        headerName: 'Action',
        field: 'action',
        width: 62,
        cellRenderer: rowIconCellRenderer,
        pinned: 'left',
        cellClass: 'lock-pinned',
        hide: true,
        sortable: false,
        suppressMenu: true,
        suppressMovable: true,
      },
      { headerName: 'Sensor Id', field: 'sensorId', width: 100 },
      { headerName: 'Sensor Data Id', field: 'sensorDataId', width: 125 },
      {
        headerName: 'Sensor Data Name',
        field: 'sensorDataName',
        width: 210,
        suppressKeyboardEvent: thisComponent.suppressKeyboardEvent,
        cellEditorFramework: 'SensorDataTypeCellEditor',
        cellRendererFramework: 'SensorDataTypeCellRenderer',
        cellEditorParams: function (params) {
          return { sensorDataTypes: thisComponent.sensorDataTypes };
        },
        pinned: 'left',
        cellClass: 'lock-pinned',
      },
      { headerName: 'Sensor Data Type Id', field: 'sensorDataTypeId', width: 150 },
      { headerName: 'Data Field Id', field: 'dataFieldId', width: 150 },
      {
        headerName: 'Data Field Name',
        colId: 'dataFieldName',
        field: 'dataFieldName',
        suppressKeyboardEvent: thisComponent.suppressKeyboardEvent,
        width: 215,
        valueGetter: function (params) {
          var dataField = thisComponent.sensorDataFields.find(el => el.dataFieldId === params.node.data.dataFieldId);
          return dataField != undefined ? dataField.displayName : '';
        },
        cellEditorFramework: 'SensorDataFieldCellEditor',
        cellRendererFramework: 'SensorDataFieldCellRenderer',
        cellEditorParams: function (params) {
          return { sensorDataFields: thisComponent.sensorDataFields };
        },
      },
      {
        headerName: 'Unit Id',
        field: 'unitId',
        colId: 'unitId',
        width: 105,
        valueGetter: function (params) {
          var dataField = thisComponent.sensorDataFields.find(_ => _.dataFieldId === params.node.data.dataFieldId);
          return dataField != undefined ? dataField.unitId : '';
        },
      },
      {
        headerName: 'Unit Name',
        field: 'unitName',
        colId: 'unitName',
        width: 120,
        valueGetter: function (params) {
          var dataField = thisComponent.sensorDataFields.find(_ => _.dataFieldId === params.node.data.dataFieldId);
          if (dataField != undefined) {
            var unit = thisComponent.units.find(_ => _.unitId === dataField.unitId);
            return unit != undefined ? unit.unitName : '';
          }
          return '';
        },
      },
      {
        headerName: 'Data Type Id',
        field: 'dataTypeId',
        colId: 'dataTypeId',
        width: 105,
        valueGetter: function (params) {
          console.log('datafield', params);
          var dataField = thisComponent.sensorDataFields.find(_ => _.dataFieldId === params.node.data.dataFieldId);
          if (dataField != undefined) {
            var dataType = thisComponent.dataTypes.find(_ => _.dataTypeId === dataField.dataTypeId);
            return dataType != undefined ? dataType.dataTypeId : '';
          }
          return '';
        },
      },
      {
        headerName: 'Data Type Name',
        field: 'dataTypeName',
        width: 105,
        valueGetter: function (params) {
          var dataField = thisComponent.sensorDataFields.find(_ => _.dataFieldId === params.node.data.dataFieldId);
          if (dataField != undefined) {
            var dataType = thisComponent.dataTypes.find(_ => _.dataTypeId === dataField.dataTypeId);
            return dataType != undefined ? dataType.dataTypeName : '';
          }
          return '';
        },
      },
      { headerName: 'Bit Offset', field: 'bitOffset', width: 125 },
      { headerName: 'Bit Length', field: 'bitLength', width: 105 },
      { headerName: 'Slope', field: 'slope', width: 105 },
      { headerName: 'Offset', field: 'offset', width: 105 },
      { headerName: 'Min', field: 'min', width: 100 },
      { headerName: 'Max', field: 'max', width: 100 },
      {
        headerName: 'Computed Min',
        field: 'computedMin',
        width: 140,
        valueGetter: function (params) {
          var min = params.data.min * params.data.slope + params.data.offset;
          var result = isNaN(min) ? '' : min;
          return result;
        },
      },
      {
        headerName: 'Computed Max',
        field: 'computedMax',
        width: 140,
        valueGetter: function (params) {
          var max = params.data.max * params.data.slope + params.data.offset;
          var result = isNaN(max) ? '' : max;
          return result;
        },
      },
      {
        headerName: 'Sensor Data Short Name',
        field: 'sensorDataShortName',
        valueGetter: function (params) {
          var dataField = thisComponent.sensorDataFields.find(_ => _.dataFieldId === params.node.data.dataFieldId);
          return dataField != undefined ? dataField.dataFieldName : '';
        },
      },
      { headerName: 'Sensor Data Description', field: 'sensorDataDescription' },
      { headerName: 'Comments', field: 'comments' },
      { headerName: 'Page', field: 'page' },
      { headerName: 'Time Array Type Id', field: 'timeArrayTypeId' },
      { headerName: 'Time Array Data Id', field: 'timeArrayDataId' },
      { headerName: 'Time Array Fixed Offset', field: 'timeArrayFixedOffset' },
      { headerName: 'Time Array Fixed Offset Type Id', field: 'timeArrayFixedOffsetTypeId' },
      { headerName: 'Offset Value Data Id', field: 'offsetValueDataId' },
      { headerName: 'Depth In Meters', field: 'depthInMeters', width: 146 },
      {
        headerName: 'Depth Data Field Id',
        field: 'depthDataFieldId', //   SHOULD THIS BE EDITABLE???
      },
      { headerName: 'Max Is Null', field: 'maxIsNull', width: 120 },
      { headerName: 'Is BCD', field: 'isBCD' },
      { headerName: 'Is Little Endian', field: 'isLittleEndian' },
      { headerName: 'Display', field: 'display', width: 100 },
      { headerName: 'Processing Order', field: 'processingOrder', width: 143 },
      {
        headerName: 'Custom Calculation',
        field: 'customCalculation',
        width: 330,
        suppressKeyboardEvent: thisComponent.suppressKeyboardEvent,
        cellEditorSelector: function (params) {
          if (params.value === null || params.value === '') {
            return { component: 'sensorCustomCalcCellEditor' };
          } else {
            return { component: 'agCellEditor' };
          }
        },
        // cellEditorFramework: 'SensorCustomCalcCellEditor',
        cellRendererFramework: 'SensorCustomCalcCellRenderer',
      },
      { headerName: 'Override Device Id', field: 'overrideDeviceId', width: 158 },
      {
        headerName: 'Override Start Date',
        field: 'overrideStartDate',
        width: 158,
        cellEditorFramework: 'SensorDateCellEditor',
        cellRendererFramework: 'SensorDateCellRenderer',
      },
      { headerName: 'Save To Database', field: 'saveToDatabase', width: 156 },
    ];
    this.hiddenColumns = [
      'sensorId',
      'sensorDataId',
      'sensorDataTypeId',
      'dataFieldId',
      'unitId',
      'dataTypeId',
      'page',
      'timeArrayTypeId',
      'timeArrayDataId',
      'timeArrayFixedOffset',
      'timeArrayFixedOffsetTypeId',
      'offsetValueDataId',
      'depthDataFieldId',
      'isBCD',
      'isLittleEndian',
    ];
  };

  var mounted = function () {
    this.lastUserAction = '';
  };

  var methods = {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;

      for (let x = 0; x < this.hiddenColumns.length; x++) {
        this.columnApi.setColumnVisible(`${this.hiddenColumns[x]}`, false);
      }
      // this.columnApi.setColumnVisible('action', true);
      this.gridApi.setPopupParent(document.body);
    },

    clearGridData() {
      this.gridApi.setRowData([]);
    },

    enableGridEditing(allowEditing) {
      this.columnApi.setColumnVisible('action', allowEditing);
      this.allowUserInteraction = allowEditing;
      editableColumns.forEach(col => {
        this.columnApi.getColumn(col).getColDef().editable = allowEditing;
      });
    },

    addEmptyRow() {
      if (this.sensorId === null) {
        return;
      }
      var newRow = { sensorDataId: 0, sensorId: this.sensorId };
      this.isEditingRow = true;
      this.gridOptions.context.isEditingRow = this.isEditingRow;
      this.gridApi.updateRowData({ add: [newRow] });
      // this.gridApi.applyTransaction({add: [newRow]});
      this.gridApi.startEditingCell({ rowIndex: 0, colKey: 'sensorDataName' });
    },

    onCellEditingStarted(params) {
      this.isEditingRow = true;
      this.gridOptions.context.isEditingRow = this.isEditingRow;
    },

    onCellValueChanged(params) {
      if (params.oldValue !== params.newValue) {
        this.fieldsChanged.push(params.colDef.field);
      }
    },

    onCellEditingStopped(params) {
      var brandNew = params.data.sensorDataId === 0;
      var noMissingItems = params.data.sensorDataName !== undefined && params.data.sensorId > 0;
      if (brandNew) {
        if (noMissingItems) {
          var obj = Object.assign({}, params.data);
          var action = 'add';
          this.sendToDatabase(obj, action);
        } else {
          this.deleteRow(params.node);
        }
      } else if (this.fieldsChanged.length > 0) {
        var obj = Object.assign({}, params.data);
        var action = 'update';
        this.sendToDatabase(obj, action);
      }
      this.fieldsChanged = [];
      this.isEditingRow = false;
    },

    refreshRowDataInGrid(obj) {
      var type = obj.type;
      var data = obj.response;
      var dataId = obj.response.sensorDataId;
      if (type === 'new') {
        var row0 = this.gridApi.getDisplayedRowAtIndex(0);
        if (row0.data.sensorDataId === 0) {
          row0.setDataValue('sensorDataId', dataId);
        } else {
          // if rowNode is not in the expected place
          this.gridApi.forEachNode(node => {
            if (node.data.sensorDataId === 0) {
              node.setDataValue('sensorDataId', dataId);
            }
          });
        }
      } else if (type === 'update') {
        var x = this.gridApi.getSelectedNodes();
        var row = x[0];
        this.gridApi.redrawRows({ rowNodes: [row] });
      } else if (type === 'delete') {
        var selectedNode = this.gridApi.getSelectedNodes()[0];
        var selected = this.gridApi.getSelectedRows();
        var id = selectedNode.data.sensorDataId;
        var fromParent = obj.response.sensorDataId;
        if (id === fromParent) {
          this.gridApi.updateRowData({ remove: selected });
          this.recalculateBytes();
        }
      }
    },

    sendToDatabase(obj, action) {
      if (obj.sensorDataId === null || obj.sensorDataId === undefined) {
        return;
      }
      var dataId = obj.sensorDataId;
      // AP-TODO add should have already checked for sensorId and dataName ... check into
      if (action === 'add' && obj.sensorId != undefined && obj.sensorDataName != undefined) {
        this.$emit('onNewDataRequest', obj);
      } else if (action === 'update') {
        this.$emit('onDataPutRequest', obj);
      } else if (action === 'delete') {
        this.$emit('onDataDeleteRequest', dataId);
      }
    },

    deleteRow(rowNode, event) {
      console.log(event);
      var event = event === undefined ? '' : event.type;
      console.log('event', event);
      var dataId = rowNode.data.sensorDataId;
      var name = rowNode.data.sensorDataName;
      if (rowNode.data.sensorDataId === 0) {
        this.gridApi.updateRowData({ remove: [rowNode.data] });
        this.recalculateBytes();
        // this.gridApi.applyTransaction({remove: [rowNode]});
      } else if (event === 'click') {
        var bodyMessage = `This will remove ${name} (sensor data id: ${dataId}) from the database.  Do you want to continue?`;
        this.bodyContent = {
          sensorDataId: dataId,
          message: bodyMessage,
        };
        this.showConfirmModal = true;
      } else {
        var sensorDataId = rowNode.data.sensorDataId;
        this.confirmedDelete(sensorDataId);
      }
    },
    confirmedDelete(id) {
      var obj = { sensorDataId: id };
      this.sendToDatabase(obj, 'delete');
      this.showConfirmModal = false;
    },
    cancelledDelete() {
      this.showConfirmModal = false;
      this.bodyContent = {};
    },

    undoRowChange(rowNode) {
      this.isEditingRow = false;
      this.gridOptions.context.isEditingRow = this.isEditingRow;
      var rowIndex = rowNode.rowIndex;
      var undoId = rowNode.data.sensorDataId;
      var clean = this.dataClone.filter(item => item.sensorDataId === undoId);
      if (clean.length > 0) {
        rowNode.setData(clean[0]);
        this.gridApi.redrawRows({ rowNodes: [rowNode] });
      } else {
        // it was added since user began editing
        // rowNode.data.sensorDataId = 0;
        this.deleteRow(rowNode);
      }
    },

    suppressKeyboardEvent(params) {
      if (!params.editing) {
        return false;
      }
      var KEY_A = 65;
      var KEY_C = 67;
      var KEY_V = 86;
      var KEY_D = 68;
      var KEY_PAGE_UP = 33;
      var KEY_PAGE_DOWN = 34;
      var KEY_TAB = 9;
      var KEY_LEFT = 37;
      var KEY_UP = 38;
      var KEY_RIGHT = 39;
      var KEY_DOWN = 40;
      var KEY_F2 = 113;
      var KEY_BACKSPACE = 8;
      var KEY_ESCAPE = 27;
      var KEY_ENTER = 13;
      var KEY_SPACE = 32;
      var KEY_DELETE = 46;
      var KEY_PAGE_HOME = 36;
      var KEY_PAGE_END = 35;
      var event = params.event;
      var key = event.which;
      var keysToSuppress = [KEY_TAB, KEY_ESCAPE, KEY_ENTER];
      if (params.editing) {
        var suppress = keysToSuppress.indexOf(key) >= 0;
      }
      return suppress;
    },

    cloneData() {
      this.dataClone = _.cloneDeep(this.sensorData);
    },

    recalculateBytes() {
      var bitsAndBytes = {
        total: 0,
        bytes: 0,
        bits: 0,
      };
      this.gridApi.forEachNode(function (node) {
        bitsAndBytes.total += node.data.bitLength;
      });
      bitsAndBytes.bits = bitsAndBytes.total % 8;
      bitsAndBytes.total = bitsAndBytes.total - bitsAndBytes.bits;
      bitsAndBytes.bytes = bitsAndBytes.total / 8;
      this.$emit('onUpdatedBytes', bitsAndBytes);
    },

    sortGridByDefaults() {
      var sort = [
        { colId: 'processingOrder', sort: 'asc' },
        { colId: 'bitOffset', sort: 'asc' },
        { colId: 'sensorDataName', sort: 'asc' },
      ];
      this.gridApi.setSortModel(sort);
    },
    ...mapActions('sensorModule', ['getSensorDataTypes', 'getDataFields', 'getUnits', 'getDataTypes', 'deleteSensorData']),
  };

  var computed = {};

  var watch = {
    // updated from sensor page dropdown
    sensorData: function (data) {
      if (this.sensorData === null || this.sensorData === undefined) {
        this.gridApi.setRowData([]);
        this.gridOptions.rowData = [];
        this.recalculateBytes();
      } else {
        this.gridApi.setRowData(this.sensorData);
        this.gridOptions.rowData = this.sensorData;
        this.recalculateBytes();
        this.sortGridByDefaults();
      }
    },

    updatedDataItem: function (data) {
      this.refreshRowDataInGrid(this.updatedDataItem);
    },

    // from sensor page buttons
    userAction: function (userAction) {
      var action = userAction.action;
      this.sensorId = userAction.sensorId;
      if (action === 'view') {
      } else if (action === 'edit') {
        this.lastUserAction = action;
        this.cloneData();
        this.enableGridEditing(true);
      } else if (action === 'copy') {
        this.lastUserAction = action;
        this.cloneData();
        this.enableGridEditing(true);
      } else if (action === 'create') {
        this.lastUserAction = action;
        if (this.sensorId === null) {
          this.clearGridData();
          this.recalculateBytes();
        } else {
          this.enableGridEditing(true);
        }
      } else if (action === 'save') {
        this.enableGridEditing(false);
        this.isEditingRow = false;
        this.gridOptions.context = { isEditingRow: this.isEditingRow };
        this.lastUserAction = '';
      } else if (action === 'cancel') {
        this.enableGridEditing(false);
        if (this.lastUserAction === 'copy') {
          var ids = this.sensorData.map(el => el.sensorDataId);
          this.$emit('onRemoveDataFromCancelledOp', ids);
          this.gridApi.setRowData([]);
          this.gridOptions.rowData = [];
        } else {
          this.isEditingRow = false;
          this.gridOptions.context = { isEditingRow: this.isEditingRow };
        }
        this.lastUserAction = '';
      }
    },
  };

  var components = {
    AgGridVue,
    SensorDataTypeCellEditor,
    SensorDataTypeCellRenderer,
    SensorDataFieldCellEditor,
    SensorDataFieldCellRenderer,
    SensorCustomCalcCellEditor,
    SensorCustomCalcCellRenderer,
    SensorDateCellEditor,
    SensorDateCellRenderer,
    SensorDataDeleteModal,
    PgSelectTooltip,
  };

  export default {
    name: 'SensorGrid',
    data,
    created,
    beforeMount,
    mounted,
    methods,
    computed,
    watch,
    components,
    props,
  };
</script>

/*************************************************************/

<style scoped>
  .sensor-grid >>> .ag-overlay {
    pointer-events: auto;
  }

  .ag-popup-editor {
    min-width: 10%;
  }
  .pg-component-toolbar {
    display: flex;
    font-size: 16px;
    font-weight: 400;
    flex-direction: row;
    align-items: center;
  }
  .pg-component-toolbar + div {
    flex: 1;
  }
  .header-icons {
    display: flex;
    padding: 2px 0;
  }

  .pg-component-toolbar-item {
    /* border: none; */
    align-items: center;
  }
  .disabled {
    pointer-events: none;
    opacity: 0.3;
  }
</style>
