<template>
  <pg-modal :show="showProfile" @close="close">
    <div class="user-profile popup-window">
      <div class="modal-body">
        <div class="popup-header">
          User Profile
          <i class="far fa-times-square closer" v-on:click="closeMe()" />
        </div>
        <div class="popup-content">
          <div v-if="true /*isDeveloper*/">
            Select a theme<br />
            <pg-select id="themePicker" :options="themes" v-model="selectedTheme" label="name" v-on:input="onDropdownInput" :allow-empty="false">
            </pg-select>
          </div>
          <div v-if="isPgStaff">
            Change organization<br />
            <pg-select
              id="orgPicker"
              :options="organizations"
              :value="selectedOrg"
              label="orgName"
              :custom-label="customLabel"
              track-by="orgId"
              @input="onDropdownInput"
              :allow-empty="false"
            >
            </pg-select>
          </div>
          <div class="profile-data">
            <label class="profile-prompt"
              >API Key:
              <span class="profile-text">{{ $pgGlobal.currentUser.apiKey }}</span>
            </label>
          </div>
          <hr />
          <div class="button-area">
            <a id="logout-button" class="floating-button" href="#" v-on:click="logmeout">Logout</a>
          </div>
        </div>
      </div>
    </div>
  </pg-modal>
</template>

<script>
  import { mapGetters, mapState, mapActions } from 'vuex';
  import environmentSpecific from 'static/environmentSpecific';
  import ProfileService from '@/services/ProfileService';
  import AvailableThemes from '@/assets/themes/themes.json';
  import PgSelect from '@/components/Common/PgSelect';
  import PgModal from '@/components/Common/PgModal';
  import { EventBus } from '@/EventBus';

  // moved to /static/utils/jQueryPrototypes.js
  // $.fn.removeClassRegex = function(regex) {
  //   var obj =this;
  //   var cssClasses = $(this).attr('class').split(' ');
  //   for (var i=0; i<cssClasses.length; i++){
  //     var cssClass= cssClasses[i];
  //     if (cssClass.match(regex)) {
  //       $(this).removeClass(cssClass);
  //     }
  //   }
  //   return obj;
  // };

  var data = function() {
    return {
      themes: AvailableThemes.themes,
      selectedTheme: null,
    };
  };

  var props = ['showProfile'];

  var computed = {
    ...mapState('organizationModule', ['organizations', 'selectedOrganization']),
    ...mapState('userModule', ['currentUser']),
    ...mapGetters('userModule', ['userHasRole']),
    isPgStaff: function() {
      return this.userHasRole('PGStaff');
    },
    isDeveloper: function() {
      return this.userHasRole('Developer');
    },
    selectedOrg: function() {
      if (this.isPgStaff) {
        if (this.selectedOrganization.orgId) {
          return this.selectedOrganization;
        }
        //var selected = this.organizations.find(o => o.orgId == this.selectedOrganization.orgId);
        this.getSelectedOrganization().then(org => {
          return org;
        });
      }
    },
  };

  var created = function() {
    var theme = ProfileService.getLocalSetting('theme', 'standard-theme');
    this.selectedTheme = this.themes.find(_ => _.selector == theme);
    this.$pgGlobal.currentTheme = theme;
    // console.log('>> created');
  };

  var beforeMount = function() {
    // console.log('>> beforeMount');
  };

  var mounted = function() {
    // var selectedOrgId = ProfileService.getLocalSetting('selectedOrgId', null);
    // if (selectedOrgId) {
    //   this.setSelectedOrganizationByOrgId(selectedOrgId);
    // }
  };

  var methods = {
    ...mapActions('userModule', ['logout']),
    ...mapActions('organizationModule', ['setSelectedOrganizationByOrgId', 'getSelectedOrganization']),
    ...mapActions('deviceModule', ['setSelectedDeviceIds']),

    customLabel({ orgName, numberActiveDevices }) {
      //var numActive = orgName === 'All Organizations' ? '' : '[' + numberActiveDevices + ']';
      var numActive = '[' + numberActiveDevices + ']';
      return `${orgName} ${numActive}`;
    },

    logmeout() {
      this.logout();
      window.location.href = environmentSpecific.loginUrl;
    },
    closeMe() {
      // console.log('emiting close...');
      this.$emit('input', { showProfile: false });
    },
    close: function() {
      this.$emit('close', { showProfile: false });
      this.title = '';
      this.body = '';
    },
    onDropdownInput(value, id) {
      if (value === null) {
        return;
      }
      if (id === 'themePicker') {
        this.selectedTheme = value;
      } else if (id === 'orgPicker') {
        this.setSelectedOrganizationByOrgId(value.orgId);
        this.closeMe();
      }
    },
  };

  var watch = {
    selectedTheme: function(value) {
      this.$pgGlobal.currentTheme = value.selector;
      $('body')
        .removeClassRegex('-theme$')
        .addClass(value.selector);
      ProfileService.saveLocalSetting('theme', this.selectedTheme.selector);
      this.closeMe();
      EventBus.$emit('THEME_CHANGED', value.selector);
    },
  };

  var components = {
    PgSelect,
    PgModal,
  };

  export default {
    name: 'UserProfile',
    data,
    props,
    created,
    beforeMount,
    mounted,
    methods,
    computed,
    watch,
    components,
  };
</script>

<style scoped>
  .user-profile {
    padding: 0;
  }
  .modal-body {
    margin: 0;
  }
  .popup-content {
    padding: 10px;
  }
  .popup-content > div {
    padding-bottom: 5px;
  }
  .button-area {
    display: flex;
    justify-content: center;
  }
  .floating-button {
    text-decoration: none;
    border-radius: 2px;
    margin-top: 1em;
    padding: 0.7em 2em;
    border: 0;
    transition: all 0.15s ease-in-out;
    text-transform: uppercase;
    cursor: pointer;
  }
  .big-button:hover {
    background-color: #5eadff;
  }
</style>
