<!-- template for the modal component -->
<template id="activation-confirm-modal">
  <pg-modal :show="show" @close="close" class="">

    <div class="popup-window activation-confirm-popup">
      <div class="popup-header">
        Please Confirm
      </div>

      <div class="popup-content text-padding">

        <div class="">
          <span>{{ action }} {{ billingInfo.chargeForUpdate ? 'the billing plan for ' : ''}} the following {{ deviceNames.length === 1 ? 'device' : 'devices' }}:</span>
        </div>

        <div class="">
          <ul>
            <li v-for="(device, index) of deviceNames" :key="index">{{device}}</li>
          </ul>
          <br/>

          <div v-if="action === 'Activate'">
            <span>Activation Fee: {{ convertToCurrency(billingInfo.activationFee)}}</span><br/>
            <span>Monthly Service Fee:  {{ convertToCurrency(billingInfo.monthlyFee)}}</span><br/>
            <span>Total cost for {{deviceNames.length}} {{ deviceNames.length === 1 ? 'device' : 'devices' }}:
              {{ convertToCurrency((billingInfo.monthlyFee + billingInfo.activationFee)*deviceNames.length) }}
            </span>
          </div>
          <div v-if="action === 'Suspend'">
            <span>Monthly Suspended Fee: {{ convertToCurrency(billingInfo.monthlySuspendedFee)}}</span><br/>
            <span>vs regular Monthly Service Fee:  {{ convertToCurrency(billingInfo.monthlyFee)}}</span><br/>
            <span>for {{ deviceNames.length === 1 ? 'this device' : 'these devices' }}</span>
          </div>
          <div v-if="action === 'Unsuspend'">
            <span>Resume monthly service for {{ deviceNames.length === 1 ? 'this device' : 'these devices' }}: </span><br/>
            <span>Cost: {{ convertToCurrency(billingInfo.monthlyFee)}}</span>
          </div>
          <div v-if="action === 'Update' && billingInfo.chargeForUpdate">
            <span>Monthly service fee: {{ convertToCurrency(billingInfo.monthlyFee)}}</span><br/>
            <span>Total cost for {{deviceNames.length}} {{ deviceNames.length === 1 ? 'device' : 'devices' }}:
              {{ convertToCurrency(billingInfo.monthlyFee*deviceNames.length) }}
            </span>
          </div>
          <div v-if="action === 'Deactivate'">
          </div>
        </div>
      </div>

      <div class="popup-footer popup-button-wrapper">
        <button class="" @click="close()">
            Cancel
        </button>
        <button class="" @click="confirm()">
            Confirm
        </button>
      </div>

    </div>

  </pg-modal>
</template>

<script>
import PgModal from "@/components/Common/PgModal";

export default {
  name: "ActivationConfirmModal",
  props: ["show", "action", "deviceNames", "billingInfo"],
  components: { PgModal },
  data() {
    return { msg: null };
  },
  methods: {
    close: function() {
      this.$emit("close");
      // this.title = "";
      // this.body = "";
    },
    confirm: function() {
      this.$emit('confirmed');
      this.close();
    },
    convertToCurrency: function(value) {
      if (value === undefined) {
        return;
      }
      return value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      });
    },

  }
};
</script>


/*************************************************************/

<style lang="scss">
// .activation-confirm-modal {
//   .modal-container.pg-modal {
//     padding: 0 4px 4px;
//     // border: 2px solid gray;

//     .modal-title {
//       // background: #569ff7;
//       border-bottom: 1px solid gray;
//       padding: 6px 10px 6px;
//       margin: 0 -4px;
//     }
//     .modal-body-wrapper {
//       // border: 1px solid #bdc3c7;
//       margin-top: 5px;
//       padding: 3px;
//     }
//     .modal-body {
//       margin: 10px 0;
//     }
//     .modal-body-wrapper ul {
//       list-style-type: none;
//       padding-left: 0;
//       margin: 0 8px;
//     }
//     .modal-button-wrapper {
//       padding-top: 6px;
//     }

//   }
// }

</style>
