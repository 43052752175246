import Vue from 'vue';
import PgSelect from '@/components/Common/PgSelect';

export default Vue.extend({
	template: `<div v-if="sensorDataFields != undefined" ref="dataFieldWrapper" id="dataFieldWrapper">
                <pg-select
                  ref="sensorDataField"
                  id="sensorDataField"
                  :options='sensorDataFields'
                  :value='selectedDataField'
                  label='displayName'
                  track-by='dataFieldId'
                  v-on:input="onDropdownInput"
                  :close-on-select="closeOnSelect"
                >
                </pg-select>
            </div>`,
	data() {
		return {
			sensorDataFields: [],
			selectedDataField: {},
			closeOnSelect: null,
			ref: null,
		};
	},

	beforeMount() {
		this.sensorDataFields = this.params.sensorDataFields;
		this.selectedDataField = this.sensorDataFields.find(_ => _.dataFieldId === this.params.node.data.dataFieldId);
	},
	beforeDestroy() {
		var y = document.getElementById('sensorDataField');
		if (y === document.activeElement) {
			document.getElementById('sensorDataField').blur();
		}
	},
	mounted() {
		var width = this.params.colDef.width;
		this.closeOnSelect = true;
		this.ref = this.$refs.sensorDataField;
		this.$nextTick(() => {
			var parent = this.$refs.dataFieldWrapper.parentNode;
			parent.style.width = width + 'px';
		});
	},

	methods: {
		onDropdownInput(value) {
			this.ref.$parent.$el.focus();
			this.$nextTick(() => {
				if (value === null) {
					return;
				}
				this.selectedDataField = value;
				this.params.node.setDataValue('dataFieldId', value.dataFieldId);
				this.params.node.setDataValue('dataFieldName', value.displayName);
				this.params.node.setDataValue('dataTypeId', value.dataTypeId);
				this.params.node.setDataValue('unitId', value.unitId);
				this.params.stopEditing();
			});
		},
		isPopup() {
			return true;
		},

		getValue() {
			// if (this.selectedSensorDataField != null) {
			var selected = this.selectedDataField.displayName;
			return selected;
			// }
		},
	},
	components: {
		PgSelect,
	},
});
