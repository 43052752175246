<template>
  <div class="billing-report-details pg-component" v-if="displayDetails">
    <h2>Details</h2>
    <div class="activation" v-if="activations.length > 0">
      <div class="heading" @click="hideDiv" id="activations">Activations ${{activationFeeTotal.toFixed(2)}}</div>
      <div class="feeDetails" :class="{'hide': hideActivations}">
        <table class="feeTable">
          <tr class="columnNames">
            <td>Device</td>
            <td>Comm ID</td>
            <td>Network</td>
            <td>Type</td>
            <td>Plan</td>
            <td>Activated</td>
            <td>Fee</td>
          </tr>
          <tr v-for="(a, index) in activations" :key="index">
            <td>{{a.deviceName}}</td>
            <td>{{a.commIdentifier}}</td>
            <td>{{a.networkName}}</td>
            <td>{{a.deviceTypeName}}</td>
            <td>{{a.pgPlanName}}</td>
            <td>{{a.activationDate}}</td>
            <td class="amount">${{a.activationFee.toFixed(2)}}</td>
          </tr>
          <tr>
            <td colspan="6" class="categoryTotalText">Activation Total</td>
            <td class="amount">${{activationFeeTotal.toFixed(2)}}</td>
          </tr>
        </table>
      </div>
    </div>

    <div class="monthly" v-if="monthly.length > 0 && monthlyFeeTotal != 0">
      <div class="heading" @click="hideDiv" id="monthly">Monthly ${{monthlyFeeTotal.toFixed(2)}}</div>
      <div class="feeDetails" :class="{'hide': hideMonthly}">
        <table class="feeTable">
          <tr class="columnNames">
            <td>Device</td>
            <td>Comm ID</td>
            <td>Network</td>
            <td>Type</td>
            <td>Plan</td>
            <td>Fee</td>
          </tr>
          <tbody v-for="(m, index) in monthly" :key="index">
            <tr class="monthDivider" v-if="m.header" colspan="6">
              <td>{{m.header}}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>

            <tr v-else>
              <td>{{m.deviceName}}</td>
              <td>{{m.commIdentifier}}</td>
              <td>{{m.networkName}}</td>
              <td>{{m.deviceTypeName}}</td>
              <td>{{m.pgPlanName}}</td>
              <td class="amount">${{m.monthlyFee.toFixed(2)}}</td>
            </tr>
          </tbody>
          <tr>
            <td colspan="5" class="categoryTotalText">Monthly Total</td>
            <td class="amount">${{monthlyFeeTotal.toFixed(2)}}</td>
          </tr>
        </table>
        <div class="legend"></div>
      </div>
    </div>

    <div class="suspended" v-if="suspended.length > 0 && suspendedFeeTotal != 0">
      <div class="heading" @click="hideDiv" id="suspended">Suspended Telemetry ${{suspendedFeeTotal.toFixed(2)}}</div>
      <div class="feeDetails" :class="{'hide': hideSuspended}">
        <table class="feeTable">
          <tr class="columnNames">
            <td>Device</td>
            <td>Comm ID</td>
            <td>Plan</td>
            <td>Network</td>
            <td>Fee</td>
          </tr>
          <tbody v-for="(s, index) in suspended" :key="index">
            <tr class="monthDivider" v-if="s.header" colspan="5">
              <td>{{s.header}}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr v-else>
              <td>{{s.deviceName}}</td>
              <td>{{s.commIdentifier}}</td>
              <td>{{s.pgPlanName}}</td>
              <td>{{s.networkName}}</td>
              <td class="amount">${{s.monthlySuspendedFee.toFixed(2)}}</td>
            </tr>
          </tbody>
          <tr>
            <td colspan="4" class="categoryTotalText">Suspended Telemetry Total</td>
            <td class="amount">${{suspendedFeeTotal.toFixed(2)}}</td>
          </tr>
        </table>
        <div class="legend"></div>
      </div>
    </div>

    <div class="iridium" v-if="iridiumWithData.length > 0 && iridiumWithDataFeeTotal != 0">
      <div class="heading" @click="hideDiv" id="iridiumWithData">Iridium with Data Services ${{iridiumWithDataFeeTotal.toFixed(2)}}</div>
      <div class="feeDetails" :class="{'hide': hideIridiumWithData}">
        <table class="feeTable">
          <tr class="columnNames">
            <td>Device</td>
            <td>Direction</td>
            <td>Msg Size</td>
            <td>Min Msg Size</td>
            <td>Num Msgs</td>
            <td>Base Charge</td>
            <td>Additional Bytes</td>
            <td>Total</td>
          </tr>
          <tbody v-for="(i, index) in iridiumWithData" :key="index">
            <tr class="monthDivider" v-if="i.header" colspan="8">
              <td>{{i.header}}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr v-else>
              <td>{{i.deviceName}}</td>
              <td>{{i.direction}}</td>
              <td>{{i.msgSize}}</td>
              <td>{{i.minMsgSize}}</td>
              <td>{{i.numMsgs}}</td>
              <td class="amount">${{i.minMsgCharge.toFixed(2)}}</td>
              <td class="amount">${{i.addlMsgCharge.toFixed(2)}}</td>
              <td class="amount">${{i.total.toFixed(2)}}</td>
            </tr>
          </tbody>
          <tr>
            <td colspan="7" class="categoryTotalText">Iridium Total</td>
            <td class="amount">${{iridiumWithDataFeeTotal.toFixed(2)}}</td>
          </tr>
        </table>
      </div>
    </div>

    <div class="iridium" v-if="iridiumWithoutData.length > 0 && iridiumWithoutDataFeeTotal != 0">
      <div class="heading" @click="hideDiv" id="iridiumWithoutData">Iridium Telemtry Only ${{iridiumWithoutDataFeeTotal.toFixed(2)}}</div>
      <div class="feeDetails" :class="{'hide': hideIridiumWithoutData}">
        <table class="feeTable">
          <tr class="columnNames">
            <td>Device Name</td>
            <td>Plan</td>
            <td>Bytes Transmitted</td>
            <td>Included Bytes</td>
            <td>Addl Bytes</td>
            <td>Fee per 1000 bytes</td>
            <td>Addl Bytes Charge</td>
          </tr>
          <tbody v-for="(i, index) in iridiumWithoutData" :key="index">
            <tr class="monthDivider" v-if="i.header" colspan="7">
              <td>{{i.header}}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr v-else>
              <td>{{i.deviceName}}</td>
              <td>{{i.pgPlanName}}</td>
              <td>{{i.numBytes}}</td>
              <td>{{i.monthlyIncludedBytes}}</td>
              <td>{{i.billableBytes}}</td>
              <td class="amount">${{i.addlBytesFee.toFixed(2)}}</td>
              <td class="amount">${{i.addlBytesCharge.toFixed(2)}}</td>
            </tr>
          </tbody>
          <tr>
            <td colspan="6" class="categoryTotalText">Iridium Telemetry Only Total</td>
            <td class="amount">${{iridiumWithoutDataFeeTotal.toFixed(2)}}</td>
          </tr>
        </table>
        <div class="legend"></div>
      </div>
    </div>

    <div class="rudics" v-if="rudics.length > 0 && rudicsFeeTotal != 0">
      <div class="heading" @click="hideDiv" id="rudics">RUDICS ${{rudicsFeeTotal.toFixed(2)}}</div>
      <div class="feeDetails" :class="{'hide': hideRudics}">
        <table class="feeTable">
          <tr class="columnNames">
            <td>Device</td>
            <td>NumSeconds</td>
            <td>Per Minute Fee</td>
            <td>Total</td>
          </tr>
          <tbody v-for="(r, index) in rudics" :key="index">
            <tr class="monthDivider" v-if="r.header" colspan="4">
              <td>{{r.header}}</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr v-else>
              <td>{{r.deviceName}}</td>
              <td>{{r.numDataSeconds}}</td>
              <td class="amount">${{r.perMinuteFee}}</td>
              <td class="amount">${{r.total.toFixed(2)}}</td>
            </tr>
          </tbody>
          <tr>
            <td colspan="3" class="categoryTotalText">RUDICS Total</td>
            <td class="amount">${{rudicsFeeTotal.toFixed(2)}}</td>
          </tr>
        </table>
        <div class="legend">RUDICS transmissions are billed in 20 second increments.</div>
      </div>
    </div>

    <div class="rudicsTelemetryOnly" v-if="rudicsTelemetryOnly.length > 0 && rudicsTelemetryOnlyFeeTotal != 0">
      <div class="heading" @click="hideDiv" id="rudicsTelemetryOnly">RUDICS Telemetry Only ${{rudicsTelemetryOnlyFeeTotal.toFixed(2)}}</div>
      <div class="feeDetails" :class="{'hide': hideRudicsTelemetryOnly}">
        <table class="feeTable">
          <tr class="columnNames">
            <td>Device</td>
            <td>NumSeconds</td>
            <td>Per Minute Fee</td>
            <td>Total</td>
          </tr>
          <tbody v-for="(r, index) in rudicsTelemetryOnly" :key="index">
            <tr class="monthDivider" v-if="r.header" colspan="4">
              <td>{{r.header}}</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr v-else>
              <td>{{r.deviceName}}</td>
              <td>{{r.numDataSeconds}}</td>
              <td class="amount">${{r.perMinuteFee}}</td>
              <td class="amount">${{r.total.toFixed(2)}}</td>
            </tr>
          </tbody>
          <tr>
            <td colspan="3" class="categoryTotalText">RUDICS Telemetry Only Total</td>
            <td class="amount">${{rudicsTelemetryOnlyFeeTotal.toFixed(2)}}</td>
          </tr>
        </table>
        <div class="legend">RUDICS transmissions are billed in 20 second increments.</div>
      </div>
    </div>

    <div class="globalstar" v-if="globalstar.length > 0 && globalstarFeeTotal != 0">
      <div class="heading" @click="hideDiv" id="globalstar">Globalstar ${{globalstarFeeTotal.toFixed(2)}}</div>
      <div class="feeDetails" :class="{'hide': hideGlobalstar}">
        <table class="feeTable">
          <tr class="columnNames">
            <td>Device</td>
            <td>ESN</td>
            <td>Msg Size</td>
            <td>Num Msgs</td>
            <td>Min Msg Charge</td>
            <td>Addl Msg Charge</td>
            <td>Total</td>
          </tr>
          <tbody v-for="(g, index) in globalstar" :key="index">
            <tr class="monthDivider" v-if="g.header" colspan="7">
              <td>{{g.header}}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr v-else>
              <td>{{g.deviceName}}</td>
              <td>{{g.commIdentifier}}</td>
              <td>{{g.msgSize}}</td>
              <td>{{g.numMsgs}}</td>
              <td class="amount">${{g.minMsgCharge.toFixed(2)}}</td>
              <td class="amount">${{g.addlBytesCharge.toFixed(2)}}</td>
              <td class="amount">${{g.total.toFixed(2)}}</td>
            </tr>
          </tbody>
          <tr>
            <td colspan="6" class="categoryTotalText">Globalstar Total</td>
            <td class="amount">${{globalstarFeeTotal.toFixed(2)}}</td>
          </tr>
        </table>
        <div class="legend">
          Rate: First {{globalstarMinBytesPerMessage}} bytes of each message ${{globalstarPriceForMinMessage.toFixed(3)}}. Additional bytes
          {{globalstarPriceAdditionalBytes.toFixed(4)}} each.
        </div>
      </div>
    </div>

    <div class="processingOnly" v-if="processingOnly.length > 0 && processingOnlyFeeTotal != 0">
      <div class="heading" @click="hideDiv" id="processingOnly">Processing Only ${{processingOnlyFeeTotal.toFixed(2)}}</div>
      <div class="feeDetails" :class="{'hide': hideProcessingOnly}">
        <table class="feeTable">
          <tr class="columnNames">
            <td>Device</td>
            <td>Direction</td>
            <td>Msg Size</td>
            <td>Min Msg Size</td>
            <td>Num Msgs</td>
            <td>Base Charge</td>
            <td>Additional Bytes</td>
            <td>Total</td>
          </tr>
          <tbody v-for="(p, index) in processingOnly" :key="index">
            <tr class="monthDivider" v-if="p.header" colspan="8">
              <td>{{p.header}}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr v-else>
              <td>{{p.deviceName}}</td>
              <td>{{p.direction}}</td>
              <td>{{p.msgSize}}</td>
              <td>{{p.minMsgSize}}</td>
              <td>{{p.numMsgs}}</td>
              <td class="amount">${{p.minMsgCharge.toFixed(2)}}</td>
              <td class="amount">${{p.addlMsgCharge.toFixed(2)}}</td>
              <td class="amount">${{p.total.toFixed(2)}}</td>
            </tr>
          </tbody>
          <tr>
            <td colspan="7" class="categoryTotalText">Processing Total</td>
            <td class="amount">${{processingOnlyFeeTotal.toFixed(2)}}</td>
          </tr>
        </table>
        <div class="legend">
          Rate: First {{processingOnlyMinBytesPerMessage}} bytes of each message ${{processingOnlyPriceForMinMessage.toFixed(3)}}. Additional bytes
          ${{processingOnlyPriceAdditionalBytes.toFixed(4)}} each.
        </div>
      </div>
    </div>

    <div class="sms" v-if="sms.length > 0 && smsFeeTotal != 0">
      <div class="heading" @click="hideDiv" id="sms">SMS ${{smsFeeTotal.toFixed(2)}}</div>
      <div class="feeDetails" :class="{'hide': hideSms}">
        <table class="feeTable">
          <tr class="columnNames">
            <td>PhoneNumber</td>
            <td>Direction</td>
            <td>Number of Messages</td>
            <td>Fee</td>
          </tr>
          <tbody v-for="(s, index) in sms" :key="index">
            <tr class="monthDivider" v-if="s.header" colspan="4">
              <td>{{s.header}}</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr v-else-if="s.phoneNumber != null">
              <td>{{s.phoneNumber}}</td>
              <td>{{s.direction}}</td>
              <td>{{s.numMsgs}}</td>
              <td class="amount">{{s.fee}}</td>
            </tr>
          </tbody>
          <tr>
            <td colspan="3" class="categoryTotalText">SMS Total</td>
            <td class="amount">${{smsFeeTotal}}</td>
          </tr>
        </table>
        <div class="legend">SMS Rate: $ per month plus per text message.</div>
      </div>
    </div>

    <div class="unused" v-if="unusedDevices && unusedDevices.length > 0">
      <h3>Money saving tip:</h3>
      <p>
        The following devices have been activated and are charged a monthly fee, but are not currently sending data. They can be deactivated on the
        Pacific Gyre website at <a href="https://www.pacificgyre.com">https://www.pacificgyre.com</a> or by calling +1 (760) 433-6300.
      </p>

      <p v-if="unusedGlobalstarDevices">There is a fee for re-activating Globalstar devices.</p>
      <span v-for="(d, index) in unusedDevices" :key="index">{{d.deviceName}}</span>
    </div>
  </div>
</template>

/*************************************************************/

<script>
  import * as pgHelpers from '@/utils/PgHelpers.js';

  var data = function () {
    return {
      activations: [],
      activationFeeTotal: 0,
      monthly: [],
      monthlyFeeTotal: 0,
      suspended: [],
      suspendedFeeTotal: 0,
      iridiumWithData: [],
      iridiumWithDataFeeTotal: 0,
      iridiumWithoutData: [],
      iridiumWithoutDataFeeTotal: 0,
      rudics: [],
      rudicsFeeTotal: 0,
      rudicsTelemetryOnly: [],
      rudicsTelemetryOnlyFeeTotal: 0,
      globalstar: [],
      globalstarFeeTotal: 0,
      globalstarMinBytesPerMessage: 0,
      globalstarPriceForMinMessage: 0,
      globalstarPriceAdditionalBytes: 0,
      processingOnly: [],
      processingOnlyFeeTotal: 0,
      processingOnlyMinBytesPerMessage: 0,
      processingOnlyPriceForMinMessage: 0,
      processingOnlyPriceAdditionalBytes: 0,
      sms: [],
      smsFeeTotal: 0,
      displayDetails: false,
      hideActivations: false,
      hideMonthly: false,
      hideSuspended: false,
      hideIridiumWithData: false,
      hideIridiumWithoutData: false,
      hideRudics: false,
      hideRudicsTelemetryOnly: false,
      hideGlobalstar: false,
      hideProcessingOnly: false,
      hideSms: false,

      unusedGlobalstarDevices: false,
    };
  };

  var props = ['billingDetails', 'unusedDevices'];

  var beforeMount = function () {};

  var mounted = function () {};

  var methods = {
    getActivationData(data, dates) {
      this.activations = data.filter(d => d.feeType === 'Activation');
      var activationFeeArray = this.activations.map(el => {
        return el.activationFee;
      });
      this.activationFeeTotal = activationFeeArray.reduce((previousValue, currentValue) => previousValue + currentValue, 0);

      this.activations.forEach(a => {
        a.activationDate = pgHelpers.dateTimeHelper.formatDate(a.activationDate, 'MM/DD/YYYY');
      });
    },

    getMonthlyData(data, dates) {
      var monthly = data.filter(d => d.feeType === 'Monthly');
      var monthlyFeeArray = monthly.map(el => {
        return el.monthlyFee;
      });
      this.monthly = this.createDetailArray(dates, monthly);
      this.monthlyFeeTotal = monthlyFeeArray.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
    },

    getSuspendedData(data, dates) {
      var suspended = data.filter(d => d.feeType === 'Suspended');
      var suspendedFeeArray = suspended.map(s => {
        return s.monthlySuspendedFee;
      });
      this.suspended = this.createDetailArray(dates, suspended);
      this.suspendedFeeTotal = suspendedFeeArray.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
    },

    getIridiumWithData(data, dates) {
      var iridiumWithData = data.filter(d => d.feeType === 'IridiumWithData');
      var iridiumWithDataFeeArray = iridiumWithData.map(s => {
        return s.total;
      });
      this.iridiumWithData = this.createDetailArray(dates, iridiumWithData);
      this.iridiumWithDataFeeTotal = iridiumWithDataFeeArray.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
    },

    getIridiumWithoutData(data, dates) {
      // Iridium Telemetry Only
      var iridiumWithoutData = data.filter(d => d.feeType === 'IridiumWithoutData');
      var iridiumWithoutDataFeeArray = iridiumWithoutData.map(s => {
        return s.addlBytesCharge;
      });
      this.iridiumWithoutData = this.createDetailArray(dates, iridiumWithoutData);
      this.iridiumWithoutDataFeeTotal = iridiumWithoutDataFeeArray.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
    },

    getRudics(data, dates) {
      var rudics = data.filter(d => d.feeType === 'RUDICS');
      var rudicsFeeArray = rudics.map(s => {
        return s.total;
      });
      this.rudics = this.createDetailArray(dates, rudics);
      this.rudicsFeeTotal = rudicsFeeArray.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
    },

    getRudicsTelemetryOnly(data, dates) {
      var rudicsTelemetryOnly = data.filter(d => d.feeType === 'RUDICSTelemetryOnly');
      var rudicsTelemetryOnlyFeeArray = rudicsTelemetryOnly.map(s => {
        return s.total;
      });
      this.rudicsTelemetryOnly = this.createDetailArray(dates, rudicsTelemetryOnly);
      this.rudicsTelemetryOnlyFeeTotal = rudicsTelemetryOnlyFeeArray.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
    },

    getGlobalstar(data, dates) {
      var globalstar = data.filter(d => d.feeType === 'Globalstar');
      var globalstarFeeArray = globalstar.map(s => {
        return s.total;
      });
      this.globalstar = this.createDetailArray(dates, globalstar);
      this.globalstarFeeTotal = globalstarFeeArray.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
      if (data[0].minMsgSize != null) {
        this.globalstarMinBytesPerMessage = data[0].minMsgSize;
      }
      if (data[0].monthlyFee != null) {
        this.globalstarPriceForMinMessage = data[0].monthlyFee;
      }
      if (data[0].addlBytesFee != null) {
        this.globalstarPriceAdditionalBytes = data[0].addlBytesFee;
      }
    },

    getProcessingOnly(data, dates) {
      var processingOnly = data.filter(d => d.feeType === 'ProcessingOnly');
      var processingOnlyFeeArray = processingOnly.map(s => {
        return s.total;
      });
      this.processingOnly = this.createDetailArray(dates, processingOnly);
      this.processingOnlyFeeTotal = processingOnlyFeeArray.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
      if (data[0].minMsgSize != null) {
        this.processingOnlyMinBytesPerMessage = data[0].minMsgSize;
      }
      if (data[0].perMessageFee != null) {
        this.processingOnlyPriceForMinMessage = data[0].perMessageFee;
      }
      if (data[0].addlBytesFee != null) {
        this.processingOnlyPriceAdditionalBytes = data[0].addlBytesFee;
      }
    },

    getSMS(data, dates) {
      var sms = data.filter(d => d.feeType === 'SMS');
      var smsFeeArray = sms.map(s => {
        return s.total;
      });
      this.sms = this.createDetailArray(dates, sms);
      this.smsFeeTotal = smsFeeArray.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
    },

    getMonthLabels(data) {
      var allDates = data.map(d => d.billingMonth);
      var uniqDates = [...new Set(allDates)];
      var monthYear = [];
      for (var x = 0; x < uniqDates.length; x++) {
        let formattedDate = pgHelpers.dateTimeHelper.formatDate(uniqDates[x], 'MMMM YYYY');
        let yyyymm = uniqDates[x].substr(0, 7);
        monthYear.push({ month: yyyymm, label: formattedDate });
      }

      if (monthYear.length > 1) {
        monthYear.sort(function (a, b) {
          if (a.month < b.month) return -1;
          if (a.month > b.month) return 1;
          return 0;
        });
      }
      return monthYear;
    },

    createDetailArray(monthYear, data) {
      var arrayWithDateLabels = [];
      var dataByMonth = [];
      var tempArray = [];

      monthYear.forEach(my => {
        tempArray = [];
        arrayWithDateLabels.push({ header: my.label });
        dataByMonth = data.map(d => {
          if (d.billingMonth.substr(0, 7) === my.month) {
            arrayWithDateLabels.push(d);
            tempArray.push(d);
          }
          return d;
        });
        if (tempArray.length === 0) {
          arrayWithDateLabels.pop();
        }
      });
      return arrayWithDateLabels;
    },
    hideDiv(e) {
      if (e.srcElement.id === 'activations') {
        this.hideActivations = !this.hideActivations;
      }
      if (e.srcElement.id === 'monthly') {
        this.hideMonthly = !this.hideMonthly;
      }
      if (e.srcElement.id === 'suspended') {
        this.hideSuspended = !this.hideSuspended;
      }
      if (e.srcElement.id === 'iridiumWithData') {
        this.hideIridiumWithData = !this.hideIridiumWithData;
      }
      if (e.srcElement.id === 'iridiumWithoutData') {
        this.hideIridiumWithoutData = !this.hideIridiumWithoutData;
      }
      if (e.srcElement.id === 'rudics') {
        this.hideRudics = !this.hideRudics;
      }
      if (e.srcElement.id === 'rudicsTelemetryOnly') {
        this.hideRudicsTelemetryOnly = !this.hideRudicsTelemetryOnly;
      }
      if (e.srcElement.id === 'globalstar') {
        this.hideGlobalstar = !this.hideGlobalstar;
      }
      if (e.srcElement.id === 'processingOnly') {
        this.hideProcessingOnly = !this.hideProcessingOnly;
      }
      if (e.srcElement.id === 'sms') {
        this.hideSms = !this.hideSms;
      }
    },
  };

  var computed = {};

  var watch = {
    billingDetails(data) {
      if (data.length > 0) {
        var dates = this.getMonthLabels(data);
        this.getActivationData(data, dates);
        this.getMonthlyData(data, dates);
        this.getSuspendedData(data, dates);
        this.getIridiumWithData(data, dates);
        this.getIridiumWithoutData(data, dates);
        this.getRudics(data, dates);
        this.getRudicsTelemetryOnly(data, dates);
        this.getGlobalstar(data, dates);
        this.getProcessingOnly(data, dates);
        this.getSMS(data, dates);
        this.displayDetails = true;
      }
    },
    unusedDevices(data) {
      this.unusedDevices = data;
      this.unusedGlobalstarDevices = find(data => data.networkId == 3);
      console.log('unused', this.unusedDevices);
    },
  };

  var components = {};

  export default {
    name: 'BillingReportDetails',
    data,
    props,
    beforeMount,
    mounted,
    methods,
    computed,
    watch,
    components,
  };
</script>

/*************************************************************/

<style scoped>
  .billing-report-details {
    display: block;
    margin-bottom: 50px;
  }
  .heading {
    background-color: #eeeeee;
    border: 1px solid #000000;
    cursor: pointer;
    margin-top: 9px;
    padding: 2px 0 2px 5px;
    width: 100%;
  }
  .feeDetails {
    margin: 0 5px 30px 5px;
    color: black;
  }
  .feeDetails tbody tr:nth-child(odd) {
    background: none;
    color: black;
  }
  .feeTable {
    background: none;
    color: black;
  }

  .feeDetails .feeTable .monthDivider {
    background: #dddddd;
  }
  .amount {
    position: relative;
    text-align: right;
  }
  .feeTable td {
    border: 1px solid #666666;
    padding-left: 2px;
    padding-right: 15px;
  }
  .feeTable {
    border-collapse: collapse;
    width: 100%;
    text-indent: initial;
    border-spacing: 2px;
    border-color: grey;
  }
  .feeTable .columnNames {
    background-color: #f0f0f0;
    font-weight: bold;
    color: #000000;
  }

  .feeTable .amount {
    padding-left: 20px;
    padding-right: 10px;
    color: #000000;
  }
  .categoryTotalText {
    text-align: right;
    background: none;
    color: #000000;
  }
  h2 {
    display: block;
    font-size: 1.5em;
    margin: 20px 0;
    font-weight: bold;
    text-align: center;
    clear: both;
  }

  .legend,
  .notes {
    font-size: 0.9em;
    font-style: italic;
  }
  .hide {
    display: none;
  }
  .unused {
    border: 5px solid silver;
    padding: 20px;
  }
  .unused span {
    display: inline-block;
    padding-left: 20px;
    width: 125px;
  }
  .unused h3 {
    margin-top: 0;
  }
</style>
