<template>
  <div id="activation-grid" class="grid-container activation-grid-container pg-component pg-sidebar">
    <div class="activation-list-grid">
      <div class="component-title">
        <h4>
          Activation History {{ isDeviceSelected ? " for the selected device" + (selectedCount > 1 ? 's ' : ' ') : " for all devices " }}{{
          selectedOrganization.orgId === -1 && !isDeviceSelected ? 'since ' + startDate : '' }}
        </h4>
      </div>
      <ag-grid-vue
        style="width: 100%"
        class="ag-theme-balham grid-holder"
        :gridOptions="gridOptions"
        :columnDefs="columnDefs"
        :modules="agGridModules"
      >
      </ag-grid-vue>
    </div>
  </div>
</template>

/*************************************************************/

<script>
  import { AgGridVue } from '@ag-grid-community/vue';
  import { AllCommunityModules } from '@ag-grid-community/all-modules';
  import { mapGetters, mapState, mapActions } from 'vuex';
  import pgUtils from '@/utils/PgUtils.js';
  import agGridUtils from '@/utils/AgGridUtils.js';
  import cancelImg from '@/assets/images/icons/close.png';
  import flagCompleteImg from '@/assets/images/icons/checkmark.png';
  import retryImg from '@/assets/images/icons/retry.png';
  import environmentSpecific from 'static/environmentSpecific';

  var myDateFormat = 'YYYY-MM-DD'; // 'YYYY-MM-DD HH:mm:ss '; // ISO  ISO.MSnp
  var thisComponent = null;

  // https://stackoverflow.com/questions/40395558/ag-gridangular-2-cannot-access-component-fields-in-cellrenderer
  var statusCellRenderer = function (params) {
    var isPgStaff = params.context.my.isPgStaff;
    var statusCellDiv = document.createElement('div');

    var cellContents = '';
    var cancelIcon = `<img class="cancel-icon" src='${cancelImg}' title='Cancel' alt='Cancel pending activation' />`;
    var retryIcon = `<img class="retry-icon" src='${retryImg}' title='Retry' alt='Retry failed activation' />`;
    var flagCompletedIcon = `<img class="completed-icon" src='${flagCompleteImg}' title='Mark Complete' alt='Mark activation completed' />`;

    if (params.value == 'Pending' || params.value == 'Error' || params.value == 'Failed') {
      statusCellDiv.innerHTML = params.value + ' ' + cancelIcon + ' ';

      if (isPgStaff) {
        statusCellDiv.innerHTML += flagCompletedIcon;
        if (params.value == 'Error' || params.value == 'Failed') {
          statusCellDiv.innerHTML += ' ' + retryIcon;
        }
      }

      var cancelBtn = statusCellDiv.querySelectorAll('.cancel-icon')[0];
      cancelBtn.addEventListener('click', function () {
        params.context.cancelActivation(`${params.data.deviceActivationLogId}`);
      });
      if (isPgStaff) {
        var completeBtn = statusCellDiv.querySelectorAll('.completed-icon')[0];
        completeBtn.addEventListener('click', function () {
          params.context.markCompleted(`${params.data.deviceActivationLogId}`);
        });
        if (params.value == 'Error' || params.value == 'Failed') {
          var retryBtn = statusCellDiv.querySelectorAll('.retry-icon')[0];
          retryBtn.addEventListener('click', function () {
            params.context.retryActivation(`${params.data.deviceActivationLogId}`);
          });
        }
      }
    } else {
      var test = params.value.search('Canc');
      // if canceled
      if (test !== -1) {
        if (params.data.comments != '') {
          statusCellDiv.innerHTML = params.data.comments;
        } else {
          statusCellDiv.innerHTML = params.value;
        }
      }
      // Not Cancelled
      else {
        statusCellDiv.innerHTML = params.value;
      }
    }
    return statusCellDiv;
  };

  var data = function () {
    return {
      columnDefs: null,
      rowData: null,
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      agGridModules: AllCommunityModules,
      gridWidth: 0,
      activations: [],
      cancelButton: null,
    };
  };

  var props = ['isEditing', 'activationHistoryData']; // No reason to disable if editing ?

  var beforeMount = function () {
    thisComponent = this;
    this.gridOptions = {
      headerHeight: 25,
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      onGridReady: this.onGridReady,
      onGridSizeChanged: this.onGridSizeChanged,
      onCellValueChanged: this.onCellValueChanged,
      // onRowClicked: this.onActivationRowClicked,
      onColumnMoved: this.onColumnMoved,
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
      },
      context: {
        cancelActivation: this.cancelActivation,
        retryActivation: this.retryActivation,
        markCompleted: this.markCompleted,
        my: this,
      },
      suppressNoRowsOverlay: true,
      rowHeight: environmentSpecific.gridRowHeightPx,
      enableCellTextSelection: true,
      ensureDomOrder: true,
    };

    this.columnDefs = [
      // {
      //   colId: "deviceActivationLogId",
      //   headerName: "Id",
      //   field: "deviceActivationLogId",
      //   minWidth: 125,
      //   width: 150,
      //   maxWidth: 200,
      // },
      {
        colId: 'deviceName',
        headerName: 'Device Name',
        field: 'deviceName',
        minWidth: 125,
        width: 150,
        maxWidth: 200,
      },
      {
        colId: 'action',
        headerName: 'Action',
        field: 'action',
        minWidth: 90,
        width: 100,
        maxWidth: 110,
      },
      {
        colId: 'pgPlanName',
        headerName: 'Plan',
        field: 'pgPlanName',
        minWidth: 90,
        width: 100,
        maxWidth: 200,
      },
      {
        colId: 'actionDateUtc',
        headerName: 'Effective',
        field: 'actionDateUtc',
        minWidth: 100,
        width: 110,
        maxWidth: 130,
        valueFormatter: function (params) {
          return pgUtils.formatDate(params.data.actionDateUtc, myDateFormat);
        },
      },
      {
        colId: 'status',
        headerName: 'Status',
        field: 'status',
        minWidth: 105,
        width: 105,
        maxWidth: 135,
        cellRenderer: statusCellRenderer,
      },
      {
        colId: 'whoEntered',
        headerName: 'Entered By',
        field: 'whoEntered',
        minWidth: 105,
        width: 105,
        maxWidth: 135,
      },
    ];
  };

  var mounted = function () {
    this.isPgStaff = this.userHasRole('PGStaff');
  };
  var methods = {
    cancelActivation(deviceActivationLogId) {
      this.updateActivation(deviceActivationLogId, 'Canceled', '/status');
    },

    markCompleted(deviceActivationLogId) {
      this.updateActivation(deviceActivationLogId, 'Completed', '/status');
    },

    retryActivation(deviceActivationLogId) {
      this.updateActivation(deviceActivationLogId, 'Pending', '/status');
    },

    updateActivation(logId, value, path) {
      var snotifyText = '';
      var innerText = '';
      var orgId = this.selectedOrganization.orgId;
      var devicesSelected = this.selectedDeviceIds.length > 0;
      var user = this.$pgGlobal.currentUser.userName;
      var comments = `Canceled by ` + user;
      var data = [];
      if (devicesSelected) {
        var selectedDeviceIds = this.selectedDeviceIds.join();
      }
      var data = [
        {
          value: value,
          path: path,
          op: 'replace',
        },
      ];
      if (value === 'Canceled') {
        data.push(
          { value: user, path: '/processedBy', op: 'replace' },
          {
            value: comments,
            path: '/comments',
            op: 'replace',
          },
        );
      }
      data.push({
        deviceActivationLogId: logId,
      });
      this.changeDeviceActivation(data).then(response => {
        if (value === 'Canceled') {
          innerText = 'has been canceled';
          this.$emit('onActivationUpdate');
        } else {
          innerText = 'has been marked as ' + value.toLowerCase();
        }
        this.$emit('onActivationUpdate');
        snotifyText = 'Request to ' + response.data.data.action.toLowerCase() + ' ' + response.data.data.commIdentifier + ' ' + innerText;
        this.$snotify.success(snotifyText, '');
      });
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.gridApi.setRowData(this.activationHistoryData);
      this.gridApi.setPopupParent(document.body);

      $('body').on('click', '.ag-header-icon', function () {});
    },

    onGridSizeChanged(params) {
      var gridWidth = document.getElementById('activation-grid').offsetWidth;
      this.gridWidth = gridWidth; // to display during dev
      agGridUtils.showColumnsBySize(params, gridWidth, hideColumnsBySize);
    },

    setGridData(data) {
      this.gridApi.setRowData(data);
    },

    prepGridForNewData() {
      this.gridApi.hideOverlay();
      this.gridApi.setRowData([]);
      this.gridOptions.suppressNoRowsOverlay = true;
    },

    ...mapActions('deviceModule', ['changeDeviceActivation', 'markActivationComplete']),
  };

  var computed = {
    ...mapState('deviceModule', ['selectedDeviceIds']),
    ...mapState('organizationModule', ['selectedOrganization']),
    ...mapGetters('userModule', ['userHasClaim', 'userHasRole']),
    selectedCount() {
      return this.selectedDeviceIds.length;
    },
    isDeviceSelected() {
      return this.selectedDeviceIds.length > 0;
    },
    startDate() {
      return this.moment().subtract(environmentSpecific.maxNumberHistoryDays, 'days').format(myDateFormat);
    },
  };

  var watch = {
    activationHistoryData: function (history) {
      if (this.gridApi != null) {
        this.prepGridForNewData();
        this.setGridData(history);
      }
    },

    selectedOrganization: function () {
      this.prepGridForNewData();
    },

    isEditing: function (value) {
      if (value) {
        this.gridOptions.api.showLoadingOverlay();
      } else {
        this.gridOptions.api.hideOverlay();
      }
    },
  };

  var components = {
    AgGridVue,
  };

  var hideColumnsBySize = [
    { maxSize: 320, hideIds: ['dateEntered', 'whoEntered', 'pgPlanName'] },
    { maxSize: 425, hideIds: ['dateEntered', 'whoEntered'] },
    { maxSize: 760, hideIds: ['dateEntered'] },
  ];

  export default {
    name: 'ActivationGrid',
    data,
    beforeMount,
    mounted,
    methods,
    computed,
    watch,
    components,
    props,
  };
</script>

/*************************************************************/

<style scoped>
  .activation-list-grid {
    width: 100%;
  }
</style>
