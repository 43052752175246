<template>
  <!-- <div class="tab pg-component" > -->
    <div class="tab-contents" v-bind:class="className('-tab-contents')" v-show="isSelected">
      <slot></slot>
    </div>
  <!-- </div> -->
</template>

/*************************************************************/

<script>
  // https://laracasts.com/series/learn-vue-2-step-by-step/episodes/11
  // import { mapGetters, mapState, mapActions } from 'vuex';
  // import DeviceListGrid from '@/components/DeviceListGrid'; // Import another component

  var data = function() {
    return {
      //tabs: null,
      isSelected: false,
    };
  };

  var props = {
    name: {required: true},
    icon: null,
    selected: {default: false},
  };

  var beforeMount = function() {};

  var created = function(){
  };

  var mounted = function() {
    // can't change prop, so set up data var
    this.isSelected = this.selected;
    // console.log('class ', this.className);
  };

  var methods = {
    className(suffix){
      return this.name.toLowerCase().replace(/ /g, '-') + suffix;
    },
  };

  var computed = {
    id() {
      return '#' + this.className('-tab');
    },
  };

  var watch= {  };

  var components = {  };

  export default {
    name: 'TabComponent',
    data,
    props,
    created,
    beforeMount,
    mounted,
    methods,
    computed,
    watch,
    components
  };

</script>

/*************************************************************/

<style scoped>
</style>
