import Vue from 'vue';
var thisGridTextFilter;
export default Vue.extend({

  template: `<div class="custom-filter">
              <div>
              <div class="custom-filter-body-wrapper">
              <div class="custom-filter-heading"><span>Search {{ filterTitle }}</span></div>
              <div class="custom-filter-body"><textarea :ref="'searchInput'" :value="inputString" v-on:keyup.enter="applyFilter"></textarea></div>
              </div>
              <div class="custom-filter-button-panel"><button @click="clearFilter">Clear Filter</button><button @click="applyFilter">Apply Filter</button></div>
              </div>
              </div>`,
  data() {
    return {
      input: null,
      valueGetter: null,
      popup: null,
      filterTitle: null,
    };
  },
              // < div contenteditable : ref = "'searchInput'" : value = "input" ></div >

  methods: {
    isFilterActive() {
      return this.input !== null && this.input !== undefined && this.input !== '';
    },
    doesFilterPass(params) {
      var passed = false;
      // if (this.$refs.searchInput) {
      //   this.input = this.$refs.searchInput.value;
      // } else {
      //   console.log('searchInput is null.  input is ', this.input);
      //   console.log('thisGridTextFilter.$refs.searchInput is ', thisGridTextFilter.$refs.searchInput);
      // }
      if (!this.input) {
        return true;
      }
      var arr = this.input.toLowerCase().trim().split(/[\r\n]/g);
      arr.forEach((input) => {
        if (params === undefined) {
          return true;
        }
        if (this.valueGetter(params.node).toLowerCase().indexOf(input) > -1) {
          passed = true;
        }
      });
      return passed;
    },
    getModel() {
      return this.input;
      // return { value: this.input };
    },
    setModel(model) {
      if (model) {
        this.input = model;
      } else if (model === null) {
        this.clearFilter();
      }
    },
    afterGuiAttached(params) {
      this.$refs.searchInput.focus();
      this.popup = params;
    },

    applyFilter() {
      this.input = this.$refs.searchInput.value;
      this.doesFilterPass();
      this.params.filterChangedCallback();
			if (this.popup != null) { this.popup.hidePopup(); }
    },
    clearFilter() {
      this.$refs.searchInput.value = null;
      this.input = null;
      // this.$refs.searchInput.value = null;
      this.applyFilter();
    },
  },

  created() {
    this.valueGetter = this.params.valueGetter;
    this.filterTitle = this.params.filterTitle;
  },

  computed: {
    inputString: function(){
      // console.log('this is ', this);
      // console.log('Getting inputString from ', this.input);
      return this.input;
      // if (this.input && this.input.value){
      //   return this.input.value;
      // } else {
      //   return null;
      // }
    },
  },

  watch: {
    // 'input': function (val, oldVal) {
    //   if (val !== oldVal) {
    //     // this.params.filterChangedCallback();
    //   }
    // }
  },

});

