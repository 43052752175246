<template>
  <div id="organization-billing-grid" class="organization-billing-grid left">
    <div class="pg-component-toolbar">
      <span class="pg-component-toolbar-left">
        <span class="pg-component-toolbar-item">
          <span v-if="dueOnly === 1" @click="onToggleDueOnly" title="Show All" class="">
            <i class="far fa-toggle-on bigger fa-lg"></i>
          </span>
          <span v-else @click="onToggleDueOnly" title="Show Due Only" class="">
            <i class="far fa-toggle-off bigger fa-lg"></i>
          </span>
        </span>
        <span class="pg-component-toolbar-item">
          <span @click="onClearCheckboxes" title="Clear All Checkboxes">
            <i class="far fa-check-square bigger fa-lg"></i>
            <i class="far fa-arrow-alt-right bigger fa-lg"></i>
            <i class="far fa-square bigger fa-lg"></i>
          </span>
        </span>
      </span>
      <span class="pg-component-toolbar-center"> </span>
      <span class="pg-component-toolbar-right">
        <span class="pg-component-toolbar-item">
          <span @click="onGenerateCurrentReport" title="Ad Hoc Report" class="fa-stack">
            <i class="far fa-file-invoice bigger fa-lg"></i>
          </span>
        </span>
        <span class="pg-component-toolbar-item">
          <span @click="onGenerateCommissionsReport" title="Commissions Report">
            <i class="far fa-dollar-sign bigger fa-lg"></i>
          </span>
        </span>
        <span class="pg-component-toolbar-item">
          <span @click="toggleColumnSelector" title="Select Columns">
            <i class="fas fa-cog bigger fa-lg"></i>
          </span>
        </span>
      </span>
      <div v-show="showColumnSelector" class="column-selector popup-window">
        <div class="popup-header">
          Columns
          <i class="far fa-times-square closer" v-on:click="showColumnSelector=false"></i>
        </div>
        <div class="popup-content">
          <div class="popup-row" v-for="col in gridColumns" v-bind:key="col.colId">
            <label>
              <input type="checkbox" v-model="col.visible" v-on:change="onChangeColumnVisibility(col)" />
              <span>{{ col.colDef.headerName }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="organization-billing-grid-body">
      <ag-grid-vue
        class="ag-theme-balham grid-holder"
        style="width: 100%"
        :gridOptions="gridOptions"
        :columnDefs="columnDefs"
        :modules="agGridModules"
      >
      </ag-grid-vue>
    </div>
  </div>
</template>

/*************************************************************/

<script>
  import { AgGridVue } from '@ag-grid-community/vue';
  import { AllCommunityModules } from '@ag-grid-community/all-modules';
  import { mapGetters, mapState, mapActions } from 'vuex';
  import * as pgHelpers from '@/utils/PgHelpers.js';

  var myDateFormat = 'MM/DD';
  var thisComponent;

  var data = function () {
    return {
      columnDefs: null,
      rowData: null,
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      agGridModules: AllCommunityModules,
      gridWidth: 0,
      selectedFields: [],
      dataIsFiltered: false,
      showColumnSelector: false,
      gridColumns: [],
    };
  };

  var props = ['billingOverview', 'clearFinishedColumn', 'dueOnly', 'updatedOrgData', 'updatedOrgBalance'];

  var beforeMount = function () {
    thisComponent = this;
    this.gridOptions = {
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      onGridReady: this.onGridReady,
      rowSelection: 'single',
      onSelectionChanged: this.onSelectionChanged,
      context: {
        organizationCellRenderer: this.organizationCellRenderer,
        finishedBillingCellRenderer: this.finishedBillingCellRenderer,
        currencyCellRenderer: this.currencyCellRenderer,
        onClickFinishedBilling: this.onClickFinishedBilling,
        clearFinishedColumn: this.clearFinishedColumn,
      },
      defaultColDef: {
        width: 100,
        sortable: true,
        filter: true,
        resizable: true,
      },
      rowHeight: 25,
      headerHeight: 25,

      suppressMenuHide: true,
      icons: {
        menu: '<i class="far fa-filter"/>',
      },
      enableCellTextSelection: true,
      ensureDomOrder: true,
    };

    this.columnDefs = [
      {
        colId: 'billingFinishedDate',
        headerName: 'Done',
        field: 'billingFinishedDate',
        width: 80,
        suppressSizeToFit: true,
        filter: false,
        valueFormatter: function (params) {
          var date = pgHelpers.dateTimeHelper.formatDate(params.data.billingFinishedDate, myDateFormat);
          return date;
        },
        cellClassRules: {
          'highlight-cell': function (params) {
            return params.value.startsWith('0001') || params.value === '';
          },
        },
        cellRenderer: thisComponent.finishedBillingCellRenderer,
      },
      {
        colId: 'orgNameBillingGroup',
        headerName: 'Organization - Billing Group',
        field: 'orgName',
        tooltipValueGetter: function (params) {
          return `${params.data.orgName}  OrgId: ${params.data.orgId} BillingGroupId ${params.data.billingGroupId}`;
        },
        // autoHeight: true, // Need to update ag-grid
        // wrapText: true,
        width: 250,
        cellRenderer: thisComponent.organizationCellRenderer,
      },
      {
        colId: 'assignedTo',
        headerName: 'Assnd To',
        field: 'assignedTo',
        width: 75,
      },
      {
        colId: 'orgCountryCode',
        headerName: 'Country',
        field: 'orgCountryCode',
        width: 75,
        hide: true,
      },
      {
        colId: 'userStr',
        headerName: 'Users',
        field: 'userStr',
        width: 250,
        hide: true,
      },
      {
        colId: 'qbCustomerName',
        headerName: 'QB Name',
        field: 'qbCustomerName',
        width: 250,
        hide: true,
      },
      {
        colId: 'currentDue',
        headerName: 'Balance',
        field: 'currentDue',
        type: 'numericColumn',
        suppressSizeToFit: true,
        filter: false,
        cellRenderer: thisComponent.currencyCellRenderer,
        width: 85,
      },
    ];
  };

  var mounted = function () {
    if (this.showColumns) {
      this.columnDefs.forEach(col => {
        if (this.showColumns.includes(col.colId)) {
          this.gridOptions.columnApi.setColumnVisible(col.colId, true);
        } else {
          this.gridOptions.columnApi.setColumnVisible(col.colId, false);
        }
      });
    }
  };

  var methods = {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    setGridData(value) {
      this.gridApi.setRowData(this.billingOverview);
      this.gridApi.setPopupParent(document.body);
      var node = this.gridApi.getRowNode(0);
      node.setSelected(true, true);
      this.gridApi.sizeColumnsToFit();
      // this.gridApi.resetRowHeights();
    },
    onSelectionChanged() {
      var row = this.gridApi.getSelectedNodes();
      this.$emit('onNewOrgSelection', row[0].data);
    },

    finishedBillingCellRenderer(params) {
      var checkedStatus = params.valueFormatted === '' ? 'fa-square' : 'fa-check-square';
      var cellDiv = document.createElement('div');
      var cellContents = `<span class="checkbox far ${checkedStatus} fa-lg"></span><span>${params.valueFormatted}</span>`;
      cellDiv.innerHTML = cellContents;
      var checkbox = cellDiv.querySelectorAll('.checkbox')[0];
      checkbox.addEventListener('click', function () {
        var node = params.node;
        params.context.onClickFinishedBilling(node);
      });
      cellDiv.classList.add('date-cell');
      cellDiv.setAttribute('style', 'white-space: nowrap');
      return cellDiv;
    },

    currencyCellRenderer(params) {
      if (params.value < 0) {
        var negativeAmt = params.value.toFixed(2).toString().replace('-', '');
        return '($' + negativeAmt + ')';
      }
      return '$' + params.value.toFixed(2);
    },

    organizationCellRenderer(params) {
      var cellDiv = document.createElement('span');
      var orgContent = '';
      var qbContent = '';
      var cellContent = null;
      if (params.data.billingGroupId != 0) {
        orgContent = params.data.orgName + ' - ' + params.data.billingGroupName;
      } else {
        orgContent = params.data.orgName;
      }
      cellContent = `<span class="orgNames">${orgContent}</span>`;
      cellDiv.innerHTML = cellContent;
      return cellDiv;
    },

    onClickFinishedBilling(node) {
      var cellContents = node.data.billingFinishedDate;
      var today = new Date().toLocaleString();
      if (cellContents != null && !cellContents.startsWith('0001') && cellContents != '') {
        today = null;
        node.setDataValue('billingFinishedDate', '');
      } else {
        node.setDataValue('billingFinishedDate', today);
      }

      var data = {
        orgId: node.data.orgId,
        billingGroupId: node.data.billingGroupId,
        billingFinishedDate: today,
      };
      this.$emit('onFinishedDateChange', data);
    },

    updateGridRow(data) {
      var redrawNodes = [];
      this.gridApi.forEachNode(node => {
        if (node.data.orgId === data.orgId) {
          node.setDataValue('orgNameBillingGroup', data.orgName);
          node.setDataValue('assignedTo', data.assignedTo);
          node.setDataValue('orgCountryCode', data.orgCountryCode);
          node.setDataValue('userStr', data.userStr);
          node.setDataValue('qbCustomerName', data.qbCustomerName);
          node.setDataValue('currentDue', data.currentDue);
          redrawNodes.push(data);
        }
      });
      console.log();
    },
    updateOrgBalance(data) {
      console.log('updating balances', data);
      var redrawNodes = [];
      this.gridApi.forEachNode(node => {
        if (node.data.orgId === data[0].orgId && node.data.billingGroupId === data[0].billingGroupId) {
          node.setData(data[0]);
          redrawNodes.push(data[0]);
        }
      });
    },

    onToggleDueOnly() {
      // switch icon
      this.$emit('onToggleDueOnly');
    },
    onClearCheckboxes() {
      this.$emit('onClearCheckboxes');
    },
    onGenerateCurrentReport() {
      this.$emit('onGenerateAdHocReport');
    },
    onGenerateCommissionsReport() {
      this.$emit('onGenerateCommissionsReport');
    },
    toggleColumnSelector() {
      this.showColumnSelector = !this.showColumnSelector;
      if (this.showColumnSelector) {
        this.gridColumns = this.columnApi.getAllColumns();
      }
    },

    onChangeColumnVisibility(column) {
      if (column.visible) {
        // workaround for bug - in ag-grid-vue?
        this.columnApi.setColumnVisible(column.colId); // should not need, but without hide fails
        this.columnApi.setColumnVisible(column.colId, true); // works
      } else {
        // workaround for bug - in ag-grid-vue?
        this.columnApi.setColumnVisible(column.colId); // hides, but shouldn't
        this.columnApi.setColumnVisible(column.colId, false); // correct, but does nothing, kept for future fix
      }
    },
  };

  var computed = {};

  var watch = {
    billingOverview: function (value) {
      this.setGridData(value);
    },

    updatedOrgData(org) {
      this.updateGridRow(org);
    },
    updatedOrgBalance(org) {
      this.updateOrgBalance(org);
    },
    clearFinishedColumn: function (value) {
      // refresh grid data
      // ??? why am i watching this
    },
  };

  var components = {
    AgGridVue,
  };

  export default {
    name: 'BillingOverviewForOrgsGrid',
    data,
    props,
    beforeMount,
    mounted,
    methods,
    computed,
    watch,
    components,
  };
</script>

/*************************************************************/

<style lang="scss">
  .organization-billing-grid {
    .grid-holder {
      width: 100%;
      flex: 1;
      overflow: hidden;
      height: 100%;
    }
    .ag-cell-value {
      margin-top: 0;
      height: 26px;
    }
    .ag-cell:nth-child(2) {
      padding: 0 0 0 5px !important;
    }
    .checkbox {
      margin-right: 10px;
    }

    // not happy with spacing ... border every other row?
    .highlight-cell {
      border: 1px solid !important;
      border-left: none !important;
    }

    .popup-window {
      position: absolute;
      z-index: 100;
      top: 0;
      right: 0;
    }
  }
</style>
