<template>
  <div class="billing-report-ad-hoc-overview pg-component" v-if="displayOverview">
    <div v-if="showAdHocNotes" class="userMessage">Note: data may be incomplete for the most recent 7 day period.</div>
    <h3 class="heading">Amount due for {{startDate}} to {{endDate}} ${{billingOverview.totalDue.toFixed(2)}}</h3>
    <div class="org-info" v-if="selectedOrg">
      <div class="">{{selectedOrg.orgName}}</div>
      <div class="">
        {{selectedOrg.orgAddress1}}
        <span v-if="selectedOrg.orgAddress2">, {{selectedOrg.orgAddress2}} </span>
      </div>
      <div class="">{{selectedOrg.orgCity}}, {{selectedOrg.orgStateProvCode}} {{selectedOrg.orgPostalCode}}</div>
      <div class="">{{selectedOrg.orgCountryCode}}</div>
    </div>
    <div class="fee-summary">
      <table id="AmountDue" class="fee-table">
        <tbody>
          <tr v-if="showActivationInOverview">
            <td class="category">Activations</td>
            <td class="adjustmentDate"></td>
            <td class="amount">${{billingOverview.activations.toFixed(2)}}</td>
          </tr>
          <tr v-if="billingOverview.monthly > 0">
            <td class="category">Monthly Fees</td>
            <td class="adjustmentDate"></td>
            <td class="amount">${{billingOverview.monthly.toFixed(2)}}</td>
          </tr>
          <tr v-if="billingOverview.suspended > 0">
            <td class="category">Suspended Fees</td>
            <td class="adjustmentDate"></td>
            <td class="amount">${{billingOverview.suspended.toFixed(2)}}</td>
          </tr>
          <tr v-if="billingOverview.iridiumWithData > 0">
            <td class="category">Iridium with Data Services</td>
            <td class="adjustmentDate"></td>
            <td class="amount">${{billingOverview.iridiumWithData.toFixed(2)}}</td>
          </tr>
          <tr v-if="billingOverview.iridiumWithoutData > 0">
            <td class="category">Iridium Telemetry Only</td>
            <td class="adjustmentDate"></td>
            <td class="amount">${{billingOverview.iridiumWithoutData.toFixed(2)}}</td>
          </tr>
          <tr v-if="billingOverview.processingOnly > 0">
            <td class="category">Processingly Only</td>
            <td class="adjustmentDate"></td>
            <td class="amount">${{billingOverview.processingOnly.toFixed(2)}}</td>
          </tr>
          <tr v-if="billingOverview.globalstar > 0">
            <td class="category">Globalstar</td>
            <td class="adjustmentDate"></td>
            <td class="amount">${{billingOverview.globalstar.toFixed(2)}}</td>
          </tr>
          <tr v-if="billingOverview.sms > 0">
            <td class="category">SMS</td>
            <td class="adjustmentDate"></td>
            <td class="amount">${{billingOverview.sms.toFixed(2)}}</td>
          </tr>
          <tr v-if="billingOverview.rudics > 0">
            <td class="category">RUDICS</td>
            <td class="adjustmentDate"></td>
            <td class="amount">${{billingOverview.rudics.toFixed(2)}}</td>
          </tr>
          <tr v-if="billingOverview.rudicsTelemetryOnly > 0">
            <td class="category">RUDICS Telemetry Only</td>
            <td class="adjustmentDate"></td>
            <td class="amount">${{billingOverview.rudicsTelemetryOnly.toFixed(2)}}</td>
          </tr>
          <tr>
            <td class="category">Current Charges</td>
            <td class="adjustmentDate"></td>
            <td class="amount current-charge">${{billingOverview.currentCharges.toFixed(2)}}</td>
          </tr>
          <tr>
            <td class="category">Previous Balance</td>
            <td class="adjustmentDate"></td>
            <td class="amount">${{billingOverview.startingBalance.toFixed(2)}}</td>
          </tr>
          <tr v-for="(adj, index) in billingOverview.billingAdjustments" :key="index">
            <td v-if="adj.description != ''" class="category">{{adj.description}}</td>
            <td v-if="adj.asOfDate != null" class="adjustmentDate">{{adj.asOfDate}}</td>
            <td v-if="adj.amount != 0" class="amount">${{adj.amount.toFixed(2)}}</td>
          </tr>
          <tr>
            <td class="category">Total Due</td>
            <td class="adjustmentDate"></td>
            <td class="amount total-due">${{billingOverview.totalDue.toFixed(2)}}</td>
          </tr>
          <tr v-if="billingOverview.totalDue < 0">
            <td class="amount" colspan="3">Credit balance - do not pay</td>
          </tr>
          <tr v-if="billingOverview.totalDue > 0">
            <td class="amount" colspan="3">All amounts in USD. Please pay promptly.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

/*************************************************************/

<script>
  var data = function () {
    return {
      displayOverview: false,
    };
  };

  var props = ['selectedOrg', 'startDate', 'endDate', 'billingOverview', 'showActivationInOverview', 'showAdHocNotes'];

  var beforeMount = function () {};

  var mounted = function () {
    setTimeout(() => {
      this.displayOverview = true;
    }, 2000);
  };

  var updated = function () {};

  var methods = {};

  var computed = {};

  var watch = {
    billingOverview(value) {},
    billingAdjustments(value) {},
  };

  var components = {};

  export default {
    name: 'BillingReportAdHocOverview',
    props,
    data,
    beforeMount,
    mounted,
    updated,
    methods,
    computed,
    watch,
    components,
  };
</script>

/*************************************************************/

<style scoped lang="scss">
  .billing-report-ad-hoc-overview {
    display: block;
    .heading {
      border: 1px solid black;
      background-color: #eeeeee;
      margin-top: 9px;
      padding: 2px 0 2px 5px;
      width: 100%;

      display: block;
      font-size: 1.17em;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
    }
    .org-info {
      border: 1px solid #666666;
      border-radius: 5px;
      float: left;
      margin: 10px 15px;
      min-width: 300px;
      padding: 10px;
    }
    .fee-table {
      float: right;
      width: 50%;
      background: none;
      margin-top: 10px;
      margin-right: 15px;
    }
    tr .amount {
      text-align: right;
    }
    tr:nth-child(even),
    tr:nth-child(odd) {
      background: none;
      color: black;
    }
    .adjustmentDate {
      text-align: right;
    }
    .fee-table,
    .fee-table td {
      border: none;
    }

    .fee-table .current-charge,
    .fee-table .total-due {
      border-top: 1px black solid;
    }
    .total-due {
      font-weight: bold;
    }
  }
</style>
