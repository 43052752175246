<!-- template for the modal component -->
<template id="modal-template">
  <transition name="modal">
    <div class="modal-mask" @click="close" v-show="show">
      <div class="pg-modal" @click.stop>
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

/*************************************************************/

<script>
export default {
  name: "PgModal",
  props: ["show"],
  mounted: function() {
    document.addEventListener("keydown", e => {
      if (this.show && e.keyCode == 27) {
        this.close();
      }
    });
  },
  methods: {
    close: function() {
      this.$emit("close");
    }
  }
};
</script>

/*************************************************************/

<style scoped>
</style>
