<template>
  <div class="bulk-add-devices-wrapper pg-component" v-if="userHasClaim('Devices', 'create')">
    <div class="panel-left">
      <div class="pg-form-wrapper">
        <div class="first">
          <div class="form-group block" :class='{ "form-group--error": commAndDeviceNames.length === 0 && $v.localDevice.$dirty}'>
            <div>
              <label v-if="!needsAttn" for="imeiInput">IMEI/Comm 1</label>
              <label v-if="needsAttn">These IMEIs could not be processed</label>
            </div>
            <div>
              <textarea id="imeiTextArea" :class='{ "needsAttn": needsAttn }' rows="35" ref="imeiTextArea" @change="getImeiInput"></textarea>
            </div>
            <!-- <div v-if="showWarnings" class="pg-validation-warning">{{ imeiValidationText }}</div> -->
          </div>
        </div>

        <div class="second">
          <div class="form-group block" :class='{ "form-group--error": selectedOrgId === null && $v.localDevice.$dirty}'>
            <div>
              <label for="orgId">Organization*</label>
            </div>
            <div>
              <pg-select
                id="orgId"
                :options="organizations"
                :value="selectedOrgDropdown"
                label="orgName"
                track-by="orgId"
                @input="onDropdownInput"
              ></pg-select>
            </div>
          </div>
          <!-- <div v-if="showWarnings" class="pg-validation-warning">{{ orgValidationText }}</div> -->
          <div>
            <div class="form-group block" :class='{ "form-group--error": selectedNetworkId === null && $v.localDevice.$dirty }'>
              <div>
                <label for="networkId">Network*</label>
              </div>
              <div>
                <pg-select
                  id="networkId"
                  :options="networks"
                  :value="selectedNetwork"
                  label="networkName"
                  track-by="networkId"
                  @input="onDropdownInput"
                >
                </pg-select>
              </div>
            </div>
            <!-- <div v-if="showWarnings" class="pg-validation-warning">{{ networkValidationText }}</div> -->
          </div>
          <div>
            <div class="form-group block" :class='{ "form-group--error": selectedDeviceTypeId === null && $v.localDevice.$dirty }'>
              <div>
                <label for="deviceTypeId">Device Type*</label>
              </div>
              <div>
                <pg-select
                  id="deviceTypeId"
                  :options="deviceTypes"
                  :value="selectedDeviceType"
                  label="deviceTypeName"
                  track-by="deviceTypeId"
                  @input="onDropdownInput"
                >
                </pg-select>
              </div>
            </div>
            <!-- <div v-if="showWarnings" class="pg-validation-warning">{{ deviceTypeValidationText }}</div> -->
          </div>

          <div class="form-group block" :class="{ 'form-group--error': sensorPackageIds === null && $v.localDevice.$dirty }">
            <div>
              <label for="sensorPackages">Sensor Package(s)*</label>
            </div>
            <div>
              <sensor-section id="sensorPackages" :sensorPackages="localDevice.sensorPackages" @onUpdate="updatedSensorsReceived"></sensor-section>
            </div>
            <!-- <div v-if="showWarnings" class="pg-validation-warning">{{ sensorsValidationText }}</div> -->
          </div>

          <div>
            <div class="form-group block" :class='{ "form-group--error": selectedServiceTypeCode === null && $v.localDevice.$dirty }'>
              <div>
                <label for="serviceTypeCode">Service Type*</label>
              </div>
              <div>
                <pg-select
                  id="serviceTypeCode"
                  :options="serviceTypes"
                  :value="selectedServiceType"
                  label="name"
                  track-by="serviceTypeCode"
                  @input="onDropdownInput"
                >
                </pg-select>
              </div>
            </div>
            <!-- <div v-if="showWarnings" class="pg-validation-warning">{{ serviceTypeValidationText }}</div> -->
          </div>
          <div>
            <div class="form-group block" :class='{ "form-group--error": selectedBillingPriceId === null && $v.localDevice.$dirty}'>
              <div>
                <label for="selectedBillingPlan">Default Billing*</label>
              </div>
              <div>
                <pg-select
                  id="selectedBillingPlan"
                  :options="localBillingPlans"
                  :value="selectedBillingPlan"
                  label="pgPlanName"
                  track-by="billingPriceId"
                  @input="onDropdownInput"
                >
                </pg-select>
              </div>
            </div>
            <!-- <div v-if="showWarnings" class="pg-validation-warning">{{ billingValidationText }}</div> -->
          </div>
          <div>
            <div class="form-group block">
              <div>
                <label for="billingGroup">BillingGroup</label>
              </div>
              <div>
                <pg-select
                  id="billingGroup"
                  :showLabels="false"
                  :options="billingGroups"
                  :value="selectedBillingGroup"
                  label="billingGroupName"
                  @input="onDropdownInput"
                >
                </pg-select>
              </div>
            </div>
            <!-- <div v-if="showWarnings" class="pg-validation-warning">{{ billingValidationText }}</div> -->
          </div>
          <div>
            <div class="form-group block">
              <div>
                <label for="deviceDescription">Device Description</label>
              </div>
              <div>
                <input id="deviceDescription" type="text" v-model="deviceDescription" />
              </div>
            </div>
          </div>
          <div class="button-wrapper form-group">
            <button @click="getFormData" type="button" class="inline-button">Submit</button>
            <button @click="resetForm" type="button" class="inline-button">Clear</button>
          </div>
        </div>
      </div>
    </div>
    <div class="panel-right" v-show="showSuccessGrid">
      <div class="first">
        <h4>Successfully added {{successfulPost.length}} devices</h4>
        <ag-grid-vue
          style="width: 100%;"
          class="ag-theme-balham grid-holder"
          :gridOptions="gridOptions"
          :columnDefs="columnDefs"
          :modules="agGridModules"
        >
        </ag-grid-vue>
      </div>
      <div class="second" v-show="showSuccessGrid">
        <h4>Select from grid to view details</h4>
        <device-details-form :deviceDetails="deviceDetails" :billingGroups='billingGroups'></device-details-form>
      </div>
    </div>
    <billing-group-modal
      v-show="showBillingGroupModal"
      @close="closeModal"
      @onUpdatingGroup="onUpdatingGroup"
      @onDeleteGroup="onDeleteGroup"
      @onUndoChange="onUndoChange"
      :billingGroups="billingGroupsForModal"
      :singleBillingGroup="singleBillingGroup"
    />
  </div>
</template>

/*************************************************************/ /* TODO - validate sensors*/ /* change validation ids*/

<script>
  import { required, minValue } from 'vuelidate/lib/validators';
  import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
  import environmentSpecific from 'static/environmentSpecific';
  import PgSelect from '@/components/Common/PgSelect.vue';
  import SensorSection from '@/components/SensorSection.vue';
  import DeviceDetailsForm from '@/components/DeviceDetailsForm.vue';
  import BillingGroupModal from '@/components/BillingGroupModal';
  import BillingGroupConfirmModal from "@/components/BillingGroupConfirmModal";
  import { AgGridVue } from '@ag-grid-community/vue';
  import { AllCommunityModules } from '@ag-grid-community/all-modules';
  import pgUtils from '@/utils/PgUtils.js';

  var props = {
    v: {},
  };

  var data = function() {
    return {
      columnDefs: null,
      gridOptions: null,
      rowData: null,
      agGridModules: AllCommunityModules,
      gridWidth: 0,

      localDevice: {},
      commAndDeviceNames: [],
      imeiRawData: '',
      selectedOrgId: '',
      selectedNetworkId: undefined,
      selectedDeviceTypeId: undefined,
      selectedServiceTypeCode: undefined,
      selectedBillingPriceId: undefined,
      selectedBillingGroup: {},
      deviceDescription: '',
      sensorPackageIds: undefined,
      imeiProcessed: [],
      showSuccessGrid: true,
      successfulPost: [],
      localBillingPlans: [],
      needsAttn: false,
      showBillingGroupModal: false,
      billingGroupsForModal: [],
      singleBillingGroup: {},

      deviceDetails: {},
      billingGroups: []
    };
  };

  var validations = {
    localDevice: {
      devices: { required }, //?
      deviceDescription: {},
      deviceTypeId: {},
      serviceTypeCode: { required },
      orgId: { required },
      networkId: {},
      sensorPackageIds: {},
      sensorPackages: {},
      defaultBillingPriceId: {},
      billingGroupId: {},
      imeiRawData: {},
    },
  };

  var components = {
    PgSelect,
    SensorSection,
    AgGridVue,
    DeviceDetailsForm,
    BillingGroupModal,
  };

  var beforeMount = function() {
    this.gridOptions = {
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      rowSelection: 'single',
      onGridReady: this.onGridReady,
      onSelectionChanged: this.onSelectionChanged,
      rowHeight: environmentSpecific.gridRowHeightPx,
      headerHeight: 25,
      defaultColDef: {
        filter: true,
        resizable: true,
        sortable: true,
      },
      enableCellTextSelection: true,
      ensureDomOrder: true,
    };

    this.columnDefs = [
      {
        checkboxSelection: true,
        colId: 'deviceId',
        headerName: 'Device Id',
        field: 'deviceId',
        width: 110,
      },
      {
        colId: 'comm1',
        headerName: 'IMEI/Comm1',
        field: 'comm1',
        width: 120,
      },
      {
        colId: 'deviceName',
        headerName: 'Device Name',
        field: 'deviceName',
        width: 120,
      },
    ];
  };

  var mounted = function() {
    this.getDeviceTypes();
    this.getNetworks();
    this.getAllBillingPlans().then(plans => (this.localBillingPlans = plans));
    this.getServiceTypes();
    this.getBillingGroupsForOrg();
    this.getOrganizations().then(response => {
      // this.disableAllOrgsInDropdown(true);
    });
  };

  var created = function() {};

  var computed = {
    ...mapState('miscItemsModule', ['deviceTypes', 'networks', 'serviceTypes']),
    ...mapGetters('userModule', ['userHasClaim']),
    ...mapState('organizationModule', ['organizations', 'selectedOrganization']),
    ...mapState('backOfficeModule', ['billingPrices']),

    selectedOrgDropdown: function() {
      // if (this.selectedOrgId) {
      //   this.orgValidationText = '';
      // }
      return this.organizations.find(_ => _.orgId === this.selectedOrgId);
    },
    selectedDeviceType: function() {
      // this.deviceTypeValidationText = '';
      return this.deviceTypes.find(_ => _.deviceTypeId === this.selectedDeviceTypeId);
    },
    selectedServiceType: function() {
      // this.serviceTypeValidationText = '';
      return this.serviceTypes.find(_ => _.serviceTypeCode === this.selectedServiceTypeCode);
    },
    selectedNetwork: function() {
      // this.networkValidationText = '';
      return this.networks.find(_ => _.networkId === this.selectedNetworkId);
    },
    selectedBillingPlan: function() {
      // this.billingValidationText = '';
      return this.billingPrices.find(_ => _.billingPriceId === this.selectedBillingPriceId);
    },
  };

  var methods = {
    ...mapActions('miscItemsModule', ['getDeviceTypes', 'getNetworks', 'getServiceTypes']),
    ...mapActions('organizationModule', ['getOrganizations']),
    ...mapActions('backOfficeModule', ['getAllBillingPlans', 'getBillingGroups', 'createBillingGroup', 'deleteBillingGroup', 'updateBillingGroup', 'getBillingGroupById', 'setDeviceBillingGroup']),
    ...mapActions('deviceModule', ['createDevice', 'setSelectedDeviceIds', 'getDevices']),

    getImeiInput() {
      var beginsWithDigits = /^(\d+)/g;
      var namesIncluded = /[\u0020\t](.+)[^\s]/gm; // grabs any spaces or tabs followed by text
      var newLines = /[\r\n]{2,}/gm;
      var lastDigitsRegex = /(-\d+)[^\d-]*$/gm; // grabs -0003
      var results = [];
      var imeiInput = '';

      this.imeiValidationText = '';
      this.commAndDeviceNames = [];

      this.imeiRawData = this.$refs.imeiTextArea.value;
      var goodData = this.imeiRawData.search(beginsWithDigits);
      if (goodData === -1) {
        this.imeiValidationText = 'IMEIs must start with a digit';
        return;
      }
      imeiInput = this.$refs.imeiTextArea.value.trim();
      var noExtraReturns = imeiInput.replace(newLines, '\n');
      // allow name to be space separated by user, but use tab internally
      noExtraReturns = noExtraReturns.replace(' ', '\t');
      results = noExtraReturns.split('\n');
      var secondColumn = imeiInput.match(namesIncluded);

      // TODO: combine these
      if (secondColumn === null) {
        // deviceNames equals comm1
        this.commAndDeviceNames = this.copyCommToName(results);
      } else if (secondColumn.length > 1) {
        // devicesNames are included in second column
        this.commAndDeviceNames = this.splitCommAndDeviceNames(results);
      } else if (secondColumn.length === 1) {
        // autofill device names
        var baseName = secondColumn[0].toString().trim();
        var newDeviceNames = this.generateDeviceName(baseName, results.length);
        var obj = this.combineCommAndNames(newDeviceNames, noExtraReturns);
        this.$refs.imeiTextArea.value = obj.imeiInput;
        this.imeiRawData = obj.imeiInput;
        this.commAndDeviceNames = obj.devices;
      } // else input needs attention
      this.$v.localDevice.$model.devices = this.commAndDeviceNames;
    },

    generateDeviceName(name, count) {
      // TODO: fix when empty line adds to count
      // fix numbers with no dash
      var lastDigitsRegex = /(\d+)[^\d-]*$/g;
      var number = name.match(lastDigitsRegex);
      var prefix = name.substring(0, name.length - number[0].length);
      var origLength = number[0].toString().length;
      var numberValue = parseInt(number);
      var deviceNames = [];
      var numberOfZeros;
      var zero = '0';
      var leadingZeros = '';
      var tempName = '';

      deviceNames.push(name);

      for (var x = 1; x < count; x++) {
        numberValue += 1;
        numberOfZeros = origLength - numberValue.toString().length;
        numberOfZeros > 0 ? (leadingZeros = zero.repeat(numberOfZeros)) : '';
        tempName = prefix + leadingZeros + numberValue.toString();
        deviceNames.push(tempName);
      }
      return deviceNames;
    },

    copyCommToName(commArray) {
      var devices = [];
      var device = {};
      for (var x = 0; x < commArray.length; x++) {
        device = {};
        device.comm1 = commArray[x];
        device.deviceName = commArray[x];
        devices.push(device);
      }
      return devices;
    },

    splitCommAndDeviceNames(commPlusNames) {
      var spacesOrTabs = /[\u0020\t]+/g;
      var devices = [];
      var device = {};
      commPlusNames.forEach(element => {
        var temp = element.split(spacesOrTabs);
        device = {};
        device.comm1 = temp[0];
        device.deviceName = temp[1];
        devices.push(device);
      });
      return devices;
    },

    combineCommAndNames(deviceNames, imeiInput) {
      var customNameRegex = /(\u0020+\w+(-\d+)+)/g;
      var newLineRegex = /[\r\n]/g;
      var removedBaseName = imeiInput.replace(customNameRegex, '');
      var comm1Array = removedBaseName.trim().split(newLineRegex);
      var devices = [];
      var device = {};
      var newImeiString = '';
      for (var x = 0; x < comm1Array.length; x++) {
        device = {};
        device.comm1 = comm1Array[x].split('\t')[0];
        device.deviceName = deviceNames[x];
        devices.push(device);
        newImeiString += device.comm1 + ' ' + device.deviceName + '\n';
      }
      return { devices: devices, imeiInput: newImeiString };
    },

    validateFormData() {
      this.$v.localDevice.$touch();
      var isValid = true;
      if (this.commAndDeviceNames.length === 0) {
        // this.imeiValidationText = 'IMEI(s) required';
        isValid = false;
      }
      if (this.selectedOrgId == undefined || this.selectedOrgId === -1) {
        this.selectedOrgId = null;
        this.$v.localDevice.orgId.$model = null;
        // this.orgValidationText = 'Please select an organization';
        isValid = false;
      }

      if (this.selectedNetworkId == undefined) {
        this.selectedNetworkId = null;
        this.$v.localDevice.networkId.$model = null;
        // this.networkValidationText = 'Please select a network';
        isValid = false;
      }

      if (this.selectedDeviceTypeId == undefined) {
        this.selectedDeviceTypeId = null;
        this.$v.localDevice.deviceTypeId.$model = null;
        // this.deviceTypeValidationText = 'Please select a device type';
        isValid = false;
      }
      if (this.sensorPackageIds == undefined) {
        this.sensorPackageIds = null;
        this.$v.localDevice.sensorPackageIds.$model = null;
        // this.sensorsValidationText = 'Please add at least one sensor package';
        isValid = false;
      }

      if (this.selectedServiceTypeCode == undefined) {
        this.selectedServiceTypeCode = null;
        this.$v.localDevice.serviceTypeCode.$model = null;
        // this.serviceTypeValidationText = 'Please select a service type';
        isValid = false;
      }
      if (this.selectedBillingPriceId == undefined) {
        this.selectedBillingPriceId = null;
        this.$v.localDevice.defaultBillingPriceId.$model = null;
        // this.billingValidationText = 'Please select a billing plan';
        isValid = false;
      }
      return isValid;
    },

    getFormData() {
      var isValid = true;
      isValid = this.validateFormData();
      if (!isValid) {
        return;
      }

      this.$v.localDevice.$model.deviceDescription = this.deviceDescription;

      var devicesToAdd = [];
      var device = {};

      device.deviceId = 0;
      device.orgId = this.$v.localDevice.$model.orgId;
      device.networkId = this.$v.localDevice.$model.networkId;
      device.deviceTypeId = this.$v.localDevice.$model.deviceTypeId;
      device.serviceTypeCode = this.$v.localDevice.$model.serviceTypeCode;
      device.defaultBillingPriceId = this.$v.localDevice.$model.defaultBillingPriceId;
      device.sensorPackageIds = this.$v.localDevice.$model.sensorPackageIds;
      device.deviceDescription = this.$v.localDevice.$model.deviceDescription;
      device.billingGroupId = this.$v.localDevice.$model.billingGroupId;
      if (device.billingGroupId === undefined || device.billingGroupId === null) {
        device.billingGroupId = 0;
      } 
      console.log('device', device)
      for (var x = 0; x < this.commAndDeviceNames.length; x++) {
        var temp = Object.assign({}, this.commAndDeviceNames[x], device);
        devicesToAdd.push(temp);
      }
      var badImeiInput = '';
      for (var x = 0; x < devicesToAdd.length; x++) {
        this.createDevice(devicesToAdd[x])
          .then(response => {
            this.successfulPost.push(response.data.data);
            this.showSuccessGrid = true;
            this.gridApi.setRowData(this.successfulPost);
            this.gridOptions.columnApi.getColumn('comm1').setSort('asc');
            this.gridApi.sizeColumnsToFit();

            var index = this.commAndDeviceNames.map(el => el.comm1).indexOf(response.data.data.comm1);
            this.commAndDeviceNames.splice(index, 1);

            badImeiInput = '';
            this.commAndDeviceNames.forEach(el => {
              var badDevice = el.comm1;
              if (el.comm1 !== el.deviceName) {
                badDevice += '\t' + el.deviceName;
              }
              badDevice += '\n';
              badImeiInput += badDevice;
            });
            this.$refs.imeiTextArea.value = badImeiInput;
            this.imeiRawData = badImeiInput;
            if (x === devicesToAdd.length) {
              this.needsAttn = badImeiInput.length > 0 ? true : false;
            }
            if (this.commAndDeviceNames.length === 0) {
              var dids = this.successfulPost.map(p => p.deviceId).join();
              this.requestFreshDeviceData(dids);
              this.resetForm();
            }
          })
          .catch(error => {
            // status 400 duplicate imei
          });
      }
    },

    requestFreshDeviceData(dids) {
      var params = {
        getBillingPlans: true,
        getCommands: false,
        getSensorPackages: true,
        deviceIds: dids,
      };
      this.getDevices(params).then(response => {
        this.successfulPost = response;
      });
    },

    onDropdownInput(value, id) {
      if (value === null) {
        return;
      }
      if (id === 'orgId') {
        this.selectedOrgId = value.orgId;
        this.$v.localDevice.orgId.$model = value.orgId;
      } else if (id === 'networkId') {
        this.selectedNetworkId = value.networkId;
        this.$v.localDevice.networkId.$model = value.networkId;
        this.filterBillingPlans(id, value.networkId);
      } else if (id === 'deviceTypeId') {
        this.selectedDeviceTypeId = value.deviceTypeId;
        this.$v.localDevice.deviceTypeId.$model = value.deviceTypeId;
      } else if (id === 'serviceTypeCode') {
        this.selectedServiceTypeCode = value.serviceTypeCode;
        this.$v.localDevice.serviceTypeCode.$model = value.serviceTypeCode;
        this.filterBillingPlans(id, value.serviceTypeCode);
      } else if (id === 'selectedBillingPlan') {
        this.selectedBillingPriceId = value.billingPriceId;
        this.$v.localDevice.defaultBillingPriceId.$model = value.billingPriceId;
      } else if (id === 'billingGroup') {
        if (value.billingGroupId === null) { // 'Add group'
          this.showBillingGroupModal = true;
        } else {
          this.selectedBillingGroup = value;
          this.$v.localDevice.billingGroupId.$model = value.billingGroupId;           
        }
      }
    },

    filterBillingPlans(id, value) {
      this.localBillingPlans = this.billingPrices;
      var filteredPlans = [];
      if (this.$v.localDevice.networkId.$dirty && this.$v.localDevice.serviceTypeCode.$dirty) {
        filteredPlans = this.billingPrices.filter(
          plan => plan.networkId === this.selectedNetworkId && plan.serviceTypeCode === this.selectedServiceTypeCode,
        );
        this.localBillingPlans = filteredPlans;
      } else {
        filteredPlans = this.billingPrices.filter(plan => plan[id] === value);
        this.localBillingPlans = filteredPlans;
      }
      var checkPlan = this.localBillingPlans.filter(plan => plan.billingPriceId === this.selectedBillingPriceId);
      if (checkPlan.length === 0) {
        this.selectedBillingPriceId = null;
      }
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },

    onSelectionChanged(params) {
      var node = this.gridApi.getSelectedNodes();
      var did = node[0].data.deviceId;
      this.getDevices({
        deviceIds: did,
        getSensorPackages: true,
        getBilling: true,
        getCommands: false,
      }).then(response => {
        console.log('replace in grid', response);
        this.setSelectedDeviceIds([did]);
        this.deviceDetails = response[0];
      });
    },

    updatedSensorsReceived(value) {
      this.sensorPackageIds = [];
      this.sensorPackageIds = value;
      this.$v.localDevice.sensorPackageIds.$model = value;
      this.sensorsValidationText = '';
    },

    resetForm() {
      // var matches = document.querySelectorAll('div.pg-validation-warning');
      // matches.forEach(node => (node.innerHTML = ''));
      this.needsAttn = false;
      this.$v.localDevice.$reset();
      this.imeiRawData = '';
      this.$refs.imeiTextArea.value = '';
      this.deviceDescription = '';
      this.localDevice.sensorPackages = [];
      this.selectedOrgId = null;
      this.selectedNetworkId = null;
      this.selectedDeviceTypeId = null;
      this.selectedServiceTypeCode = null;
      this.selectedBillingPriceId = null;
      this.selectedBillingGroup = null;

    },

    getBillingGroupsForOrg() {
      var orgId = this.selectedOrganization.orgId;
      this.getBillingGroups(orgId).then(response => {
        this.billingGroupsForModal = response.filter(re => re.isActive == true);
        this.billingGroups = response.filter(re => re.isActive == true);
        this.billingGroups.splice(0, 0, {billingGroupId: 0, billingGroupName: 'Unassigned'});
        this.billingGroups.push({billingGroupId: null, billingGroupName: 'Add Group'});
      });
    },
    closeModal() {
      this.showBillingGroupModal = false;
    },
    disableAllOrgsInDropdown(setState) {
      this.organizations.forEach(org => {
        if (org.orgId === -1) {
          org.$isDisabled = setState;
        }
      });
    },
    
    onUpdatingGroup(changes) {
      console.log(changes)
      var whoEntered = this.$pgGlobal.currentUser.userName;
      var nowUtc = pgUtils.formatDate(this.moment().format(), 'ISO');
      var id = null;
      var newGroup = [];
      changes.forEach(el => {
          id = el.id;
          if (id != 0) {
            var patch = [ {
              "op": "replace",
              "path" : '/' + el.column,
              "value": el.value,
            },
            id];
          this.updateBillingGroup(patch).then(response => {
            console.log(changes.length)
          });
        } else {
          newGroup.push(el);
        }
      });
      if (newGroup.length > 0) {
        this.onAddBillingGroup(newGroup);
      }
    },

    onAddBillingGroup(group) {
      var orgId = this.selectedOrganization.orgId;
      var name = '';
      var description = '';
      group.forEach(gr => {
        if (gr.column == "billingGroupName") {
          name = gr.value;
        }
        if (gr.column == "billingGroupDescription") {
          description = gr.value;
        }
      });
      var isActive = true;
      var whoEntered = this.$pgGlobal.currentUser.userName;
      var nowUtc = pgUtils.formatDate(this.moment().format(), 'ISO');
      
      var newGroup = {
        orgId : orgId,
        billingGroupName : name,
        billingGroupDescription : description,
        isActive : isActive,
        whenUpdated: nowUtc,
        whoUpdated: whoEntered
      };
      this.createBillingGroup(newGroup).then(response => {
        this.getBillingGroupsForOrg();
      });
    },
    
    onDeleteGroup(group) {
      var id = group.billingGroupId;
      this.deleteBillingGroup(id).then(response => {
        this.getBillingGroupsForOrg();
      });
    },
    
    onUndoChange(id) {
      this.getBillingGroupById(id).then(response => {
        this.singleBillingGroup = response[0];
      });
    },
  };

  var watch = {};

  export default {
    data,
    props,
    validations,
    components,
    beforeMount,
    mounted,
    created,
    computed,
    methods,
    watch,
  };
</script>

/*************************************************************/

<style scoped>
 
</style>
