<template>
  <div class="device-map-component pg-component">
    <div class="map-container">
      <ul id="map-dropdown-menu" class="pg-dropdown-menu">
        <!-- View -->
        <li class="menu-dropdown-li">
          <span class="menu-dropdown menu-item">
            <i class="fal fa-eye"></i>
            <span>View</span>
          </span>
          <ul>
            <li title="Group nearby devices into clusters"
              v-on:click="onClusterCheckboxClick();closeMapMenu();" >
              <i class="fa-stack">
                <span class="far fa-radiation fa-stack-1x"></span>
                <span class="fal fa-radiation fa-stack-1x fa-rotate-180"></span>
              </i>
              <span>Cluster</span>
              <i class="far fa-check" v-show="userOptionsMap.showClustersFlag"></i>
            </li>
            <li title="Show latitude & longitude lines"
              v-on:click="onGridLineCheckboxClick();closeMapMenu();" >
              <i class="fal fa-table"></i>
              <span>Grid</span>
              <i class="far fa-check" v-show="userOptionsMap.showGridLinesFlag"></i>
            </li>
            <li>
              <select id="pgMapType" name="pgMapType" v-model="userOptionsMap.mapTypeId" v-on:change="onMapTypeChange()">
                <option value="terrain">Terrain</option>
                <option value="satellite">Satellite</option>
                <option value="roadmap">Street Map</option>
                <option value="hybrid">Hybrid</option>
              </select>
            </li>
          </ul>
        </li>

        <!-- Zoom -->
        <li>
          <span class="menu-dropdown menu-item">
            <i class="far fa-search"></i>
            <span>Zoom</span>
          </span>
          <ul>
            <li title="Zoom out" v-on:click="zoomMap(-1)">
              <i class="far fa-search-minus"></i>
              <span>Zoom Out</span>
            </li>
            <li title="Zoom in" v-on:click="zoomMap(1)">
              <i class="far fa-search-plus"></i>
              <span>Zoom In</span>
            </li>
            <li title="Zoom to fit all devices on map" v-on:click="zoomMap(0)">
              <i class="far fa-rectangle-landscape"></i>
              <span>Fit All</span>
            </li>
            <li
              title="Click here or hold down shift key.  Then draw an area on the map."
              v-on:click="onRectangleZoomClick()"
            >
              <i class="far fa-search pg-icon-dash-border"></i>
              <span>Zoom to Selection</span>
            </li>
          </ul>
        </li>

        <!-- Tracks -->
        <li>
          <span class="menu-dropdown menu-item">
            <i class="far fa-signature"></i>
            <span>Tracks</span>
          </span>
          <ul>
            <li title="Show tracks for all devices on map" v-on:click="onTrackChange(true);">
              <i class="far fa-signature"></i>
              <span>Show All Tracks</span>
            </li>
            <li title="Remove all tracks from map" v-on:click="onTrackChange(false);">
              <i class="fal fa-signature"></i>
              <span>Hide All Tracks</span>
            </li>
            <li>
              <div title="Number of days tracks to display">
                <select id="trackNum" name="trackNum" v-model="trackNum" v-on:change="onTrackNumChange()">
                  <option
                    v-for="item in trackNumOptions"
                    v-bind:value="item"
                    v-bind:key="item"
                  >{{ item }}</option>
                </select>
                <span>&nbsp; days</span>
              </div>
            </li>
          </ul>
        </li>

        <!-- Dates -->
        <li class="display-none">
          <span class="menu-dropdown menu-item">
            <i class="far fa-signature"></i>
            <span>Dates - {{ endDateMsg }}</span>
          </span>
          <ul>
            <li title="Last date to display" v-on:click="setEndDate();">
              <span>Through</span>
              <input type="date" id="dashboard-end-date" v-model="endDate" />
            </li>
            <li title v-on:click="/**/">
              <span>Through</span>
              <select v-model="endDateNum" v-on:change="setEndDateNumDays();">
                <option
                  v-for="item in endDateOptions"
                  v-bind:value="item"
                  v-bind:key="item"
                >{{ item }}</option>
              </select>
              <span>&nbsp; days ago</span>
            </li>
            <li title="Show map data through today" v-on:click="resetEndDate();">
              <a class="force-link" href="#">clear</a>
            </li>
          </ul>
        </li>

        <li v-on:click="onShowInfoWindowClick" v-bind:class="{disabled: !highlightedDeviceId}">
          <span class="menu-item">
            <i class="far fa-newspaper"></i>
            <span>Info Window</span>
          </span>
        </li>
      </ul>

      <google-map
        :zoom="2"
        :lat="0"
        :lon="0"
        :trackPointNum="trackNum"
        :trackPointUnit="'days'"
        :mapTypeId="userOptionsMap.mapTypeId"
      ></google-map>
    </div>
  </div>
</template>

/*************************************************************/

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import GoogleMap from "@/components/GoogleMap";
import { EventBus } from "@/EventBus";
import ProfileService from "@/services/ProfileService";

var data = function() {
  return {
    // map: null,
    mapZoom: 1,

    // toolbar
    showMapInfoWindowFlag: false,
    trackNumOptions: [1, 5, 10, 30, 60, 90, 180],
    trackNum: 30, // default # days tracks

    // map end date
    endDateNum: "any",
    endDateOptions: ["any", 1, 5, 10, 30, 60, 90, 180],
    endDate: null,
    endDateMsg: null,

    // saved as defaults for next time
    userOptionsMap: {
      mapTypeId: "terrain",
      showClustersFlag: true,
      showGridLinesFlag: false
    }
  };
};

var created = function() {
  EventBus.$on("MAP_LOADED", () => {
    this.applyUserOptions();
  });
};

var beforeMount = function() {};

var mounted = function() {
    $(".pg-dropdown-menu span.menu-dropdown").on("click", function(event) {
      $(this).parent().siblings().removeClass('active-menu');
      $(this).parent().toggleClass("active-menu");
      event.stopPropagation();
    });
    $(".dashboard-page .page-content :not(span.menu-dropdown)").on("click", function(event) {
      if (event.target && (event.target.name === 'pgMapType' || event.target.name === 'trackNum')) {
        // do nada
      } else {
        $(".active-menu").removeClass("active-menu");
      }
    });
};

var methods = {
  saveUserOptions: function() {
    ProfileService.saveLocalSetting("userOptionsMap", this.userOptionsMap);
  },
  restoreUserOptions: function() {
    var savedOptions = ProfileService.getLocalSetting("userOptionsMap", null);
    if (savedOptions) {
      // combine saved options with default
      this.userOptionsMap = Object.assign(this.userOptionsMap, savedOptions);
    }
  },

  applyUserOptions: function() {
    this.restoreUserOptions();
    // call after map loads...
    EventBus.$emit("MAP_SHOW_CLUSTERS", this.userOptionsMap.showClustersFlag);
    EventBus.$emit("MAP_SHOW_GRIDLINES", this.userOptionsMap.showGridLinesFlag);
    EventBus.$emit("MAP_SET_MAP_TYPE", this.userOptionsMap.mapTypeId);
  },

  // toolbar
  zoomMap: function(amount) {
    EventBus.$emit("MAP_ZOOM_AMOUNT", amount);
  },
  onClusterCheckboxClick: function() {
    this.userOptionsMap.showClustersFlag = !this.userOptionsMap.showClustersFlag;
    this.saveUserOptions();
    EventBus.$emit("MAP_SHOW_CLUSTERS", this.userOptionsMap.showClustersFlag);
  },
  onGridLineCheckboxClick: function() {
    this.userOptionsMap.showGridLinesFlag = !this.userOptionsMap.showGridLinesFlag;
    this.saveUserOptions();
    EventBus.$emit("MAP_SHOW_GRIDLINES", this.userOptionsMap.showGridLinesFlag);
  },
  onShowInfoWindowClick: function() {
    EventBus.$emit("MAP_TOGGLE_INFO_WINDOW");
  },
  onMapTypeChange: function() {
    this.saveUserOptions();
    EventBus.$emit("MAP_SET_MAP_TYPE", this.userOptionsMap.mapTypeId);
    $(".active-menu").removeClass("active-menu");
  },
  onRectangleZoomClick: function() {
    EventBus.$emit("MAP_TOGGLE_RECTANGLE_ZOOM");
  },
  onTrackChange(value) {
    $(".active-menu").removeClass("active-menu");
    if (value) {
      EventBus.$emit("MAP_SHOW_ALL_TRACKS");
    } else {
      EventBus.$emit("MAP_HIDE_ALL_TRACKS");
    }
  },
  onTrackNumChange() {
    EventBus.$emit("MAP_NUM_TRACK_UNITS", this.trackNum);
    console.log("from on select change", this.trackNum);
  },
  closeMapMenu() {
    console.log("closeMapMenu()");
    $(".active-menu").removeClass("active-menu");
  },

  resetEndDate() {
    this.endDateNum = "any";
    this.endDate = null;
    this.endDateMsg = "All";
  },
  setEndDate() {
    this.endDateNum = "any";
    this.endDateMsg = "Through " + this.endDate;
  },
  setEndDateNumDays() {
    this.endDate = null;
    this.endDateMsg = "Through " + this.endDateNum + " days ago";
  }
};

var computed = {
  // access these getters from MainStore (which gets them from store Modules)
  ...mapGetters("userModule", ["userHasRole"]),
  ...mapGetters("deviceModule", ["highlightedDevice"]),
  ...mapState("organizationModule", ["selectedOrganization"]),
  ...mapState("deviceModule", ["highlightedDeviceId"]),
  showThroughToday: function() {
    return this.endDate == null && this.endDateNum === "any";
  }
};

var watch = {};

var components = {
  GoogleMap
};

export default {
  name: "DeviceMap",
  data,
  created,
  beforeMount,
  mounted,
  methods,
  computed,
  watch,
  components
};
</script>

/*************************************************************/

<style lang="scss">
.device-map-component {
  flex: 1;
  display: flex;

  .map-container {
    flex: 1 1 auto;
    display: flex;
    position: relative;
    flex-direction: column;
  }

  // map toolbar
  .pg-toolbar {
    display: flex;
    flex: 0 0 25px;
    align-items: center;
    position: relative;
    overflow: hidden;

    div {
      margin: auto;
    }
    // min-height: 25px;

    /* Font Awesome adjustments */
    .fa-stack {
      height: 1em;
      line-height: 1em;
      width: 1em;
    }
  }
  .pg-toolbar-item {
    margin-left: 7px;
    margin-right: 7px;
    cursor: pointer;
    display: flex;
  }
  .pg-toolbar-item i {
    margin-right: 3px;
  }
  // .disabled {
  //   opacity: 0.5;
  //   cursor: default;
  // }
  .hide-tracks {
    opacity: 0.5;
  }
  .pg-icon-dash-border {
    border: 1px dashed;
    padding: 2px;
    font-size: 0.8em;
  }

  .touch .mouse-only {
    display: none;
  }

  .pg-toolbar {
    .toolbar-text {
      display: none;
    }
    //display: none !important; /* test */
  }

  // map dropdown menu
  .fa-stack {
    width: 1em;
  }
  .pg-dropdown-menu {
    z-index: 10;
  }
  .pg-dropdown-menu,
  .pg-dropdown-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    // background-color: #eeeeee;
  }
  .pg-dropdown-menu li {
    position: relative;
    float: left;
    text-decoration: none;
    white-space: nowrap;
    line-height: 2em;
    cursor: pointer;
    padding: 1px 10px 0 10px;
  }
  .pg-dropdown-menu li > ul {
    position: absolute;
    left: -999em;
    top: 0;
    transition: top 0.5s ease;
  }
  .pg-dropdown-menu ul ul {
    transition: transform 0.5s ease, opacity 0.3s ease;
    transform: translateX(0);
    opacity: 0;
  }
  .pg-dropdown-menu a {
    text-decoration: none;
  }
  .pg-dropdown-menu ul li {
    float: none;
    border-top: none;
  }
  .menu-item {
    padding: 0 10px 0;
    display: block;
  }
  /* hover effects */

  .has-mouse .pg-dropdown-menu li:hover > ul,
  .pg-dropdown-menu li.active-menu > ul {
    left: 0;
    top: 100%;
  }
  .has-mouse .pg-dropdown-menu li li:hover > ul,
  .pg-dropdown-menu li li.active-menu > ul {
    left: 0;
    transform: translateX(100%);
    top: 0;
    opacity: 1;
  }

  .menu-dropdown:after {
    content: "";
    border: 4px solid transparent;
    border-top: 4px solid inherit;
    margin-left: 6px;
    margin-bottom: 7px;
    display: inline-block;
    vertical-align: bottom;
  }

  .pg-dropdown-menu a.force-link {
    text-decoration: underline;
  }
  .pg-dropdown-menu .helper {
    font-style: italic;
    text-align: center;
  }
}
</style>
