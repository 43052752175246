import axios from 'axios';
import environmentSpecific from 'static/environmentSpecific';


import { resolve } from 'path';
import router from '../router/router';
/*
  currentUser sample:
    apiKey:"64E0156C-ED1D-4CBC-996A-XXXXXXXXXXXX"
    cell:""
    claims:Array[8]
      0:Object
        claimId:1
        claimType:"Activations"
        claimValue:"create|read|update|delete"
        claimValues:Array[4]
        comments:"View/change activations"
        possibleSettings:"create|read|update|delete"
      1:Object
        ...
    eMail:"ted.yates@pacificgyre.com"
    firstName:"Ted"
    lastLoginTime:"0001-01-01T00:00:00"
    lastName:"Yates"
    orgId:1
    orgName: "Pacific Gyre"
    phone:""
    roleIds:Array[3]
      0:100
      1:400
      2:300
    roles:Array[3]
      0:Object
        claims:null
        comments:"Access to all orgs"
        description:"PG personnel"
        roleId:100
        roleKey:"PGStaff"
        roleName:"Pacific Gyre Staff"
      1:Object...
    title:""
    userId:91
    userName:"tyates"

*/
var thisStore = this;

const state = {
  currentUser: {},
  usersByOrg: [],
  selectedUser: {},
  roles: [],
  allClaims: [],
  loginExpiresUtc: null,
};

/* mutations are the only way to modify state, no business logic         */
/* synchronous, "simple setters", not directly accessed by componets     */
const mutations = {
  setCurrentUser(state, currentUser) {
    state.currentUser = currentUser;
    //console.warn('currentUser set to ', currentUser);
  },
  setUsersForOrg(state, users) {
    state.usersByOrg = users;
  },
  setSelectedUser(state, user) {
    state.selectedUser = user;
  },
  setUserRoles(state, userRoles) {
    state.roles = userRoles;
  },
  setAllClaims(state, claims) {
    state.allClaims = claims;
  },
  setLoginExpires(state, loginExpiresUtc) {
    state.loginExpiresUtc = loginExpiresUtc;
  },
};

const actions = {
  isLoggedIn(state) {
    dispatch('getCurrentUserInfo').then((response) => {
      let now = new Date();
      return (state.loginExpiresUtc && state.loginExpiresUtc > now);
    });
  },

  logout({ commit }) {
    var authCookieName = environmentSpecific.pgAuthCookieName;
    var fullUrl = `${environmentSpecific.apiBaseUrl}users/logout`;

    return new Promise((resolve, reject) => {
      axios.get(fullUrl)
        .then((response) => {
          document.cookie = authCookieName + '=; expires=Thu, 01-Jan-70 00:00:01 GMT;';
          commit('setCurrentUser', null);
          commit('setLoginExpires', new Date('1971'));
          resolve(response);
        })
        .catch((error) => {
          document.cookie = authCookieName + '=; expires=Thu, 01-Jan-70 00:00:01 GMT;';
          console.log('UserStore logout() caught error', error);
          reject(error);
        });
    });
  },

  getUsersByOrgId({ commit }, OrgId) {
    if (OrgId === -1) {
      var fullUrl = `${environmentSpecific.apiBaseUrl}users/search`;
    } else {
      var fullUrl = `${environmentSpecific.apiBaseUrl}users/search?orgId=${OrgId}`;
    }
    return new Promise((resolve, reject) => {
      axios.get(fullUrl)
        .then((response) => {
          if (response.data.data.length > 0) {
            commit('setUsersForOrg', response.data.data);
          }
          resolve(response);
        })
        .catch((error) => { console.log('UserStore getUsersByOrgId() caught error', error); reject(error); });
    });
  },

  getUserByUserId({ commit }, UserId) {
    var fullUrl = `${environmentSpecific.apiBaseUrl}users/search?userId=${UserId}`;
    return new Promise((resolve, reject) => {
      axios.get(fullUrl)
        .then((response) => {
          if (response.data.data.length > 0) {
            commit('setSelectedUser', response.data.data[0]);
          }
          resolve(response);
        })
        .catch((error) => { console.log('UserStore getUserByUserId() caught error', error); reject(error); });
    });
  },

  getAllUserRoles({ commit }) {
    var fullUrl = `${environmentSpecific.apiBaseUrl}roles/search`;
    return new Promise((resolve, reject) => {
      axios.get(fullUrl)
        .then((response) => {
          if (response.data.data.length > 0) {
            var arr = response.data.data;
            arr.sort(function (a, b) {
              if (a.roleId < b.roleId) return -1;
              if (a.roleId > b.roleId) return 1;
              return 0;
            });
            commit('setUserRoles', arr);
          }
          resolve(response);
        })
        .catch((error) => { console.log('UserStore getAllUserRoles() caught error', error); reject(error); });
    });
  },

  getAllClaims() {
    var fullUrl = `${environmentSpecific.apiBaseUrl}claims/search`;
    return new Promise((resolve, reject) => {
      axios.get(fullUrl)
        .then((response) => {
          var arr = response.data.data;
          if (response.data.data.length > 0) {
            arr.sort(function (a, b) {
              if (a.claimType < b.claimType) return -1;
              if (a.claimType > b.claimType) return 1;
              return 0;
            });
            commit('setAllClaims', arr);
          }
          resolve(arr);
        })
        .catch((error) => { console.log('UserStore getAllClaims() caught error', error); reject(error); });
    });
  },

  getCurrentUserInfo({ commit }) {
    // console.log('3 getCurrentUserInfo() calling API...');
    var fullUrl = `${environmentSpecific.apiBaseUrl}users/checklogin`;
    return new Promise((resolve, reject) => {
      axios.get(fullUrl)
        .then((response) => {
          if (response.data.data) {
            commit('setCurrentUser', response.data.data);
            commit('setLoginExpires', response.data.metaData.expiresUtc);
          }
          resolve(response);
          // console.log('4 getCurrentUserInfo() got user info ', response.data.data);
        })
        .catch((error) => {
          console.log('UserStore getCurrentUserInfo() caught error', JSON.stringify(error));
          if (error.response && error.response.status) {
            console.log('UserStore getCurrentUserInfo() caught error number', error.response.status);
          }
          if (error.response && error.response.status === 401) {
            window.location.href = environmentSpecific.loginUrl;
            //router.push('login');
          }

          // router.push({name: 'Maintenance', params: {msg: error.response} });
          // window.location.href = '/static/maintenance.html?msg=' + error.response;
          reject(error);
        });
    });
  },

  getCurrentUser({ state, dispatch }) {
    return new Promise((resolve, reject) => {
      //console.log(' [[ 22222 ]]');
      if (state.currentUser.userId == undefined) {
        // console.log(' [[ 22222 ]] UserStore getCurrentUser: currentUser not populated, getting it now');
        dispatch('getCurrentUserInfo').then(()=> {
          // console.log(' [[ 22222 DONE getting data]]', state.currentUser.userName);
          resolve(state.currentUser);
        });
      } else {
        // console.log(' [[ 22222 DONE old data]]', state.currentUser.userName);
        resolve(state.currentUser);
      }
    });
  },

  checkUserNameAvailability({ }, UserName) {
    var fullUrl = `${environmentSpecific.apiBaseUrl}users/search?userName=${UserName}`;
    return new Promise((resolve) => {
      axios.get(fullUrl)
        .then((response) => { resolve(response); })
        .catch((error) => { console.log('UserStore checkUserNameAvailability() caught error', error); reject(error); });
     });
   },

  setSelectedUser({ state, commit, dispatch }, UserId) {
    if (UserId === null) {
      commit('setSelectedUser', {});
    } else {
      dispatch('getUserByUserId', UserId);
    }
  },

  createNewUser({ commit, dispatch, state }, user) {
    var fullUrl = `${environmentSpecific.apiBaseUrl}users`;
    return new Promise((resolve, reject) => {
      axios.post(fullUrl, user)
        .then((response) => {
          if (response.status === 200) {
            // dispatch('getUsersByOrgId', response.data.data.orgId);
            commit('setSelectedUser', response.data.data);
          }
          resolve(response);
        })
        .catch((error) => { console.log('UserStore createNewUser() caught error', error); reject(error); });
    });
  },

  updateUser({ commit, dispatch }, user) {
    var userId = user.pop();
    var fullUrl = `${environmentSpecific.apiBaseUrl}users/${userId.userId}`;
    return new Promise((resolve, reject) => {
      axios.patch(fullUrl, user)
        .then((response) => {
          if (response.status === 200) {
            // dispatch('getUsersByOrgId', response.data.data.orgId);
            commit('setSelectedUser', response.data.data);
          }
          resolve(response);
        })
        .catch((error) => { console.log('UserStore editUser() caught error', error); reject(error); });
    });
  },

  deleteUser({ dispatch }, userId) {
    var fullUrl = `${environmentSpecific.apiBaseUrl}users/${userId}?confirmation=really`;
    return new Promise((resolve, reject) => {
      axios.delete(fullUrl)
        .then((response) => {
          if (response.status === 204) {
            dispatch('setSelectedUser', null);
          }
          resolve(response);
        })
        .catch((error) => { console.log('UserStore deleteUser() caught error', error); reject(error); });
    });
  },

};

const methods = {};

/* "computed properties for stores" - calculate data based on store state  */
const getters = {
  userIsSelected(state){
    // not updated until after selectionChanged event completes
    // generally, listen for UserListGrid to emit onUserSelection instead
    // https://forum.vuejs.org/t/check-if-object-is-empty/3014/2
    return Object.keys(state.selectedUser).length > 0;
  },
  userHasClaim(state) {
    return (claimType, setting) => {

      if (state.currentUser) {
        if (state.currentUser.claims) {
          var foundClaimType = state.currentUser.claims.filter(claim => (claim.claimType.toLowerCase() === claimType.toLowerCase()));
          if (foundClaimType.length > 0) {
            var foundClaimValue = foundClaimType[0].claimValues.filter(cv => (cv === setting));
            // console.log(`Claim ${claimType}:${setting} found.`);
            return foundClaimValue.length > 0;
          } else {
            // console.warn(`User [${state.currentUser.userName}] does not have claim [${claimType}:${setting}] but has `, state.currentUser.claims);
          }
        } else {
          console.error(`UserStore currentUser [${state.currentUser.userName}] has no claims`);
        }
      } else {
        console.error(`UserStore currentUser does not exist`);
      }
      return false;
    };
  },
  userHasRole: (state) => (roleKey) => {
    if (state.currentUser && state.currentUser.roles) {
      var found = state.currentUser.roles.findIndex(role => (role.roleKey.toLowerCase() === roleKey.toLowerCase()));
      return found >= 0;
    }
    return false;
  },

};


export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  methods,
};
