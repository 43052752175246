import Vue from 'vue';
import PgSelect from '@/components/Common/PgSelect';

export default Vue.extend({
  template: `<div ref="actionWrapper" id="actionWrapper" tabindex=-1>
                <pg-select
                  ref="actionSelect"
                  id="actionSelect"
                  :options='options'
                  :value='selectedAction'
                  label='action'
                  track-by='action'
                  v-on:input="onDropdownInput"
                  v-on:close="onDropdownClose"
									:close-on-select="closeOnSelect"
                >
                </pg-select>
            </div>`,
  data() {
    return {
      options: [{ action: 'Payment' }, { action: 'Credit' }, { action: 'Charge' }],
      selectedAction: null,
      closeOnSelect: null,
      ref: null,
    };
  },

  beforeMount() {},

  beforeDestroy() {
    var y = document.getElementById('actionSelect');
    if (y === document.activeElement) {
      document.getElementById('actionSelect').blur();
    }
  },

  mounted() {
    this.selectedAction = this.options[0];
    var width = this.params.colDef.width;
    this.closeOnSelect = true;
    this.ref = this.$refs.actionSelect;
    this.$nextTick(() => {
      var parent = this.$refs.actionWrapper.parentNode;
      parent.style.width = width + 'px';
    });
  },

  methods: {
    onDropdownInput(value) {
      this.ref.$parent.$el.focus();

      this.$nextTick(() => {
        if (value === null) {
          return;
        }
        this.selectedAction = value;
        this.params.node.setDataValue('action', value.action);
        this.params.stopEditing();
      });
    },

    onDropdownClose(value, id) {},

    isPopup() {
      return true;
    },

    getValue() {
      var selected = this.selectedAction.action;
      return selected;
    },
  },
  components: {
    PgSelect,
  },
});
