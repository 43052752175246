<!-- template for the modal component -->
<template>
  <pg-modal :show="show" @close="close">
    <div class="popup-window billing-uncheck-all-modal">
      <div class="modal-body">
        <div class="popup-header">
          Please Confirm
          <i class="far fa-times-square closer" v-on:click="close()" />
        </div>

        <div class="popup-content">
          <div class="">
            <span>This will reset all billing finished dates. Do you wish to continue?</span>
          </div>
        </div>

        <div class="popup-footer popup-button-wrapper">
          <button class="" @click="close()">
            Cancel
          </button>
          <button class="" @click="confirm()">
            Confirm
          </button>
        </div>
      </div>
    </div>
  </pg-modal>
</template>

<script>
  import PgModal from '@/components/Common/PgModal';

  export default {
    name: 'BillingUncheckAllConfirmModal',
    props: ['show'],
    components: { PgModal },
    data() {
      return { msg: null };
    },
    methods: {
      close: function() {
        this.$emit('close');
      },
      confirm: function() {
        this.$emit('confirmed');
        this.close();
      },
    },
  };
</script>

/*************************************************************/

<style scoped></style>
