import axios from 'axios';
import environmentConfig from 'static/environmentSpecific';
import * as pgHelpers from "@/utils/PgHelpers.js";


//import { resolve } from 'path';

const state = {
  images: [],
};

/* mutations are the only way to modify state, no business logic         */
/* synchronous, "simple setters", not directly accessed by componets     */
const mutations = {
  setImagesState(state, images) {
    state.images = images;
  },
};

const actions = {
  getImages({ commit, rootState }, { orgId, deviceIds, startDate, endDate, timeRange, sortOrder, skipRecords, takeRecords }) {
    var fullUrl = `${environmentConfig.apiBaseUrl}images/search?`;
    if (orgId != -1) {
      fullUrl += '&orgId=' + orgId;
    }
    if (!deviceIds) {
      // override - must select device(s) to get images
      deviceIds = 0;
    }
    // if (deviceIds) {
    fullUrl += '&deviceIds=' + deviceIds;
    // }
    if (startDate) {
      fullUrl += '&startDateTime=' + startDate;
    }
    if (endDate) {
      fullUrl += '&endDateTime=' + endDate;
    }
    if (timeRange) {
      fullUrl += '&timeRange=' + timeRange;
    }
    if (sortOrder) {
      fullUrl += '&sortOrder=' + sortOrder;
    }
    if (skipRecords) {
      fullUrl += '&skipRecords=' + skipRecords;
    }
    if (takeRecords) {
      fullUrl += '&takeRecords=' + takeRecords;
    }
    // console.log('getImages', fullUrl);
    return new Promise((resolve, reject) => {
      axios.get(fullUrl)
        .then((response) => {
          var orgId = rootState.organizationModule.selectedOrganization.orgId;
          var format = "d";
          var lat = '';
          var long = '';
          response.data.data.forEach(image => {
            lat = image.latitude;
            long = image.longitude;
            image.latlong = pgHelpers.latLongDisplayHelper.formatLatLong(lat, long, format);
            image.imagePath === null ? image.imagePath = '/dataImages/' + orgId + '/' : '';
          });
          commit('setImagesState', response.data.data);
          resolve(response);
        })
        .catch((error) => { console.log('ImageStore getImages() caught error', error); reject(error); });
    });
  },
};

const methods = {};

const getters = {
  numImages: state => state.images.length,

  updateCoordFormat: (state) => (format) => {
    console.log(format)
    state.images.forEach(image => {
      image.latlong = pgHelpers.latLongDisplayHelper.formatLatLong(image.latitude, image.longitude, format);
      console.log(image.latlong)
    });
    return state.images;
  }
};

const plugins = [];

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  plugins,
  methods,
};
