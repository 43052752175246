<template>
  <div class="privacy-policy pg-component">
    <article class="">
      <h1 id="pacificgyre-privacy-statement">Pacific Gyre Privacy Statement</h1>

      <p>Effective date: <strong>July 2, 2019</strong></p>

      <p>Thank you for using Pacific Gyre services. Holding on to your private information is a serious responsibility,
        and we want you to know how we're handling it.</p>

      <h3 id="the-short-version"><a href="#the-short-version">The short version</a></h3>
      <p>We only collect the information you choose to give us, and we process it with your consent, or on another legal
        basis;
        we only require the minimum amount of personal information that is necessary to fulfill the purpose of your
        interaction with us;
        we don't sell it to third parties; and we only use it as this Privacy Statement describes.
      </p>

      <p>Of course, the short version doesn't tell you everything, so please read on for more details!</p>
      <h3 id="summary"><a href="#summary">Summary</a></h3>

      <table>
        <thead>
          <tr>
            <th class="col-section">Section</th>
            <th class="col-section-description">What can you find there?</th>
          </tr>
        </thead>
        <tbody class="same-color-rows">
          <tr>
            <td><a href="#what-information-pacificgyre-collects-and-why">What information Pacific Gyre collects and why</a></td>
            <td>Pacific Gyre collects basic information from visitors to our website, and some personal information from our
              users. We only require the minimum amount of personal information necessary from you. This section gives
              details.</td>
          </tr>
          <tr>
            <td><a href="#what-information-pacificgyre-does-not-collect">What information Pacific Gyre does not collect</a></td>
            <td>We don’t collect information from children under 13, and we don’t collect sensitive data.</td>
          </tr>
          <tr>
            <td><a href="#how-we-share-the-information-we-collect">How we share the information we collect</a></td>
            <td>We share information to provide the service to you, to comply with your requests. We do
              not host advertising on Pacific Gyre and we do not sell your personal information. </td>
          </tr>
          <tr>
            <td><a href="#how-you-can-access-and-control-the-information-we-collect">How you can access and control the
                information we collect</a></td>
            <td>We provide ways for you to access, alter, or delete your profile information. You can also contact
              <a href="mailto:info@pacificgyre.com" title="+1-760-433-6300 or email info@pacificgyre.com">Support</a> for more help.</td>
          </tr>
          <tr>
            <td><a href="#our-use-of-cookies-and-tracking">Our use of cookies and tracking</a></td>
            <td>We use cookies for the overall functionality of our website, and we use a small number of tracking and
              analytics services on our site.  </td>
          </tr>
          <tr>
            <td><a href="#how-pacificgyre-secures-your-information">How Pacific Gyre secures your information</a></td>
            <td>We take all measures reasonably necessary to protect the confidentiality, integrity, and availability of
              your personal information on Pacific Gyre and to protect the resiliance of our servers as they host your
              information.</td>
          </tr>
          <tr>
            <td><a href="#pacificgyres-global-privacy-practices">Pacific Gyre's global privacy practices</a></td>
            <td>Pacific Gyre complies with the the General Data Protection Regulation. Please see this section for more specific information.</td>
          </tr>
          <tr>
            <td><a href="#how-we-respond-to-compelled-disclosure">How we respond to compelled disclosure</a></td>
            <td>We may share your information in response to a warrant, subpoena, or other court action, or if disclosure is
              necessary to protect our rights or the rights of the public at large. We strive for transparency, and will
              notify you when possible.</td>
          </tr>
          <tr>
            <td><a href="#how-we-and-others-communicate-with-you">How we, and others, communicate with you</a></td>
            <td>We communicate with you by email. You can set your email address in your account settings.</td>
          </tr>
          <tr>
            <td><a href="#resolving-complaints">Resolving complaints</a></td>
            <td>In the unlikely event that we are unable to resolve a privacy concern quickly and thoroughly, we provide a
              path of dispute resolution through external arbiters.</td>
          </tr>
          <tr>
            <td><a href="#changes-to-our-privacy-statement">Changes to our Privacy Statement</a></td>
            <td>We will notify you of material changes to this Privacy Statement 30 days in advance of any such changes
              becoming effective. </td>
          </tr>
          <tr>
            <td><a href="#contacting-pacificgyre">Contacting Pacific Gyre</a></td>
            <td>Please feel free to contact us if you have questions about our Privacy Statement.</td>
          </tr>
        </tbody>
      </table>

      <h3 id="what-information-pacificgyre-collects-and-why"><a href="#what-information-pacificgyre-collects-and-why">What information
          Pacific Gyre collects and why</a></h3>

      <h4 id="categories-of-personal-information"><a href="#categories-of-personal-information">Categories of personal
          information</a></h4>

      <p>"User Personal Information" is any personal information about one of our users which could, alone or together with
        other information, personally identify them. Information such as a user name and password, an email address, and a real
        name are examples of “User Personal Information.” User Personal Information includes Personal Data
        as defined in the General Data Protection Regulation.</p>
      <p>"Technical Information" may include information we collect from website browsers, such as web server logs, or other
        log information, such as User session or activity logs. Technical Information may be connected to User Personal
        Information such as a username or an email address, or to other potentially personally-identifying information like
        Internet Protocol (IP) addresses.</p>
      <p>User Personal Information does not include aggregated, non-personally identifying information. We may use
        aggregated, non-personally identifying information to operate, analyze, improve, and optimize our website and
        service.</p>

      <h4 id="information-from-users-with-accounts"><a href="#information-from-users-with-accounts">Information from users
          with accounts</a></h4>
      <p>If you create an account, we require some basic information at the time of account creation. You
        will create your own user name and password, and we will ask you for a valid email address. You also have the option
        to give us more information if you want to, and this may include "User Personal Information."</p>

      <h4 id="information-from-website-browsers"><a href="#information-from-website-browsers">Information from website
          browsers</a></h4>
      <p>If you're just browsing the website, we collect the same basic information that most websites
        collect. We use common internet technologies, such as cookies and web server logs, to collect Technical Information.
        This is stuff we collect from everybody, whether they have an account or not.</p>
      <p>The information we collect about all visitors to our website includes the visitor’s browser type, language
        preference, referring site, additional websites requested, and the date and time of each visitor request. We also
        collect potentially personally-identifying information like Internet Protocol (IP) addresses.</p>

      <h4 id="information-we-collect-from-third-parties"><a href="#information-we-collect-from-third-parties">Information we
          collect from third parties</a></h4>
      <p>Your organization may create an account on your behalf.</p>

      <h5 id="why-we-collect-this-information"><a href="#why-we-collect-this-information">Why we collect this
          information</a></h5>
      <ul>
        <li>We need your User Personal Information to create your account, and to provide the services you request,
          including to provide the Pacific Gyre data service or to respond to support requests.</li>
        <li>We use your User Personal Information, specifically your user name, to identify you on Pacific Gyre.</li>
        <li>We will use your email address to communicate with you when necessary about your account or services.</li>
        <li>We collect Technical Information to better understand how our website visitors use Pacific Gyre, and to monitor
          and protect the security of the website.</li>
        <li>We use your User Personal Information and Technical Information for internal purposes, such as to maintain logs
          for security reasons, for training purposes, and for legal documentation and compliance.</li>
        <li>We limit our use of your User Personal Information to the purposes listed in this Privacy Statement. If we need
          to use your User Personal Information for other purposes, we will ask your permission first. You can always see
          what information we have in your user profile>.</li>
      </ul>
      <h5 id="our-legal-basis-for-processing-information"><a href="#our-legal-basis-for-processing-information">Our legal
          basis for processing information</a></h5>
      <p>Under certain international laws (including GDPR), Pacific Gyre is required to notify you about the legal basis on
        which we process User Personal Information. Pacific Gyre processes User Personal Information on the following legal
        bases:</p>
      <ul>
        <li>
          <p>Contract Performance:</p>
          <ul>
            <li>When you create a Pacific Gyre account, you provide your user name and an email address. We require those
              data elements for you to enter into the Terms of Service agreement with us, and we process those elements on
              the basis of performing that contract. We also process your user name and email address on other bases.</li>
            <li>If you have a Pacific Gyre paid account with us, there will
              be other data elements we must collect and process on the basis of performing that contract. </li>
          </ul>
        </li>
        <li>
          <p>Consent:</p>
          <ul>
            <li>As a user, you can fill out the information in your user profile. We process this information on the basis of consent.
              </li>
          </ul>
        </li>
        <li>
          <p>Legitimate Interests:</p>
          <ul>
            <li>Generally, the remainder of the processing of personal information we perform is necessary for the purposes
              of our legitimate interests. For example, for legal compliance purposes or to maintain ongoing
              confidentiality, integrity, availability and resilience of Pacific Gyre's systems, website, and service, we
              must keep logs of Technical Information; and, in order to respond to legal process, we are required to keep
              records of users who have sent and received DMCA takedown notices.</li>
          </ul>
        </li>
        <li>If you would like to request erasure of data we process on the basis of consent or object to our processing of
          personal information, <a href="emailto:info@pacificgyre.com">email us at info@pacificgyre.com</a>.</li>
      </ul>
      <h3 id="what-information-pacificgyre-does-not-collect"><a href="#what-information-pacificgyre-does-not-collect">What information
          Pacific Gyre does not collect</a></h3>
      <p>We do not intentionally collect <strong>sensitive personal information</strong>, such as social security numbers,
        genetic data, health information, or religious information. </p>
      <p>If you're a <strong>child under the age of 13</strong>, you may not have an account on Pacific Gyre. Pacific Gyre
        does not knowingly collect information from or direct any of our content specifically to children under 13. If we
        learn or have reason to suspect that you are a user who is under the age of 13, we will unfortunately have to close
        your account. We don't want to discourage you from accessing scientific data, but those are the rules. Please see our <a
          href="/en/articles/pacificgyre-terms-of-service/">Terms of Service</a> for information about account termination. Other
        countries may have different minimum age limits, and if you are below the minimum age for providing consent for data
        collection in your country, you may not use Pacific Gyre without obtaining your parents' or legal guardians'
        consent.</p>

        <h3 id="how-we-share-the-information-we-collect"><a href="#how-we-share-the-information-we-collect">How we share the
          information we collect</a></h3>
      <p>We <strong>do not</strong> share, sell, rent, or trade User Personal Information with third parties for their
        commercial purposes, except where you have specifically told us to.</p>
      <p>We <strong>do not</strong> host advertising on Pacific Gyre. We may occasionally embed content from third party
        sites, such as YouTube, and that content may include ads. While we try to minimize the amount of ads our embedded
        content contains, we can't always control what third parties show. Any advertisements on individual Pacific Gyre
        Pages or in Pacific Gyre repositories are not sponsored by, or tracked by, Pacific Gyre.</p>
      <p>We <strong>do not</strong> disclose User Personal Information outside Pacific Gyre, except in the situations listed
        in this section or in the section below on <a href="#how-we-respond-to-compelled-disclosure">Compelled
          Disclosure</a>.</p>
      <p>We may share User Personal Information if we are involved in a merger, sale, or acquisition. If any such change of
        ownership happens, we will ensure that it is under terms that preserve the confidentiality of User Personal
        Information, and we will notify you on our website or by email before any transfer of your User Personal
        Information. The organization receiving any User Personal Information will have to honor any promises we have made
        in our Privacy Statement or in our Terms of Service.</p>

        <h4 id="pacificgyre-pages"><a href="#pacificgyre-pages">Pacific Gyre Pages</a></h4>
      <p>If you create a Pacific Gyre Pages website, it is your responsibility to post a privacy statement that accurately
        describes how you collect, use, and share personal information and other visitor information, and how you comply
        with applicable data privacy laws, rules, and regulations. Please note that Pacific Gyre may collect Technical
        Information from visitors to your Pacific Gyre Pages website, including logs of visitor IP addresses, to maintain
        the security and integrity of the website and service.</p>

      <h4 id="pacificgyre-applications"><a href="#pacificgyre-applications">Pacific Gyre applications</a></h4>
      <p>You can also install Pacific Gyre applications on your mobile devices. These applications each have their own terms and may collect different
        kinds of User Personal Information; however, all Pacific Gyre applications are subject to this Privacy Statement,
        and we will always collect the minimum amount of User Personal Information necessary, and use it only for the
        purpose for which you have given it to us.</p>

      <h3 id="how-you-can-access-and-control-the-information-we-collect"><a
          href="#how-you-can-access-and-control-the-information-we-collect">How you can access and control the information
          we collect</a></h3>
      <p>If you're already a Pacific Gyre user, you may access, update, alter, or delete your basic user profile information
        by editing your user profile or contacting <a href="mailto:info@pacificgyre.com" title="+1-760-433-6300 or email info@pacificgyre.com">Pacific Gyre Support</a>.


        <h4 id="data-retention-and-deletion-of-data"><a href="#data-retention-and-deletion-of-data">Data retention and
          deletion of data</a></h4>
      <p>Generally, Pacific Gyre will retain User Personal Information for as long as your account is active or as needed to
        provide you services.</p>
      <p>We may retain certain User Personal Information indefinitely, unless you delete it or request its deletion. For
        example, we don’t automatically delete inactive user accounts, so unless you choose to delete your account, we will
        retain your account information indefinitely.</p>
      <p>If you would like to cancel your account or delete your User Personal Information, you may do so by emailing
        <a href="mailto:info@pacificgyre.com" title="+1-760-433-6300 or email info@pacificgyre.com">Pacific Gyre Support</a>.
        We will retain and use your information as necessary to
        comply with our legal obligations, resolve disputes, and enforce our agreements, but barring legal requirements, we
        will delete your full profile (within reason) within 90 days. You may contact
        <a href="mailto:info@pacificgyre.com" title="+1-760-433-6300 or email info@pacificgyre.com">Pacific Gyre Support</a>
        to request the erasure of the data we process on the basis of consent within 30 days.</p>

        <h3 id="our-use-of-cookies-and-tracking"><a href="#our-use-of-cookies-and-tracking">Our use of cookies and
          tracking</a></h3>
      <h4 id="cookies"><a href="#cookies">Cookies</a></h4>
      <p>Pacific Gyre uses cookies to make interactions with our service easy and meaningful. We use cookies (and similar
        technologies, like HTML5 localStorage) to keep you logged in, remember your preferences, and provide information for
        future development of Pacific Gyre. For security reasons, we use cookies to identify a device. By using our website,
        you agree that we can place these types of cookies on your computer or device. If you disable your browser or
        device’s ability to accept these cookies, you will not be able to log in or use Pacific Gyre’s services.</p>

      <table>
        <tr class="header">
          <td class="col-cookie">Cookie</td>
          <td class="col-name">Name</td>
          <td class="col-purpose">Purpose</td>
        </tr>
        <tr>
          <td>Authentication</td>
          <td>PG<br>domain .pacificgyre.com</td>
          <td>This cookie is strictly necessary and is used to keep you logged into the Pacific Gyre website.
              It contains personal identifying information including your username, session ID and an authentication token.
          </td>
        </tr>
        <tr>
          <td>Google Analytics</td>
          <td>_ga<br>_gid<br>
            domain: .pacificgyre.com
          </td>
          <td class="wrap-long-words">
            <p>These cookies are used to collect information about how visitors use this website. We use the information
            to compile reports and to help us improve this website. The cookies collect information in an anonymous form,
            including the number of visitors to this website, where visitors have come to this website from and the
            pages they visited.</p>
            <p>For further information see:
              <a href="https://support.google.com/analytics/answer/6004245">https://support.google.com/analytics/answer/6004245</a></p></td>
        </tr>
        <tr>
          <td>Youtube</td>
          <td>domain: .youtube.com</td>
          <td class="wrap-long-words">
            <p>We embed videos from our official YouTube channel using YouTube's privacy-enhanced mode. This mode
              may set cookies on your computer once you click on the YouTube video player, but YouTube will not store
              personally-identifiable cookie information for playbacks of embedded videos using the privacy-enhanced mode.</p>
            <p>Read more at YouTube's embedding videos information page.
              <a href="https://support.google.com/youtube/answer/171780">https://support.google.com/youtube/answer/171780</a>
              </p>
            </td>
        </tr>
        <tr>
          <td>Preferences</td>
          <td>Various in Local Storage</td>
          <td>
            <p>Various user settings and preferences are saved on your browser's local storage but are not sent to
              any server.
            </p>
          </td>
        </tr>

      </table>

        <h4 id="tracking-and-analytics"><a href="#tracking-and-analytics">Tracking and analytics</a></h4>
      <p>We use a number of third party analytics and service providers to help us evaluate our users' use of Pacific Gyre;
        compile statistical reports on activity; and improve our content and website performance.
        In addition, we use our own internal analytics software to provide features and improve our content and
        performance.</p>
      <p>Some browsers have incorporated "Do Not Track" (DNT) features that can send a signal to the websites you visit
        indicating you do not wish to be tracked. Pacific Gyre responds to browser DNT signals and follows the <a
          href="https://www.w3.org/TR/tracking-dnt/">W3C standard for responding to DNT signals</a>. If you have not enabled
        DNT on a browser that supports it, cookies on some parts of our website will track your online browsing activity on
        other online services over time, though we do not permit third parties other than our analytics and service
        providers to track Pacific Gyre users' activity over time on Pacific Gyre.</p>
      <h3 id="how-pacificgyre-secures-your-information"><a href="#how-pacificgyre-secures-your-information">How Pacific Gyre secures
          your information</a></h3>
      <p>Pacific Gyre takes all measures reasonably necessary to protect User Personal Information from unauthorized access,
        alteration, or destruction; maintain data accuracy; and help ensure the appropriate use of User Personal
        Information.</p>
      <p>Pacific Gyre enforces a written security information program. Our program:</p>
      <ul>
        <li>aligns with industry recognized frameworks; </li>
        <li>includes security safeguards reasonably designed to protect the confidentiality, integrity, availability, and
          resilience of our users' data;</li>
        <li>is appropriate to the nature, size, and complexity of Pacific Gyre’s business operations;</li>
        <li>includes incident response and data breach notification processes; and</li>
        <li>complies with applicable information security related laws and regulations in the geographic regions where
          Pacific Gyre does business.</li>
      </ul>
      <p>In the event of a data breach that affects your User Personal Information, we will act promptly to mitigate the
        impact of a breach and notify any affected users without undue delay.</p>
      <p>Transmission of data on Pacific Gyre is encrypted using SSH, HTTPS, and SSL/TLS.
        </p>
      <p>No method of transmission, or method of electronic storage, is 100% secure. Therefore, we cannot guarantee its
        absolute security. </p>

      <h3 id="pacificgyres-global-privacy-practices"><a href="#pacificgyres-global-privacy-practices">Pacific Gyre's global privacy
          practices</a></h3>
      <p><strong>We store and process the information that we collect in the United States</strong> in accordance with this
        Privacy Statement (our subprocessors may store and process data outside the United States). However, we understand
        that we have users from different countries and regions with different privacy expectations, and we try to meet
        those needs even when the United States does not have the same privacy framework as other countries'.</p>
      <p>We provide a high standard of privacy protection — as described in this Privacy Statement — to all our users around
        the world, regardless of their country of origin or location, and we are proud of the levels of notice, choice,
        accountability, security, data integrity, access, and recourse we provide. We work hard to comply with the
        applicable data privacy laws wherever we do business</p>
      <p>In particular:</p>
      <ul>
        <li>Pacific Gyre provides clear methods of unambiguous, informed consent at the time of data collection, when we do
          collect your personal information using consent as a basis.</li>
        <li>We collect only the minimum amount of personal information necessary for our purposes, unless you choose to
          provide more. We encourage you to only give us the amount of data you are comfortable sharing.</li>
        <li>We offer you simple methods of accessing, correcting, or deleting the User Personal Information we have
          collected.</li>
        <li>We provide our users notice, choice, accountability, security, and access, and we limit the purpose for
          processing. We also provide our users a method of recourse and enforcement. </li>
      </ul>


      <h3 id="how-we-respond-to-compelled-disclosure"><a href="#how-we-respond-to-compelled-disclosure">How we respond to
          compelled disclosure</a></h3>
      <p>Pacific Gyre may disclose personally-identifying information or other information we collect about you to law
        enforcement in response to a valid subpoena, court order, warrant, or similar government order, or when we believe
        in good faith that disclosure is reasonably necessary to protect our property or rights, or those of third parties
        or the public at large.</p>
      <p>In complying with court orders and similar legal processes, Pacific Gyre strives for transparency. When permitted,
        we will make a reasonable effort to notify users of any disclosure of their information, unless we are prohibited by
        law or court order from doing so, or in rare, exigent circumstances.</p>
      <p>We will use your email address to communicate with you, if you've said that's okay, <strong>and only for the
          reasons you’ve said that’s okay</strong>. For example, if you contact our Support team with a request, we will
        respond to you via email. </p>

        <h3 id="resolving-complaints"><a href="#resolving-complaints">Resolving complaints</a></h3>
      <p>If you have concerns about the way Pacific Gyre is handling your User Personal Information, please let us know
        immediately. We want to help. You may contact us by contacting
        <a href="mailto:info@pacificgyre.com" title="+1-760-433-6300 or email info@pacificgyre.com">Pacific Gyre Support</a>.
    </p>

    <h4 id="dispute-resolution-process"><a href="#dispute-resolution-process">Dispute resolution process</a></h4>
      <p>In the unlikely event that a dispute arises between you and Pacific Gyre regarding our handling of your User
        Personal Information, we will do our best to resolve it. If we cannot, we have selected to cooperate with the
        relevant EU Data Protection Authority, or a panel established by the European data protection authorities, for
        resolving disputes with EU individuals, and with the Swiss Federal Data Protection and Information Commissioner
        (FDPIC) for resolving disputes with Swiss individuals. Please contact us if you’d like us to direct you to your data
        protection authority contacts.</p>
      <p>Additionally, if you are a resident of an EU member state, you have the right to file a complaint with your local
        supervisory authority.</p>
      <h4 id="independent-arbitration"><a href="#independent-arbitration">Independent arbitration</a></h4>
      <p>Under certain limited circumstances, EU, EEA, Swiss, and UK individuals may invoke binding Privacy Shield
        arbitration as a last resort if all other forms of dispute resolution have been unsuccessful. To learn more about
        this method of resolution and its availability to you, please read more about <a
          href="https://www.privacyshield.gov/article?id=ANNEX-I-introduction">Privacy Shield</a>. Arbitration is not
        mandatory; it is a tool you can use if you choose to.</p>
      <p>We are subject to the jurisdiction of the U.S. Federal Trade Commission (FTC).</p>

      <h3 id="changes-to-our-privacy-statement"><a href="#changes-to-our-privacy-statement">Changes to our Privacy
          Statement</a></h3>
      <p>Although most changes are likely to be minor, Pacific Gyre may change our Privacy Statement from time to time. We
        will provide notification to Users of material changes to this Privacy Statement through our Website at least 30
        days prior to the change taking effect by posting a notice on our home page or sending email to the primary email
        address specified in your Pacific Gyre account. </p>

        <h3 id="contacting-pacificgyre"><a href="#contacting-pacificgyre">Contacting Pacific Gyre</a></h3>
      <p>Questions regarding Pacific Gyre's Privacy Statement or information practices should be directed to our contacting
        <a href="mailto:info@pacificgyre.com" title="+1-760-433-6300 or email info@pacificgyre.com">Pacific Gyre Support</a>.</p>

    </article>
  </div>
</template>

/*************************************************************/

<script>
  // import { mapGetters, mapState, mapActions } from 'vuex';
  import HtmlEditor from '@/components/HtmlEditor';

  var data = function() {
    return { msg: '' };
  };

  var beforeMount = function() {};

  var mounted = function() {};

  var methods = {
    // access these actions from MainStore
    // e.g. setDeviceName('Bob') is the same as
    // this.$store.dispatch('setDeviceName', 'Bob')
    //...mapActions('storeName', ['action1','action2', ]),
  };

  var computed = {
    // access these getters from MainStore (which gets them from store Modules)
    //...mapState('storeName', ['getterName1', 'getterName2']),
  };

  var watch= {  };

  var components = {
    HtmlEditor,
  };

  export default {
    name: 'PrivacyPolicy',
    data,
    beforeMount,
    mounted,
    methods,
    computed,
    watch,
    components
  };

</script>

/*************************************************************/

<style lang="scss">

.privacy-policy {
    padding: 2em;
}

.privacy-policy-page {

  .pg-component table {
    display: table;
    table-layout: fixed;
    line-height: 1.5;
    border-collapse: collapse;
    width: 90%;
    margin: auto;
  }

  .pg-component table td,
  .pg-component table th{
    padding: 6px 13px;
  }

  .col-cookie {width: 106px;}
  .col-name {width: 130px}
  .wrap-long-words {
    overflow-wrap: break-word;
    word-wrap: break-word;
  }


}

</style>
