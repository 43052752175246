<template>
  <div class="contacts-page">
    <contact-list-grid />
  </div>
</template>

<script>
import ContactListGrid from "@/components/ContactListGrid";

export default {
  name: 'ContactsPage',
  data () {
    return {
      msg: 'ContactsPage'
    }
  },
  components: {
    ContactListGrid,
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
