import axios from 'axios';
import environmentConfig from 'static/environmentSpecific';

import { resolve } from 'path';

const state = {
  sensors: [],
  sensorTypes: [],
  // selectedSensor: {}, 07092020 used by sensors page -- no longer
  sensorDataTypes: [],
  dataFields: [],
};

/* mutations are the only way to modify state, no business logic         */
/* synchronous, "simple setters", not directly accessed by componets     */
const mutations = {
  setSensorListState(state, sensors) {
    state.sensors = sensors;
  },
  setSensorTypes(state, sensorTypes) {
    state.sensorTypes = sensorTypes;
  },
  // setSensor(state, sensor) {  07092020 used by sensors page -- no longer
  //   state.selectedSensor = sensor;
  // },
  setSensorDataTypes(state, sensorDataTypes) {
    state.sensorDataTypes = sensorDataTypes;
  },
  setDataFields(state, dataFields) {
    state.dataFields = dataFields;
  },
};

const actions = {
  getSensors({ state, commit, rootState }) {
    var fullUrl = `${environmentConfig.apiBaseUrl}sensors/search`;
    return new Promise((resolve, reject) => {
      axios
        .get(fullUrl)
        .then(response => {
          commit('setSensorListState', response.data.data);
          resolve(response);
        })
        .catch(error => {
          console.log('SensorStore getSensors() caught error', error);
          reject(error);
        });
    });
  },
  getSensorTypes({ state, commit, rootState }) {
    var fullUrl = `${environmentConfig.apiBaseUrl}sensorTypes`;
    return new Promise((resolve, reject) => {
      axios
        .get(fullUrl)
        .then(response => {
          commit('setSensorTypes', response.data.data);
          resolve(response);
        })
        .catch(error => {
          console.log('SensorStore getAllSensorTypes() caught error', error);
          reject(error);
        });
    });
  },
  // setSelectedSensor({ state, commit, rootState }, sensor) {  07092020 used by sensors page -- no longer
  //   commit('setSensor', sensor);
  // },
  getSelectedSensorData({ state, commit, rootState }, sensorId) {
    var fullUrl = `${environmentConfig.apiBaseUrl}sensorData/search?sensorId=${sensorId}`;
    return new Promise((resolve, reject) => {
      axios
        .get(fullUrl)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(error => {
          console.log('SensorStore getSelectedSensordData() caught error', error);
          reject(error);
        });
    });
  },
  // for Sensor Grid
  // getSensorDataTypes({ state, commit, rootState }) {
  //   var fullUrl = `${environmentConfig.apiBaseUrl}sensorDataTypes`;
  //   return new Promise((resolve, reject) => {
  //     axios.get(fullUrl)
  //       .then((response) => { commit('setSensorDataTypes', response.data.data); resolve(response.data.data); })
  //       .catch((error) => { console.log('SensorStore getSensordDataTypes() caught error', error); reject(error); })
  //   });
  // },
  getSensorDataTypes({ state, commit, rootState }, params) {
    var fullUrl = `${environmentConfig.apiBaseUrl}sensorDataTypes/search`;
    return new Promise((resolve, reject) => {
      axios
        .post(fullUrl, params)
        .then(response => {
          commit('setSensorDataTypes', response.data.data);
          resolve(response.data.data);
        })
        .catch(error => {
          console.log('SensorStore getSensordDataTypes() caught error', error);
          reject(error);
        });
    });
  },
  // move into device store -- method has already been created there
  getDataFields({ state, commit, rootState }) {
    // console.trace('sensor store')
    var fullUrl = `${environmentConfig.apiBaseUrl}dataFields/search`;
    return new Promise((resolve, reject) => {
      axios
        .post(fullUrl)
        .then(response => {
          commit('setDataFields', response.data.data);
          resolve(response.data.data);
        })
        .catch(error => {
          console.log('SensorStore getDataFields() caught error', error);
          reject(error);
        });
    });
  },
  getUnits({ state, commit, rootState }) {
    var fullUrl = `${environmentConfig.apiBaseUrl}units`;
    return new Promise((resolve, reject) => {
      axios
        .get(fullUrl)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(error => {
          console.log('SensorStore getUnits() caught error', error);
          reject(error);
        });
    });
  },
  getDataTypes({ state, commit, rootState }) {
    var fullUrl = `${environmentConfig.apiBaseUrl}dataTypes`;
    return new Promise((resolve, reject) => {
      axios
        .get(fullUrl)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(error => {
          console.log('SensorStore getDataTypes() caught error', error);
          reject(error);
        });
    });
  },

  // **************************SENSOR DATA**********************************
  createSensorData({}, payload) {
    var fullUrl = `${environmentConfig.apiBaseUrl}sensorData`;
    return new Promise((resolve, reject) => {
      axios
        .post(fullUrl, payload)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          console.log('SensorStore createSensorData() caught error', error);
          reject(error);
        });
    });
  },
  updateSensorData({}, payload) {
    var dataId = payload.sensorDataId;
    var fullUrl = `${environmentConfig.apiBaseUrl}sensorData/${dataId}`;
    return new Promise((resolve, reject) => {
      axios
        .put(fullUrl, payload)
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          console.log('SensorStore updateSensorDataId() caught error', error);
          reject(error);
        });
    });
  },
  deleteSensorData({}, dataId) {
    var fullUrl = `${environmentConfig.apiBaseUrl}sensorData/${dataId}?confirmation=really`;
    return new Promise((resolve, reject) => {
      axios
        .delete(fullUrl)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log('SensorStore deleteSensorData() caught error', error);
          reject(error);
        });
    });
  },

  // **************************SENSOR DETAILS**********************************
  createNewSensorPackage({}, payload) {
    var fullUrl = `${environmentConfig.apiBaseUrl}sensors`;
    return new Promise((resolve, reject) => {
      axios
        .post(fullUrl, payload)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(error => {
          console.log('SensorStore createNewSensorPackage() caught error', error);
          reject(error);
        });
    });
  },
  getCopyOfSensorPackage({}, sensorId) {
    var fullUrl = `${environmentConfig.apiBaseUrl}sensors/copy?sensorId=${sensorId}`;
    return new Promise((resolve, reject) => {
      axios
        .post(fullUrl)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(error => {
          console.log('SensorStore getCopyOfSensorPackage() caught error', error);
          reject(error);
        });
    });
  },
  updateSensorPackage({}, payload) {
    var sensor = payload.pop();
    var fullUrl = `${environmentConfig.apiBaseUrl}sensors/${sensor.sensorId}`;
    return new Promise((resolve, reject) => {
      axios
        .patch(fullUrl, payload)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(error => {
          console.log('SensorStore updateSensorPackage() caught error', error);
          reject(error);
        });
    });
  },
  updateAllSensorDetails({}, payload) {
    var sensorId = payload.sensorId;
    if (sensorId === undefined) {
      return;
    }
    var fullUrl = `${environmentConfig.apiBaseUrl}sensors/${sensorId}`;
    return new Promise((resolve, reject) => {
      axios
        .put(fullUrl, payload)
        .then(response => {
          resolve(response.data.data);
        })
        .catch(error => {
          console.log('SensorStore updateAllSensorDetails() caught error', error);
          reject(error);
        });
    });
  },
};

const methods = {};

/* "computed properties for stores" - calculate data based on store state  */
const getters = {
  sensorList: state => {
    var sensorList = [];
    for (var index in state.sensors) {
      var sensorId = state.sensors[index].sensorId;
      var sensorName = state.sensors[index].sensorName;
      var sensorDescription = state.sensors[index].sensorDescription;
      var $isDisabled = false;
      var sensorListIndex = index;
      sensorList.push({ sensorId, sensorName, sensorDescription, $isDisabled, sensorListIndex });
    }
    return sensorList;
  },
  allSensorDataTypes: state => {
    return state.sensorDataTypes;
  },
  sensorsGetter: state => {
    var sensors = [];
    sensors = state.sensors.filter(sensor => {
      if (sensor.sensorName !== null && sensor.sensorName.search('CANCELLED') === -1) {
        return sensor;
      }
    });
    return sensors;
  },
};

const plugins = [];

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  plugins,
  methods,
  getters,
};
