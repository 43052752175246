<template>
  <div id="device-list-grid" class="device-list-grid">
    <div class="device-list-grid-body">
      <div class="pg-component-toolbar">
        <span class="pg-component-toolbar-left">
          <span class="pg-component-toolbar-item" :class="{'pg-component-toolbar-active': activeDevicesToggle }">
            <span @click="toggleActivatedDevices" class="fa-stack" :class="{ 'disabled': isEditing }">
              <i
                class="far fa-broadcast-tower fa-stack-1x extra-pad"
                title="Select active devices"
                alt="Select active devices"
                :class="{ 'pg-icon-dash-border': activeDevicesToggle}"
              />
            </span>
          </span>
          <span class="pg-component-toolbar-item" :class="{ 'disabled': selectedDeviceIds.length === 0 }">
            <span class="fa-stack" @click="deselectAll" title="Clear selection">
              <i class="far fa-check-square fa-stack-1x"></i>
              <i class="far fa-slash fa-stack-1x"></i>
            </span>
          </span>
          <span class="pg-component-toolbar-item" :class="{'pg-component-toolbar-active': filterActivatedOnly}">
            <span @click="filterActivatedOnly = !filterActivatedOnly" class="fa-stack" :class="{ 'disabled': isEditing, }">
              <i
                class="fas fa-power-off fa-stack-1x"
                title="Filter active devices"
                alt="Filter active devices"
                :class="{ 'pg-icon-dash-border': filterActivatedOnly}"
              />
              <!-- <i class="fal fa-square fa-stack-2x" v-if="filterActivatedOnly" /> -->
            </span>
          </span>
          <span class="pg-component-toolbar-item" :class="{ 'disabled': isEditing || !dataIsFiltered }">
            <span class="fa-stack" @click="clearAllFilters" title="Clear all filters" :class="{ 'disabled': isEditing || !dataIsFiltered }">
              <i class="far fa-filter fa-stack-1x"></i>
              <i class="fal fa-slash fa-stack-1x"></i>
            </span>
          </span>
          <span class="pg-component-toolbar-item">
            <span class="fa-stack" @click="updateLastTransmit" title="Refresh last transmit">
              <i class="far fa-arrow-from-top"></i>
            </span>
          </span>
          <!-- <span class="pg-component-toolbar-item">
            <span @click="!filterActivatedOnly" title="Configure" :class="{ 'disabled': isEditing }" >
              <i class="fas fa-cog"></i>
            </span>
          </span>-->
        </span>
        <span class="pg-component-toolbar-center"></span>
        <span class="pg-component-toolbar-right">
          <span class="pg-component-toolbar-item">
            <span @click="toggleColumnSelector" title="Select Columns" class="fa-stack">
              <i class="fas fa-cog"></i>
            </span>
          </span>
          <span class="pg-component-toolbar-item">
            <span @click="resetGridState()" title="Reset grid" class="fa-stack">
              <i class="far fa-undo"></i>
            </span>
          </span>
        </span>

        <div v-show="showColumnSelector" class="column-selector popup-window">
          <div class="popup-header">
            Columns
            <i class="far fa-times-square closer" v-on:click="showColumnSelector=false"></i>
          </div>
          <div class="popup-content">
            <div class="popup-row" v-for="col in gridColumns" v-bind:key="col.colId">
              <label>
                <input type="checkbox" v-model="col.visible" v-on:change="onChangeColumnVisibility(col)" />
                <span>{{ col.colDef.headerName }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="debug-grid-width">Grid width: {{ gridWidth }}</div> -->
      <ag-grid-vue
        class="ag-theme-balham grid-holder"
        style="width: 100%;"
        :gridOptions="gridOptions"
        :columnDefs="columnDefs"
        :modules="agGridModules"
      ></ag-grid-vue>
    </div>
  </div>
</template>

/*************************************************************/

<script>
  import { AgGridVue } from '@ag-grid-community/vue';
  import { AllCommunityModules } from '@ag-grid-community/all-modules';
  import { mapGetters, mapState, mapActions } from 'vuex';
  import * as pgHelpers from '@/utils/PgHelpers.js';
  import agGridUtils from '@/utils/AgGridUtils.js';
  import GridTextFilter from '@/utils/GridTextFilter.js';
  import GridCheckboxFilter from '@/utils/GridCheckboxFilter.js';
  import { EventBus } from '../EventBus.js';
  import environmentSpecific from 'static/environmentSpecific';
  import ProfileService from '@/services/ProfileService';

  // When creating an image as <img src='@/assets/myimage.png'/>
  // Webpack will process correctly.  If creating image as a string
  // like we do in statusCellRenderer, we need to import the images
  // to get WebPack to do it's thing.  It won't process
  // var icon='<img src='@assets/myImage.png'/>';
  import activeImg from '@/assets/images/icons/active.png';
  import activatedImg from '@/assets/images/icons/activated.png';
  import deactivatedImg from '@/assets/images/icons/deactivated.png';
  import suspendedImg from '@/assets/images/icons/suspended.png';
  import deployedImg from '@/assets/images/icons/deployed.png';
  import testingImg from '@/assets/images/icons/testing.png';

  var myDateFormat = 'YYYY-MM-DD HH:mm:ss '; // ISO  ISO.MSnp
  var thisComponent = null;
  var i = 0;
  var debounceTimer;
  const LOOP_AT_END = true;
  const DO_NOT_LOOP_AT_END = false;

  function debounce(func, wait) {
    var context = this;
    var args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(function() {
      func.apply(context, args);
    }, wait);
  }

  var statusCellRenderer = function(params) {
    var cellContents = '';

    var activeIcon = `<img src='${activeImg}' title='Active' alt='This device has transmitted recently' />`;
    var activatedIcon = `<img src='${activatedImg}' title='Activated' alt='This device has been activated' />`;
    var deactivatedIcon = `<img src='${deactivatedImg}' title='Deactivated' alt='This device is not currently activated' />`;
    var suspendedIcon = `<img src='${suspendedImg}' title='Suspended' alt='This device has been suspended' />`;
    var deployedIcon = `<img src='${deployedImg}' title='Deployed' alt='This device is deployed' />`;
    var testingIcon = `<img src='${testingImg}' title='Testing' alt='This device is being tested' />`;

    if (params.data.active) {
      cellContents += activeIcon;
    } else if (params.data.activationStatus === 'Activated') {
      cellContents += activatedIcon;
    } else if (params.data.activationStatus === 'Deactivated') {
      cellContents += deactivatedIcon;
    } else if (params.data.activationStatus === 'Inactive') {
      // never activated
      cellContents += deactivatedIcon;
    } else if (params.data.activationStatus === 'Suspended') {
      cellContents += suspendedIcon;
    }

    if (params.data.deployed) {
      cellContents += deployedIcon;
    }

    if (
      params.data.activationStatus &&
      params.data.activationStatus.startsWith('Activ') &&
      params.data.billingPlan != null &&
      params.data.billingPlan.contains('Demo')
    ) {
      cellContents += testingIcon;
    }
    return cellContents;
  };

  var statusSortOrder = {
    Active: 1,
    Activated: 2,
    Suspended: 3,
    Testing: 4,
    Inactive: 4,
    Deactivated: 5,
  };

  var statusSort = function(valueA, valueB, nodeA, nodeB) {
    var xStatus = nodeA.data.activationStatus;
    var yStatus = nodeB.data.activationStatus;
    var xDeployed = nodeA.data.deployed;
    var yDeployed = nodeB.data.deployed;
    var xDeviceName = nodeA.data.deviceName;
    var yDeviceName = nodeB.data.deviceName;

    if (xStatus < yStatus) {
      return -1;
    }
    if (xStatus > yStatus) {
      return 1;
    }
    if (xStatus == yStatus) {
      if (xDeployed > yDeployed) {
        return -1;
      }
      if (xDeployed < yDeployed) {
        return 1;
      }
    }
    if (xDeployed == yDeployed) {
      if (xDeviceName < yDeviceName) {
        return -1;
      }
      if (xDeviceName > yDeviceName) {
        return 1;
      }
      return 0;
    }
    // return 0;
  };

  var data = function() {
    return {
      columnDefs: null,
      rowData: null,
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      gridColumns: [],
      filterActivatedOnly: false,
      activeDevicesToggle: this.selectActivatedDevices,
      dataIsFiltered: false,
      gridWidth: 0,
      frameworkComponents: null,
      includesTelemetryOnlyDevices: false,
      origNoShows: [],
      agGridModules: AllCommunityModules,
      showColumnSelector: false,
      restoringGridState: false,
      gridIsLoaded: false,
      pageName: 'unknown',
    };
  };

  var props = {
    // [{deviceId, deviceName}]
    value: {
      type: Array,
      default: function() {
        return [];
      },
    },
    // overrides which columns can be shown
    showColumns: { type: String, default: '' },
    noShowColumns: {
      type: Array,
      default: function() {
        return [];
      },
    },
    hideColumnsBySize: {
      type: Array,
      default: function() {
        return hideColumnsBySizeDefault;
      },
    },
    imageOnly: { type: Boolean, default: false },
    isEditing: { type: Boolean, default: false },
    excludeTelemetryOnly: { type: Boolean, default: true },
    showSelectAll: { type: Boolean, default: true },
    newDeviceInfo: { type: Object, default: null },
    selectActivatedDevices: { type: Boolean, default: false },
    orgDevices: null,
  };

  var created = function() {
    thisComponent = this;
    this.createEvents();
    this.pageName = this.$route.name;
  };

  var beforeMount = function() {
    // thisComponent = this;
    // console.log("beforemount excludeTelemetryOnly=", this.excludeTelemetryOnly);
    this.gridOptions = {
      headerHeight: 25,
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      onGridReady: this.onGridReady,
      onGridSizeChanged: this.onGridSizeChanged,
      onFilterChanged: this.onFilterChanged,
      onCellClicked: this.onAnyCellClicked,
      rowSelection: 'multiple',
      rowMultiSelectWithClick: true,
      suppressRowClickSelection: true,
      onSelectionChanged: this.onSelectionChanged,
      onColumnMoved: this.onGridStateChanged,
      onDisplayedColumnsChanged: this.onGridStateChanged,
      onColumnResized: this.onGridStateChanged,
      isExternalFilterPresent: this.isExternalFilterPresent,
      doesExternalFilterPass: this.doesExternalFilterPass,
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
      },
      frameworkComponents: {
        gridTextFilter: GridTextFilter,
        gridCheckboxFilter: GridCheckboxFilter,
      },
      enableCellTextSelection: true,
      ensureDomOrder: true,
      suppressNoRowsOverlay: true,
      suppressMenuHide: true,
      suppressPropertyNamesCheck: true, // allow custom col def property pgOnly
      icons: {
        menu: '<i class="far fa-filter"/>',
      },
      rowHeight: environmentSpecific.gridRowHeightPx,
      // set dom role-id = deviceId
      getRowNodeId: data => data.deviceId,
    };

    this.columnDefs = [
      {
        colId: 'deviceName',
        headerName: 'Device Name',
        field: 'deviceName',
        minWidth: 125,
        width: 175,
        maxWidth: 650,
        checkboxSelection: true,
        headerCheckboxSelection: this.showSelectAll,
        headerCheckboxSelectionFilteredOnly: true,
        filter: 'gridTextFilter',
        filterParams: { apply: true, filterTitle: 'Device Names' },
        tooltipValueGetter: function(params) {
          return `Device ID: ${params.data.deviceId}`;
        },
      },
      {
        colId: 'highlight',
        headerName: 'Highlight',
        width: 25,
        minWidth: 25,
        maxWidth: 25,
        resizable: false,
        checkboxSelection: false,
        suppressMenu: true,
        sortable: false,
        cellRenderer: function(params) {
          // console.log("highlight cellRenderer params", params);
          //let highlightMe = params.data.deviceId == thisComponent.highlightedDeviceId ? " highlighted " : "";
          let cellValue = '<span class="highlight-symbol"></span>';
          return cellValue;
        },
        cellStyle: function(params) {
          return { padding: 0 };
        },
        tooltipValueGetter: function(params) {
          return 'Highlight this device';
        },
        onCellClicked: this.highlightButtonClicked,
      },
      {
        colId: 'orgName',
        headerName: 'Organization',
        field: 'orgName',
        minWidth: 125,
        width: 150,
        maxWidth: 300,
        filterParams: {
          applyButton: true,
          resetButton: true,
        },
        pgOnly: true,
      },
      {
        colId: 'deviceDescription',
        headerName: 'Description',
        field: 'deviceDescription',
        minWidth: 130,
        width: 180,
        maxWidth: 350,
        filterParams: {
          applyButton: true,
          resetButton: true,
        },
      },
      {
        colId: 'billingGroupName',
        headerName: 'Billing Group',
        field: 'billingGroupName',
        minWidth: 100,
        width: 115,
        maxWidth: 175,
        filterParams: {
          applyButton: true,
          resetButton: true,
        },
      },
      {
        colId: 'destinations',
        headerName: 'Destinations',
        field: 'destinations',
        minWidth: 130,
        width: 180,
        /*maxWidth: 350,*/
        filterParams: {
          applyButton: true,
          resetButton: true,
        },
        pgOnly: true,
      },
      {
        colId: 'commIds',
        headerName: 'Comm ID',
        field: 'comm1',
        minWidth: 120,
        width: 150,
        maxWidth: 250,
        filter: 'gridTextFilter',
        filterParams: {
          apply: true,
          filterTitle: 'Comm Ids',
        },
      },
      {
        colId: 'status',
        headerName: 'Status',
        field: 'status',
        minWidth: 100,
        width: 100,
        maxWidth: 120,
        filter: 'gridCheckboxFilter',
        filterParams: {
          apply: true,
          values: ['Activated', 'Active', 'Suspended', 'Deployed', 'Testing'],
          filterTitle: 'Status',
        },
        cellRenderer: statusCellRenderer,
        cellClass: 'image-icons',
        comparator: statusSort,
      },
      {
        colId: 'xmitDate',
        headerName: 'Last Transmit (UTC)',
        field: 'lastTransmission',
        minWidth: 150,
        width: 165,
        maxWidth: 265,
        valueFormatter: function(params) {
          return pgHelpers.dateTimeHelper.formatDate(params.data.lastTransmission, myDateFormat);
        },
        filter: 'agDateColumnFilter',
        filterParams: {
          applyButton: true,
          resetButton: true,
          comparator: agGridUtils.dateOnlyCompare,
          debounceMs: 500,
        },
      },
      {
        colId: 'xmitText',
        headerName: 'Last Xmit',
        field: 'lastTransmission',
        minWidth: 100,
        width: 115,
        maxWidth: 175,
        valueFormatter: function(params) {
          return pgHelpers.dateTimeHelper.timeAgo(params.data.lastTransmission);
        },
        tooltipValueGetter: function(params) {
          return pgHelpers.dateTimeHelper.formatDate(params.data.lastTransmission, myDateFormat);
        },
        suppressMenu: true,
      },
      {
        colId: 'wmoId',
        headerName: 'WMO ID',
        field: 'wmoId',
        minWidth: 100,
        width: 115,
        maxWidth: 175,
        filter: 'agNumberColumnFilter',
        filterParams: {
          applyButton: true,
          resetButton: true,
        },
      },
      {
        colId: 'networkName',
        headerName: 'Network',
        field: 'networkName',
        minWidth: 100,
        width: 115,
        maxWidth: 175,
        filterParams: {
          applyButton: true,
          resetButton: true,
        },
      },

    ];

    if (!this.$pgGlobal.currentUser.isPgStaff) {
      this.columnDefs = this.columnDefs.filter(d => !d.pgOnly);
    }

    // if supplied with a list of columns to display, use that order
    if (this.showColumns) {
      var order = this.showColumns.split(',');
      this.columnDefs = _.sortBy(this.columnDefs, function(column) {
        return _.indexOf(order, column.colId);
      });
    }
  };

  var mounted = function() {
    if (this.showColumns) {
      this.columnDefs.forEach(col => {
        if (this.showColumns.includes(col.colId)) {
          this.gridOptions.columnApi.setColumnVisible(col.colId, true);
        } else {
          this.gridOptions.columnApi.setColumnVisible(col.colId, false);
        }
      });
    }
  };

  var methods = {
    toggleColumnSelector() {
      this.showColumnSelector = !this.showColumnSelector;
      if (this.showColumnSelector) {
        this.gridColumns = this.columnApi.getAllColumns();
        // console.log('toggleColumnSelector -> this.gridColumns', this.gridColumns);
      }
    },

    onChangeColumnVisibility(column) {
      // console.log((column.visible ? 'Showing ' : 'Hiding ') + column.colId);
      if (column.visible) {
        // workaround for bug - in ag-grid-vue?
        this.columnApi.setColumnVisible(column.colId); // should not need, but without hide fails
        this.columnApi.setColumnVisible(column.colId, true); // works
      } else {
        // workaround for bug - in ag-grid-vue?
        this.columnApi.setColumnVisible(column.colId); // hides, but shouldn't
        this.columnApi.setColumnVisible(column.colId, false); // correct, but does nothing, kept for future fix
      }
    },

    highlightButtonClicked(node) {
      node.node.setSelected(true);
      // toggle highlighted
      if (node.data.deviceId === this.highlightedDeviceId) {
        this.setHighlightedDeviceId(null);
      } else {
        this.setHighlightedDeviceId(node.data.deviceId);
      }
    },

    highlightByDeviceId(deviceId) {
      // console.log('setting focus to ', deviceId);
      // this.setHighlightedDeviceId(deviceId);
      this.gridOptions.api.forEachNode(node => {
        console.warn('TODO: highlightByDeviceId()', node);
        if (node.data.deviceId == deviceId) {
          node.setSelected(true);
          // TODO add class to row
        } else {
          // remove class from row
        }
      });
    },

    createEvents() {
      EventBus.$on('MAP_SELECT_NEXT_DEVICE', () => {
        this.highlightNextDevice(LOOP_AT_END);
      });
      EventBus.$on('MAP_SELECT_PREVIOUS_DEVICE', () => {
        this.highlightPreviousDevice(LOOP_AT_END);
      });
      EventBus.$on('REFRESH_DEVICE_LIST_DATA', () => {
        this.updateLastTransmit();
      });
    },

    updateLastTransmit() {
      var params = {
        getBillingPlans: false,
        getCommands: false,
        getSensorPackages: false,
        orgId: thisComponent.selectedOrganization.orgId,
      };
      this.getDevices(params).then(response => {
        var allDeviceData = response.data.data;
        this.gridApi.forEachNodeAfterFilter(node => {
          var did = node.data.deviceId;
          // console.log('node deviceId:', did);
          var d = allDeviceData.find(data => data.deviceId === did);
          // console.log('data for device ', did, d);
          node.setDataValue('xmitDate', d.lastTransmission);
        });
      });
    },

    highlightNextDevice(loopAtEnd) {
      const selectedNodes = _.sortBy(this.gridApi.getSelectedNodes(), 'rowIndex');
      for (var i = 0; i < selectedNodes.length; i++) {
        if (selectedNodes[i].data.deviceId === this.highlightedDeviceId) {
          if (i < selectedNodes.length - 1) {
            this.setHighlightedDeviceId(selectedNodes[i + 1].data.deviceId);
          } else if (loopAtEnd) {
            this.setHighlightedDeviceId(selectedNodes[0].data.deviceId);
          }
          return;
        }
      }
      if (selectedNodes.length > 0) {
        this.setHighlightedDeviceId(selectedNodes[0].data.deviceId);
      }
    },

    highlightPreviousDevice(loopAtEnd) {
      const selectedNodes = _.sortBy(this.gridApi.getSelectedNodes(), 'rowIndex');
      for (var i = 0; i < selectedNodes.length; i++) {
        if (selectedNodes[i].data.deviceId === this.highlightedDeviceId) {
          if (i === 0 && loopAtEnd) {
            this.setHighlightedDeviceId(selectedNodes[selectedNodes.length - 1].data.deviceId);
          } else {
            this.setHighlightedDeviceId(selectedNodes[i - 1].data.deviceId);
          }
          return;
        }
      }
      if (selectedNodes.length > 0) {
        this.setHighlightedDeviceId(selectedNodes[0].data.deviceId);
      }
    },

    onAnyCellClicked(cell) {
      // console.log("onAnyCellClicked()", cell);
      if (cell.colDef.colId !== 'highlight') {
        // only do this stuff if highlight button not clicked...
        this.setHighlightedDeviceId(null);
        this.setSelectedDeviceIds(cell.data.deviceId);
      }
    },

    onRowClicked(node) {
      // use onAnyCellClicked() instead so we know what was clicked
      // this way we can treat highlight button differently
      //console.log("onRowClicked()", node);
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.gridApi.setPopupParent(document.body);
      thisComponent.gridIsLoaded = true;
      thisComponent.retoreGridState();
      this.gridWidth = document.getElementById('device-list-grid').offsetWidth; // to display during dev
      this.columnApi.sizeColumnsToFit(this.gridWidth);

      $('body').on('click', '.ag-header-icon', function() {
        // console.log("ref", thisComponent);
        thisComponent.$nextTick(function() {
          $('.ag-header-icon')
            .find('button')
            .css('background-color', 'purple');
        });
      });
    },

    onGridStateChanged(params) {
      if (thisComponent.gridIsLoaded && !thisComponent.restoringGridState) {
        // console.log(' ** grid state changed   restoringGridState=', thisComponent.restoringGridState, ',  params=', params ? params.type || params : params);
        pgHelpers.systemHelper.debounce(function() {
          thisComponent.saveGridState();
        }, 1000);
      }
    },

    onSelectionChanged(params) {
      // console.log('onSelectionChanged() doing nothing');
      pgHelpers.systemHelper.debounce(function() {
        thisComponent.getSelectedRows();
      }, 300);
    },

    onGridSizeChanged(params) {
      // if prop "hideColumnsBySize" is null, use []
      // if prop is [] use []
      // if prop not set, use default
      // if prop set, use it
      return; // TODO clean this up   ///////////////////// TEST TEST // TEST TEST // TEST TEST // TEST TEST
      this.gridWidth = document.getElementById('device-list-grid').offsetWidth; // to display during dev
      if (this.hideColumnsBySize === null) {
        this.hideColumnsBySize = [];
      }
      agGridUtils.showColumnsBySize(params, this.gridWidth, this.hideColumnsBySize, this.showColumns, this.noShowColumns);
    },

    onFilterChanged(params) {
      var filteredIds = [];
      var selectedNodes = [];
      var colFilterActive = null;
      if (this.selectedDeviceIds.length > 0) {
        this.gridApi.forEachNodeAfterFilter(node => {
          filteredIds.push(node.data.deviceId);
        });
        selectedNodes = this.gridApi.getSelectedNodes();
        selectedNodes.forEach(node => {
          if (!filteredIds.includes(node.data.deviceId)) {
            node.setSelected(false);
          }
        });
      }
      colFilterActive = this.gridApi.isColumnFilterPresent();
      this.dataIsFiltered = colFilterActive || this.filterActivatedOnly ? true : false;
      this.onGridStateChanged();
    },

    getSelectedRows() {
      const selectedNodes = this.gridApi.getSelectedNodes();
      const selectedData = selectedNodes.map(node => node.data);
      const selectedDataStringPresentation = selectedData.map(node => /*node.deviceId + ' ' +*/ node.deviceName).join(', ');
      var selectedDeviceIds = selectedData.map(node => node.deviceId);
      // console.log('>>> DeviceListGrid getSelectedRows() calling setSelectedDeviceIds()');
      if (this.activeDevicesToggle) {
        selectedData.forEach(d => {
          if (d.activationStatus != 'Activated') {
            this.activeDevicesToggle = !this.activeDevicesToggle;
          }
        });
      }
      this.setSelectedDeviceIds(selectedDeviceIds);
      return selectedDataStringPresentation;
    },

    isExternalFilterPresent() {
      return this.filterActivatedOnly || this.imageOnly || this.excludeTelemetryOnly;
    },

    doesExternalFilterPass(node) {
      var activatedOk = true;
      if (this.filterActivatedOnly) {
        activatedOk = node.data.active;
      }
      var imageOk = true;
      if (this.imageOnly) {
        imageOk = node.data.hasCamera; // this.isImageDevice(node.data);
      }
      var telemetryOnlyOk = false;
      if (this.excludeTelemetryOnly === false || node.data.serviceTypeCode !== 'telemetryOnly') {
        telemetryOnlyOk = true;
      }

      return activatedOk && imageOk && telemetryOnlyOk;
    },

    externalFilterChanged(event) {
      // Inside of JS functions like nextTick or setTimeout, we lose 'this' so save it as my
      // 'this' changes but 'my' won't
      var my = this;
      this.$nextTick(function() {
        my.gridOptions.api.onFilterChanged();
      });
    },

    disableGrid() {
      this.gridOptions.api.showLoadingOverlay();
    },

    enableGrid() {
      this.gridOptions.api.hideOverlay();
    },

    clearAllFilters() {
      if (this.gridApi === null) {
        return;
      }
      this.gridApi.setFilterModel(null);
      if (this.filterActivatedOnly) {
        this.filterActivatedOnly = !this.filterActivatedOnly;
      }
      this.gridApi.onFilterChanged();
    },

    checkForPrevSelectedDevices() {
      this.gridOptions.api.forEachNode(node => {
        if (this.selectedDeviceIds.includes(node.data.deviceId)) {
          node.setSelected(true);
        } else {
          node.setSelected(false);
        }
      });
    },

    defaultSort(a, b) {
      var aDeployed = a.deployed;
      var bDeployed = b.deployed;
      var aStatus = a.active ? 'Active' : a.activationStatus;
      var bStatus = b.active ? 'Active' : b.activationStatus;
      var aLastTransmission = a.lastTransmission;
      var bLastTransmission = b.lastTransmission;

      if (aDeployed > bDeployed) {
        return -1;
      }
      if (aDeployed < bDeployed) {
        return 1;
      }
      if (aDeployed == bDeployed) {
        if (statusSortOrder[aStatus] < statusSortOrder[bStatus]) {
          return -1;
        }
        if (statusSortOrder[aStatus] > statusSortOrder[bStatus]) {
          return 1;
        }
      }
      if (statusSortOrder[aStatus] == statusSortOrder[bStatus]) {
        return new Date(bLastTransmission) - new Date(aLastTransmission);
      }
    },

    setGridData(data) {
      if (this.gridApi === null) {
        this.rowData = data;
        return;
      }
      if (data.length === 0) {
        this.gridOptions.suppressNoRowsOverlay = false;
        this.gridApi.showNoRowsOverlay();
      } else {
        var sortedData = [...data].sort(this.defaultSort);
        this.gridApi.setRowData(sortedData);
        this.checkForPrevSelectedDevices();
        // for dashboard page
        if (this.selectActivatedDevices) {
          this.selectActiveDevices();
        }
      }
    },

    prepGridForNewData() {
      this.gridApi.setRowData([]);
      this.gridApi.hideOverlay();
      // this.setSelectedDeviceIds([]); handled when org change happens in user profile
      this.gridOptions.suppressNoRowsOverlay = true;
      this.clearAllFilters();
    },

    toggleActivatedDevices() {
      if (this.selectedDeviceIds.length > 0 && this.activeDevicesToggle) {
        this.deselectAll();
      } else {
        this.selectActiveDevices();
        this.activeDevicesToggle = !this.activeDevicesToggle;
      }
    },

    deselectAll() {
      if (this.activeDevicesToggle) {
        this.activeDevicesToggle = !this.activeDevicesToggle;
      }
      var selected = this.gridOptions.api.getSelectedNodes();
      selected.forEach(node => {
        node.setSelected(false);
      });
    },

    selectActiveDevices() {
      this.gridApi.forEachNode(node => {
        // Use Active instead of Activated because of ProcessingOnly and DoD
        if (node.data.serviceTypeCode !== 'telemetryOnly' && node.data.active) {
          node.setSelected(true);
        } else {
          node.setSelected(false);
        }
      });
    },

    getGridIndexByDeviceId(deviceId) {
      var idx = null;
      this.gridOptions.api.forEachNode((node, index) => {
        if (node.data.deviceId === deviceId) {
          idx = node.rowIndex;
        }
      });
      return idx;
    },

    saveGridState(event) {
      // console.log('Saving grid state >>>');
      var filterModel = thisComponent.gridApi.getFilterModel();
      ProfileService.saveLocalSetting(thisComponent.pageName + '-filterState', filterModel);
      ProfileService.saveLocalSetting(thisComponent.pageName + '-deviceListGridColumnState', thisComponent.columnApi.getColumnState());
      ProfileService.saveLocalSetting(thisComponent.pageName + '-sortState', thisComponent.gridApi.getSortModel());
    },

    retoreGridState() {
      thisComponent.restoringGridState = true;
      // console.log('<<< restoring grid state ')
      var savedStateColumnState = ProfileService.getLocalSetting(thisComponent.pageName + '-deviceListGridColumnState', null);
      if (savedStateColumnState) {
        thisComponent.columnApi.setColumnState(savedStateColumnState);
      }

      var savedSortState = ProfileService.getLocalSetting(thisComponent.pageName + '-sortState', null);
      if (savedSortState) {
        thisComponent.gridApi.setSortModel(savedSortState);
      }

      var savedFilterState = ProfileService.getLocalSetting(thisComponent.pageName + '-filterState', null);
      if (savedFilterState) {
        thisComponent.gridApi.setFilterModel(savedFilterState);
      }
      thisComponent.restoringGridState = false;
      // console.log('<   restoring grid state end')
    },

    resetGridState(event) {
      if (thisComponent.columnApi) {
        thisComponent.columnApi.resetColumnState();
        var params = { gridApi: thisComponent.gridApi, columnApi: thisComponent.columnApi };
        thisComponent.onGridSizeChanged(params);
        // this.columnApi.setSortModel(null);
        // this.clearAllFilters();
      } else {
        // console.log("resetGridState() this.columnApi not set");
      }
    },

    ...mapActions('deviceModule', ['getDevices', 'setSelectedDeviceIds', 'setHighlightedDeviceId']),
  };

  var computed = {
    ...mapState('deviceModule', ['deviceList', 'selectedDeviceIds', 'highlightedDeviceId']),
    ...mapState('organizationModule', ['selectedOrganization']),
    ...mapGetters('deviceModule', ['deviceListWithDestinationsByOrg', 'currentDeviceListOrgId']),
  };

  var watch = {
    selectedDeviceIds: function(newList, oldList) {
      this.checkForPrevSelectedDevices();
    },

    highlightedDeviceId: function(newDeviceId, oldDeviceId) {
      //console.log('>> grid watch highlight from ', oldDeviceId, ' to ', newDeviceId);
      // ag-grid not helpful working with DOM directly and does not refresh on changes
      // sucker punching works though
      // console.log('Highlighting ', newDeviceId);
      $('.ag-row.highlighted').removeClass('highlighted');

      if (newDeviceId) {
        var selector = 'div[row-id=' + newDeviceId + ']';
        $(selector).addClass('highlighted');
        var gridIndex = this.getGridIndexByDeviceId(newDeviceId);

        // ensure highlighted device row has focus
        var focusedCell = this.gridApi.getFocusedCell();
        if (!focusedCell || focusedCell.rowIndex !== gridIndex) {
          this.gridApi.setFocusedCell(gridIndex, 'deviceName', null);
        }
        this.gridApi.ensureIndexVisible(gridIndex);
      }
    },

    // property from parent
    orgDevices: function(devices) {
      this.setGridData(devices);
    },

    selectedOrganization: function(org) {
      this.prepGridForNewData();
    },

    noShowColumns(value) {
      if (this.columnApi != null) {
        this.origNoShows.forEach(col => {
          this.columnApi.setColumnVisible(col, true);
        });
        value.forEach(col => {
          this.columnApi.setColumnVisible(col, false);
        });
        this.origNoShows = [];
        this.origNoShows = [...value];
      } else {
        this.origNoShows = [...value];
      }
    },

    filterActivatedOnly: function() {
      this.externalFilterChanged();
    },

    // https://www.hesselinkwebdesign.nl/2019/nexttick-vs-settimeout-in-vue/
    isEditing: function(value) {
      setTimeout(() => {
        value ? this.disableGrid() : this.enableGrid();
      }, 500);
    },

    newDeviceInfo(value) {
      var params = {
        getBillingPlans: true,
        getCommands: true,
        getSensorPackages: true,
        orgId: thisComponent.selectedOrganization.orgId,
      };
      this.getDevices(params).then(response => {
        this.setGridData(this.deviceListWithDestinationsByOrg);
        this.gridApi.forEachNode(node => {
          if (value.deviceId === node.data.deviceId) {
            node.setSelected(true);
            this.gridApi.ensureIndexVisible(node.rowIndex, 'middle');
          } else {
            node.setSelected(false);
          }
        });
      });
    },
  };

  var components = {
    AgGridVue,
  };

  var hideColumnsBySizeDefault = [
    {
      // remove 'xmitDate'
      maxSize: 320,
      hideIds: ['orgName', 'networkName', 'deviceDescription', 'wmoId', 'billingGroupName', 'commIds', 'deviceId'],
    },
    {
      // remove 'xmitDate'
      maxSize: 500,
      hideIds: ['orgName', 'networkName', 'deviceDescription', 'wmoId', 'billingGroupName', 'commIds'],
    },
    {
      maxSize: 760,
      hideIds: ['orgName', 'networkName', 'xmitDate', 'deviceDescription', 'wmoId', 'billingGroupName'],
    },
    {
      maxSize: 1024,
      // hideIds: [ "orgName",  "networkName",  "xmitDate",  "deviceDescription",  "billingGroupName"    ]
      hideIds: ['networkName', 'xmitDate', 'deviceDescription', 'billingGroupName'],
    },
    {
      maxSize: 1200,
      // hideIds: [ "billingGroupName", "xmitDate" ]
      hideIds: ['networkName', 'xmitDate', 'deviceDescription', 'billingGroupName'],
    },
  ];

  export default {
    name: 'DeviceListGrid',
    data,
    created,
    beforeMount,
    mounted,
    computed,
    methods,
    watch,
    components,
    props,
  };
</script>

/*************************************************************/

<style lang="scss">
  // <label class="highlight-button-container"><input class="highlight-button" name="highlighted" type="checkbox"><span class="highlight-symbol"></label>
  .highlight-button-container {
    display: flex;
  }
  .highlight-button-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .highlight-symbol {
    position: absolute;
    height: 12px;
    width: 12px;
    top: 7px;
    background-color: transparent;
    border-radius: 12px;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    left: 7px;
    border: 1px solid;
  }
  .ag-row.highlighted .highlight-symbol {
    background-color: yellow;
  }
</style>

<style scoped lang="scss">
  .grid-holder {
    /* needs to be separate from grid holder in sensors grid */
    width: 100%;
    flex: 1;
    overflow: hidden;
    height: 100%;
  }

  .device-list-grid-body {
    width: 100%;
  }

  .device-list-grid >>> .ag-overlay {
    pointer-events: auto;
  }

  .device-list-grid >>> .ag-body-viewport-wrapper.ag-layout-normal {
    overflow-x: scroll;
    overflow-y: scroll;
  }

  // .disabled {
  //   opacity: 0.5;
  //   pointer-events: none;
  // }

  .pg-icon-dash-border {
    border: 1px dashed;
    padding: 0;
    font-size: 1em;
  }

  .popup-window {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;

    .popup-row {
    }
  }
</style>
