<!-- template for the modal component -->
<template id="billing-group-modal">
    <pg-modal :show="show" @close="close" class="">
      <div class="popup-window billing-group-popup">
        <div class="popup-header">
          Billing Groups
          <i class="far fa-times-square closer" v-on:click="close()" />
        </div>
        <div class="popup-content text-padding">
          <div class="grid-container billing-groups-grid-container">
            <ag-grid-vue
              style="width: 100%; "
              class="ag-theme-balham grid-holder"
              :gridOptions="gridOptions"
              :columnDefs="columnDefs"
              :modules="agGridModules"
            >
            </ag-grid-vue>
            <billing-group-confirm-modal
              v-show="showConfirmModal"
              :group="billingGroupToRemove"
              @close="onCloseConfirmModal"
              @confirmed="onUserDeleteConfirmation"
            />
          </div>
        </div>

        <div class="popup-footer popup-button-wrapper">
          <button class="" :class="{'disabled': disableAddBtn}" @click="addEmptyRow()">
            Add Group
          </button>
          <button class="" :class="{'disabled': disableSaveBtn}" @click="saveAll()">
            Save All
          </button>
          <button class="" @click="cancelAll()">
            Cancel
          </button> 
        </div>
      </div>
    </pg-modal>

</template>

<script>
  import PgModal from "@/components/Common/PgModal";
  import { AgGridVue } from '@ag-grid-community/vue';
  import { AllCommunityModules } from '@ag-grid-community/all-modules';
  import environmentSpecific from 'static/environmentSpecific';
  import BillingGroupConfirmModal from "@/components/BillingGroupConfirmModal";

  var thisComponent = null;

  var actionCellRenderer = function(params) {
    var cellDiv = document.createElement('div');
    // cellDiv.classList.add('button-div');
    var deleteIcon = `<span title="Delete row" alt="Delete row" class="far fa-times-circle fa-lg"></span>`;
    var undoIcon = `<span title="Undo changes" alt="Undo changes" class="far fa-undo fa-md"></span>`;

     if (params.context.isEditingRow) {
      cellDiv.innerHTML = undoIcon;
      var undoBtn = cellDiv.querySelectorAll('.fa-undo')[0];
      undoBtn.addEventListener('click', function() {
        var node = params.node;
        params.context.undoRowChange(node);
      });
    } else {
      cellDiv.innerHTML = deleteIcon;
      var deleteBtn = cellDiv.querySelectorAll('.fa-times-circle')[0];
      deleteBtn.addEventListener('click', function() {
        var node = params.node;
        params.context.deleteRow(node);
      });
    }
   
    return cellDiv;
  };


  export default {
    name: "BillingGroupModal",
    props: ["show", "billingGroups", "singleBillingGroup"],
    components:  {PgModal, AgGridVue, BillingGroupConfirmModal},
    data() {
      return { 
        groupName: '',
        groupDescription: '',

        columnDefs: null,
        rowData: null,
        gridOptions: null,
        gridApi: null,
        columnApi: null,
        agGridModules: AllCommunityModules,
        gridWidth: 0,
        isEditingRow: false,
        showConfirmModal: false,
        billingGroupToRemove: {},
        fieldsChanged: [],
        disableAddBtn: false,
        disableSaveBtn: true,
      };
    },

    beforeMount() {
      thisComponent = this;
      this.gridOptions = {
        headerHeight: 25,
        rowData: this.rowData,
        columnDefs: this.columnDefs,
        onGridReady: this.onGridReady,
        onGridSizeChanged: this.onGridSizeChanged,
        onCellValueChanged: this.onCellValueChanged,
        onColumnMoved: this.onColumnMoved,
        defaultColDef: {
          sortable: true,
          resizable: true,
        },
        context: {
          undoRowChange: this.undoRowChange,
          deleteRow: this.deleteRow,
          isEditingRow: this.isEditingRow,
        },
        enableCellTextSelection: true,
        ensureDomOrder: true,
        rowSelection: 'single',
        suppressNoRowsOverlay: true,
        stopEditingWhenGridLosesFocus: true,
        rowHeight: environmentSpecific.gridRowHeightPx,
      };

       this.columnDefs = [
        {
          colId: 'billingGroupName',
          headerName: 'Name',
          field: 'billingGroupName',
          minWidth: 125,
          width: 150,
          maxWidth: 200,
          editable: true,
        },
        {
          colId: 'billingGroupDescription',
          headerName: 'Description',
          field: 'billingGroupDescription',
          minWidth: 150,
          width: 400,
          editable: true,
        },
        {
          colId: 'updateGroup',
          headerName: 'Update Group',
          field: 'updateGroup',
          minWidth: 125,
          width: 125,
          maxWidth: 200,
          cellRenderer: actionCellRenderer,
        }
      ];

    },

    mounted() { },
    
    methods: {
      close: function() {
        this.cancelAll();
      },
      // to be removed
      confirm: function() {
        this.$emit('confirmed');
        this.close();
      },

      addEmptyRow: function() {
        this.disableAddBtn = true;
        var newRow = { billingGroupId: 0 };
        var node = {};
        this.isEditingRow = true;
        this.gridOptions.context.isEditingRow = this.isEditingRow;
        this.gridApi.updateRowData({ add: [newRow] });
        // this.gridApi.applyTransaction({add: [newRow]});
        this.gridApi.forEachNode((rowNode, index) => {
          if (rowNode.lastChild) {
            node = rowNode;  
          }
        });
        this.gridApi.startEditingCell({ rowIndex: node.rowIndex, colKey: 'billingGroupName' });
      },

      saveAll: function() {
        this.$emit('onUpdatingGroup', this.fieldsChanged);
        this.fieldsChanged = [];
        this.isEditingRow = false;
        this.gridOptions.context.isEditingRow = this.isEditingRow;
        this.gridApi.redrawRows();
        this.disableAddBtn = false;
        this.disableSaveBtn = true;
      },

      cancelAll() {
        this.fieldsChanged = [];
        this.isEditingRow = false;
        this.gridOptions.context.isEditingRow = this.isEditingRow;
        this.gridApi.redrawRows();
        this.$emit('close');
      },

      onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
        this.gridApi.setRowData(this.billingGroups);
        this.gridApi.setPopupParent(document.body);
      },

      onCellValueChanged(params) {
        if (params.oldValue !== params.newValue) {
          var node = this.gridApi.getSelectedNodes();
          var row = node[0];
          var newValue = {
            id: params.data.billingGroupId,
            column: params.colDef.field,
            value: params.newValue,
            oldValue: params.oldValue
          };
          this.fieldsChanged.push(newValue);
          this.isEditingRow = true;
          this.gridOptions.context.isEditingRow = this.isEditingRow;
          this.gridApi.redrawRows({rowNodes: [row]});
        }
        this.disableSaveBtn = false;
      },

      undoRowChange(rowNode) {
        this.isEditingRow = false;
        this.gridOptions.context.isEditingRow = this.isEditingRow;

        var undoId = rowNode.data.billingGroupId;
        var undoIdStr = undoId.toString();
        if (undoId =! 0) {
          this.$emit('onUndoChange', undoIdStr);
        } else { // newly added group
          this.gridApi.updateRowData({ remove: [rowNode.data] });
          this.disableAddBtn = false;
        }        
        var index = this.fieldsChanged.findIndex(f => f.id === rowNode.data.billingGroupId);
        if (index > -1) {
          this.fieldsChanged.splice(index, 1);
        }
      },

      updateRow(value) {
        this.isEditingRow = false;
        this.gridOptions.context.isEditingRow = this.isEditingRow;
        var node = this.gridApi.getSelectedNodes();
        node[0].setData(value);
        this.gridApi.redrawRows({ rowNodes: node });
      },
      
      deleteRow: function(node) {
        this.showConfirmModal = true;
        this.billingGroupToRemove = node.data;
      },

      onCloseConfirmModal: function() {
        this.showConfirmModal = false;
      }, 
      
      onUserDeleteConfirmation: function() {
        this.showConfirmModal = false;
        this.$emit('onDeleteGroup', this.billingGroupToRemove)
      },

    },
    watch: {
      billingGroups: function(value) {
        this.gridApi.setRowData(this.billingGroups);
      },
      singleBillingGroup: function(value) {
        this.updateRow(value);
      }

    },
  };
</script>


/*************************************************************/

<style lang="scss">

</style>
