import Vue from 'vue';
export default Vue.extend({

    template: `<span>{{ params.value }} </span>`,
    data() {
        return {
            dataField: null,
        }
    },
    created() {
        // console.log(this.params)
        // this.dataField = this.params.sensorDataFields.find(_ => _.dataFieldId === this.params.node.data.dataFieldId);
     },
    beforeMount() { },
    mounted() { },

    methods: { },
    components: {},

});

