<template>
  <div class="dashboard-notifications pg-component">
    <div class="component-title">
      <div>DEVICE NOTIFICATIONS</div>
    </div>
    <div class="notifications-wrapper" v-if="isPgStaff">
      <div>
        <div class="section-divider battery-section" v-show="lowBatteryDevices.length > 0">
          <i class="far fa-battery-bolt"></i>
          <span>Low Battery Warning</span>
        </div>
        <div class="notification-container">
          <ul>
            <li v-for="(device, index) in lowBatteryDevices" :key="index">
              {{device.deviceName}} is at {{device.battVoltage}} volts
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div
          class="section-divider transmitting-section"
          v-show="notTransmitting.length > 0"
          :class="{ 'lower-section' : lowBatteryDevices.length > 0 }"
        >
          <i class="far fa-power-off"></i>
          <span>Activated but not transmitting</span>
        </div>
        <div class="notification-container">
          <ul>
            <li v-for="(device, index) in notTransmitting" :key="index">
              {{device.name}}: {{device.daysAgo}} days
              <span v-if="userHasClaim('Activations', 'update')">
                <span v-if="!device.activationPending" :class="{ 'span-link' : !device.activationPending }" @click="showModal(device)"
                  >Deactivate</span
                >
                <span v-else><em>Deactivation Pending</em></span>
              </span>
            </li>
          </ul>
        </div>
        <p v-if="errorMessage.length > 0">{{errorMessage}}</p>
      </div>
    </div>
    <activation-confirm-modal
      v-show="showConfirmModal"
      @close="closeModal"
      @confirmed="onUserConfirmation"
      :action="action"
      :deviceNames="deviceNames"
      :billingInfo="billingInfo"
    />
  </div>
</template>

/*************************************************************/

<script>
  import { mapGetters, mapState, mapActions } from 'vuex';
  import pgUtils from '@/utils/PgUtils.js';
  import ActivationConfirmModal from '@/components/ActivationConfirmModal';

  var data = function() {
    return {
      msg: '',
      notTransmitting: [],
      lowBatteryDevices: [],
      showConfirmModal: false,
      action: '',
      deviceNames: [],
      billingInfo: {},
      deviceToDeactivate: {},
      errorMessage: '',
      isPgStaff: false,
    };
  };

  var props = ['orgDevices', 'orgActivations'];

  var beforeMount = function() {};

  var mounted = function() {
    this.isPgStaff = this.userHasRole('PGStaff');
  };

  var methods = {
    getLowBatteryInfo(devices) {
      this.lowBatteryDevices = [];
      devices.forEach(device => {
        // if (device.active && device.battVoltage <=11.5) {
        if (device.battVoltage < 11.5) {
          var isInt = Number.isInteger(device.battVoltage);
          var volt = isInt ? device.battVoltage.toString() + '.0' : device.battVoltage.toString();
          this.lowBatteryDevices.push({
            deviceName: device.deviceName,
            battVoltage: volt,
          });
        }
      });
    },
    getDevicesNotTransmitting(devices) {
      var today = this.moment.utc();
      var maxDaysSinceTransmit = 2;
      var numDays = null;
      var transmitDate = '';
      var pending = null;
      var notTransmittingIds = [];
      var devicesToTrack = [];
      devices.forEach(device => {
        // looking for devices that are activated, but haven't transmitted for a while
        if (device.activationStatus === 'Activated' && device.serviceTypeCode === 'full') {
          transmitDate = this.moment.utc(device.lastTransmission);
          numDays = today.diff(transmitDate, 'days');
          if (numDays > maxDaysSinceTransmit) {
            notTransmittingIds.push(device.deviceId);
            devicesToTrack.push({ deviceId: device.deviceId, deviceName: device.deviceName, numDays: numDays });
          }
        }
      });
      var deviceIds = notTransmittingIds.join(',');
      devicesToTrack.sort(function(a, b) {
        return b.numDays - a.numDays;
      });
      if (this.orgActivations) {
        this.notTransmitting = [];
        devicesToTrack.forEach(d => {
          var status = this.orgActivations.filter(dev => dev.deviceId === d.deviceId);
          pending = status[0] && status[0].status === 'Pending';
          this.notTransmitting.push({
            name: d.deviceName,
            id: d.deviceId,
            daysAgo: d.numDays,
            activationPending: pending,
          });
        });
      }
    },
    showModal(device) {
      this.deviceToDeactivate = device;
      this.action = 'Deactivate';
      this.deviceNames = [device.name];
      this.billingInfo.chargeForUpdate = false;
      this.showConfirmModal = true;
    },

    onUserConfirmation() {
      this.deactivateDevice(this.deviceToDeactivate);
    },

    closeModal() {
      this.showConfirmModal = false;
    },

    deactivateDevice(device) {
      var nowUtc = pgUtils.formatDate(this.moment().format(), 'ISO');
      var dev = this.deviceList.filter(d => d.deviceId === device.id);
      var body = {
        deviceActivationLogId: 0,
        deviceId: dev[0].deviceId,
        commIdentifier: dev[0].comm1, // remove
        networkId: dev[0].networkId, // remove
        orgId: dev[0].orgId,
        // dateEntered: nowUtc, // remove
        actionDateUtc: nowUtc, // optional
        // actionDate: nowLocal, // remove
        // whenCompleted: nowUtc, // remove
        // updateDate: nowUtc, // remove
        whoEntered: this.$pgGlobal.currentUser.userName,
        action: 'Deactivate',
        billingPriceId: dev[0].defaultBillingPriceId,
        billingPlan: dev[0].pgPlanName, // remove
        status: 'Pending',
      };
      this.createDeviceActivationLog(body)
        .then(response => {
          console.log(response.data.data);
          // if (response.status === 200) {
          if (response.data.data.status === 'Pending') {
            this.notTransmitting.forEach(d => {
              if (d.id === response.data.data.deviceId) {
                d.activationPending = true;
              }
            });
          }
        })
        .catch(error => {
          this.errorMessage = `There was a problem completing your request to deactivate ${dev[0].deviceName}.`;
        });
    },
    ...mapActions('deviceModule', ['getDeviceActivationStatusByDeviceIds', 'createDeviceActivationLog']),
  };

  var computed = {
    ...mapState('organizationModule', ['selectedOrganization']),
    ...mapState('deviceModule', ['deviceList', 'deviceActivations']),
    ...mapGetters('userModule', ['userHasClaim', 'userHasRole']),
  };

  var watch = {
    // deviceList: function(value) {
    //   this.getDevicesNotTransmitting(value);
    //   this.getLowBatteryInfo(value);
    // },
    selectedOrganization(value) {
      this.notTransmitting = [];
      this.lowBatteryDevices = [];
    },
    orgDevices: function(data) {
      this.getDevicesNotTransmitting(data);
      this.getLowBatteryInfo(data);
    },
  };

  var components = {
    ActivationConfirmModal,
  };

  export default {
    name: 'DashboardNotifications',
    data,
    props,
    beforeMount,
    mounted,
    methods,
    computed,
    watch,
    components,
  };
</script>

/*************************************************************/

<style scoped lang="scss">
  .dashboard-notifications {
    overflow-y: auto;
    overflow-x: hidden;

    .notification-container {
      padding: 5px;
    }
    ul {
      overflow-y: auto;
      overflow-x: hidden;
      list-style-type: none;
      margin: 0;
      padding: 0 0 0 2px;
    }

    li span.span-link {
      text-decoration: underline;
      cursor: pointer;
    }

    .lower-section {
      margin-top: 15px;
    }
  }
</style>
